import { createSlice } from "@reduxjs/toolkit";

const candidacySlice = createSlice({
  name: "myCandidacy",
  initialState: {
    status:'',
    updateCandidacy: [],
    filteredData: [],
    totalCandidacy: {
      tFavoris: 0,
      tMessage: 0,
      tArhiver: 0,
    },
  },
  reducers: {
    addCandidacy(state, action) {
      state.updateCandidacy = action.payload.allCandidacy;
      state.filteredData = action.payload.allCandidacy;
      state.status = action.payload.status
      // state.status = 'error'
    },
    handleQuantity(state) {
      //   state.totalCandidacy.tFavoris = state.updateCandidacy.reduce(
      //     (total = 0, candidat) => {
      //       if (candidat.situation === "favoris") {
      //         return total+1;
      //       } else {
      //         return total;
      //       }
      //     }
      //   );
      //   state.totalCandidacy.tArhiver= state.updateCandidacy.reduce(
      //     (total = 0, candidat) => {
      //       if (candidat.situation === "archiver") {
      //         return  total+1;
      //       } else {
      //         return total;
      //       }
      //     }
      //   );
      //   state.totalCandidacy.tMessage = state.updateCandidacy.reduce(
      //     ( total=1, candidat) => {
      //        let result
      //       if (candidat.situation === "message") {
      //            result =Number(total)
      //       return result
      //       }
      //      else{
      //         return total
      //      }
      //     }
      //   );
      //   console.log(state.totalCandidacy.tMessage )
    },

    filterByCategorie(state, action) {
      state.filteredData = state.candidacy;
      if (
        action.payload.categorie.name.toLowerCase() === "tous" ||
        !action.payload.categorie.name.toLowerCase()
      ) {
        state.filteredData = state.updateCandidacy;
      } else {
        state.filteredData = state.updateCandidacy.filter(
          (candidacy) =>
            candidacy?.situation.toLowerCase() ===
              action.payload.categorie.name.toLowerCase() ||
            candidacy.etat.toLowerCase() ===
              action.payload.categorie.name.toLowerCase()
        );
      }
    },
    // favoris
    moveToFavoris(state, action) {
      const candidacy = action.payload.candidacy;

      const candidacyIndex = state.filteredData.findIndex(
        (candidat) => candidat.idcandidacy === candidacy.idcandidacy
      );
      //request
      state.filteredData[candidacyIndex].situation = "favoris";
    },
    moveToMessage(state, action) {
      const candidacy = action.payload.candidacy;

      const candidacyIndex = state.filteredData.findIndex(
        (candidat) => candidat.idcandidacy === candidacy.idcandidacy
      );
      state.filteredData[candidacyIndex].situation = "message";
    },
    moveToArchive(state, action) {
      const candidacy = action.payload.candidacy;

      const candidacyIndex = state.filteredData.findIndex(
        (candidat) => candidat.idcandidacy === candidacy.idcandidacy
      );
      state.filteredData[candidacyIndex].situation = "archiver";
    },
    removeCandidacy(state, action) {
      const candidacy = action.payload.candidacy;
      state.filteredData = state.filteredData.filter(
        (candidat) => candidat.idcandidacy !== candidacy.idcandidacy
      );
      // console.log(candidacy);
    },
    // search
    searchCandidacy(state, action) {
      state.filteredData = state.updateCandidacy.filter((candidacy) =>
        candidacy?.jobName
          .toLowerCase()
          .includes(action.payload.searchText.toLowerCase())
      );
    },
  },
});
export const candidacyActions = candidacySlice.actions;
export default candidacySlice;
