import React from 'react'
import { FaPen, FaTrash } from 'react-icons/fa'

function SkillItem({NomCompetence})
{
    return(
        <div className="relative flex justify-between p-4 mb-4 bg-white rounded-md shadow-sm cursor-pointer hover:bg-slate-200">
             <div>
               <label className='text-lg font-semibold text-gray-800'>{NomCompetence}</label>
             </div>
             <div className=''>
               <button className="justify-end bg-transparent border-none focus:outline-none" >
                  <FaPen className='w-5 h-5 text-gray-500 hover:text-[#457AA8]'/>
               </button>
               <button>
                <FaTrash className='w-5 h-5 text-gray-500 hover:text-[#457AA8]'/>
               </button>
             </div>
        </div>
    )
}

export default SkillItem ;