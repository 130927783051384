import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const toastUtils = (status, text) => {
  const toastDuration = 5000;

  switch (status) {
    case "success":
      return toast.success(text, {
        position: "bottom-right",
        autoClose: toastDuration,
        bodyClassName: "font-bold",
        className: "w-auto",
        toastId: "success-toast",
      });
    case "error":
      return toast.error(text, {
        position: "bottom-right",
        autoClose: toastDuration,
        bodyClassName: "font-bold",
        className: "w-auto",
        toastId: "error-toast",
      });
    case "warning":
      return toast.warn(text, {
        position: "bottom-right",
        autoClose: toastDuration,
        bodyClassName: "font-bold",
        className: "w-auto",
        toastId: "error-toast",
      });

    default:
      break;
  }
};
export default toastUtils;
