import React, { createContext, useEffect, useState } from "react";
import { getJob } from "../services/HttpRequest/jobRequest/jobRequest";
import { useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { jobsSliceAction } from "../store/slice/jobsSlice";
import  { jobsSliceAdminAction } from "../store/slice/jobsSliceAdmin";

// context fpr job
const JobContext = createContext({
  allJobs: [],
  activeJob: {},
  statusJob: "",
  error: {},
  handleAddActiveJob: (activeJob) => {},
  handleFilterJob: (catId) => {},
  handleSearchText: (text) => {},
  handleJobForCatId: (id) => {},
  numberOfJobs: 0,
});
//  make a job active a job
const handleActiveJob = () => {
  const getActiveJob = localStorage.getItem("activeJob");

  const job = JSON.parse(getActiveJob);

  if (job) {
    return job;
  }
  return {};
};

export const JobContextProvider = ({ children }) => {

const dispatch = useDispatch()


  const [activeJob, setActiveJob] = useState({});
  const [allJobs, setAllJob] = useState([]);
  const [catId, setCatId] = useState(null);
  // get cat query
  const { data, status, error } = useQuery({
    queryKey: ["getJob"],
    queryFn: getJob,
    staleTime:Infinity
  });

  // handle search job

  const handleSearchText = (text) => {
    let searchedJob;
    if (text && text !== "") {
      searchedJob = data?.data.filter((job) =>
        job?.jobName.toLowerCase().includes(text.toLowerCase())
      );
    } else {
      searchedJob = data?.data;
    }

    setAllJob(searchedJob);
  };

//--------------action for reducer-------------

  useEffect(() => {
    dispatch(jobsSliceAction.fetchAllJobs({status,data,error}))
    dispatch(jobsSliceAdminAction.fetchAllJobs({status,data,error}))
  }, [data, status,dispatch,error]);

  //handle Job By cat

  const handleFilterJob = (id) => {
    let ConvertId = parseInt(id);
    if (id === "All") {
      ConvertId = null;
    }
    setCatId(ConvertId);
  };
  // get a table of job for a given id
  const handleJobForCatId = (id) => {
    if (status === "success") {
      if (id) {
        return data.data.filter((job) => job.categoryId === id);
      }
      return data.data;
    }
  };

  //setactive job in local storage

  const handleAddActiveJob = (activeJob) => {
    localStorage.setItem("activeJob", JSON.stringify(activeJob));
    setActiveJob(handleActiveJob());
  };
  // jobNumber
  const numberOfJobs = allJobs.length;
  // value to provide
  const values = {
    error,
    statusJob: status,
    allJobs,
    activeJob,
    handleAddActiveJob,
    numberOfJobs,
    handleSearchText,
    handleFilterJob,
    handleJobForCatId,
  };

  return <JobContext.Provider value={values}>{children}</JobContext.Provider>;
};
export default JobContext;
