import {
  ALL_JOB_URL,
  CREATE_JOB_FORM_COMPANY,
  UPDATE_JOB_URL,
} from "../../api";
import axios, { axiosUseToken } from "../../axiosConfig";

export const getJob = async () => {
  const response = await axios.get(ALL_JOB_URL);
  // console.log("je suis tous les job", response);
  return response;
};

export const createJob = async ({ data }) => {
  // console.log('ceated job',data)


    const response = await axiosUseToken.post(CREATE_JOB_FORM_COMPANY, data);
    // console.log('responseins', response)
    return response;

};
export const updateJob = async ({ data, id }) => {
 const response = await axiosUseToken.put(UPDATE_JOB_URL + id, data);
    return response;
   
  // console.log('responseupd', response)
};
