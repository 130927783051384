import { createContext } from "react";

 const AddJobContext = createContext({
    formContent: {
      jobName: { value: "", didEdit: false },
      salary: { value: 0, didEdit: false },
      salaryRate: { value: "", didEdit: false },
      description: { value: "", didEdit: false },
      summary: { value: "", didEdit: false },
      qualifications: { value: "", didEdit: false },
      currency: { value: "", didEdit: false },
      requiredHeadCount: { value: "0", didEdit: false },
      employmentStatus: { value: "", didEdit: false },
      advantages: { value: "", didEdit: false },
      period: { value: "", didEdit: false },
      other: { value: "", didEdit: false },
      applicationDeadLine: { value: "", didEdit: false },
      isRegistered: { value: true, didEdit: false },
      companyId: { value: "", didEdit: false },
      categoryId: { value: "", didEdit: false },
      periodMinTime: { value: "", didEdit: false },
      periodMaxTime: { value: "", didEdit: false },
      periodPayment: { value: "", didEdit: false },
      periodDu: { value: "", didEdit: false },
      periodAu: { value: "", didEdit: false },
      id: { value: 0.1, didEdit: false },
    },
    textAreaState: {
      description: { stringValue: "", table: [] },
      qualifications: { stringValue: "", table: [] },
      summary: { stringValue: "", table: [] },
      advantages: { stringValue: "", table: [] },
      other: { stringValue: "", table: [] },
    },
    validEachInput: {
      isNotAvantages: false,
      isNotApplicationDeadline: false,
      isNotCategory: false,
      isNotCompanyId: false,
      isNotCurrency: false,
      isNotDescription: false,
      isNotEmploymentStatus: false,
      isNotJobName: false,
      isNotOther: false,
      isNotSalary: false,
      isNotRequiredHeadCount: false,
      isNotSalaryRate: false,
      isNotSummary: false,
      isNotQualifications: false,
    },
    validStepOk: {
      isFirstStepOk: false,
      isSecondStepOk: false,
      isThirdStepOk: false,
      isFourStepOk: false,
      isFiveStepOk: false,
    },
    step: 1,
    isEditing: false,
    jobId: "",
    employementStatusEdit:[],
    handleBlurField: (id) => {},
    handleChangeField: (id, value) => {},
    HandleTextareaDispatch: (id, type, value) => {},
    initDiDedit: (value) => {},
    handleStep: (step, isStepValid = true) => {},
    HandleDataToEdit: (dataToEdit,isEditing,allCats,allComps) => {},
    handleEmployementStatus:(table)=>{}
  });
  

  export default  AddJobContext