import React, { useEffect, useContext } from "react";
import CategoryTable from "./CatTable/CatTable";
import PendingUI from "../../../UI/RequestUI/PendingUI";
import LoadDataFailUI from "../../../UI/RequestUI/LoadDataFailUI";
import CatContext from "../../../../context/CatContext";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../../../../services/HttpRequest/queryClientProvider/queryClientProvider";
import { deleteCategory } from "../../../../services/HttpRequest/catRequest/catRequest";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { useTranslation, Trans } from "react-i18next";

const CatManage = () => {
  const { t } = useTranslation();

  const cat = useSelector((state) => state.categoryAdmin.allCats);
  const statusCat = useSelector((state) => state.categoryAdmin.statusCat);

  const { mutate, status } = useMutation({
    mutationFn: deleteCategory,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["getCategories"] });
    },
  });

  useEffect(() => {
    if (status === "success") {
      toast.success(t("catManage.success"), {
        position: "top-center",
        autoClose: 5000,
        bodyClassName: "font-bold",
        className: "w-[200%]",
        toastId: "success-toast",
      });
    }
    if (status === "error") {
      toast.error(t("catManage.error"), {
        position: "top-center",
        autoClose: 5000,
        bodyClassName: "font-bold",
        className: "w-[200%]",
        toastId: "error-toast",
      });
    }
  }, [status]);

  const handleDelete = (cat) => {
    mutate({ data: cat.id });
  };

  const handleEdit = (cat, editedJob) => {
    // console.log("Edited cat:", editedJob);
  };

  const handleBlock = (user) => {
    // console.log("Block user:", user);
  };

  let catContent;

  switch (statusCat) {
    case "pending":
      catContent = <PendingUI />;
      break;
    case "error":
      catContent = <LoadDataFailUI />;
      break;
    case "success":
      catContent = (
        <CategoryTable
          data={cat}
          onDelete={handleDelete}
          onEdit={handleEdit}
          onBlock={handleBlock}
        />
      );
      break;
    default:
      break;
  }

  // traduction de texte de ce composant
  // Gestion des Categories - Category management

  return (
    <div className="container mx-auto mt-8">
      <ToastContainer />
      {/* <h1 className="mb-4 text-3xl font-bold">Gestion des Catégories</h1> */}
      <h1 className="mb-4 text-3xl font-bold text-[#457AA8]">{t("catManage.title")}</h1>
      {catContent}
    </div>
  );
};

export default CatManage;
