import { Box } from "@mui/material";

const SelectUI = ({
  id,
  label,
  classname,
  isRequired,
  placeholderValue,
  data,
  ...props
}) => {
  const classe = ` text-sm p-2  border-gray-300 hover:border-blue-500 focus:border-gray-300 focus:ring focus:ring-blue-500 mt-1 p-1 border rounded-md w-[94.99%] focus:outline-none ${classname} `;

  const DataField = data?.map((tData) => (
    <option key={tData.id} value={tData.nom} className="lg">
      {tData.nom}
    </option>
  ));
  return (
    <Box className="space-x-2 max-lg:mt-2 max-lg:ml-1  lg:m-1  text-xl mb-4 w-full  ">
      <div >
        <label htmlFor={id} className="mb-1 block text-sm font-medium text-gray-700">
          {label}
          {isRequired ? (
            <span className="text-red-600">*</span>
          ) : (
            <span className=""></span>
          )}
        </label>

        <select name={id} id={id} {...props} className={classe}>
          <option value={""}>---</option>
          {DataField}
        </select>
      </div>
    </Box>
  );
};

export default SelectUI;
