import React from "react";
import jobFooter from "../../../assets/jobFooter.png";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const JobFooter = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/Contact");
  };
  return (
    <section
      className="w-full items-center py-10 mx-auto mb-10 shadow-md  flex  justify-center rounded-xl "
      style={{
        backgroundImage: `linear-gradient(to right, rgba(176, 63, 4, 0.5), rgba(176, 63, 4, 0.5)) , url(${jobFooter})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
      data-aos="fade-up"
    >
      <div className=" flex items-center">
        <div className="p-2 mx-auto">
          <div className="p-4">
            <h1 className="text-[42px] [font-family:'Inter-Bold',Helvetica] font-bold text-white text-center w-full">
              {t("jobFooter.title")}
            </h1>

            <div className=" font-medium flex justify-center ">
              <p className="pt-5  w-[80%]  text-white ">
                {t("jobFooter.content")}
              </p>
            </div>

            <div className="pt-8 flex justify-center">
              <button
                className="px-2 py-2 [font-family:'Inter-Bold',Helvetica] font-bold  text-[#EA580C] hover:border-2 border-[#EA580C] hover:text-white hover:border-white duration-500 bg-white rounded-full btn md:ml-2 md:static shadow-[4px_7px_4.7px_-6px_#00000040]  hover:bg-indigo-500 text-center"
                onClick={handleClick}
              >
                {t("jobFooter.button")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default JobFooter;
