import { Button } from "@material-tailwind/react";
import { useContext } from "react";
import { FaArrowCircleLeft, FaArrowCircleRight, FaSave } from "react-icons/fa";
import SelectUI from "../../UI/SelectUI";
import { heures, paymentRateTable, semaine } from "./TableDate";
import AddJobContext from "../../../context/AddJobContext/context";
import { useTranslation, Trans } from "react-i18next";

const StepTree = () => {
  const { t } = useTranslation();
  const {
    handleChangeField,
    formContent,
    handleBlurField,
    handleStep,
    validStepOk,
    isEditing,
  } = useContext(AddJobContext);
  const didEditPeriod =
    formContent.periodMinTime.didEdit &&
    formContent.periodMaxTime.didEdit &&
    formContent.periodDu.didEdit &&
    formContent.periodAu.didEdit;

  return (
    <>
      <div className="grid   gap-4">
        <fieldset className="border-2 rounded-lg p-3">
          <legend className=" ml-3 block text-sm font-medium text-center text-gray-700">
            {t("stepThree.subtitle")}
          </legend>
          <div className="grid grid-cols-2  max-md:grid-cols-1  ">
            <SelectUI
              value={formContent.periodMinTime.value}
              data={heures}
              label={t("stepThree.label1")}
              id={"periodMinTime"}
              isRequired
              onChange={(event) =>
                handleChangeField("periodMinTime", event.target.value)
              }
              onBlur={() => handleBlurField("periodMinTime")}
            />
            <SelectUI
              value={formContent.periodMaxTime.value}
              data={heures}
              label={t("stepThree.label2")}
              id={"periodMaxTime"}
              onChange={(event) =>
                handleChangeField("periodMaxTime", event.target.value)
              }
              onBlur={() => handleBlurField("periodMaxTime")}
            />
            <SelectUI
              value={formContent.periodPayment.value}
              data={paymentRateTable}
              label={t("stepThree.label3")}
              id={"periodPayment"}
              onChange={(event) =>
                handleChangeField("periodPayment", event.target.value)
              }
              onBlur={() => handleBlurField("periodPayment")}
            />
            <SelectUI
              value={formContent.periodDu.value}
              data={semaine}
              label={t("stepThree.label4")}
              id={"periodDu"}
              onChange={(event) =>
                handleChangeField("periodDu", event.target.value)
              }
              onBlur={() => handleBlurField("periodDu")}
            />
            <SelectUI
              value={formContent.periodAu.value}
              data={semaine}
              label={t("stepThree.label5")}
              id={"periodAu"}
              onChange={(event) =>
                handleChangeField("periodAu", event.target.value)
              }
              onBlur={() => handleBlurField("periodAu")}
            />
          </div>
        </fieldset>
        {!validStepOk.isThirdStepOk && didEditPeriod && (
          <h3 className="text-red-500 text-center">{t("stepThree.error")}</h3>
        )}{" "}
      </div>
      <div className="grid xl:grid-cols-2 gap-4"></div>
      <div className="flex w-full justify-evenly">
        <Button
          onClick={() => handleStep(2)}
          variant="filled"
          color={"blue"}
          className={`flex justify-center bg-opacity-85 w-auto h-12 items-center ml-4 hover:bg-opacity-70  `}
        >
          <span className="justify-end text-md">
            <FaArrowCircleLeft className=" size-4" />
          </span>
          <span className="justify-start mx-1 max-md:hidden">
            <div></div>
            {t("stepThree.back")}
          </span>
        </Button>
        {isEditing && (
          <Button
            type="submit"
            variant="filled"
            color={"green"}
            className={`flex justify-center bg-opacity-85 w-auto h-12 items-center ml-4 hover:bg-opacity-70  `}
          >
            <span className="justify-start mx-1 max-md:hidden ">
              <div></div>
              {t("stepThree.term")}
            </span>
            <span className="justify-end text-md">
              <FaSave className=" size-4" />
            </span>
          </Button>
        )}
        <Button
          onClick={() => handleStep(4, validStepOk.isThirdStepOk)}
          variant="filled"
          color={"blue"}
          className={`flex justify-center bg-opacity-85 w-auto h-12 items-center ml-4 hover:bg-opacity-70  `}
        >
          <span className="justify-start mx-1 max-md:hidden">
            <div></div>
            {t("stepThree.next")}
          </span>
          <span className="justify-end text-md">
            <FaArrowCircleRight className=" size-4" />
          </span>
        </Button>
      </div>
    </>
  );
};
export default StepTree;
