import React, { useMemo, useState, useEffect } from "react";
import { useTable } from "react-table";
import Pagination from "../../../Pagination/Pagination";

const formatDate = (dateString) => {
  return new Date(dateString).toISOString().split("T")[0];
};

const NewsletterTable = ({ data }) => {
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (Array.isArray(data)) {
      const formattedData = data.map(item => ({
        ...item,
        creationDate: formatDate(item.creationDate)
      }));
      setFilteredData(formattedData);
    }
  }, [data]);

  const pageCount = useMemo(() => Math.ceil(filteredData.length / itemsPerPage), [filteredData]);

  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredData.slice(startIndex, endIndex);
  }, [currentPage, filteredData]);

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Creation Date",
        accessor: "creationDate",
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: paginatedData,
    });

  const onPageChanges = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="overflow-x-auto">
      {filteredData.length > 0 ? (
        <table
          {...getTableProps()}
          className="w-full mt-4 border border-collapse border-[#457AA8] min-w-max"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    className="p-2 bg-[#457AA8] border border-[#457AA8] text-white"
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className="hover:bg-gray-200 bg-white border-[#457AA8] cursor-pointer"
                >
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      className="p-2 text-center border border-[#457AA8] text-black"
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div className="flex items-center flex-col space-y-4 w-full text-center text-gray-600 my-8">
          <p className="text-2xl">No Data Available</p>
        </div>
      )}

      <Pagination
        currentPage={currentPage}
        totalPages={pageCount}
        onPageChange={onPageChanges}
      />
    </div>
  );
};

export default NewsletterTable;
