import { USER_GET_ALL_MY_APPLICATION } from "../../api";
import { axiosUseToken } from "../../axiosConfig";

const getAllMyApplication = async (userId, signal) => {
  const response = await axiosUseToken.get(USER_GET_ALL_MY_APPLICATION, {
    signal,
  });

  return response;
};
export default getAllMyApplication;
