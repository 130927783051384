import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";

const ModalUI = ({ open, autoclose, children, classname }) => {
  const dialog = useRef();

  useEffect(() => {
    if (open) {
      dialog.current.showModal();
      return;
    }

    return dialog.current.close();
  }, [open]);

  const classes = `my-auto  border-none rounded-2xl outline-2  ${classname}`;

  const modalVariants = {
    hidden: { opacity: 0, y: -30 },
    showModal: { opacity: 1, y: 0 },
    hide: { opacity: 0, y: 30 },
  };

  return createPortal(
    <AnimatePresence>
      <motion.dialog
        ref={dialog}
        variants={modalVariants}
        initial="hidden"
        animate={open ? "showModal" : "hidden"}
        exit="hide"
        transition={{ duration: 0.36 }}
        onClose={autoclose}
        className={classes}
      >
        {children}
      </motion.dialog>
    </AnimatePresence>,
    document.getElementById("root")
  );
};

export default ModalUI;
