import React from "react";
import SectionOne from "../components/Students/SectionOne";
import SectionTwo from "../components/Students/SectionTwo";
import SectionTree from "../components/Students/SectionTree";

const StudentsPage = () => {
  return (
    <main className="min-h-svh h-full">
      <SectionOne />
      <SectionTwo />
      <SectionTree/>
    </main>
  );
};

export default StudentsPage;
