import React, { useState } from "react";
import ResetPwdModal from "../ResetPwdModal/ResetPwdModal";
import ComfirmResetPWModal from "../ConfirmResetPwd/ConfirmResetPwd";

const ModalRPWD = ({ onClose }) => {
  const [showResetPwdModal, setShowResetPwdModal] = useState(true);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [email, setEmail] = useState("");

  const handleSuccess = (email) => {
    setShowResetPwdModal(false);
    setShowConfirmModal(true);
    setEmail(email);
  };

  const handleClose = () => {
    setShowResetPwdModal(false);
    setShowConfirmModal(false);
    onClose(); 
  };

  return (
    <div>
      {showResetPwdModal && (
        <ResetPwdModal onSuccess={handleSuccess} onClose={handleClose} />
      )}
      {showConfirmModal && (
        <ComfirmResetPWModal MonEmail={email} onClose={handleClose} />
      )}
    </div>
  );
};

export default ModalRPWD;
