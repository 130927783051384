import { RefreshCcw } from "react-feather";
import { FaArrowAltCircleRight, FaHome, FaTools } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const MaintenanceError = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/");
  };
  return (
    // <div className="w-full">
    <div className="flex justify-center ">
      <div className="w-auto p-6 my-4 text-center bg-blue-300 border border-collapse border-gray-600 rounded-lg ">
        <p className=" font-[600] text-4xl border-b-2 border-gray-600/50 pb-2 uppercase font-mono">Page en cours de maintenance</p>
        <FaTools size={70} className="mx-auto my-2 text-blue-800 animate-pulse "/> 
        <p className="w-full mx-auto my-5 text-2xl">  Cette page est actuellement indisponible pour des raisons de maintenance</p>
        <p className="w-full mx-auto my-5 text-2xl hover:cursor-pointer hover:text-green-50" onClick={handleClick}>  Cliquer ici <FaHome size={50} className="mx-auto my-2 text-green-800 transition-all duration-500 ease-in-out hover:text-green-700 hover:scale-150" />   pour retourner vers la page  d'acceuil</p>

      {/* <Link to='/'>  <p className="text-xl"> </p></Link> */}
        {/* <Link to='/error' className="justify-center flex m-4 text-[#5190B7] hover:text-blue-400 "><RefreshCcw className="" size={100}/></Link> */}
        <p className=" font-[600] text-4xl  py-2"> </p>

      </div>
    {/* </div> */}
    </div>
  );
};

export default MaintenanceError;
