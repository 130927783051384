// axiosConfig.js
import axios from "axios";
import dayjs from "dayjs";
import { REFRESH_TOKEN_URL } from "./api";
import { redirect } from "react-router-dom";

const baseURL = process.env.REACT_APP_BASE_URL;
// console.log("base usrl",baseURL );
// const baseURL = "https://api.laproueinc.com";

const instance = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

export default instance;

//post request whithout token

const getAcess = () => {
  const userAcess = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;
  return userAcess;
};
const userAcess = getAcess();

// method to get infos from userAcess
// const accessToken = userAcess?.accessToken;
// const refreshToken = userAcess?.refreshToken;
// const expiresIn = userAcess?.expiresIn;

// instance pour les requetes necessitant un refresh token

export const axiosUseToken = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${userAcess?.accessToken}`,
  },
});

// refresh token request

axiosUseToken.interceptors.request.use(async (req) => {
  const userAcess = getAcess();
  if (!userAcess) {
    localStorage.removeItem("persist:root");
    return;
  }
  req.headers.Authorization = `Bearer ${userAcess?.accessToken}`;

  const expiresIn = userAcess.expiresIn;
  const isTokenExpired = dayjs.unix(expiresIn).diff(dayjs()) < 1;

  if (!isTokenExpired) {
    return req;
  }

  const { data } = await axios.post(baseURL + REFRESH_TOKEN_URL, {
    refreshToken: userAcess.refreshToken,
  });
  // console.log('data', data)
  if (!data.accessToken) {
    localStorage.removeItem("user");
    localStorage.removeItem("persist:root");
    return req;
  }

  const user = {
    accessToken: data.accessToken,
    refreshToken: data.refreshToken,
    expiresIn: data.expiresIn + dayjs().unix(),
  };

  localStorage.setItem("user", JSON.stringify(user));
  req.headers.Authorization = `Bearer ${data.accessToken}`;
  return req;
});
