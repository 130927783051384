// table salary

export const salaryRateTable = [
  { nom: "heure", id: 0 },
  { nom: "jour", id: 1 },
  { nom: "semaine", id: 2 },
  { nom: "mois", id: 3 },
  { nom: "ans", id: 4 },
];
export const paymentRateTable = [
  { nom: "jour", id: 1 },
  { nom: "semaine", id: 2 },
  { nom: "mois", id: 3 },
  { nom: "ans", id: 4 },
];

export const heures = [
  { id: 1, nom: "1h" },
  { id: 2, nom: "2h" },
  { id: 3, nom: "3h" },
  { id: 4, nom: "4h" },
  { id: 5, nom: "5h" },
  { id: 6, nom: "6h" },
  { id: 7, nom: "7h" },
  { id: 8, nom: "8h" },
  { id: 9, nom: "9h" },
  { id: 10, nom: "10h" },
  { id: 11, nom: "11h" },
  { id: 12, nom: "12h" },
  { id: 13, nom: "13h" },
  { id: 14, nom: "14h" },
  { id: 15, nom: "15h" },
  { id: 16, nom: "16h" },
  { id: 17, nom: "17h" },
  { id: 18, nom: "18h" },
  { id: 19, nom: "19h" },
  { id: 20, nom: "20h" },
  { id: 21, nom: "21h" },
  { id: 22, nom: "22h" },
  { id: 23, nom: "23h" },
  { id: 24, nom: "24h" },
];

export const employementsTypeText = [
  "Poste permanent (Emploi à durée indéterminée)",
  "Travailleur indépendant",
  "Contrat à durée déterminée (CDD)",
  "Contrat temporaire",
  "Contrat saisonnier",
  "Contrat d'intérim",
  "Contrat à temps partiel",
  "Contrat à temps plein",
  "Contrat freelance",
];

export const employmentTypes = [
  {
    id: 1,
    nom: "Poste permanent (Emploi à durée indéterminée)",
    description:
      "Les employés ayant des droits à l'emploi travaillent en vertu d'un contrat de travail à durée indéterminée.",
  },
  {
    id: 2,
    nom: "Travailleur indépendant",
    description:
      "Ceux qui sont véritablement indépendants et travaillent comme entrepreneurs indépendants.",
  },
  {
    id: 3,
    nom: "Contrat à durée déterminée (CDD)",
    description:
      "Les employés sous contrat à durée déterminée ont un emploi pour une période spécifique, généralement fixée à l'avance.",
  },
  {
    id: 4,
    nom: "Contrat temporaire",
    description:
      "Un contrat pour une durée limitée, souvent utilisé pour des projets ponctuels ou des remplacements.",
  },
  {
    id: 5,
    nom: "Contrat saisonnier",
    description:
      "Un contrat lié à une saison spécifique (par exemple, l'agriculture, le tourisme).",
  },
  {
    id: 6,
    nom: "Contrat d'intérim",
    description:
      "Un contrat pour remplacer temporairement un employé absent (congé maladie, congé maternité, etc.).",
  },
  {
    id: 7,
    nom: "Contrat à temps partiel",
    description:
      "Un contrat où l'employé travaille moins d'heures par semaine que dans un emploi à temps plein.",
  },
  {
    id: 8,
    nom: "Contrat à temps plein",
    description:
      "Un contrat où l'employé travaille les heures standard d'un emploi (généralement 35 à 40 heures par semaine).",
  },
  {
    id: 9,
    nom: "Contrat freelance",
    description:
      "Un contrat pour des missions spécifiques, souvent dans le domaine créatif (graphisme, rédaction, etc.).",
  },
];

export const semaine = [
  { id: 1, nom: "Lundi" },
  { id: 2, nom: "Mardi" },
  { id: 3, nom: "Mercredi" },
  { id: 4, nom: "Jeudi" },
  { id: 5, nom: "Vendredi" },
  { id: 6, nom: "Samedi" },
  { id: 0, nom: "Dimanche" },
];

export const itemVariant = {
  animeHover: { scale: 1.12 },
};

export const devises = [
  { id: 1, nom: "USD" },
  { id: 2, nom: "EUR" },
  { id: 3, nom: "JPY" },
  { id: 4, nom: "GBP" },
  { id: 5, nom: "AUD" },
  { id: 6, nom: "FRF" },
  { id: 7, nom: "CAD" },
  { id: 8, nom: "NZD" },
  { id: 9, nom: "HKD" },
  { id: 10, nom: "SGD" },
  // Ajoutez d'autres devises ici...
];

export const disponibility = [
  { id: 1, nom: "Temps plein" },
  { id: 2, nom: "Temps partiel" },
  { id: 3, nom: "A distance" },
  { id: 4, nom: "Hybride" },
];
