import { Button } from "@material-tailwind/react";
import { FaArrowCircleRight, FaSave, FaTrash } from "react-icons/fa";
import SelectUI from "../../UI/SelectUI";
import TextFieldUI from "../../UI/TextFieldUI";
import { useContext } from "react";
import { devises, salaryRateTable } from "./TableDate";
import AddJobContext from "../../../context/AddJobContext/context";
import { isNumberValid } from "../../Utils/ValidFormElement";
import { useTranslation, Trans } from "react-i18next";

const StepOne = ({ catTableData, compTableData, onCancel }) => {
  const { t } = useTranslation();
  const {
    handleChangeField,
    isEditing,
    formContent,
    validEachInput,
    handleBlurField,
    handleStep,
    validStepOk,
  } = useContext(AddJobContext);

  return (
    <>
      <div className="grid  gap-4">
        <div>
          <TextFieldUI
            label={t("stepOne.jobname")}
            isRequired
            placeholderValue={t("stepOne.job_placeholder")}
            type="text"
            id="jobName"
            value={formContent.jobName.value}
            onChange={(event) =>
              handleChangeField("jobName", event.target.value)
            }
            onBlur={() => handleBlurField("jobName")}
            classname="w-full p-2 mt-1 border rounded-md focus:outline-none"
          />

          <h3 className="ml-2 text-red-500">
            {validEachInput.isNotJobName && t("stepOne.jobname_error")}
          </h3>
        </div>
      </div>
      <div className="grid md:grid-cols-3 gap-4">
        <div>
          {/* <input  type="number" /> */}
          <TextFieldUI
            isRequired
            label={t("stepOne.wage_label")}
            placeholderValue={t("stepOne.wage_placeholder")}
            type="number"
            step="0.01"
            min="0"
            id="salary"
            value={formContent.salary.value||undefined}
            onChange={(event) =>
              handleChangeField("salary", event.target.value)
            }
            onBlur={() => handleBlurField("salary")}
            classname="w-full p-2 mt-1 border rounded-md focus:outline-none"
          />

          {/* <h3 className="ml-2 text-red-500">
            {validEachInput.isNotSalary && "Entrer un montant supérieur à 0"}
          </h3> */}
        </div>
        <div>
          <SelectUI
            classname={` ${
              !isNumberValid(formContent.salary.value) && "bg-gray-100"
            } w-full p-2 mt-1 border rounded-md focus:outline-none`}
            id="salaryRate"
            isRequired
            label={t("stepOne.rate_label")}
            disabled={!isNumberValid(formContent.salary.value)}
            data={salaryRateTable}
            value={formContent.salaryRate.value||undefined}
            onChange={(event) =>
              handleChangeField("salaryRate", event.target.value)
            }
            onBlur={() => handleBlurField("salaryRate")}
          />

          {/* <h3 className="ml-2 text-red-500">
            {validEachInput.isNotSalaryRate && "Frequance de payment invalide"}
          </h3> */}
        </div>
        <div>
          <SelectUI
            label={t("stepOne.devise_label")}
            data={devises}
            isRequired
            placeholderValue={t("stepOne.devise_placeholder")}
            disabled={!isNumberValid(formContent.salary.value)}
            id="currency"
            value={formContent.currency.value||undefined}
            onChange={(event) =>
              handleChangeField("currency", event.target.value)
            }
            onBlur={() => handleBlurField("currency")}
            classname={` ${
              !isNumberValid(formContent.salary.value) && "bg-gray-100"
            } w-full p-2 mt-1 border rounded-md focus:outline-none`}
          />

          {/* <h3 className="ml-2 text-red-500">
            {validEachInput.isNotCurrency && "Veuillez selectionner une devise"}
          </h3> */}
        </div>
      </div>
      <div className="grid md:grid-cols-2 gap-4">
        <div>
          <SelectUI
            classname="w-full p-2 mt-1 border rounded-md focus:outline-none"
            id="company"
            isRequired
            label={t("stepOne.comp_label")}
            data={compTableData}
            value={formContent.companyId.value}
            onChange={(event) =>
              handleChangeField("companyId", event.target.value)
            }
            onBlur={() => handleBlurField("companyId")}
            className="w-full p-2 mt-1 border rounded-md focus:outline-none"
          />
          <h3 className="ml-2 text-red-500">
            {validEachInput.isNotCompanyId && t("stepOne.comp_error")}
          </h3>
        </div>
        <div>
          <SelectUI
            classname="w-full p-2 mt-1  border rounded-md focus:outline-none"
            id="category"
            isRequired
            label={t("stepOne.cat_label")}
            data={catTableData}
            onChange={(event) =>
              handleChangeField("categoryId", event.target.value)
            }
            value={formContent.categoryId.value}
            onBlur={() => handleBlurField("categoryId")}
            className="w-full p-2 mt-1 border rounded-md focus:outline-none"
          />

          <h3 className="ml-2 text-red-500">
            {validEachInput.isNotCategory && t("stepOne.cat_error")}
          </h3>
        </div>
      </div>
      <div className="flex w-full justify-evenly">
        <Button
          onClick={onCancel}
          variant="filled"
          color={"red"}
          className={`flex justify-center bg-opacity-80  h-12 pt-4 ml-4 hover:bg-opacity-70  `}
        >
          <span className="justify-start max-sm:hidden mx-1 ">
            <div></div>
            {t("stepOne.cancel")}
          </span>
          <FaTrash className=" size-4" />
          <span className="justify-end text-md"></span>
        </Button>
        {isEditing && (
          <Button
            type="submit"
            variant="filled"
            color={"green"}
            className={`flex justify-center w-auto bg-opacity-80 h-12 items-center ml-4 hover:bg-opacity-70  `}
          >
            <span className="justify-start max-sm:hidden mx-1">
              <div></div>
              {t("stepOne.save")}
            </span>
            <FaSave className=" size-4" />
            <span className="justify-end text-md"></span>
          </Button>
        )}
        <Button
          onClick={() => handleStep(2, validStepOk.isFirstStepOk)}
          variant="filled"
          color={"blue"}
          className={`flex justify-center bg-opacity-80 w-auto h-12 items-center ml-4 hover:bg-opacity-70  `}
        >
          <span className="justify-start max-sm:hidden mx-1">
            <div></div>
            {t("stepOne.next")}
          </span>
          <span className="justify-end text-md">
            <FaArrowCircleRight className=" size-4" />
          </span>
        </Button>
      </div>
    </>
  );
};

export default StepOne;
