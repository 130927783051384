import React from "react";
import proueLogo from "../../assets/logo.png";
import {
  FaLinkedin,
  FaTwitter,
  FaFacebook,
  FaInstagram,
} from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./footer.css";

import { jobsSliceAction } from "../../store/slice/jobsSlice";
import { categorySliceAction } from "../../store/slice/categorySlice";


const social = {
  linkedin: process.env.REACT_APP_SOCIAL_LINKEDIN,
  twitter: process.env.REACT_APP_SOCIAL_TWITTER,
  facebook: process.env.REACT_APP_SOCIAL_FACEBOOK,
  instagram: process.env.REACT_APP_SOCIAL_INSTAGRAM,
};

// console.log("socail", social)
const Footer = () => {
  const [catList, setCatList] = useState([]);

  const catState = useSelector((state) => state.category);
  useEffect(() => {
    if (catState.statusCat === "success") {
      const slicedCat = catState.allCatWithJob.slice(0, 4);
      setCatList(slicedCat);
    }
  }, [catState]);
  // console.log("je suis la catlist", catList);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSelectCat = (id) => {
    dispatch(jobsSliceAction.filterJobsByCatId({ categoryId: id }));
    dispatch(categorySliceAction.getCatByIdForFilter({ selectedCatId: id }));
    navigate("/Job");
  };

  const { t } = useTranslation();
  return (
    <div className="pt-[10px] footer-container w-full shadow-inner">
      <div className="p-10 text-[#EA580C] text-[16px] bg-white">
        <div className="max-w-full mx-auto">
          <div className="flex-shrink flex space-x-auto justify-between flex-wrap">
            <div>
            <a href="/" target="_blank" rel="noopener noreferrer">
                <img src={proueLogo} className="w-16 h-16 " alt="logo" />
              </a>

              <p className="text-[#EA580C] text-[16px]">
                {t("footer.text1")} <br />
              </p>
              <p className="text-gray-500 text-[15px] pt-2 space-y-2">
                AP-48904950 <br />
                AP-23043566 <br />
              </p>
            </div>
            <div>
              <span className="pb-4 text-[#EA580C]">{t("footer.text9")}</span>
              <ul className="text-gray-500 text-[15px] pt-2 space-y-2">
                {catList.map((cat, index) => (
                  <li key={cat.id} onClick={() => handleSelectCat(cat.id)} className="hover:text-blue-500 cursor-pointer">
                    {/* <a href="/" > */}
                      {cat.categoryName}
                    {/* </a> */}
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <span className="pb-4 text-[#EA580C]">{t("footer.text10")}</span>
              <ul className="text-gray-500 text-[15px] pt-2 space-y-2">
                <li>
                  <a href="/contact" className="hover:text-blue-500">
                    {t("footer.text5")}
                  </a>
                </li>
                <li>
                  <a href="/about" className="hover:text-blue-500">
                    {t("footer.text6")}
                  </a>
                </li>
                <li>
                  <a href="/privatePolicy" className="hover:text-blue-500">
                    {t("footer.text7")}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full px-10 text-[#98A2B3] bg-[#F9FAFB] flex justify-between h-full items-center text-[16px] ">
        <div className="p-2 text-center sm:text-sm">
        <p className="space-x-1">
            Copyright © 2024 Laproue -
            <span >{t("footer.text8")} </span>
            <span>Designed by</span>
            <a
              href="https://nebulageek.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-orange-600  hover:text-blue-600"
            >
              Nebulageek
            </a>
          </p>

        </div>
        <div className="flex flex-row">
          <p className="m-2 text-gray-500 hover:text-blue-500 cursor-pointer">
            <a href={social.linkedin} target="_blank" rel="noopener noreferrer">
              <FaLinkedin />
            </a>
          </p>
          <p className="m-2 text-gray-500 hover:text-blue-500 cursor-pointer">
            <a href={social.twitter} target="_blank" rel="noopener noreferrer">
              <FaTwitter />
            </a>
          </p>
          <p className="m-2 text-gray-500 hover:text-blue-500 cursor-pointer">
            <a href={social.facebook} target="_blank" rel="noopener noreferrer">
              <FaFacebook />
            </a>
          </p>
          <p className="m-2 text-gray-500 hover:text-blue-500 cursor-pointer">
            <a href={social.instagram} target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
