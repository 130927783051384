export const isTextValid = (text) => {
  if (!text) {
    return false;
  }

  try {
    const isValid = text.toString().trim().length > 0;

    return isValid;
  } catch (error) {
    throw new Error("invalid string : " + text);
  }
};

export const isEmailValid = (email) => {
  const isValid = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email);

  return isValid;
};
export const isPhoneValid = (tel) => {
  const motif = /^(0|[1-9]\d*)$/;
  if (!motif.test(tel)) {
    return false;
  }
  return true;
};

export const isNumberValid = (number) => {
  if (number) {
    return (
      !isNaN(parseFloat(number)) && isFinite(number) && parseFloat(number) > 0
    );
  } else {
    return false;
  }
};

export const isDateValid = (date) => {
  if (!date) {
    return false;
  }
  const newDate = new Date(date);

  const aujourdHui = new Date();

  // Ajouter un jour à la date d'aujourd'hui
  const demain = new Date(aujourdHui);
  demain.setDate(demain.getDate() + 1);

  return newDate > demain;
};

export const isPasswordValid = (password) => {
  const valid =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{4,}$/.test(
      password
    );

  return valid;
};
