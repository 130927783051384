
import { USER_DELETE_CV } from "../../../api"
import { axiosUseToken } from "../../../axiosConfig"


export const deleteCv =async({id})=>{
  // console.log('id', id)
    const response = await axiosUseToken.delete(USER_DELETE_CV+id)
    // console.log('response', response)
    return response

  
  
}
