import { FaFolderOpen } from "react-icons/fa";

const EmptyData = ({ children }) => {
  return (
    <div className="    font-bold  text-sm lg:text-xl text-gray-700  text-center  w-full ">
      <div className="justify-center  mx-auto">
        <FaFolderOpen className="mx-auto size-10 lg:size-14 text-[#5190B7]" />
        <div className="text-center  text-[#667085]">{children}</div>
      </div>
    </div>
  );
};

export default EmptyData;
