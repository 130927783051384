import { useReducer } from "react";
import { viewCv } from "../../services/HttpRequest/User/cvRequest/get";

const initialState = {
  status: "",
  error: {},
  data: {},
};

const reducerFn = (state, action) => {
  const status = action.payload.status;
  switch (action.payload.status) {
    case "success":
      const doc = action.payload.file;
      const decodedBase64 = atob(doc);

      const filename = action.payload.fileName;
      const mime = "application/pdf";
      const u8arr = new Uint8Array(decodedBase64.length);
      for (let i = 0; i < decodedBase64.length; i++) {
        u8arr[i] = decodedBase64.charCodeAt(i);
      }
      const files = new File([u8arr], filename, { type: mime });
      //     let files = new Blob([decoded], { type: 'application/pdf' });
      let fileURL = URL.createObjectURL(files);
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = action.payload.fileName;
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(fileURL);
      return { ...state, error: action.payload.error,fileName:{filename}, status: status };

    case "error":
      return { ...state, error: action.payload.error, status: status };

    case "pending":
      return {
        ...state,
        error: action.payload.error,
        status: status,
      };

    default:
      break;
  }
};

const useViewPdf = () => {
  const [state, dispatch] = useReducer(reducerFn, initialState);

  const handleViewDoc = async (id) => {
    try {
      dispatch({
        type: "view",
        payload: {
          status: "pending",
          fileName: "",
          file: "",
          error: {},
        },
      });

      const response = await viewCv(id);

      dispatch({
        type: "view",
        payload: {
          status: "success",
          fileName: response.data.fileName,
          file: response.data.file,
          error: {},
        },
      });
    } catch (error) {
      dispatch({
        type: "view",
        payload: {
          status: "error",
          fileName: "",
          file: "",
          error: error,
        },
      });
    }
  };

  // console.log("state", state);
  return {
    status: state.status,
    data: state.data,
    error: state.error,
    handleViewDoc,
  };
};

export default useViewPdf;
