import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

function Caroussel() {
  let i = 0;
  const { t } = useTranslation();
  const slides = [
    {
      titre:
        " Vous êtes à la recherche d’un travail qui vous passionne et qui vous permet de vous épanouir professionnellement ? ",
      text: "Vous êtes au bon endroit !",
    },
    {
      titre: "Bienvenue sur LA PROUE.",
      text: "Agence de placement de personnel et de recrutement de travailleur temporaires.",
    },
    {
      titre: "Nous sommes l'excellence en terme de recherche d'emploi",
      text: "plus de 500 refugiés en 1 mois ont trouvés du travail grace à LA PROUE.",
    },

    {
      titre:
        "Agence de placement de personnel et de recrutement de travailleur temporaires.",
      text: "Nous formons des partenariats professionnels prospères et solides",
    },
    // {
    //   titre:
    //     "Nous offrons des opportunités de carrière passionnantes dans divers domaines, allant de la technologie à la finance en passant par le marketing et les ressources humaines.",
    //   text: "Nous Réalisons des rêves professionnels avec passion et dévouement.",
    // },
  ];

  for (i = 0; i < slides.length; i++) {
    let k = i + 1;
    slides.at(i).titre = t("connection.SliderTitle" + k);
    slides.at(i).text = t("connection.SliderText" + k);
  }
  const [currentIndex, setCurrentIndex] = useState(0);
  // Auto Slide
  useEffect(() => {
    const SlideAuto = setInterval(() => {
      nextSlide();
    }, 8000);
    return () => {
      clearInterval(SlideAuto);
    };
  }, [currentIndex]);

  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  return (
    <div className="relative h-full  my-20 w-full bg-cover  group [font-family:'Inter-Semi-Bold',Helvetica]">
      <div className="absolute top-0 left-0 max-w-md p-4 px-10 py-10 mt-20 text-justify md:max-w-full">
        <h2 className="mb-5 font-bold text-white sm:text-sm lg:text-2xl xl:text-4xl">
          {slides[currentIndex].titre}
        </h2>
        <p className="mb-8 font-bold text-gray-200 lg:text-lg xl:text-xl sm:text-sm italic ">
          {slides[currentIndex].text}
        </p>
      </div>
    </div>
  );
}

export default Caroussel;
