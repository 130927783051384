import React, { useContext, useEffect, useState } from "react";
import TextAreaUI from "../../UI/TextAreaUI";
import { Button } from "@material-tailwind/react";
import {
  FaArrowCircleLeft,
  FaEdit,
  FaPlus,
  FaSave,
  FaTrash,
} from "react-icons/fa";
import { isTextValid } from "../../Utils/ValidFormElement";
import { motion } from "framer-motion";
import { itemVariant } from "./TableDate";
import AddJobContext from "../../../context/AddJobContext/context";
import { useTranslation, Trans } from "react-i18next";
import TextAreaButton from "./TextAreaButton";
import ModalInfos from "../../UI/RequestUI/ModalInfos";

const StepFive = () => {
  const { t } = useTranslation();
  const {
    handleChangeField,
    HandleTextareaDispatch,
    formContent,
    textAreaState,
    validEachInput,
    handleBlurField,
    handleStep,
  } = useContext(AddJobContext);

  const [summaryEditValue, setSummaryEditValue] = useState();
  const [otherEditValue, setOtherEditValue] = useState();
  const [fieldControllersum, setFielControllersum] = useState(false);
  const [fieldControlleroth, setFielControlleroth] = useState(false);
  const [controlEdit, setControlEdit] = useState({
    summary: false,
    other: false,
  });

  useEffect(() => {
    if (summaryEditValue) {
      handleChangeField("summary", summaryEditValue);
    }
  }, [summaryEditValue, fieldControllersum]);
  // --------------------
  useEffect(() => {
    if (otherEditValue) {
      handleChangeField("other", otherEditValue);
    }
  }, [otherEditValue, fieldControlleroth]);

  return (
    <>
      <div className="grid xl:grid-cols-2 gap-4">
      <ModalInfos
          autoClose={() => {
            setControlEdit((prev) => ({
              ...prev,
              other: false,
              summary: false,
            }));
          }}
          isOpen={controlEdit.summary|| controlEdit.other}
          message={t("jobpage.textarea_error")}
        />
        <div className="">
          <div className="w-full flex">
            {" "}
            <TextAreaUI
              label={t("stepFive.label1")}
              value={formContent.summary.value}
              isRequired
              placeholderValue={t("stepFive.placeholder1")}
              type="text"
              id="summary"
              onChange={(event) =>
                handleChangeField("summary", event.target.value)
              }
              onBlur={() => handleBlurField("summary")}
              classname="w-full h-24 lg:h-32 p-2 mt-1 border rounded-md focus:outline-none"
            />
            <TextAreaButton
              onClick={() => HandleTextareaDispatch("summary", "add")}
            ></TextAreaButton>
          </div>

          <h3 className="ml-2 text-red-500">
            {validEachInput.isNotSummary && t("stepFive.error1")}
          </h3>
          <motion.ul
            layout
            className="flex max-h-32 overflow-y-scroll flex-wrap"
          >
            {textAreaState.summary.table &&
              textAreaState.summary.table.map(
                (text) =>
                  text !== "" && (
                    <li
                      key={Math.random()}
                      className="w-[19rem]  p-1 h-14 lg:h-20 pl-1 flex m-1  text-white bg-[#457AA8] rounded-sm  shadow-2xl border-opacity-75 "
                    >
                      <h3 className="w-72 overflow-x-hidden  scroll-p-0 hover:scroll-auto  ">
                        {text}
                      </h3>
                      <div className="flex flex-col items-center justify-around ml-1 -mr-1 ">
                        <motion.span
                          variants={itemVariant}
                          whileHover="animeHover"
                          className=" hover:text-green-500 mr-2 pt-1"
                        >
                          <FaEdit
                            size={15}
                            onClick={() => {
                              if (text) {
                                if (
                                  formContent.summary.value.trim().length > 0
                                ) {
                                  setControlEdit((prev) => ({
                                    ...prev,
                                    summary: true,
                                  }));
                                } else {
                                  handleChangeField("summary", text);
                                  setSummaryEditValue(text);
                                  setFielControllersum((prev) => prev);
                                  HandleTextareaDispatch(
                                    "summary",
                                    "del",
                                    text
                                  );
                                }
                              }
                            }}
                          />
                        </motion.span>
                        <motion.span
                          variants={itemVariant}
                          whileHover="animeHover"
                          className=" hover:text-red-500 mr-2 pt-1"
                        >
                          <FaTrash
                            size={15}
                            onClick={() => {
                              HandleTextareaDispatch("summary", "del", text);
                            }}
                          />
                        </motion.span>
                      </div>
                    </li>
                  )
              )}
          </motion.ul>
        </div>
        <div>
          <div className="w-full flex">
            <TextAreaUI
              value={formContent.other.value}
              label={t("stepFive.label2")}
              isRequired
              placeholderValue={t("stepFive.placeholder2")}
              type="text"
              id="other"
              onChange={(event) =>
                handleChangeField("other", event.target.value)
              }
              onBlur={() => handleBlurField("other")}
              classname="w-full h-24 lg:h-32 p-2 mt-1 border rounded-md focus:outline-none"
            />
            <TextAreaButton
              onClick={() => HandleTextareaDispatch("other", "add")}
            ></TextAreaButton>
          </div>

          <h3 className="ml-2 text-red-500">
            {validEachInput.isNotOther && t("stepFive.error2")}
          </h3>
          <motion.ul
            layout
            className="flex max-h-32  overflow-y-scroll flex-wrap"
          >
            {textAreaState.other.table.length > 0 &&
              textAreaState.other.table.map(
                (text) =>
                  text !== "" && (
                    <li
                      key={Math.random()}
                      className="w-[19rem]  p-1 h-14 lg:h-20 pl-1 flex m-1  text-white bg-[#457AA8] rounded-sm  shadow-2xl border-opacity-75 "
                    >
                      <h3 className="w-72 overflow-x-hidden  scroll-p-0 hover:scroll-auto  ">
                        {text}
                      </h3>
                      <div className="flex flex-col items-center justify-around ml-1 -mr-1 ">
                        <motion.span
                          variants={itemVariant}
                          whileHover="animeHover"
                          className=" hover:text-green-500 mr-2 pt-1"
                        >
                          <FaEdit
                            size={15}
                            onClick={() => {
                              if (text) {
                                if (formContent.other.value.trim().length > 0) {
                                  setControlEdit((prev) => ({
                                    ...prev,
                                    other: true,
                                  }));
                                } else {
                                  handleChangeField("other", text);
                                  setOtherEditValue(text);
                                  setFielControlleroth((prev) => !prev);
                                  HandleTextareaDispatch("other", "del", text);
                                }
                              }
                            }}
                          />
                        </motion.span>
                        <motion.span
                          variants={itemVariant}
                          whileHover="animeHover"
                          className=" hover:text-red-500 mr-2 pt-1"
                        >
                          <FaTrash
                            size={15}
                            onClick={() => {
                              HandleTextareaDispatch("other", "del", text);
                            }}
                          />
                        </motion.span>
                      </div>
                    </li>
                  )
              )}
          </motion.ul>
        </div>
      </div>

      {/*  */}

      <div className="flex w-full justify-evenly">
        <Button
          onClick={() => handleStep(4)}
          variant="filled"
          color={"blue"}
          className={`flex bg-opacity-85 justify-center w-auto h-12 items-center ml-4 hover:bg-opacity-70  `}
        >
          <span className="justify-end text-md">
            <FaArrowCircleLeft className="size-4" />
          </span>
          <span className="justify-start max-md:hidden mx-1">
            <div></div>
            {t("stepFive.back")}
          </span>
        </Button>

        <Button
          onClick={() => handleStep(5)}
          type="submit"
          variant="filled"
          color={"green"}
          className={`flex bg-opacity-85 justify-center w-auto h-12 items-center ml-4 hover:bg-opacity-70  `}
        >
          <span className="justify-start max-md:hidden mx-1">
            <div></div>
            {t("stepFive.term")}
          </span>
          <span className="justify-end text-md">
            <FaSave className="size-4" />
          </span>
        </Button>
      </div>
    </>
  );
};
export default StepFive;
