import axios from "axios";
import {
  POST_QUICK_APPLY,
  REGISTERED_USER_APP,
  UNREGISTERED_USER_APP,
} from "../../api";
import aXios, { axiosUseToken } from "../../axiosConfig";

const baseURL = process.env.REACT_APP_BASE_URL;
const baseUrl = baseURL + "/jobApplication";
export const sendApplyRequest = async ({ data, role }) => {
  // console.log('data', data)

  const formData = new FormData();
  role === "Client"
    ? formData.append("docId", data.resume)
    : formData.append("resume", data.resume);
  formData.append("email", data.email);
  formData.append("firstName", data.firstName);
  formData.append("lastName", data.lastName);
  formData.append("address", data.address);
  formData.append("phoneNumber", data.phoneNumber);
  formData.append("jobId", data.jobId);
  formData.append("interestRegion", data.interestRegion);
  formData.append("canadianWorkingPermit", data.canadianWorkingPermit);

  // console.log("clientRole", role);
  const url =
    role === "Client"
      ? baseUrl + REGISTERED_USER_APP
      : baseUrl + UNREGISTERED_USER_APP;
  //
  if (role === "Client") {
    try {
      const response = await axiosUseToken.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (error) {
      // console.log("responseaaaaaaaaaaaaaaaaaaa", error);
      // console.log("dataapply", data);
    }

    // console.log("url", url);
  }

  const response = await axios.post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  // console.log("url", url);
  // console.log("dataapply", data);

  // console.log("response", response);

  return response;
};

export const quickApply = async ({ data }) => {
  const formData = new FormData();
  formData.append("File", data.file);
  formData.append("Email", data.Email);
  formData.append("FirstName", data.FirstName);
  formData.append("LastName", data.LastName);
  formData.append("Address", data.Address);
  formData.append("PhoneNumber", data.PhoneNumber);
  formData.append("Job", data.Cat);
  // formData.append("JobId", 0);
  formData.append("Town", data.Town);
  formData.append("Region", data.Region);
  formData.append("PostalCode", data.PostalCode);
  formData.append("workingHour", data.workingHour);
  formData.append("CanadianWorkPermit", data.CanadiantWorkPermit);

  try {
    const response = await aXios.post(POST_QUICK_APPLY, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    // console.log("response", response);
    return response;
  } catch (error) {
    // console.log("error----------------", error);
  }
};
