import React, { useState, useEffect } from "react";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";

function PercentCard({
  totalPersons,
  DailyPersons,
  referencePercentage,
  title,
  day,
  barcolor,
}) {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    // Calculer le pourcentage
    const calculatedPercentage = (DailyPersons / totalPersons) * 100;
    setPercentage(calculatedPercentage);
  }, [totalPersons, DailyPersons]);

  return (
    <div className="w-full  p-2">
      <div className="bg-white shadow-md rounded-lg p-4 w-full h-full mt-4">
        <div className="flex justify-between items-start">
          <div>
            <p className="text-gray-600">{title}</p>
            <div className="flex items-center mt-1">
              <p className="text-[#3CB802]">{percentage.toFixed(2)}%</p>
              {percentage > referencePercentage ? (
                <IoMdArrowDropup className="text-[#3CB802] w-5 h-5" />
              ) : (
                <IoMdArrowDropdown className="text-[#DA251C] w-5 h-5" />
              )}
            </div>
          </div>
          <p className="text-[#0284C7] text-xs text-center  bg-[#EAF0F3] rounded-full h-[34px] px-2 flex items-center">
            {day}
          </p>
        </div>
        <div className="mt-8 py-2">
          <p className="text-[#3CB802] flex justify-end">
            {percentage.toFixed(0)}
          </p>
          <div className="w-full bg-gray-200 rounded-full overflow-hidden">
            <div
              className={`${barcolor} h-2`}
              style={{ width: `${percentage}%` }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PercentCard;
