import React from "react";
import grayImg from "../../assets/studentsImg/grayImg.png";

import { useTranslation, Trans } from "react-i18next";
const SectionOne = () => {
  const { t } = useTranslation();
  return (
    <section>
      <div
        className="h-52 md:h-[450.31px]   w-full  bg-cover bg-no-repeat mt-16   "
        style={{
          backgroundImage: `linear-gradient(to left,rgba(0, 0, 0, 0), rgba(2, 132, 199, 0.1)), url(${grayImg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center top 0%",
        }}
      >
        <div className="h-full w-full bg-gradient-to-l from-[#0284C7]  to-transparent flex justify-center items-center filter ">
          <div className="relative flex justify-center w-[84%] h-[184px] items-center shadow-lg rounded-[35px]">
            <div className="absolute inset-0 bg-gray-100 opacity-10 rounded-[35px]"></div>
            <div className="relative flex  text-center  w-full mx-auto">
              <h1 className="font-bold text-white text-6xl text-center w-full ">
                <div className="font-bold text-white text-3xl md:text-6xl pb-10  ">
                  <span className=" ">{t("studentForm.title")}</span>
                </div>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionOne;
