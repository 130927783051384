import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { newsletterGet } from "../../../services/HttpRequest/adminRequest/newsletter";
import LoadDataFailUI from "../../UI/RequestUI/LoadDataFailUI";
import PendingUI from "../../UI/RequestUI/PendingUI";
import NewsletterTable from "../NewsLetter/NewsletterTable/NewsletterTable";
import EmptyData from "../../UI/RequestUI/EmptyData";

export default function NewsLetter() {
  const [emailData, setEmailData] = useState([]);

  const { data, status } = useQuery({
    queryKey: ['newsletterGet'],
    queryFn: newsletterGet,
  });
//   console.log("le résultatzzzzz", data?.data);
  useEffect(() => {
    if (status === "success") {
      setEmailData(Array.isArray(data?.data) ? data?.data : []);
    }
  }, [data, status]);

//   console.log("le résultat", emailData);

  let content;
  switch (status) {
    case "error":
      content = <LoadDataFailUI />;
      break;
    case "pending":
      content = <PendingUI />;
      break;
    case "success":
      content = (
        <NewsletterTable
          data={emailData}
        />
      );
      break;
    default:
      content = null;
      break;
  }

  return (
    <div className="container w-full mx-auto bg-[#F9F9F9]">
      <h1 className="mb-4 text-3xl font-bold mt-6  text-[#457AA8]">
        Liste des mails ayant souscrit à la newsletter
      </h1>
      {content}
    </div>
  );
};
