import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../services/axiosConfig";
import { REFRESH_TOKEN_URL } from "../../services/api";

const initialState = {
  data: null,
  loading: false,
  error: null,
};

export const performRequest = createAsyncThunk(
  "request/perform",
  async ({ method, url, data, requiresAuth }, { getState }) => {
    try {
      const accessToken = getState().loginInfo.accessToken;

      if (requiresAuth && !accessToken) {
        throw new Error("Token not found");
      }

      const config = {
        method: method,
        url: url,
        headers: {
          "Content-Type": "application/json",
          ...(requiresAuth &&
            accessToken && { Authorization: `Bearer ${accessToken}` }),
        },
        data: data,
      };

      const response = await axios(config);

      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && requiresAuth) {
        const refreshToken = getState().loginInfo.refreshToken;
        // console.log(refreshToken)
        if (refreshToken) {
          try {
            const refreshResponse = await axios.post(REFRESH_TOKEN_URL, {
              refreshToken,
            });
            const newAccessToken = refreshResponse.data.accessToken;
            const newConfig = {
              method: method,
              url: url,
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${newAccessToken}`,
              },
              data: data,
            };

            const newResponse = await axios(newConfig);

            return newResponse.data;
          } catch (refreshError) {
            throw refreshError;
          }
        } else {
          throw new Error("No refresh token available");
        }
      } else {
        throw error;
      }
    }
  }
);

const requestSlice = createSlice({
  name: "request",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(performRequest.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(performRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(performRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message; // Vous pouvez personnaliser cela selon votre logique d'erreur
      });
  },
});

export default requestSlice.reducer;
