import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import HomePage from "../pages/HomePage";
import AboutPage from "../pages/AboutPage";
import Inscription from "../pages/InscriptionPage";
import Connexion from "../pages/ConnexionPage";
import Job from "../pages/JobPage";
import Cv from "../pages/CvPage";
import Profil from "../pages/ProfilPage";
import ContactPage from "../pages/ContactPage";
import FormApply from "../components/Form/FormApply";
import MyApplication from "../pages/MyApplication";
import JobDes from "../pages/JobDesPage";
import ManagePage from "../pages/AdminPage/ManagePage";
// import CandidacyManage from "../components/Admin_Cpt/ManageBord/AppliManage/CandidacyManage/CandidacyManage";
import DashBord from "../pages/AdminPage/DashBordPage";
import NewsLetterPage from "../pages/AdminPage/NewsLetterPage";
import RequestError from "../components/Error/RequestError";
import MaintenanceError from "../components/Error/MaintenanceError";
import { useSelector } from "react-redux";
import {
  selectLoginStatus,
  selectCurrentUser,
} from "../store/slice/loginSlice";
import { CompContextProvider } from "../context/CompContext";
import JobFormPage from "../pages/JobFormPage";
import FormApplyUser from "../components/Form/FormApplyUser";
import QuickApplyPage from "../pages/AdminPage/QuickApplyPage";
import StudentsPage from "../pages/StudentsPages";
import CvContextProvider from "../context/CvContext/Provider";
import TermAndCondidtionPage from "../pages/AdminPage/TermAndConditionPage";
import PrivacyPolicyPage from "../pages/PrivacyPolicyPage";
import EditJobPage from "../pages/EditJobPage";

const RootRoutes = () => {
  const loginStatus = useSelector(selectLoginStatus);
  const currentUser = useSelector(selectCurrentUser);
  let role;
  if (currentUser) {
    if (currentUser.roles && currentUser.roles.length > 0) {
      role = currentUser.roles[0];
    }
  } else {
    role = null;
  }

  return (
    <Routes>
      {loginStatus === "succeeded" && role ? (
        <>
          {role === "Admin" && (
            <>
              <Route path="/admin">
                <Route path="MenuGestion" element={<ManagePage />} />
                <Route path="DashBord" element={<DashBord />} />
                <Route path="NewsLetter" element={<NewsLetterPage />} />
                {/* <Route path="MenuGestion/CandidacyManage" element={<CandidacyManage />} /> */}
                <Route path="editJob/:id" element={<EditJobPage/>}/>

                <Route
                  path="MenuGestion/JobFormPage"
                  element={<JobFormPage />}
                />
              </Route>
              <Route path="*" element={<Navigate to="/admin/MenuGestion" />} />
            </>
          )}
          {role === "Manager" && (
            <>
              <Route path="/manager">
                <Route path="MenuGestion" element={<ManagePage />} />
                <Route path="DashBord" element={<DashBord />} />
                <Route path="editJob/:id" element={<EditJobPage/>}/>
                {/* <Route path="MenuGestion/CandidacyManage" element={<CandidacyManage />} /> */}
                <Route path="NewsLetter" element={<NewsLetterPage />} />

                <Route
                  path="MenuGestion/JobFormPage"
                  element={<JobFormPage />}
                />
              </Route>
              <Route
                path="*"
                element={<Navigate to="/manager/MenuGestion" />}
              />
            </>
          )}
          {role === "Client" && (
            <>
              <Route path="/client">
                <Route path="MesCv" element={<Cv />} />
                <Route path="MesCandidatures" element={<MyApplication />} />
                <Route path="MonProfil" element={<Profil />} />
                <Route path="Application" element={<FormApply />} />
              </Route>
              <Route
                path="*"
                element={<Navigate to="/client/MesCandidatures" />}
              />
            </>
          )}
          {role !== "Admin" && role !== "Manager" && role !== "Client" && (
            <>
              <Route path="*" element={<Navigate to="/error" />} />
            </>
          )}

          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          {/* <Route path="/Inscription" element={<Inscription />} /> */}
          <Route path="/error" element={<RequestError />} />
          <Route path="/Application/:jobId" element={<FormApply />} />

          <Route path="/Job/JobDescription/:jobId" element={<JobDes />} />

          <Route path="/Job" element={<Job />} />
          <Route path="/error" element={<MaintenanceError />} />
          <Route path="/Contact" element={<ContactPage />} />
        </>
      ) : (
        <>
          <Route path="/Job" element={<Job />} />
          <Route path="/Job/JobDescription/:jobId" element={<JobDes />} />

          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/Connexion" element={<Connexion />} />
          <Route path="/Inscription" element={<Inscription />} />
          <Route path="/error" element={<MaintenanceError />} />
          <Route path="/Application/:jobId" element={<FormApply />} />
          <Route path="/Contact" element={<ContactPage />} />
          <Route path="/quickApply" element={<QuickApplyPage />} />
        </>
      )}

      {/* Routes communes pour les utilisateurs authentifiés et non authentifiés */}

      <Route path="/studentContact" element={<StudentsPage />} />
      <Route path="/termAndCondition" element={<TermAndCondidtionPage />} />
      <Route path="/privatePolicy" element={<PrivacyPolicyPage />} />
      <Route path="*" element={<Navigate to="/error" />} />
    </Routes>
  );
};

export default RootRoutes;
