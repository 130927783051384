import React from "react";

const Contactinfo = (props) => {
  return (
    <>
      <div className="flex flex-col items-center justify-center mb-6">
        <div className="flex items-center justify-center w-10 h-10 bg-[#0284C7] rounded-full">
          {props.icon}
        </div>
        <div className="items-center lg:h-4 text-center">
          <h2 className="font-semibold text-center">{props.title}</h2>
          <span className="text-center">{props.text}</span>
        </div>
      </div>
    </>
  );
};

export default Contactinfo;
