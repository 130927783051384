import React, { useContext, useEffect } from "react";
import { Button } from "@material-tailwind/react";
import { useTranslation, Trans } from "react-i18next";

import { motion } from "framer-motion";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../../services/HttpRequest/queryClientProvider/queryClientProvider";
import { isNumberValid, isTextValid } from "../Utils/ValidFormElement";
import CompContext from "../../context/CompContext";
import {
  createJob,
  getJob,
  updateJob,
} from "../../services/HttpRequest/jobRequest/jobRequest";
import PendingUI from "../UI/RequestUI/PendingUI";
import RequestSuccess from "../UI/RequestUI/RequestSucess";
import RequestFailPanel from "../UI/RequestUI/RequestFailPanel";
import StepOne from "./RegistrationJobFormStep/StepOne";
import StepTwo from "./RegistrationJobFormStep/StepTwo";
import StepTree from "./RegistrationJobFormStep/StepTree";
import StepFour from "./RegistrationJobFormStep/StepFour";
import StepFive from "./RegistrationJobFormStep/StepFive";
import AddJobContext from "../../context/AddJobContext/context";
import { useDispatch, useSelector } from "react-redux";
import JobContext from "../../context/JobContext";
import { useNavigate } from "react-router-dom";
import { jobsSliceAction } from "../../store/slice/jobsSlice";

// table salary

// Vous pouvez maintenant utiliser le tableau 'joursSemaine_objects' dans votre code !

const RegistrationJobForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // calling context
  const {
    formContent,
    textAreaState,
    step,
    validStepOk,
    jobId,
    initDiDedit,
    isEditing,
    handleStep,
  } = useContext(AddJobContext);

  // select the mode
  const mutationFunction = isEditing ? updateJob : createJob;

  // mutation
  const { mutate, error, status } = useMutation({
    mutationFn: mutationFunction,
    onSuccess: async (data) => {
      queryClient.invalidateQueries({ queryKey: ["getJob"] });
      // dispatch(jobsSliceAction.fetchAllJobs({ status:"pending",data: {}, error:{} }));
      // const res = await getJob();
      // console.log("res", res);
      // dispatch(
      //   jobsSliceAction.fetchAllJobs({
      //     status: res.status === 200 ? "success" : "error",
      //     data: res,
      //     error: res.status !== 200 ? res : null,
      //   })
      // );
    },
  });

  useEffect(() => {
    if (status === "success") {
    }
  }, [status]);

  const { allComps } = useContext(CompContext);
  const allCats = useSelector((state) => state.category.allCats);
  const compTableData = allComps.map((prevComp) => ({
    id: prevComp.id,
    nom: prevComp.companyName,
  }));
  const catTableData = allCats?.map((prevCat) => ({
    nom: prevCat.categoryName,
    id: prevCat.id,
  }));

  const handleCancel = () => {
    navigate("/admin/MenuGestion");
  };
  // Navigate to job

  // const navigate = useNavigate();

  // const handleNavigate = (link) => {
  //   navigate(link);
  // };

  // Vous pouvez maintenant utiliser ces variables pour effectuer des actions en fonction de leur valeur.

  // valid id
  const isIdValid =
    isTextValid(formContent.companyId.value) &&
    isTextValid(formContent.categoryId.value);

  // Valide Submition

  const isContentValid =
    validStepOk.isFirstStepOk &&
    validStepOk.isSecondStepOk &&
    validStepOk.isThirdStepOk &&
    validStepOk.isFourStepOk &&
    validStepOk.isFiveStepOk;

  //getting the external id value

  let companyId;
  let categoryId;

  if (isIdValid) {
    const company = allComps.filter(
      (comp) => comp.companyName === formContent.companyId.value
    );

    const category = allCats.filter(
      (cat) => cat.categoryName === formContent.categoryId.value
    );

    companyId = company[0]?.id;
    categoryId = category[0]?.id;
    // console.log(categoryId);
  }

  // submitting

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!validStepOk.isFiveStepOk) {
      handleStep(5);
    }
    if (!validStepOk.isFourStepOk) {
      handleStep(4);
    }
    if (!validStepOk.isThirdStepOk) {
      handleStep(3);
    }
    if (!validStepOk.isSecondStepOk) {
      handleStep(2);
    }
    if (!validStepOk.isFirstStepOk) {
      handleStep(1);
    }

    initDiDedit(true);

    let dataToSend;
    dataToSend = {
      jobName: formContent.jobName.value,
      // salary: formContent.salary.value,
      // salaryRate: formContent.salaryRate.value,
      description: textAreaState.description.stringValue,
      summary: textAreaState.summary.stringValue,
      qualifications: textAreaState.qualifications.stringValue,
      // currency: formContent.currency.value,
      requiredHeadCount: +formContent.requiredHeadCount.value,
      employmentStatus: formContent.employmentStatus.value,
      advantages: textAreaState.advantages.stringValue,
      period: `${t("jobformreg.text1")} ${formContent.periodMinTime.value} ${t(
        "jobformreg.text2"
      )} ${formContent.periodMaxTime.value} ${t("jobformreg.text3")} ${
        formContent.periodPayment.value
      } ${t("jobformreg.text4")} ${formContent.periodDu.value} ${t(
        "jobformreg.text5"
      )} ${formContent.periodAu.value}`,
      other: textAreaState.summary.stringValue,
      // applicationDeadLine: formContent.applicationDeadLine.value,
      isRegistered: formContent.isRegistered.value,
      companyId: +companyId,
      categoryId: +categoryId,
    };

    if (isNumberValid(formContent.salary.value)) {
      dataToSend.salary = formContent.salary.value;
      dataToSend.currency = formContent.currency.value;
      dataToSend.salaryRate = formContent.salaryRate.value;
    }

    if (isTextValid(formContent.applicationDeadLine.value)) {
      dataToSend.applicationDeadLine = formContent.applicationDeadLine.value;
    }

    //

    if (isIdValid && isContentValid) {
      mutate({ data: dataToSend, id: jobId });
    }
  };

  // making a stepForm

  let stepContent;
  switch (step) {
    case 1:
      stepContent = (
        <StepOne
          catTableData={catTableData}
          compTableData={compTableData}
          onCancel={handleCancel}
        />
      );

      break;
    case 2:
      stepContent = <StepTwo />;
      break;

    case 3:
      stepContent = <StepTree />;
      break;

    case 4:
      stepContent = <StepFour />;

      break;
    case 5:
      stepContent = <StepFive />;

      break;

    default:
      break;
  }
  //

  return (
    <motion.div
      initial={{ y: -30, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ delay: 0.3 }}
      className="p-4 mx-auto overflow-y-auto rounded-lg bg-gray-500/5"
    >
      {error?.response?.status === 400 && (
        <p className="mb-2 text-sm font-extrabold text-center text-red-500 border border-t-0 border-b-2 border-red-500 border-solid">
          {t("jobformreg.error1")}
        </p>
      )}
      {error?.response?.status && error?.response?.status !== 400 && (
        <p className="mb-2 text-sm font-extrabold text-center text-red-500 border border-t-0 border-b-2 border-red-500 border-solid">
          {t("jobformreg.error2")}
        </p>
      )}

      <form onSubmit={handleSubmit} className="space-y-2   p-5">
        {status === "pending" ? (
          <PendingUI />
        ) : status === "success" ? (
          <div>
            <RequestSuccess>
              {isEditing?t('jobformreg.editsuccess'):t('jobformreg.addsuccess')}
            </RequestSuccess>
            <Button
              onClick={() => handleCancel()}
              variant="filled"
              color={status === "pending" ? "gray" : "blue"}
              disabled={status === "pending"}
              className={`flex justify-center h-12 pt-4 w-full hover:bg-opacity-70  `}
            >
              <span className="justify-start ">{t("jobformreg.text6")}</span>
              <span className="justify-end text-md">{/* fail action */}</span>
            </Button>
          </div>
        ) : status === "error" ? (
          <div>
            <RequestFailPanel />

            <Button
              onClick={() => handleCancel()}
              variant="filled"
              color={status === "pending" ? "gray" : "blue"}
              disabled={status === "pending"}
              className={`flex justify-center h-12 pt-4 w-full hover:bg-opacity-70  `}
            >
              <span className="justify-start ">
                <div></div>
                {t("jobformreg.text7")}
              </span>
              <span className="justify-end text-md"></span>
            </Button>
          </div>
        ) : (
          stepContent
        )}
      </form>
    </motion.div>
  );
};

export default RegistrationJobForm;
