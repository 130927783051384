import {
  FiEdit,
  FiChevronDown,
  FiTrash,
  FiShare,
  FiPlusSquare,
} from "react-icons/fi";
import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import React from 'react';
import { NavLink } from 'react-router-dom';

const DropDown = ({ name, items, onSelect }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const closeDropdown = (event) => {
      if (!event.target.closest('.dropdown')) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', closeDropdown);
    return () => {
      document.removeEventListener('mousedown', closeDropdown);
    };
  }, []);

  const handleSelect = (link) => {
    setOpen(false);
    onSelect(link); // Notify parent about the selection
  };

  return (
    <div className="flex items-center justify-center">
      <motion.div
        animate={open ? "open" : "closed"}
        className="relative dropdown"
        onMouseEnter={() => setOpen(true)}
      >
        <button className="flex items-center gap-2 px-1">
          <span className="text-sm">{name}</span>
          <motion.span variants={iconVariants}>
            <FiChevronDown />
          </motion.span>
        </button>

        <motion.ul
          initial={wrapperVariants.closed}
          variants={wrapperVariants}
          style={{ originY: "top", translateX: "-50%" }}
          className="flex flex-row gap-2 p-2 rounded-lg bg-white shadow-xl absolute top-[140%] left-[50%] overflow-hidden"
          onMouseLeave={() => setOpen(false)}
        >
          {items.map((item, index) => (
            <Option key={index} Icon={item.icon} text={item.text} link={item.link} handleSelect={handleSelect} />
          ))}
        </motion.ul>
      </motion.div>
    </div>
  );
};

const Option = ({ text, Icon, link, handleSelect }) => {
  return (
    <motion.li variants={itemVariants} className="w-full">
      <NavLink
        to={link}
        onClick={() => handleSelect(link)}
        // className="flex items-center w-full gap-2 p-2 text-xs font-medium transition-colors rounded-md cursor-pointer whitespace-nowrap hover:bg-indigo-100 text-slate-700 hover:text-indigo-500"
        className={`flex items-center w-full gap-2 p-2 text-xs font-medium transition-colors rounded-md cursor-pointer whitespace-nowrap hover:bg-indigo-100 text-slate-700 hover:text-[#0284c7] ${
          window.location.pathname === link
            ? "activeHeader"
            : "" // Ajoute la classe active si l'URL courante correspond à l'URL de l'élément
        }`} >
        <motion.span variants={actionIconVariants}>
          <Icon />
        </motion.span>
        <span>{text}</span>
      </NavLink>
    </motion.li>
  );
};

export default DropDown;

const wrapperVariants = {
  open: {
    scaleY: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
  closed: {
    scaleY: 0,
    transition: {
      when: "afterChildren",
      staggerChildren: 0.1,
    },
  },
};

const iconVariants = {
  open: { rotate: 180 },
  closed: { rotate: 0 },
};

const itemVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: {
      when: "beforeChildren",
    },
  },
  closed: {
    opacity: 0,
    y: -15,
    transition: {
      when: "afterChildren",
    },
  },
};

const actionIconVariants = {
  open: { scale: 1, y: 0 },
  closed: { scale: 0, y: -7 },
};
