import React, { useEffect } from "react";
import sec6img1 from "../../../assets/HpSec6img1.png";

import { Link, useNavigate } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

import AOS from "aos";
import "aos/dist/aos.css";

const SectionTen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/Inscription");
  };

  useEffect(() => {
    AOS.init({
      duration: 1000, // Durée de l'animation en millisecondes
    });
  }, []);

  return (
    <section
      className="w-full py-6 mx-auto overflow-hidden shadow-md "
      style={{
        backgroundImage: `linear-gradient(rgba(2, 132, 199, 0.5), rgba(2, 132, 199, 0.5)), url(${sec6img1})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      data-aos="fade-up"
    >
      <div className="space-x-3 md:flex ">
        <div className="p-2 mx-auto">
          <div className="p-4">
            <h1 className="text-[42px] [font-family:'Inter-Bold',Helvetica] font-bold text-white text-center w-full">
              {t("hP-sectionTen.title")}
            </h1>

            <div className=" font-medium flex justify-center ">
              <p className="pt-5  w-[80%]  text-white ">
                {t("hP-sectionTen.content")}
              </p>
            </div>

            <div className="pt-8 flex justify-center">
              <button
                className="px-2 py-2 [font-family:'Inter-Bold',Helvetica] font-bold  text-white hover:border-2 hover:text-[orange-500] hover:border-white duration-500 bg-[#EA580C] rounded-full btn md:ml-2 md:static shadow-[4px_7px_4.7px_-6px_#00000040]  hover:bg-indigo-500 text-center"
                onClick={handleClick}
              >
                {t("hP-sectionTen.button")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionTen;
