import React, { useMemo, useState, useEffect, useContext } from "react";
import { useTable } from "react-table";
import {
  FaEdit,
  FaTrash,
  FaBan,
  FaSave,
  FaUserPlus,
  FaFolderMinus,
  FaRegFolderOpen,
} from "react-icons/fa";
import ModalAddUser from "../ModalAddUser/ModalAddUser";
import { Button } from "@material-tailwind/react";
import Modal from "../../Modal/Modal";
import Pagination from "../../../../Pagination/Pagination";
import { Input } from "@material-tailwind/react";
import { FaPlus, FaSearch } from "react-icons/fa";
import UserContext from "../../../../../context/AdminContext/UserContext";
import { useTranslation, Trans } from "react-i18next";

const UserTable = ({ data, onDelete, onEdit, onBlock }) => {
  const { t } = useTranslation();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isSaveModalOpen, setSaveModalOpen] = useState(false);
  const [isAddUserModalOpen, setAddUserModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const [selectedRole, setSelectedRole] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [formTitle, setFormTitle] = useState("");

  const roles = [t("usertable.role1"), t("usertable.role2")];

  const itemsPerPage = 10;
  const { usersData, statusUser, deleteUser } = useContext(UserContext);

  useEffect(() => {
    setCurrentPage(1);
  }, [data]);

  const pageCount = Math.ceil(filteredData.length / itemsPerPage);

  // useEffect(() => {
  //   // Extract unique roles from data
  //   const uniqueRoles = [...new Set(data.map((user) => user?.roles))];
  //   setRoles(uniqueRoles);
  // }, [data]);

  const handleRoleChange = (e) => {
    const value = e.target.value;
    setSelectedRole(value);

    // !xx   writting here

    if (value === "All") {
      setFilteredData(data);

      return;
    }
    setFilteredData(data.filter((user) => user?.roles[0] === value));

    //
  };

  useEffect(() => {
    setFilteredData(
      data.filter((user) =>
        user?.email.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  }, [data, searchText]);

  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredData.slice(startIndex, endIndex);
  }, [currentPage, filteredData]);

  const columns = useMemo(() => {
    if (!filteredData || filteredData.length === 0) {
      return [];
    }

    return [
      ...Object.keys(filteredData[0])
        .map((key) => {
          if (
            key === "id" ||
            key === "lastName" ||
            key === "firstName" ||
            key === "address" ||
            key === "phoneNumber"
          ) {
            return null;
          }

          return {
            Header: key,
            accessor: key,
            Cell: ({ row }) => (
              <div>
                {key === "id" ? (
                  row.original[key]
                ) : (
                  <>
                    {isEditing && selectedUser?.id === row.original.id ? (
                      <input
                        type="text"
                        value={editedData[key] || row.original[key]}
                        onChange={(e) =>
                          setEditedData({
                            ...selectedUser,
                            [key]: e.target.value,
                          })
                        }
                      />
                    ) : (
                      row.original[key]
                    )}
                  </>
                )}
              </div>
            ),
          };
        })
        .filter(Boolean),
      {
        Header: t("usertable.action"),
        accessor: "actions",
        Cell: ({ row }) => (
          <div className="flex justify-center space-x-2">
            <button
              onClick={() => handleEdit(row.original)}
              // onClick={() => {
              //   setSelectedUser(row.original);
              //   setSaveModalOpen(true);
              // }}
              className="text-blue-500 hover:text-blue-700"
            >
              <FaEdit />
            </button>
            <button
              onClick={() => {
                setSelectedUser(row.original);
                setDeleteModalOpen(true);
              }}
              className="text-red-500 hover:text-red-700"
            >
              <FaTrash />
            </button>
            {/* <button
              onClick={() => onBlock(row.original)}
              className="hover:text-yellow-500"
            >
              <FaBan />
            </button> */}
          </div>
        ),
      },
    ];
  }, [filteredData, isEditing, selectedUser, editedData]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: paginatedData,
    });

  const handleEdit = (user) => {
    if (user) {
      setAddUserModalOpen(true);
      setSelectedUser(user);
      setFormTitle(t("usertable.changeform_title"));
    }
  };
  const onSaveEdit = () => {
    setSaveModalOpen(true);
  };

  // const onCancelEdit = () => {
  //   setIsEditing(false);
  //   setSelectedUser(null);
  //   setEditedData({});
  // };

  // const handleDelete = (user) => {
  //   setSelectedUser(user);
  //   setDeleteModalOpen(true);
  // };

  const confirmDelete = () => {
    deleteUser(selectedUser);
    // onDelete(selectedUser);
    setDeleteModalOpen(false);
  };

  const cancelDelete = () => {
    setSelectedUser(null);
    setDeleteModalOpen(false);
  };

  const confirmSave = () => {
    onEdit(selectedUser, editedData);
    // console.log("je suis les données à editer", editedData);
    // console.log("je suis le user selectionner", selectedUser);

    setSaveModalOpen(false);
    setIsEditing(false);
    setSelectedUser(null);
    setEditedData({});
  };

  const cancelSave = () => {
    setSaveModalOpen(false);
  };

  const handleAddUser = () => {
    setSelectedUser(null);
    setAddUserModalOpen(true);
    setFormTitle(t("usertable.addform_title"));
  };

  //texte: filtrer par role
  //texte: filtrer par role
  //traduction: Filtering By Role

  //text: Aucun Enregistrement
  //traduction: No Registration

  //texte: ajouter un utilisateur
  //traduction: Add a user

  const onPageChanges = (pageNumber) => {
    const pageToSave = JSON.stringify(pageNumber);
    localStorage.setItem("userPage", pageToSave);
    setCurrentPage(pageNumber);
  };

  const getSavePage = localStorage.getItem("userPage");
  useEffect(() => {
    if (getSavePage) {
      const activePage = JSON.parse(getSavePage);
      setCurrentPage(activePage);
    }

    // return () => {
    //   localStorage.removeItem('userPage')
    // };
  }, [getSavePage, data]);

  return (
    <div className="">
      <div className="flex justify-between py-3">
        <div className="relative items-center justify-start h-[40px] w-1/4">
          <Input
            type="text"
            value={searchText}
            size="md"
            onChange={(e) => setSearchText(e.target.value)}
            placeholder={t("usertable.input_placeholder")}
            aria-label={t("usertable.search_label")}
            className="border pl-10 border-[#D0D5DD] rounded-full bg-white placeholder-[#667085] focus:border-[#457AA8] text-[#667085] h-full"
          />
          <span className="absolute left-3 top-1/2 transform -translate-y-1/2">
            <FaSearch className="text-[#667085]" />
          </span>
        </div>
        <div className="justify-center">
          <span>{t("usertable.role_label")} </span>
          <select value={selectedRole} onChange={handleRoleChange}>
            <option value="All">{t("usertable.roleoption_label")}</option>
            {roles.map((role, index) => (
              <option key={index} value={role}>
                {role}
              </option>
            ))}
          </select>
        </div>
        <div className="justify-end">
          <button
            className="px-2 text-sm flex py-2 space-x-2 font-bold text-white hover:border-2 hover:text-[orange-500] hover:border-white duration-500 bg-[#ea580c] rounded-[25px] btn md:ml-2 md:static shadow-[4px_7px_4.7px_-6px_#00000040] hover:bg-[#457AA8] text-center"
            onClick={handleAddUser}
          >
            <span className="justify-start text-md">
              <FaPlus className="size-6" />
            </span>
            <span className="justify-end">{t("usertable.btn")}</span>
          </button>
        </div>
      </div>
      <div className="overflow-x-auto">
        {filteredData.length > 0 ? (
          <table
            {...getTableProps()}
            className="w-full mt-4 border border-collapse border-[#457AA8]  min-w-max "
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} >
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps()}
                      className={`p-2 bg-[#457AA8] border  border-[#457AA8] text-white ${
                        column.id === "id" ? "w-16" : ""
                      }`}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    className="hover:bg-gray-200 bg-white  border-[#457AA8] cursor-pointer "
                  >
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        className="p-2 text-center border border-[#457AA8]"
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <div className=" items-center flex-col space-y-4 flex w-full text-center text-gray-600  my-8">
            {" "}
            <p className="text-2xl"> {t("usertable.empty_msg")} </p>
            <FaRegFolderOpen size={35} />
          </div>
        )}
      </div>

      {isDeleteModalOpen && (
        <Modal
          title="Confirmation"
          message={t("usertable.dropconfirm_msg") + ` ${selectedUser?.email}?`}
          onCancel={cancelDelete}
          onConfirm={confirmDelete}
        />
      )}
      {isSaveModalOpen && (
        <Modal
          title="Confirmation"
          message={
            t("usertable.saveconfirm_msg") + ` ${selectedUser?.username}?`
          }
          onCancel={cancelSave}
          onConfirm={confirmSave}
        />
      )}
      {isAddUserModalOpen && (
        <ModalAddUser
          isOpen={isAddUserModalOpen}
          onCancel={() => setAddUserModalOpen(false)}
          onConfirm={confirmSave}
          formTitle={formTitle}
          editedUserData={selectedUser}
        />
      )}
      <Pagination
        currentPage={currentPage}
        totalPages={pageCount}
        onPageChange={onPageChanges}
      />
    </div>
  );
};

export default UserTable;
