import React, { useState } from "react";
import { changePwdCode } from "../../../services/HttpRequest/User/pwdRequest/pwdRequest";
import { useMutation } from "@tanstack/react-query";

const ComfirmResetPWModal = ({ onClose, MonEmail }) => {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const { mutate, status } = useMutation({
    mutationFn: changePwdCode,
    onSuccess: () => {
      setShowSuccessModal(true);
    },
    onError: (error) => {
      setError(error.message);
    },
  });

  const handleResetClick = () => {
    if (!email || !code || !password || !confirmPassword) {
      setError("Veuillez remplir tous les champs.");
      return;
    }

    if (password !== confirmPassword) {
      setError("Les mots de passe ne correspondent pas.");
      return;
    }

    mutate({
      email: email,
      code: code,
      newPassword: password,
    });
  };

  const handleModalOk = () => {
    setShowSuccessModal(false);
    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="p-8 bg-white rounded-md shadow-md w-96">
        <h2 className="mb-4 text-2xl font-bold">Réinitialiser le mot de passe</h2>
        {error && (
          <p className="mb-2 text-sm font-bold text-center text-red-500">
            {error}
          </p>
        )}
             <h4 className="mb-4 text-sm text-gray-700">
          Un code de vérification a été envoyé à <span className="font-bold">{MonEmail}</span>. Veuillez entrer le code ainsi que votre nouveau mot de passe.
        </h4>
        <div>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-700"
            >
              Email :
            </label>
            <input
              type="email"
              id="email"
              value={MonEmail}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full border-1 focus:border-2 border-gray-200 p-2 focus:border-[#457AA8] focus:outline-none focus:ring-0 rounded-xl bg-blue-100 text-black"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="code"
              className="block mb-2 text-sm font-medium text-gray-700"
            >
              Code de vérification :
            </label>
            <input
              type="text"
              id="code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              className="w-full border-1 focus:border-2 border-gray-200 p-2 focus:border-[#457AA8] focus:outline-none focus:ring-0 rounded-xl bg-blue-100 text-black"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="password"
              className="block mb-2 text-sm font-medium text-gray-700"
            >
              Nouveau mot de passe :
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full border-1 focus:border-2 border-gray-200 p-2 focus:border-[#457AA8] focus:outline-none focus:ring-0 rounded-xl bg-blue-100 text-black"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="confirmPassword"
              className="block mb-2 text-sm font-medium text-gray-700"
            >
              Confirmer le mot de passe :
            </label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="w-full border-1 focus:border-2 border-gray-200 p-2 focus:border-[#457AA8] focus:outline-none focus:ring-0 rounded-xl bg-blue-100 text-black"
            />
          </div>
          <div className="flex justify-center space-x-4">
            <button
              type="button"
              onClick={handleResetClick}
              className="px-4 py-2 text-white bg-[#457AA8] rounded-md hover:bg-gray-700"
            >
              Réinitialiser
            </button>
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 mr-2 text-gray-800 bg-gray-200 rounded-md hover:bg-gray-300"
            >
              Fermer
            </button>
          </div>
        </div>
      </div>

      {showSuccessModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
          <div className="p-8 bg-white rounded-md shadow-md w-96">
            <h2 className="mb-4 text-2xl font-bold">Réinitialisation réussie</h2>
            <p className="mb-4 text-sm text-gray-700">
              Votre mot de passe a été réinitialisé avec succès.
            </p>
            <div className="flex justify-center">
              <button
                type="button"
                onClick={handleModalOk}
                className="px-4 py-2 text-white bg-[#457AA8] rounded-md hover:bg-gray-700"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ComfirmResetPWModal;
