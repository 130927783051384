import React from "react";
import { FaCheckCircle, FaFilePdf } from "react-icons/fa";

const CvListItem = ({ file, id }) => {
  const handleClick = () => {};

  return (
    <li className="my-1  mx-2 justify-between bg-[#f7fbff] transition-all duration-200 ease-in-out  border-b-2 h-16 items-center w-auto rounded-md px-2 hover:bg-slate-300  flex">
      <FaFilePdf size={20} className="text-[#457AA8] " />
      <h3 className="overflow-y-hidden text-green-600">{file.fileName}</h3>
      <h3 className="overflow-hidden text-gray-600">
        {file.id === id && (
          <FaCheckCircle className="size-4 lg:size-5 text-green-400" />
        )}
      </h3>
    </li>
  );
};

export default CvListItem;
