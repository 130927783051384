import React from "react";
import ModalUI from "../ModalUI";
import ButtonStudentUI from "../ButtonUI";
import { useTranslation } from "react-i18next";

const ModalInfos = ({
  message,
  isOpen,
  autoClose,
  textClass,
  buttonClass,
  icon,
  containerClass,
}) => {
  const { t } = useTranslation();
  return (
    <ModalUI classname={"p-3"} open={isOpen} autoclose={autoClose}>
      <div
        className={`lg:max-w-72 text-center flex space-y-5 pt-2 max-w-64 text-sm lg:text-lg flex-col items-center justify-center w-full  ${containerClass} `}
      >
        {icon}
        <p className={textClass}>{message}</p>
        <ButtonStudentUI onClick={autoClose} classname={`${buttonClass}`}>
         ok
        </ButtonStudentUI>
      </div>
    </ModalUI>
  );
};

export default ModalInfos;
