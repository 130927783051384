import { createContext } from "react";

const CvContext = createContext({
    allDocsStatus: "pending" || "error" || "success",
    allDocsData: [],
    handleViewDoc:(id)=>{},
    viewDoc: {
       status:'idle',
       fileName:'',
       file:'',
       id:''
      },
})
export default CvContext