import { NEWSLETTER_GET } from "../../api"
import { axiosUseToken } from "../../axiosConfig"



  export const newsletterGet = async () => {
    const response = await axiosUseToken.get(NEWSLETTER_GET);
  // console.log('voici la reponse de la requete', response);

    return response;
  };