import React, { useContext, useEffect, useState } from "react";

import RegistrationJobForm from "../components/Form/RegistrationJobForm";
import FormContainerUI from "../components/UI/FormContainerUI";
import { useNavigate, useParams } from "react-router-dom";
import AddJobContext from "../context/AddJobContext/context";
import { getOneCategory } from "../services/HttpRequest/catRequest/catRequest";
import { getOneComp } from "../services/HttpRequest/adminRequest/compRequest";
import { useSelector } from "react-redux";
import PendingModal from "../components/QuickApply_Cpt/UI/PendingModal";
import ModalStatusMessage from "../components/Contact/SectionOne.js/ModalStatusMessage";
import { Trans, useTranslation } from "react-i18next";
import CompContext from "../context/CompContext";
import { FaArrowCircleLeft } from "react-icons/fa";
import ManageBord from "../components/Admin_Cpt/ManageBord/ManageBord";
import Navbar from "../components/Header/NavBar/Navbar";
import { selectCurrentUser } from "../store/slice/loginSlice";

const stepTable = [1, 2, 3, 4, 5];

const EditJobPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const user = useSelector(selectCurrentUser);
  const userRole = user?.roles[0];
  const { allComps, statusComp } = useContext(CompContext);
  const allCats = useSelector((state) => state.category.allCats);
  const statusCat = useSelector((state) => state.category.statusCat);
  const [preEditStatut, setPreEditStatus] = useState("idle");
  const [controlPreEditError, setControlPreEditError] = useState(false);
  const { step, isEditing, HandleDataToEdit } = useContext(AddJobContext);
  const { id } = useParams();
  const jobsState = useSelector((state) => state.jobs);
  const activeJob = jobsState?.saveAllobs?.find(
    (job) => job.id.toString() === id.toString()
  );

  const HandleEditJob = async (activeJob) => {
    if (id === "newJob") {
      HandleDataToEdit({}, false);
      return;
    }
    try {
      setPreEditStatus("pending");
      // const catResponse = await getOneCategory(activeJob.categoryId);
      const cat = allCats.find((cat) => cat.id === activeJob?.categoryId);
      HandleDataToEdit(activeJob, true, cat, {});
      // const compResponse = await getOneComp(activeJob.companyId);
      setPreEditStatus("success");
      // navigate("/admin/editJob/"+job.id);
      const comp = allComps.find((comp) => comp.id === activeJob?.companyId);
      HandleDataToEdit(activeJob, true, cat, comp);
    } catch (error) {
      // navigate("/admin/editJob/" + job.id);

      setPreEditStatus("error");
      setControlPreEditError(true);
    }
  };
  useEffect(() => {
    if (id === "newJob") {
      HandleDataToEdit({}, false);
      return;
    }
    if (activeJob) {
      HandleEditJob(activeJob);
    }
    if (
      jobsState.statusJob === "pending" ||
      statusComp === "pending" ||
      statusCat === "pending"
    ) {
      setPreEditStatus("pending");
    } else {
      setPreEditStatus("idle");
    }
  }, [
    activeJob,
    id,
    allCats,
    allComps,
    statusCat,
    statusComp,
    jobsState,
    isEditing,
  ]);

  useEffect(() => {
    if (!isEditing) {
      // HandleDataToEdit({},false)
    }
  }, [isEditing]);

  return (
    <main className=" mt-20 min-h-svh h-full">
      <div className="flex justify-center ">
        <Navbar userRole={userRole} />
      </div>
      {/* <div
        className="flex p-2 cursor-pointer hover:opacity-85"
        onClick={() => navigate("/admin/MenuGestion")}
      >
        <FaArrowCircleLeft className=" hover:text-red-500 size-7 text-[#457AA8] hover:scale-110 shadow-xl ease-in-out duration-300 lg:size-10 lg:ml-[10%] " />
        <h2 className="font-semibold text-gray-700 mx-2 hover:text-red-500 ">
          Annuler
        </h2>
      </div> */}
      <div className="h-20 lg:h-16 w-full  mb-6 flex items-center justify-center ">
        <h1 className="text-stone-700  font-semibold">
          {isEditing ? t("addjob.title1") : t("addjob.title2")}
        </h1>
      </div>
      <PendingModal isOpen={preEditStatut === "pending"} autoclose={() => {}} />
      <ModalStatusMessage
        message={t("jobtable.error")}
        status={preEditStatut}
        open={preEditStatut === "error" && controlPreEditError}
        autoclose={() => {
          setControlPreEditError(false);
        }}
      />
      <div className="  flex justify-around items-center  max-w-[920px]  mx-auto rounded-xl bg-[#457AA8] bg-opacity-85 h-2 lg:h-4 ">
        {stepTable.map((stepValue) => (
          <span
            key={stepValue}
            className={`size-10 lg:size-14 transition-all ease-linear duration-300 border-8 border-gray-200 text-center pt-1 lg:pt-2 lg:font-bold  font-semibold max-lg:text-[12px] text-gray-700  rounded-full ${
              stepValue === step
                ? "bg-[#EA580C] text-white scale-125 "
                : "bg-white"
            } `}
          >
            {stepValue}{" "}
          </span>
        ))}
      </div>
      <div className="justify-center pt-14  flex">
        <FormContainerUI>
          <RegistrationJobForm />
        </FormContainerUI>
      </div>
    </main>
  );
};

export default EditJobPage;
