import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import fetchDataCandidacy from "../store/action/fetchDataCandidacy";
import { candidacyActions } from "../store/slice/candidacySlice";
import UserApplication from "../components/MyCandidacy/UserApplication";
import { selectCurrentUser } from "../store/slice/loginSlice";
import CandidacyContextProvider from "../context/CandidacyContext/CandidacyContextProvider";
// import Favoris from "../components/MyCandidacy/Favoris";
// import Archives from "../components/MyCandidacy/Archives";
// import Candidacy from "../components/MyCandidacy/Candidacy";

const MyCandidacy = () => {
  //get user

  //userr
  const currentUser = useSelector(selectCurrentUser);

  const dispatch = useDispatch();

  // fetch the data from the table

  // const newDataCandidacy = useSelector(
  //   (state) => state.candidacy.updateCandidacy
  // );
  // // setting the quantity
  // useEffect(() => {
  //   dispatch(candidacyActions.handleQuantity());
  // }, [dispatch, newDataCandidacy]);

  // setting an active menu

  return (
    <div className="flex-1 self-center bg-[#F9FAFB] px-5 w-full mx-auto min-h-svh">
      {/* <div className="justify-center flex-1">
        <h1 className="text-4xl border-b-2 border-blue-500 max-md:text-xl">
          Mes Candidatures<span className="md:hidden"></span>
        </h1>
        <ul className="flex justify-around mt-2 mb-5 shadow-xl h-28">
          <li
            className={`p-5 rounded-md hover:cursor-pointer hover:bg-gray-100 flex items-center transition-all duration-150 flex-col ${
              isMenuActive === "favoris" && "border-b-gray-500  border-b-4"
            }`}
            onClick={() => handleActiveMenu("favoris")}
          >
            <span className="justify-center text-sm text-white size-8 py-[0.4rem] bg-[#5190B7] text-center rounded-full">
              {tFavoris}
            </span>
            <h2 className="text-xl max-md:hidden">Favoris</h2>
            <FaStar size={40} className="md:hidden text-[#5190B7]" />
          </li>
          <li
            className={`p-5 rounded-md hover:cursor-pointer hover:bg-gray-100 flex items-center transition-all duration-150 flex-col ${
              isMenuActive === "candidacy" && "border-b-gray-500   border-b-4"
            }`}
            onClick={() => handleActiveMenu("candidacy")}
          >
            <span className="justify-center text-sm text-white size-8 mx-[10%] py-[0.4rem] bg-[#5190B7] text-center rounded-full">
              {tMessage}
            </span>
            <h2 className="flex text-xl max-md:hidden">Candidature envoyée</h2>
            <FaEnvelope size={40} className="md:hidden text-[#5190B7]" />
          </li>
          <li
            className={`rounded-md flex hover:cursor-pointer hover:bg-gray-100 items-center transition-all duration-150 p-5 flex-col ${
              isMenuActive === "archives" && "border-b-gray-500   border-b-4"
            }`}
            onClick={() => handleActiveMenu("archives")}
          >
            <span className="justify-center text-sm text-white size-8 py-[0.4rem] bg-[#5190B7] text-center rounded-full">
              {tArhiver}
            </span>
            <h2 className="text-xl max-md:hidden">Archives</h2>
            <FaArchive size={40} className="md:hidden text-[#5190B7] mb-1" />
          </li>
        </ul>
      </div> */}
      {/* <div className="">
        {isMenuActive === "favoris" && <Favoris />}
        {isMenuActive === "archives" && <Archives />}
        {isMenuActive === "candidacy" && <Candidacy />}
      </div> */}
      <CandidacyContextProvider>
        <UserApplication />
      </CandidacyContextProvider>
    </div>
  );
};

export default MyCandidacy;
