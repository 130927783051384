import React, { useMemo, useState, useEffect } from "react";
import { useTable } from "react-table";
import { FaEdit, FaTrash, FaBan, FaSave, FaBuilding } from "react-icons/fa";
import { Button } from "@material-tailwind/react";
import Modal from "../../Modal/Modal";
import ModalUI from "../../../../UI/ModalUI";
import ModalAddUser from "../ModalAddComp/ModalAddComp";
import Pagination from "../../../../Pagination/Pagination";
import ManageBanner from "../../ManageBanner/ManageBanner.js";
import { useTranslation, Trans } from "react-i18next";

const CompTable = ({ data, onDelete, onEdit, onBlock }) => {
  const { t } = useTranslation();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isSaveModalOpen, setSaveModalOpen] = useState(false);
  const [isAddCompModalOpen, setAddCompModalOpen] = useState(false);
  const [selectedComp, setSelectedComp] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const [modalDescription, setModalDescription] = useState(false);
  const [compForModalDescription, setCompForModalDescription] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const pageCount = Math.ceil(filteredData.length / pageSize);
  const [formTitle, setFormTitle] = useState("");

  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return filteredData.slice(startIndex, endIndex);
  }, [currentPage, filteredData]);

  // handle page change
  const onPageChange = (pageNumber) => {
    const pageToSave = JSON.stringify(pageNumber);
    localStorage.setItem("compPage", pageToSave);
    setCurrentPage(pageNumber);
  };

  const getSavePage = localStorage.getItem("compPage");
  useEffect(() => {
    if (getSavePage) {
      const activePage = JSON.parse(getSavePage);
      setCurrentPage(activePage);
    }

    // return () => {
    //   localStorage.removeItem('compPage')
    // };
  }, [getSavePage, data]);

  //
  const HandleOpenModalDescription = (selectedComp) => {
    // setModalDescription(true);
    // setCompForModalDescription(selectedComp);
  };

  const handleAddComp = () => {
    setSelectedComp();
    setFormTitle(t("comp_table.form_title1"));
    setAddCompModalOpen(true);
  };

  const handleCloseModalCat = () => {
    setAddCompModalOpen(false);
  };

  const HandleCloseModalDescription = () => {
    setModalDescription(false);
  };

  const columns = useMemo(() => {
    if (!data || data.length === 0) {
      return [];
    }

    return [
      ...Object.keys(data[0]).map((key) => ({
        Header: key,
        accessor: key,
        Cell: ({ row }) => (
          <div
            className="hover:text-[#457AA8] cursor-pointer"
            onClick={() => HandleOpenModalDescription(row.original)}
          >
            {row.original[key]}
          </div>
        ),
      })),
      {
        Header: t("comp_table.action"),
        accessor: "actions",
        Cell: ({ row }) => (
          <div className="flex justify-center space-x-2">
            <button
              onClick={() => onEditUser(row.original)}
              className="text-blue-500 hover:text-blue-700"
            >
              <FaEdit />
            </button>
            <button
              onClick={() => handleDelete(row.original)}
              className="text-red-500 hover:text-red-700"
            >
              <FaTrash />
            </button>
            {/* <button
              onClick={() => onBlock(row.original)}
              title="Block"
              className="hover:text-yellow-500"
            >
              <FaBan />
            </button> */}
          </div>
        ),
      },
    ];
  }, [data]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: paginatedData,
    });

  useEffect(() => {
    setFilteredData(
      data?.filter((comp) =>
        comp.companyName.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  }, [data, searchText]);

  const onEditUser = (comp) => {
    setSelectedComp(comp);
    setFormTitle(t("comp_table.form_title2"));
    setAddCompModalOpen(true);
  };

  const handleDelete = (comp) => {
    setSelectedComp(comp);
    setDeleteModalOpen(true);
  };

  const confirmDelete = () => {
    onDelete(selectedComp);
    setDeleteModalOpen(false);
  };

  const cancelDelete = () => {
    setSelectedComp(null);
    setDeleteModalOpen(false);
  };

  const confirmSave = () => {
    onEdit(selectedComp);
    setSaveModalOpen(false);
    setSelectedComp(null);
  };

  const cancelSave = () => {
    setSaveModalOpen(false);
  };

  const handleAddUser = () => {
    handleAddComp();
  };

  return (
    <div>
      <ManageBanner
        handleClick={handleAddUser}
        buttonText={t("comp_table.add_btn")}
        searchText={searchText}
        setSearchText={setSearchText}
      />
      <div className="overflow-x-auto">
        <table
          {...getTableProps()}
          className="w-full mt-4 border border-collapse border-[#457AA8] min-w-max"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    className="p-2 bg-[#457AA8] border  border-[#457AA8] text-white"
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className="hover:bg-gray-200 bg-white  border-[#457AA8] cursor-pointer"
                >
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      className="p-2 text-center border border-[#457AA8]"
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {isDeleteModalOpen && (
        <Modal
          title="Confirmation"
          message={t("comp_table.drop_msg") + ` ${selectedComp?.companyName}?`}
          onCancel={cancelDelete}
          onConfirm={confirmDelete}
        />
      )}
      {isSaveModalOpen && (
        <Modal
          title="Confirmation"
          message={t("comp_table.save_msg") + `${selectedComp?.companyName}?`}
          onCancel={cancelSave}
          onConfirm={confirmSave}
        />
      )}

      {isAddCompModalOpen && (
        <ModalAddUser
          isOpen={isAddCompModalOpen}
          onCancel={handleCloseModalCat}
          onConfirm={handleAddComp}
          editedCompanyData={selectedComp}
          formTitle={formTitle}
        />
      )}
      <ModalUI
        autoclose={HandleCloseModalDescription}
        open={modalDescription}
        classname="md:w-[40rem]"
      >
        <div className="p-4">
          {" "}
          <div className=" border-b-2 text-center text-xl text-[#457AA8] font-[500]">
            {compForModalDescription.companyName}
          </div>
          <div className="space-x-4 ">
            <div className="my-1 text-center ">
              <h1>
                {t("comp_table.address")} <br />
                {compForModalDescription.companyAdress}
              </h1>
              <h1 className="text-gray-400">
                {compForModalDescription.companyEmail}
              </h1>
            </div>
            <div>
              <h1 className="text-md my-1 text-center font-mono text-[#1d3448]">
                {t("comp_table.description1")}
              </h1>
              <div className="">
                <p>{t("comp_table.changes_msg")}</p>
                <p>{t("comp_table.description2")}</p>
              </div>
            </div>
          </div>
          <div className="flex justify-center my-4 ">
            <Button
              variant="filled"
              onClick={HandleCloseModalDescription}
              className="h-10 outline-none py-1 hover:opacity-75 text-lg bg-[#457AA8] w-28"
            >
              {t("comp_table.cancel")}
            </Button>
          </div>
        </div>
      </ModalUI>
      <Pagination
        currentPage={currentPage}
        totalPages={pageCount}
        onPageChange={onPageChange}
      />
    </div>
  );
};

export default CompTable;
