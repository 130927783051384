import React, { createContext, useEffect, useState } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { getAllSuperUser, deleteSuperUser } from "../../services/HttpRequest/adminRequest/userRequest";
import { queryClient } from "../../services/HttpRequest/queryClientProvider/queryClientProvider";
import { toast } from 'react-toastify';

const UserContext = createContext({
  usersData: [],
  statusUser: "",
  numberOfUsers: 0,
  deleteUser: (userId) => {},
  deleteStatus: "",
});

const handleActiveUser = () => {
  const getActiveUser = localStorage.getItem("activeUser");
  const user = JSON.parse(getActiveUser);
  if (user) {
    return user;
  }
  return {};
};

export const UserContextProvider = ({ children }) => {
  const [usersData, setUsersData] = useState([]);
  const [deleteStatus, setDeleteStatus] = useState("");

  const { data, status } = useQuery({
    queryKey: ['getAllSuperUser'],
    queryFn: getAllSuperUser,
  });

  useEffect(() => {
    if (status === "success") {
      setUsersData(data.data);
    }
  }, [data]);

  const { mutate: deleteUserMutation, status: deleteMutationStatus } = useMutation({
    mutationFn: deleteSuperUser, 
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getAllSuperUser"] });
      setDeleteStatus("success");
    },
    onError: () => {
      setDeleteStatus("error");
    },
  });

  const deleteUser = (userId) => {
    deleteUserMutation(userId.id);
  };

  const numberOfUsers = usersData.length;

  const values = {
    usersData,
    statusUser: status,
    numberOfUsers,
    deleteUser,
    deleteStatus,
  };

  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};

export default UserContext;
