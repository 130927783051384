import React, { useEffect, useState } from "react";
import { Button } from "@material-tailwind/react";
import { FaSave, FaTrash } from "react-icons/fa";
import {
  isEmailValid,
  isTextValid,
  isPasswordValid,
} from "../Utils/ValidFormElement";
import { useMutation } from "@tanstack/react-query";
import {
  createUserRequest,
  editUserRequest,
} from "../../services/HttpRequest/adminRequest/userRequest";
import { queryClient } from "../../services/HttpRequest/queryClientProvider/queryClientProvider";
import { motion } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

const userRoles = [
  { name: "Administrateur", id: 0 },
  { name: "Manager", id: 1 },
];

const AddUserForm = ({ onCancel, editedUserData }) => {
  const { t } = useTranslation();
  const [formContent, setFormContent] = useState({
    email: { value: "", didEdit: false, error: "" },
    role: { value: "", didEdit: false, error: "" },
    password: { value: "", didEdit: false, error: "" },
    confirmPassword: { value: "", didEdit: false, error: "" },
  });

  const mutationFn = editedUserData ? editUserRequest : createUserRequest;

  const { mutate, error, status } = useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries(["getAllUsers"]);
      toast.success(editedUserData ? t("form.update") : t("form.save"));
      onCancel();
    },
  });

  useEffect(() => {
    if (editedUserData) {
      setFormContent({
        email: { value: editedUserData.email, didEdit: false, error: "" },
        role: { value: editedUserData.role, didEdit: false, error: "" },
        password: { value: "", didEdit: false, error: "" },
        confirmPassword: { value: "", didEdit: false, error: "" },
      });
    }
  }, [editedUserData]);

  const validateForm = () => {
    let isValid = true;

    // Validation de l'email
    if (!isEmailValid(formContent.email.value)) {
      setFormContent((prev) => ({
        ...prev,
        email: { ...prev.email, error: t("form.emailInvalid") },
      }));
      isValid = false;
    } else {
      setFormContent((prev) => ({
        ...prev,
        email: { ...prev.email, error: "" },
      }));
    }

    // Validation du rôle
    if (!isTextValid(formContent.role.value)) {
      setFormContent((prev) => ({
        ...prev,
        role: { ...prev.role, error: t("form.roleRequired") },
      }));
      isValid = false;
    } else {
      setFormContent((prev) => ({
        ...prev,
        role: { ...prev.role, error: "" },
      }));
    }

    // Validation du mot de passe (si non vide lors de l'édition)
    if (!editedUserData && !formContent.password.value) {
      setFormContent((prev) => ({
        ...prev,
        password: { ...prev.password, error: t("form.passwordRequired") },
      }));
      isValid = false;
    } else if (
      !editedUserData &&
      !isPasswordValid(formContent.password.value)
    ) {
      setFormContent((prev) => ({
        ...prev,
        password: { ...prev.password, error: t("form.passwordInvalid") },
      }));
      isValid = false;
    } else {
      setFormContent((prev) => ({
        ...prev,
        password: { ...prev.password, error: "" },
      }));
    }

    // Validation de la confirmation de mot de passe (si mot de passe non vide)
    if (
      formContent.password.value &&
      formContent.password.value !== formContent.confirmPassword.value
    ) {
      setFormContent((prev) => ({
        ...prev,
        confirmPassword: {
          ...prev.confirmPassword,
          error: t("form.passwordMismatch"),
        },
      }));
      isValid = false;
    } else {
      setFormContent((prev) => ({
        ...prev,
        confirmPassword: { ...prev.confirmPassword, error: "" },
      }));
    }

    return isValid;
  };

  const handleChangeField = (id, value) => {
    setFormContent((prev) => ({
      ...prev,
      [id]: { value: value, didEdit: true },
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (validateForm()) {
      const dataToSend = {
        email: formContent.email.value,
        role: formContent.role.value,
        password: formContent.password.value,
      };

      if (editedUserData) {
        mutate({
          data: { id: editedUserData.id, data: dataToSend },
        });
      } else {
        mutate({ data: dataToSend });
      }
    }
  };

  return (
    <motion.div
      initial={{ y: -30, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ delay: 0.3 }}
      className="p-4 mx-auto overflow-y-auto rounded-lg bg-gray-500/5"
    >
      <ToastContainer />
      {error?.response?.status && (
        <p className="mb-2 text-sm font-extrabold text-center text-red-500 border border-t-0 border-b-2 border-red-500 border-solid">
          {error?.response?.status === 400
            ? t("form.emailUsed")
            : t("form.errorOccurred")}
        </p>
      )}
      <form onSubmit={handleSubmit} className="space-y-2 overflow-y-auto">
        <div className="grid md:grid-cols-2 gap-4">
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              {t("form.email")}
            </label>
            <input
              disabled={status === "pending"}
              type="text"
              name="email"
              id="email"
              value={formContent.email.value}
              onChange={(e) => handleChangeField("email", e.target.value)}
              className="w-full p-2 mt-1 border rounded-md focus:outline-none"
            />
            <p className="text-red-500 text-xs mt-1">
              {formContent.email.error}
            </p>
          </div>
          <div>
            <label
              htmlFor="role"
              className="block text-sm font-medium text-gray-700"
            >
              {t("form.role")}
            </label>
            <select
              id="role"
              value={formContent.role.value}
              onChange={(e) => handleChangeField("role", e.target.value)}
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              disabled={status === "pending"}
            >
              <option value="">{t("form.role")}</option>
              {userRoles.map((role) => (
                <option key={role.id} value={role.name}>
                  {t(`userRoles.${role.name}`)}
                </option>
              ))}
            </select>
            <p className="text-red-500 text-xs mt-1">
              {formContent.role.error}
            </p>
          </div>
          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              {t("form.password")}
            </label>
            <input
              disabled={status === "pending"}
              type="password"
              name="password"
              id="password"
              value={formContent.password.value}
              onChange={(e) => handleChangeField("password", e.target.value)}
              className="w-full p-2 mt-1 border rounded-md focus:outline-none"
            />
            <p className="text-red-500 text-xs mt-1">
              {formContent.password.error}
            </p>
          </div>
          <div>
            <label
              htmlFor="confirmPassword"
              className="block text-sm font-medium text-gray-700"
            >
              {t("form.confirmPassword")}
            </label>
            <input
              disabled={status === "pending"}
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              value={formContent.confirmPassword.value}
              onChange={(e) =>
                handleChangeField("confirmPassword", e.target.value)
              }
              className="w-full p-2 mt-1 border rounded-md focus:outline-none"
            />
            <p className="text-red-500 text-xs mt-1">
              {formContent.confirmPassword.error}
            </p>
          </div>
        </div>

        {status === "pending" ? (
          <div className="my-28  justify-evenly text-center  ">
            <motion.span
              animate={{ scale: 1.6, opacity: [1, 0.7, 0.5, 0.3, 0.5, 0.7] }}
              transition={{ repeat: 100, duration: 3 }}
              className="justify-start text-center text-2xl font-bold text-green-500 "
            >
              {t("form.saving")}
            </motion.span>
          </div>
        ) : (
          <div className="flex justify-evenly">
            <Button
              type="reset"
              variant="filled"
              color="red"
              onClick={onCancel}
              disabled={status === "pending"}
              className="flex items-center space-x-2"
            >
              <FaTrash />
              <span>{t("form.cancel")}</span>
            </Button>
            <Button
              type="submit"
              variant="filled"
              color="green"
              disabled={status === "pending"}
              className="flex items-center space-x-2"
            >
              <FaSave />
              <span>{editedUserData ? t("form.update") : t("form.save")}</span>
            </Button>
          </div>
        )}
      </form>
    </motion.div>
  );
};

export default AddUserForm;
