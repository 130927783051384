import React, { useMemo, useState, useEffect } from "react";
import { useTable } from "react-table";
import Modal from "../../Modal/Modal";
import Pagination from "../../../../Pagination/Pagination";
import ManageBanner from "../../ManageBanner/ManageBanner.js";
import { useTranslation } from "react-i18next";

const CandidatureTable = ({
  data,
  columns,
  onDelete,
  onEdit,
  addForm,
  pageSize = 10,
}) => {
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    setCurrentPage(1);
  }, [data]);

  const pageCount = useMemo(
    () => Math.ceil(data.length / pageSize),
    [data, pageSize]
  );

  const filteredData = useMemo(() => {
    if (!Array.isArray(data)) {
      return [];
    }
    return data.filter((item) => {
      return Object.values(item).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchText.toLowerCase())
      );
    });
  }, [data, searchText]);

  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return filteredData.slice(startIndex, endIndex);
  }, [currentPage, filteredData, pageSize]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: paginatedData,
    });

  const cancelModal = () => {
    setSelectedItem(null);
    setModalOpen(false);
  };

  const confirmDelete = () => {
    if (selectedItem) {
      onDelete(selectedItem);
      setModalOpen(false);
    }
  };

  return (
    <div>
      <ManageBanner
        handleClick={addForm}
        // buttonText="Ajouter un élément"
        searchText={searchText}
        setSearchText={setSearchText}
      />

      <div className="overflow-x-auto">
        <table
          {...getTableProps()}
          className="w-full mt-4 border border-collapse border-[#457AA8] min-w-max"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    className="p-2 bg-[#457AA8] border  border-[#457AA8] text-white"
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className="hover:bg-gray-200 bg-white  border-[#457AA8] cursor-pointer"
                >
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      className="p-2 text-center border border-[#457AA8]"
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {modalOpen && (
        <Modal
          title="Confirmation"
          message={t("applytable.modal_msg")}
          onCancel={cancelModal}
          onConfirm={confirmDelete}
        />
      )}
      <Pagination
        currentPage={currentPage}
        totalPages={pageCount}
        onPageChange={setCurrentPage}
      />
    </div>
  );
};

export default CandidatureTable;
