import React from "react";
import ConnexionCpt from "../components/Conexion/Connexion";

const Connexion = () => {
  return (
    <main className="">
      <ConnexionCpt/>
    </main>
  );
};

export default Connexion;
