import {
  FORGOT_PASSWORD,
  SEND_VERIFICATION_CODE,
  RESEND_CONFIRMATION_MAIL,
  CONFIRM_MAIL,
  UPDATE_PASSWORD,
  CHANGE_PWD_WITH_CODE
} from "../../../api";

import axios, { axiosUseToken } from "../../../axiosConfig";

export const forgotPassword = async ( data ) => {
  // console.log('reçu', data)
  const response = await axios.post(FORGOT_PASSWORD, data);
  // console.log('responseinsgbfgb', response)
  return response;
};

export const changePwdCode = async ( data ) => {
  // console.log('reçu', data)
  const response = await axios.post(CHANGE_PWD_WITH_CODE, data);
  // console.log('CHANGE_PWD_WITH_CODE', response)
  return response;
};



export const sendVerifCode = async ( data ) => {
  // console.log('reçu', data)
  const response = await axios.post(SEND_VERIFICATION_CODE, data);
  // console.log('SEND_VERIFICATION_CODE', response)
  return response;
};

export const UpdatePassword = async ( data ) => {
  // console.log('responseins', data)
  const response = await axiosUseToken.post(UPDATE_PASSWORD, data);
  // console.log('responseins', response)
  return response;
};
