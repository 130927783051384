import { Button } from "@material-tailwind/react";
import ModalUI from "../../../../UI/ModalUI";
import { useContext, useState } from "react";

import { filterTableData } from "../../../../Utils/filterData";
import CatContext from "../../../../../context/CatContext";
import CompContext from "../../../../../context/CompContext";
import {
  dateFormatterLong,
  dateFormatterShort,
} from "../../../../Utils/dateFormatter";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
import { Monitor } from "react-feather";
import TextItems from "../../../../Cpt_Emploi/DetailJob/TextItems";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../../store/slice/loginSlice";
import { useTranslation, Trans } from "react-i18next";

const Description = ({ handleClose, openValue, job }) => {
  const { t } = useTranslation();
  //state

  const [step, setStep] = useState(1);

  // contex call
  const allCats = useSelector((state) => state.category.allCats);
  const category = filterTableData(
    allCats,
    "id",
    job?.categoryId
  )?.categoryName;
  const { allComps } = useContext(CompContext);
  const company = filterTableData(allComps, "id", job?.companyId)?.companyName;

  // step

  const handleStep = (type) => {
    if (type === "prev" && step > 1) {
      setStep((prevStep) => prevStep - 1);
    }
    if (type === "next" && step < 3) {
      setStep((prevStep) => prevStep + 1);
    }
  };

  const user = useSelector(selectCurrentUser);
  const userRole = user?.roles[0];
  // nanimation

  const anime = {
    hidden: { x: 30, opacity: 0 },
    visible: { x: 0, opacity: 1 },
    final: { x: -30, opacity: 0 },
    visibleFromLEft: { x: -30, opacity: 0 },
  };

  // step
  let stepContent;
  switch (step) {
    case 1:
      stepContent = (
        <>
          <div className="flex py-2 text-gray-600 max-md:flex-col ">
            <div className="flex flex-col flex-1 h-auto p-2 md:border-r-2 border-r-gray-600 bg-blue-5 ">
              {userRole !== "Client" && (
                <>
                  {" "}
                  <p className="my-1 flex flex-col   h-8 bg-[#457AA8] p-1 mx-1 text-white  text-center items-center w-full">
                    {t("description.label1")}
                  </p>
                  <h4 className="h-8 ml-2 text-sm text-center">
                    {company && company}
                  </h4>
                </>
              )}
              <p className="my-1 flex flex-col h-8 bg-[#457AA8] p-1 mx-1 text-white  text-center items-center w-full">
                {t("description.label2")}
              </p>
              <h4 className="min-h-8 ml-2 text-center">
                {category && category}
              </h4>
              <p className="my-1 flex flex-col h-8 bg-[#457AA8] p-1 mx-1 text-white  text-center items-center w-full">
                {t("description.label3")}
              </p>
              <h4 className="min-h-8 ml-2 text-sm text-center">
                {job?.creationDate?.trim().length > 0 && dateFormatterLong(job.creationDate)}
              </h4>
              <p className="my-1 flex flex-col h-8 bg-[#457AA8] p-1 mx-1 text-white  text-center items-center w-full">
                {" "}
                {t("description.label4")}
              </p>
              <h4 className="min-h-8 ml-2 text-sm text-center">
                {job.applicationDeadLine?.trim().length>0 ? dateFormatterLong(job.applicationDeadLine):"/"}
              </h4>
              <p className="my-1 flex flex-col h-8 bg-[#457AA8] p-1 mx-1 text-white  text-center items-center w-full">
                {t("description.label5")}
              </p>
              <h4 className="min-h-8 ml-2 text-sm text-center">
                {job.salary ? `${job.salary} ${job.currency} ${job.salaryRate}`:"/"}
              </h4>
              <p className="my-1 flex flex-col h-8 bg-[#457AA8] p-1 mx-1 text-white  text-center items-center w-full">
                {t("description.label6")}
              </p>
              <h4 className="min-h-8 ml-2 text-sm text-center">
                {`${job.period}`}
              </h4>
              <p className="my-1  flex flex-col h-8 bg-[#457AA8] p-1 mx-1 text-white  text-center items-center w-full">
                {t("description.label7")}
              </p>
              <h4 className="min-h-8 ml-2 text-sm text-center">
                {job.employmentStatus &&(
                  <p>
                        <TextItems items={job.employmentStatus} />
                  </p>
                )}
              </h4>
            </div>
            {/* space 2 */}
            <div className="flex-1 p-2 ">
              <p className="my-1 flex flex-col h-8 bg-[#457AA8] p-1 mx-1 text-white  text-center items-center w-full">
                {" "}
                {t("description.label9")}
              </p>
              {job.description && <TextItems items={job.description} />}
            </div>
          </div>
          <div className="flex flex-col items-center my-8 space-y-5">
            <div className="flex flex-row">
              <h4 className="w-5 text-center ">{step}</h4>
              <FaArrowCircleRight
                onClick={() => handleStep("next")}
                className="shadow-sm hover:text-[#86abcc] text-[#457AA8] hover:scale-110 duration-150 hover:ease-linear hover:cursor-pointer "
                size={25}
              />
            </div>
          </div>
        </>
      );

      break;
    case 2:
      stepContent = (
        <>
          {" "}
          <div className="flex py-2 text-gray-600 max-md:flex-col ">
            <div className="flex flex-col flex-1 h-auto p-2 md:border-r-2 border-r-gray-600 bg-blue-5 ">
              <p className="my-1 flex flex-col h-8 bg-[#457AA8] p-1 mx-1 text-white  text-center items-center w-full">
                {" "}
                {t("description.label10")}
              </p>
              <ul className="min-h-8 ml-2  text-sm ">
                {job.qualifications && <TextItems items={job.qualifications} />}
              </ul>
            </div>
            {/* space 2 */}
            <div className="flex-1 p-2 ">
              <p className="my-1 flex flex-col h-8 bg-[#457AA8] p-1 mx-1 text-white  text-center items-center w-full">
                {" "}
                {t("description.label11")}
              </p>
              <ul className="min-h-8 ml-2 text-sm  ">
                {job.advantages && <TextItems items={job.advantages} />}
              </ul>
            </div>
          </div>
          <div className="flex   flex-col items-center my-8 space-y-5">
            <div className="flex flex-row">
              <FaArrowCircleLeft
                onClick={() => handleStep("prev")}
                className="shadow-sm text-[#457AA8] hover:text-[#86abcc] hover:scale-110 hover:cursor-pointer "
                size={25}
              />
              <h4 className="w-5 text-center ">{step}</h4>
              <FaArrowCircleRight
                onClick={() => handleStep("next")}
                className="shadow-sm hover:text-[#86abcc] text-[#457AA8] hover:scale-110 duration-150 hover:ease-linear hover:cursor-pointer "
                size={25}
              />
            </div>
          </div>
        </>
      );
      break;
    case 3:
      stepContent = (
        <>
          {" "}
          <div className="flex py-2 text-gray-600 max-md:flex-col ">
            <div className="flex flex-col flex-1 h-auto p-2 md:border-r-2 border-r-gray-600 bg-blue-5 ">
              <p className="my-1 flex flex-col h-8 bg-[#457AA8] p-1 mx-1 text-white  text-center items-center w-full">
                {" "}
                {t("description.label12")}
              </p>
              <ul className="min-h-8 ml-2 text-sm ">
                {job.summary && <TextItems items={job.summary} />}
              </ul>
            </div>
            {/* space 2 */}
            <div className="flex-1 p-2 ">
              <p className="my-1 flex flex-col h-8 bg-[#457AA8] p-1 mx-1 text-white  text-center items-center w-full">
                {" "}
                {t("description.label13")}
              </p>
              <ul className="h-8 ml-2 text-sm ">
                {job.other && <TextItems items={job.other} />}
              </ul>
            </div>
          </div>
          <div className="flex flex-col items-center my-8 space-y-5">
            <div className="flex flex-row">
              <FaArrowCircleLeft
                onClick={() => handleStep("prev")}
                className="shadow-sm text-[#457AA8] hover:text-[#86abcc] hover:scale-110 hover:cursor-pointer "
                size={25}
              />
              <h4 className="w-5 text-center ">{step}</h4>
            </div>
          </div>
        </>
      );
      break;

    default:
      break;
  }

  // variable contenant l'ensembles des elements textuels du composant
  const traduction = {
    text1: "Fermer",
    //traduction: Close
    text2: "Entreprise",
    //traduction : Company
    text3: "Date de Publication",
    //traduction: Publication Date
    text4: "Date de Fin",
    // traduction: End Date
    text5: "Salaire",
    //traduction: Salary
    text6: "periode de travail",
    //traduction: Working Period
    text7: "Etat",
    //traduction: Status
    text8: "Archive par ",
    //traduction: archived By
    text9: "description",
    // traduction Description
    text10: "Qualification",
    // traduction Qualification
    text11: "Avantages",
    // Advantages
    text12: "Sommaire",
    //Summary
    text13: "Autres Infomations",
    //Other Information
  };

  return (
    <ModalUI
      autoclose={handleClose}
      open={openValue}
      classname="w-full max-w-[60rem] bgb"
    >
      <div className="p-4">
        <div className=" border-b-2 text-center text-xl text-[#457AA8] font-[500]">
          {/* if role == admin or manager befor displaying the job */}
          <div className="my-1 text-center ">
            <h2>{job.jobName}</h2>
            <h1 className="text-gray-400">{job.Adresse}</h1>
          </div>
        </div>
        {stepContent}
        <div className="flex flex-col items-center my-8 space-y-5">
          <Button
            size="sm"
            variant="filled"
            onClick={() => {
              handleClose();
              setStep(1);
            }}
            className="  h-10 outline-none  hover:opacity-75 text-lg bg-[#457AA8] w-28"
          >
            {t("description.btn")}
          </Button>
        </div>
      </div>
    </ModalUI>
  );
};

export default Description;
