import React, { useEffect, useState } from "react";
import CandidacyContext from "./CandidacyContext";
import { useQuery } from "@tanstack/react-query";
import getAllMyApplication from "../../services/HttpRequest/clientRequest/getAllMyApplication";
import { candidacyActions } from "../../store/slice/candidacySlice";
import { selectCurrentUser } from "../../store/slice/loginSlice";
import { useDispatch, useSelector } from "react-redux";

const CandidacyContextProvider = ({ children }) => {
  //userr
  const currentUser = useSelector(selectCurrentUser);
  const [filterValue, setFilterValue] = useState("");

  const dispatch = useDispatch();

  const onFilter = (items) => {
    setFilterValue(items);
  };

  const { data, status } = useQuery({
    queryKey: ["getMyCandidacy"],
    queryFn: ({ signal }) => getAllMyApplication(currentUser.user.id, signal),
  });

  useEffect(() => {
    switch (status) {
      case "pending":
        dispatch(
          candidacyActions.addCandidacy({ allCandidacy: [], status: "pending" })
        );
        break;
      case "success":
        dispatch(
          candidacyActions.addCandidacy({
            allCandidacy: data.data.data,
            status: "success",
          })
        );
        break;
      case "error":
        dispatch(
          candidacyActions.addCandidacy({ allCandidacy: [], status: "error" })
        );
        break;

      default:
        break;
    }
  }, [status, data, dispatch]);

  const value = {
    onFilter,
  };
  return (
    <CandidacyContext.Provider value={value}>
      {children}
    </CandidacyContext.Provider>
  );
};

export default CandidacyContextProvider;
