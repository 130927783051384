import React, { Component } from "react";

class GlobalErrorBoundary extends Component {
  constructor() {
    super();
    this.state = { hasError: false };
  }

  componentDidCatch(error) {
    // console.log(error)
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError)
      <div className="text-4xl text-red-300 h-96 w-96 text-center my-[30%]">
        {" "}
        Somethink went wrong While rendering
      </div>;
    return this.props.children;
  }
}
export default GlobalErrorBoundary;
