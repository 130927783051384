import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allCats: [],
  allCatWithJob: [],
  activeCat: {},
  statusCat: "",
  isError: false,
  error: {},
  CatForOneJob: null,
  numberOfCategories: 0,
};

const categorySliceAdmin = createSlice({
  name: "categorySliceAdmin",
  initialState,
  reducers: {
    fetchAllCategory(state, action) {
      state.statusCat = action.payload.status;
      if (action.payload.status === "success") {
        state.allCats = action.payload.data.data;
        state.numberOfCategories = action.payload.data.data.length;
        // ------------------------------------------------
        const catWithJob = action.payload.data.data.filter(
          (cat) => cat.jobs.length > 0
        );
        state.allCatWithJob = catWithJob.map((cat) => ({
          categoryName: cat.categoryName,
          jobQuantity: cat.jobs.length,
          id: cat.id,
        }));
        //   ------------------------------------------------
      } else {
        state.error = action.payload.error;
      }
    },

    getCatById(state, action) {
      state.CatForOneJob = state.allCats.find(
        (cat) => cat.id === action.payload.selectedCatId
      );
    },
  },
});
export const categorySliceAdminAction = categorySliceAdmin.actions;
export default categorySliceAdmin.reducer;
