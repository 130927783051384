import React from "react";
import emploiSection from "../../../assets/emploiSection.png";

import SearchBar from "../SearchBar/SearchBar";
import { useTranslation, Trans } from "react-i18next";

const JobSecOne = () => {
  const { t } = useTranslation();

  return (
    <section
      className="lg:pt-[25px] xl:pt-0 2xl:pt-0  overflow-hidden mx-auto mb-10 shadow-md h-[550px] "
      data-aos="fade-down"
    >
      <div
        className="w-full h-full items-center flex flex-wrap justify-center"
        style={{
          backgroundImage: `linear-gradient(rgba(232, 69, 29, 0.3), rgba(0, 0, 0, 20)), url(${emploiSection})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center top -25%",
        }}
      >
        <div className="py-auto space-y-20">
          <h1 className="text-[42px] [font-family:'Inter-Bold',Helvetica] font-bold text-white text-center w-full">
            {t("jobSecOne.title")}
          </h1>

          <div className=" font-medium  justify-center flex">
            <p className="pt-5  w-[80%]  text-white text-center">
              {t("jobSecOne.content")}
            </p>
          </div>

          <div className="flex w-full">
            <SearchBar />
          </div>
        </div>
      </div>
    </section>
  );
};

export default JobSecOne;
