import { FaExclamationTriangle } from "react-icons/fa";
import { useTranslation, Trans } from "react-i18next";

const LoadDataFailUI = ({ labelClassname, containerClassname, children }) => {
  const { t } = useTranslation();
  return (
    <div
      className={` mt-16 flex items-center  justify-center h-auto   font-bold  text-xl text-gray-700  text-center   w-full  ${containerClassname} `}
    >
      <div className=" w-full mx-auto">
        <FaExclamationTriangle size={60} className="mx-auto text-[#c77851]" />
        <p className={`text-center ${labelClassname} `}>
          {children ? children : t("dataFailure.msg")}
        </p>
      </div>
    </div>
  );
};

export default LoadDataFailUI;
