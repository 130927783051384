
import { DELETE_JOB_URL } from "../../api"
import { axiosUseToken } from "../../axiosConfig"


export const deleteJob = async ({ data }) => {
    const response = await axiosUseToken.delete(DELETE_JOB_URL+data);
  // console.log('voici la reponse de la requete', response);
    return response;
  };
  