import React from "react";
import SectionTen from "../components/Cpt_HomePage/SectionTen/SectionTen";
import SectionNine from "../components/Cpt_HomePage/SectionNine/SectionNine";
import SectionEight from "../components/Cpt_HomePage/SectionEight/SectionEight";
import SectionThree from "../components/Cpt_HomePage/SectionThree/SectionThree";
import SectionFoor from "../components/Cpt_HomePage/SectionFour/SectionFoor";
import SectionFive from "../components/Cpt_HomePage/SectionFive/SectionFive";
import SectionTwo from "../components/Cpt_HomePage/SectionTwo/SectionTwo";
import SectionOne from "../components/Cpt_HomePage/SectionOne/SectionOne";
import SectionSix from "../components/Cpt_HomePage/SectionSix/SectionSix";
import SectionSeven from "../components/Cpt_HomePage/SectionSeven/SectionSeven";
const HomePage = () => {
  return (
    <main className="overflow-hidden">
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFoor />
      <SectionFive />
      <SectionSix />
      <SectionSeven />
      <SectionEight />
      <SectionNine />
      <SectionTen />
    </main>
  );
};

export default HomePage;
