import React from "react";

const ButtonStudentUI = ({ children, classname, ...props }) => {
  return (
    <button
      className={` bg-[#EA580C]  hover:opacity-75 scale-105 font-semibold hover min-h-8 rounded-md text-white min-w-32 px-2 ${classname}`}
      {...props}
    >
      {children}
    </button>
  );
};

export default ButtonStudentUI;
