import React, { useEffect } from "react";

import { FiTarget, FiUser, FiCalendar } from "react-icons/fi";
import { IoBagHandleOutline } from "react-icons/io5";

import { Link, useNavigate } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import AOS from 'aos';
import 'aos/dist/aos.css';

const SectionEight = () => {
  const { t } = useTranslation();
  let statistique = [
    {
      icon: <FiUser className="mt-auto text-white size-10 w-14 h-14 " />,
      stat: "750",
      text: t("hP-sectionEight.stat1"),
      id: 1,
    },
    {
      icon: <FiCalendar className="mt-auto text-white size-10 w-14 h-14 " />,
      stat: "2023",
      text: t("hP-sectionEight.stat2"),
      id: 2,
    },
    {
      icon: <FiTarget className="mt-auto text-white size-10 w-14 h-14 " />,
      stat: "94",
      text: t("hP-sectionEight.stat3"),
      id: 3,
    },
    {
      icon: (
        <IoBagHandleOutline className="mt-auto text-white size-10 w-14 h-14 " />
      ),
      stat: "2164",
      text: t("hP-sectionEight.stat4"),
      id: 4,
    },
  ];

  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/About");
  };

  useEffect(() => {
    AOS.init({
      duration: 1000, // Durée de l'animation en millisecondes
    });
  }, []);

  return (
    <div className="max-w-full max-h-full bg-[#FFEADF] py-5 [font-family:'Inter-Bold',Helvetica]"
    data-aos="fade-down">
      <div>
        <h1 className="text-[#EA580C] text-4xl font-semibold flex justify-center mb-8">
          {t("hP-sectionEight.title")}
        </h1>
        <div className="flex justify-center">
          <p className="text-center text-[#B03F04] w-[80%]">
            {t("hP-sectionEight.textcontent")}
          </p>
        </div>
        <p
          className="text-black cursor-pointer flex justify-center italic font-semibold mt-8 mb-8"
          onClick={handleClick}
        >
          {t("hP-sectionEight.button")}
        </p>
      </div>
      <div
        className="bg-[#EA580C] max-w-full max-h-full text-white p-8 flex flex-wrap justify-center h-full lg:flex-nowrap"
        data-aos="fade-up"
      >
        {statistique.map((StatVal, index) => (
          <div
            key={StatVal.id}
            data-aos="fade-up"
            data-aos-delay={`${index * 500}`}
            className="flex flex-col items-center justify-center p-4 sm:px-6 lg:mx-auto"
          >
            <div className="flex justify-center">
              {StatVal.icon}
              <div className="ml-2">
                <p className="text-3xl">{StatVal.stat}</p>
                <p>{StatVal.text}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionEight;
