import React from "react";
import { useTranslation, Trans } from "react-i18next";

const Modal = ({ title, message, onCancel, onConfirm }) => {
  const { t } = useTranslation();
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="p-8 bg-white rounded-md w-80">
        <h2 className="mb-4 text-lg font-bold">{title}</h2>
        <p className="mb-4 text-sm ">{message}</p>
        <div className="flex justify-center">
          <button
            onClick={onCancel}
            className="px-4 py-2 mr-2 text-white bg-gray-500 rounded-md hover:bg-gray-600"
          >
            {t("usermodal.cancel")}
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 text-white bg-red-500 rounded-md hover:bg-red-600"
          >
            {t("usermodal.confirm")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
