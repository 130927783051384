import React from "react";
import DashBord from "../../components/Admin_Cpt/DashBord/DashBord";
const DashBordPage = () => {
  return (
    <div className="">
      <DashBord />
    </div>
  );
};

export default DashBordPage;
