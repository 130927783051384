import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { AnimatePresence, motion } from "framer-motion";
import { Button } from "@material-tailwind/react";
import { dateFormatterLong } from "../../Utils/dateFormatter";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import TextItems from "./TextItems";
import { jobsSliceAction } from "../../../store/slice/jobsSlice";
import PendingUI from "../../UI/RequestUI/PendingUI";
import EmptyData from "../../UI/RequestUI/EmptyData";
import {
  FaCalendarAlt,
  FaClock,
  FaMoneyBill,
  FaArrowDown,
  FaBriefcase,
  FaWallet,
  FaCopy,
  FaLinkedin,
  FaFacebook,
  FaEnvelope,
  FaCalendarTimes,
  FaCalendarDay,
  FaCalendarCheck,
  FaFileContract,
  FaLevelDownAlt,
  FaLevelUpAlt,
} from "react-icons/fa";
import clipboardCopy from "clipboard-copy";
import { useTranslation } from "react-i18next";

const DetailJob = () => {
  const { jobId } = useParams();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const allJobs = useSelector((state) => state.jobs.allJobs);
  const statusJob = useSelector((state) => state.jobs.statusJob);
  const [createDate, setCreateDate] = useState("N/A");
  const [endDate, seEndDate] = useState("N/A");
  const [jobData, setJobData] = useState(null);
  const jobIdNumber = parseInt(jobId.match(/\d+/)[0], 10);

  useEffect(() => {
    if (statusJob === "success") {
      const jobDataget = allJobs.find((job) => job.id === jobIdNumber);
      setJobData(jobDataget);
      if (jobDataget) {
        console.log("jesiooo", jobDataget);
        if (jobDataget?.creationDate == null) {
          setCreateDate("N/A");
        } else {
          const dateOnly = new Date(jobDataget?.creationDate)
            .toISOString()
            .split("T")[0];
          setCreateDate(dateOnly);
        }

        if (jobDataget?.applicationDeadLine == null) {
          seEndDate("N/A");
        } else {
          // console.log("dateeeee",jobDataget?.applicationDeadLine);
          const dateEnd = new Date(jobDataget?.applicationDeadLine)
            .toISOString()
            .split("T")[0];
          // console.log("dateeeee",dateEnd);
          seEndDate(dateEnd);
        }
      }
    }
  }, [statusJob, allJobs, jobIdNumber]);

  // console.log("deddeded", jobIdNumber);

  // console.log("les jobs", allJobs);

  const [isSeeMore, setIsseeMore] = useState(false);

  const navigate = useNavigate();

  // const handlePostulerClick = () => {
  //   navigate("/Application");
  // };
  const postTitle = t("detailjob.post_title");
  const postDescription = t("detailjob.post_description");

  const linkedInShareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
    window.location.href
  )}&title=${encodeURIComponent(postTitle)}&summary=${encodeURIComponent(
    postDescription
  )}`;
  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    window.location.href
  )}`;
  const mailtoLink = `mailto:?subject=${encodeURIComponent(
    postTitle
  )}&body=${encodeURIComponent(postDescription + " " + window.location.href)}`;

  // const linkedInShareUrl = jobData ? `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
  //   window.location.href
  // )}&title=${encodeURIComponent(jobData.jobName)}&summary=${encodeURIComponent(
  //   jobData.description
  // )}` : '';

  // const facebookShareUrl = jobData ? `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
  //   window.location.href
  // )}` : '';

  // const mailtoLink = jobData ? `mailto:?subject=${encodeURIComponent(
  //   jobData.jobName
  // )}&body=${encodeURIComponent(
  //   jobData.description + " " + window.location.href
  // )}` : '';

  const handlePostulerClick = (selectedJob) => {
    navigate("/Application/idJob=" + selectedJob.id);
  };

  const toogleSeeMore = () => {
    setIsseeMore((prevState) => !prevState);
  };

  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    clipboardCopy(window.location.href);
    setCopied(true);

    // Remettre copied à false après quelques secondes
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  useEffect(() => {
    if (jobData) {
      dispatch(
        jobsSliceAction.filterJobsByCatId({ categoryId: jobData?.categoryId })
      );
    }
    return () => {
      dispatch(jobsSliceAction.filterJobsByCatId({ categoryId: null }));
    };
  }, [jobData, dispatch]);

  if (!jobData) {
    return statusJob === "pending" ? (
      <PendingUI />
    ) : (
      <motion.div
        initial={{ y: 50, opacity: 0.1 }}
        animate={{ y: [0, -10, 0], opacity: 1 }}
        className="flex-1 text-center justify-center  w-96 "
      >
        <div className="flex items-center justify-center h-screen">
          <EmptyData>
            <h1 className="font-mono  ">{t("detailjob.empty")}</h1>
          </EmptyData>
        </div>
      </motion.div>
    );
  }
  return (
    <AnimatePresence>
      <motion.div
        initial={{ y: -20, opacity: 0.1 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 20, opacity: 0 }}
        ref={jobData}
        className="flex-1 px-1 "
      >
        <div className="bg-[#F1F2F4] w-full mb-5 ">
          <h1 className="text-3xl font-bold text-orange-500 text-center">
            {t("detailjob.title")}
          </h1>
        </div>
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="flex items-center mb-4 md:mb-0">
            <div className="flex items-center justify-center w-12 h-12 rounded-full bg-[#F1F2F4] mr-4">
              <FaBriefcase size="1.5em" color="#0284C7" />
            </div>
            <div>
              <h1 className="text-3xl font-bold text-orange-500">
                {jobData && jobData.jobName}
              </h1>
              {/* <ul className="mb-2 text-start font-bold text-sky-600">
                {jobData &&
                  jobData.employmentStatus
                    .split(";")
                    .map((text) => <li key={text}>- {text}</li>)}
              </ul> */}
            </div>
          </div>

          <button
            className="bg-[#0284C7] rounded-full px-2 text-white w-full md:w-[150px] h-[35px] text-[16px] font-semibold hover:border-2 hover:border-[#EA580C] hover:bg-white hover:text-[#EA580C]"
            onClick={() => handlePostulerClick(jobData)}
          >
            {t("detailjob.btn")}
          </button>
        </div>

        <div className=" justify-between flex flex-col md:flex-row">
          <div className="justify-start order-2 md:order-1 1x:w-2/3 xl:w-2/3 lg:w-2/3  px-8  text-[#667085]">
            <h2 className="p-2 mb-2 text-2xl -ml-8 text-black font-bold border-b  border-orange-500">
              {t("detailjob.subtitle")}
            </h2>
            <p className="text-[#667085]">
              {jobData.description && <TextItems items={jobData.description} />}
            </p>
          </div>
          <div className="justify-end mt-10 h-full md:order-2 order-1 1xl:w-1/3 lg:w-1/3 xl:w-1/3 border-rgba(231, 240, 250, 1) border-2 rounded-xl ">
            <p className="p-4 font-semibold">{t("detailjob.sidetitle")}</p>
            <div className="flex space-x-3 px-4">
              <div className="text-start">
                <div className="mx-auto inline-block ">
                  <FaCalendarAlt color="#0284C7 " />
                </div>

                <div className="text-[#767F8C]">{t("detailjob.sideitem1")}</div>
                <div>{createDate}</div>
              </div>
              <div className="text-start">
                <div className="mx-auto inline-block">
                  <FaClock color="#0284C7" />
                </div>

                <div className="text-[#767F8C]">{t("detailjob.sideitem2")}</div>
                <div> {endDate}</div>
              </div>{" "}
              <div className="text-start">
                <div className="mx-auto inline-block">
                  <FaWallet color="#0284C7" />
                </div>

                <div className="text-[#767F8C]">{t("detailjob.sideitem3")}</div>
                <div>
                  {" "}
                  {jobData.salary} {jobData.currency}/{jobData.salaryRate}
                </div>
              </div>
            </div>
            <div className="flex space-x-3 p-4">
              <div className="text-start">
                <div className="mx-auto inline-block ">
                  <FaCalendarCheck color="#0284C7 " />
                </div>

                <div className="text-[#767F8C]">{t("detailjob.sideitem4")}</div>
                <div>{jobData.period}</div>
              </div>
            </div>
            <div className="flex space-x-3 p-4">
              <div className="text-start">
                <div className="mx-auto inline-block ">
                  <FaFileContract color="#0284C7 " />
                </div>

                <div className="text-[#767F8C]">{t("detailjob.sideitem5")}</div>
                <div>
                  {" "}
                  <ul className="text-orange-600 font-semibold ">
                    {jobData &&
                      jobData.employmentStatus
                        .split(";")
                        .map((text) => <li key={text}> - {text}</li>)}
                  </ul>
                </div>
              </div>
            </div>
            <div className="w-full border-t border-rgba(231, 240, 250, 1) p-4">
              <div>
                <p className="font-medium">{t("detailjob.sharetitle")}</p>
              </div>
              <div className="flex m-2">
                <div className="">
                  {copied ? (
                    <div className="transform items-center -translate-x-center bg-gray-200 text-gray-800 p-2 rounded-md opacity-75">
                      {t("detailjob.shareinfo")}
                    </div>
                  ) : (
                    <div
                      className="flex cursor-pointer items-center bg-[#E7F0FA] hover:bg-[#0284C7] hover:text-white text-[#0284C7] rounded-md p-1"
                      onClick={handleCopy}
                    >
                      <span className="mr-2 rounded-full">
                        <FaCopy />
                      </span>
                      <div>
                        <p className="">{t("detailjob.sharebtn")}</p>
                      </div>
                    </div>
                  )}
                </div>
                <div className="flex m-2">
                  <a
                    href={linkedInShareUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center bg-[#E7F0FA] hover:bg-[#0284C7] text-[#0284C7] hover:text-white rounded-md p-1 px-2 mx-2"
                  >
                    <FaLinkedin />
                  </a>
                  <a
                    href={facebookShareUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center bg-[#E7F0FA] hover:bg-[#0284C7] text-[#0284C7] hover:text-white rounded-md p-1 px-2 mx-2"
                  >
                    <FaFacebook />
                  </a>
                  <a
                    href={mailtoLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center bg-[#E7F0FA] hover:bg-[#0284C7] text-[#0284C7] hover:text-white rounded-md p-1 px-2 mx-2"
                  >
                    <FaEnvelope />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-2 text-gray-600"> {jobData.datePub}</div>

        <div className="mb-4">
          <AnimatePresence>
            {!isSeeMore ? (
              <motion.div
                initial={{ y: 29, opacity: 0 }}
                animate={{ y: [29, -2, 0], opacity: 1 }}
                exit={{ y: -300, opacity: 0 }}
              >
                <Button
                  color="blue"
                  size="lg"
                  variant="text"
                  onClick={toogleSeeMore}
                  className="hover:scale-110 text-center flex  items-center"
                >
                  {t("detailjob.morebtn")}
                  <FaLevelDownAlt />
                </Button>

                <div></div>
              </motion.div>
            ) : (
              <motion.div
                initial={{ y: -29, opacity: 0 }}
                animate={{ y: [-29, 2, 0], opacity: 1 }}
                exit={{ y: -300, opacity: 0 }}
              >
                <div className="">
                  <h2 className="p-2 mb-2 text-2xl font-bold border-b  border-orange-500">
                    {t("detailjob.summary")}
                  </h2>
                  <p className="text-[#667085] px-8">
                    {jobData.summary && <TextItems items={jobData.summary} />}
                  </p>
                  <div className="mb-2 text-gray-700  my-3 ">
                    <div className="mb-4">
                      <h2 className="p-2 mb-2 text-2xl font-bold border-b  border-orange-500">
                        {t("detailjob.strength")}
                      </h2>
                      <p className="text-[#667085] px-8">
                        {jobData.qualifications && (
                          <TextItems items={jobData.qualifications} />
                        )}
                      </p>
                    </div>
                    {/* <div className="mb-2 text-gray-700  my-3 ">
                      <div className="mb-4">
                        <h2 className="p-2 mb-2 text-2xl font-bold border-b  border-orange-500">
                          {t("detailjob.info")}
                        </h2>
                        <p className="text-[#667085] px-8">
                          {jobData.description && (
                            <TextItems items={jobData.description} />
                          )}
                        </p>
                      </div>
                    </div> */}
                    <div className="mb-2 text-gray-700  my-3 ">
                      <div className="mb-4">
                        <h2 className="p-2 mb-2 text-2xl font-bold border-b  border-orange-500">
                          {t("detailjob.benefits")}
                        </h2>
                        <p className="text-[#667085] px-8">
                          {jobData.advantages && (
                            <TextItems items={jobData.advantages} />
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <Button
                  color="blue"
                  size="lg"
                  variant="text"
                  onClick={toogleSeeMore}
                  className="hover:scale-110"
                >
                  Voir Moin
                </Button> */}
                <Button
                  color="blue"
                  size="lg"
                  variant="text"
                  onClick={toogleSeeMore}
                  className="hover:scale-110 text-center flex  items-center"
                >
                  {t("detailjob.lessbtn")}
                  <FaLevelUpAlt />
                </Button>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        {/* <button
          className="px-4 py-2 [font-family:'Inter-Bold',Helvetica] font-bold hover:scale-110 hover:text-orange-500 hover:border-white  text-white  duration-500 bg-gradient-to-r from-[#5190B7] to-[#457AA8] rounded-xl btn md:ml-2 md:static shadow-[4px_7px_4.7px_-6px_#00000040]"
          // onClick={handlePostulerClick}
          onClick={() => handlePostulerClick(jobData)}>
          
          Postuler
        </button> */}
        <div className="flex justify-center">
          <button
            className="justify-center bg-[#0284C7] rounded-full px-2 text-white w-[150px] h-[35px] text-[16px] font-semibold hover:border-2 hover:border-[#EA580C] hover:bg-white hover:text-[#EA580C]"
            onClick={() => handlePostulerClick(jobData)}
          >
            {t("detailjob.btn2")}
          </button>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default DetailJob;
