import React from "react";
import ModalUI from "../UI/ModalUI";
import { Button } from "@material-tailwind/react";
import ButtonStudentUI from "../UI/ButtonUI";

const DeleteModal = ({
  handleToogleModal,
  isModalOpen,
  candidacy,
  handleDelete,
}) => {


  return (
    <ModalUI
      open={isModalOpen}
      autoclose={() => {
        handleToogleModal(false);
      }}
    >
      <div className="w-auto h-auto p-3">
        <div>
          <h2 className="text-red-500 ">Supprimer</h2>
          <hr />
          <p>
            Etes sur de vouloir supprimer.
            <br />
            <span className="text-[#457AA8]">{candidacy.jobName}</span> ?
          </p>
        </div>
        <div className="flex w-full gap-10 my-3  justify-around ">
          <ButtonStudentUI
            type="button"
          classname={'bg-blue-500'}
            onClick={() => handleToogleModal(false)}
          >
            Annuler
          </ButtonStudentUI>
          <ButtonStudentUI
            type="button"

            classname="bg-red-500 "
            onClick={() => handleDelete(candidacy)}
          >
            Supprimer
          </ButtonStudentUI>
        </div>
      </div>
    </ModalUI>
  );
};

export default DeleteModal;
