import React from "react";
import { useTranslation, Trans } from "react-i18next";

const PrivacyPolicyPage = () => {
  const { t } = useTranslation();
  const ulStyle = "";
  const liStyle = "";
  const titleStyle = "font-semibold text-[#101828] mt-4 mb-2  ";
  const pStyle = "text-[#667085] my-1 ";

  return (
    <div className="flex justify-center my-32 ">
      {" "}
      <div className="max-w-[1000px] mx-4 ">
        <div className="w-full  text-center text-[#101828] font-bold text-3xl lg:text-4xl my-4">
          {t("privacyPolicy.title")}{" "}
          <span className="text-orange-500">{t("privacyPolicy.title2")}</span>
        </div>
        <p className={`${pStyle} text-center lg:mb-14 mb-12 `}>
          {t("privacyPolicy.description")}
        </p>
        <p>
          <h2 className={titleStyle}>{t("datacollection.title")}</h2>
          <p className={pStyle}>{t("datacollection.text")}</p>
          <ul className={pStyle}>
            <li>{t("datacollection.enum")}</li>
            <li>{t("datacollection.enum2")}</li>
            <li>{t("datacollection.enum3")}</li>
            <li>{t("datacollection.enum4")}</li>
            <li>{t("datacollection.enum5")}</li>
          </ul>
        </p>
        <h2 className={titleStyle}>{t("usedata.title")}</h2>

        <p className={pStyle}>
          {t("usedata.text")}
          <ul>
            <li>{t("usedata.enum")}</li>
            <li>{t("usedata.enum2")}</li>
            <li>{t("usedata.enum3")}</li>
          </ul>
        </p>
        <h2 className={titleStyle}> {t("sharedata.title")}</h2>
        <p className={pStyle}>
          {t("sharedata.text")}
          <ul>
            <li> {t("sharedata.enum")}</li>
            <li> {t("sharedata.enum2")}</li>
          </ul>
        </p>
        <h2 className={titleStyle}>{t("securedata.title")}</h2>
        <p className={pStyle}>{t("securedata.text")}</p>

        <h2 className={titleStyle}> {t("notification.title")}</h2>
        <p className={pStyle}>{t("notification.text")}</p>
        <h2 className={titleStyle}> {t("rights.title")}</h2>
        <p className={pStyle}>
          {t("rights.text")}
          <ul className={pStyle}>
            <li> {t("rights.enum")}</li>
            <li> {t("rights.enum2")}</li>
            <li> {t("rights.enum3")}</li>
          </ul>
        </p>

        <h2 className={titleStyle}>{t("contact.title")}</h2>

        <p className={pStyle}>
          {t("contact.text")}
          <a href="mailto:contact@laproueinc.com" className="text-[#4d9fe7] ">
            {" "}
            {t("contact.link")}
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
