import React, { useState } from "react";
import SideMenu from "../SideBar/SideBar";
import Cv from "../Cv/Cv"
import PersoInfo from "./PersoInfo/PersoInfo";
import ProfExp from "./ProfExp/ProfExp";
import Formation from "./Formation/Formation";
import Skills from "./Skills/Skills";

const Profil = () => {
  // const [selectedItem, setSelectedItem] = useState("");

  // const handleItemClick = (item) => {
  //   setSelectedItem(item);
  // };

  
  // const renderContent = () => {
  //   switch (selectedItem) {
  //     case "Informations personnelles":
  //       return <div className="px-6 py-6 "><PersoInfo /></div>;
  //     case "Experiences professionnelles":
  //       return <div className="px-6"><ProfExp/></div>;
  //     case "Mes CV":
  //       return <div><Cv/></div>;
  //     case "Formation":
  //       return <div className="px-6"><Formation/></div>;
  //     case "Compétences":
  //       return <div className="px-6"><Skills/></div>;
  //     case "Langues":
  //       return <div>Contenu des langues</div>;
  //     default:
  //       return <div>Veuillez sélectionner une option dans la barre latérale</div>;
  //   }
  // };

  return (
    <div className="  shadow-xl min-h-svh bg-[#F9F9F9]">
    <div className="px-6 py-6 justify-center"><PersoInfo /></div>
      {/* <div className="w-1/4 ">
        <SideMenu onItemClick={handleItemClick} />
      </div>
      <div className="w-4/5 bg-gray-100 2xl:w-5/6">
        {renderContent()}
      </div> */}
    </div>
  );
};

export default Profil;
