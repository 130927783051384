import React from 'react';
import Profil from '../components/Profil/Profil';
const profile = () => {
    return (
        <div className="bg-[#F9F9F9] ">
            <Profil/>
        </div>
    );
};

export default profile;