import { USER_GET_ALL_CV, USER_VIEW_CV } from "../../../api";
import { axiosUseToken } from "../../../axiosConfig";

export const getAllDocs = async () => {
  try {
    const response = await axiosUseToken(USER_GET_ALL_CV);
    return response;
  } catch (error) {
    throw error;
  }
};

//view a cv

export const viewCv = async (id) => {
  try {
    const response = await axiosUseToken.get(USER_VIEW_CV + id);

    return response;
  } catch (error) {
    throw error;
  }
};
