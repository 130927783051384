import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import {
  isDateValid,
  isNumberValid,
  isTextValid,
} from "../components/Utils/ValidFormElement";
import CatContext from "./CatContext";
import CompContext from "./CompContext";
import { filterTableData } from "../components/Utils/filterData";
import { textAreaReducer } from "./AddJobContext/textAreaReducer";
import AddJobContext from "./AddJobContext/context";
import { useSelector } from "react-redux";
import {
  dateFormatterInput,
  dateFormatterShort,
} from "../components/Utils/dateFormatter";

// reducer

const initialState = {
  description: { stringValue: "", table: [] },
  qualifications: { stringValue: "", table: [] },
  summary: { stringValue: "", table: [] },
  advantages: { stringValue: "", table: [] },
  other: { stringValue: "", table: [] },
};

const AddJobContextProvider = ({ children }) => {
  // form state
  const [formContent, setFormContent] = useState({
    jobName: { value: "", didEdit: false },
    salary: { value: 0, didEdit: false },
    salaryRate: { value: "", didEdit: false },
    description: { value: "", didEdit: false },
    summary: { value: "", didEdit: false },
    qualifications: { value: "", didEdit: false },
    currency: { value: "", didEdit: false },
    requiredHeadCount: { value: 0, didEdit: false },
    employmentStatus: { value: "", didEdit: false },
    advantages: { value: "", didEdit: false },
    other: { value: "", didEdit: false },
    applicationDeadLine: { value: "", didEdit: false },
    isRegistered: { value: true, didEdit: false },
    companyId: { value: "", didEdit: false },
    categoryId: { value: "", didEdit: false },
    period: { value: "", didEdit: false },
    periodMinTime: { value: "", didEdit: false },
    periodMaxTime: { value: "", didEdit: false },
    periodPayment: { value: "", didEdit: false },
    periodDu: { value: "", didEdit: false },
    periodAu: { value: "", didEdit: false },
    id: { value: 0.1, didEdit: false },
  });
  const [employementStatusEdit, setEmployementStatusEdit] = useState([]);
  // jobId

  const [jobId, setJobId] = useState();

  // edit state

  const [isEditing, setIsEditing] = useState(false);

  // step stat

  const [step, setStep] = useState(1);
  useEffect(()=>{
    setStep(1)
  },[isEditing])
  // Reducer for textarea data

  const [textAreaState, textAreaDispatch] = useReducer(
    textAreaReducer,
    initialState
  );

  //function

  //handle formContent

  const handleChangeField = (id, value) => {
    setFormContent((prevContent) => ({
      ...prevContent,
      [id]: { value, didEdit: false },
    }));
  };

  const handleBlurField = (id) => {
    setFormContent((prevContent) => ({
      ...prevContent,
      [id]: { ...prevContent[id], didEdit: true },
    }));
  };
  // dispatch reducer

  const HandleTextareaDispatch = (id, type, value) => {
    let values = value;

    if (type === "add") {
      values = formContent[id].value;
    }

    textAreaDispatch({
      payload: {
        value: values,
        id: id,
      },
      type: type,
    });

    handleChangeField(id, "");
  };

  //init did edit

  const initDiDedit = (value) => {
    for (const idField in formContent) {
      setFormContent((prevContent) => ({
        ...prevContent,
        [idField]: { ...prevContent[idField], didEdit: value },
      }));
    }
  };

  // handle Step

  const handleStep = (step, isStepValid = true) => {
    initDiDedit(true);

    if (isStepValid) {
      setStep(step);
      initDiDedit(false);
    }
  };

  //
  //
  //validate everyField
  //

  const isNotJobName =
    formContent.jobName.didEdit && !isTextValid(formContent.jobName.value);
  const isNotSalary =
    formContent.salary.didEdit && !isNumberValid(formContent.salary.value);
  const isNotSalaryRate =
    formContent.salaryRate.didEdit &&
    !isTextValid(formContent.salaryRate.value);
  const isNotDescription =
    formContent.description.didEdit &&
    !textAreaState.description.table.length > 0;
  const isNotSummary =
    formContent.summary.didEdit && !textAreaState.summary.table.length > 0;
  const isNotQualifications =
    formContent.qualifications.didEdit &&
    !textAreaState.qualifications.table.length > 0;
  const isNotCurrency =
    formContent.currency.didEdit && !isTextValid(formContent.currency.value);
  const isNotRequiredHeadCount =
    formContent.requiredHeadCount.didEdit &&
    !isNumberValid(formContent.requiredHeadCount.value);
  const isNotEmploymentStatus =
    formContent.employmentStatus.didEdit &&
    !isTextValid(formContent.employmentStatus.value);
  const isNotAvantages =
    formContent.advantages.didEdit &&
    !textAreaState.advantages.table.length > 0;

  const isNotOther =
    formContent.other.didEdit && !textAreaState.other.table.length > 0;
  const isNotApplicationDeadline =
    formContent.applicationDeadLine.didEdit &&
    !isDateValid(formContent.applicationDeadLine.value);
  const isNotCompanyId =
    formContent.companyId.didEdit && !isTextValid(formContent.companyId.value);
  const isNotCategory =
    formContent.categoryId.didEdit &&
    !isTextValid(formContent.categoryId.value);
  const isNotRegistered = !formContent.isRegistered.value;

  // valid step
  // step 1 validation

  const isFirstStepOk =
    isTextValid(formContent.jobName.value) &&
    // isNumberValid(formContent.salary.value) &&
    // isTextValid(formContent.salaryRate.value) &&
    // isTextValid(formContent.currency.value) &&
    isTextValid(formContent.companyId.value) &&
    isTextValid(formContent.categoryId.value);

  // valid Step 2
  const isSecondStepOk =
    textAreaState.description.table.length > 0 &&
    isNumberValid(formContent.requiredHeadCount.value) &&
    isTextValid(formContent.employmentStatus.value);
  // && isDateValid(formContent.applicationDeadLine.value);

  // valid  Step 3
  const isThirdStepOk =
    isTextValid(formContent.periodMinTime.value) &&
    isTextValid(formContent.periodMaxTime.value) &&
    isTextValid(formContent.periodPayment.value) &&
    isTextValid(formContent.periodAu.value) &&
    isTextValid(formContent.periodDu.value);
  // valid step 4

  const isFourStepOk =
    textAreaState.qualifications.table.length > 0 &&
    textAreaState.advantages.table.length > 0;

  // valid step 5
  const isFiveStepOk =
    textAreaState.summary.table.length > 0 &&
    textAreaState.other.table.length > 0;

  // data to edit
  // *editing block

  const HandleDataToEdit = (dataToEdit, editing, cat, comp) => {
    setIsEditing(editing);

    // action for non edit click (addJob) clearing the field
    if (!editing) {
      handleStep(1);
      for (const field in formContent) {
                  setEmployementStatusEdit([]);

        if (
          field === "summary" ||
          field === "description" ||
          field === "advantages" ||
          field === "other" ||
          field === "qualifications"
        ) {
          textAreaDispatch({
            payload: {
              value: "",
              id: field,
            },
            type: "update",
          });
        }

        if (field !== "isRegistered") {
          handleChangeField(field, "");
        }
        // if (field === "employmentStatus") {
        //   let editEmpl = [dataToEdit].split(";");
        //   setEmployementStatusEdit(editEmpl);
        //   handleChangeField(field, dataToEdit[field]);

        // }
      }

      return;
    }

    setJobId(dataToEdit.id);

    for (const field in formContent) {
      handleChangeField(field, dataToEdit[field]);

      // setting cathegorie
      if (field === "categoryId") {

        // const category = filterTableData(allCats, "id", dataToEdit[field]);
        handleChangeField(field, cat?.categoryName);
      }
      // setting company
      if (field === "companyId") {
        handleChangeField(field, comp?.companyName);
      }

      //
      if (field === "employmentStatus") {
        const empStatus = dataToEdit[field]?.split(";");
        setEmployementStatusEdit(empStatus);
        handleChangeField(field, dataToEdit[field]);
      }
      if (
        field === "applicationDeadLine" &&
        dataToEdit[field]?.trim().length > 0
      ) {
        // const appDeadLine = dateFormatterShort(dataToEdit[field]);
        // const appDeadLine = new Date(dataToEdit[field])

        const appDeadLine = dateFormatterInput(dataToEdit[field]);

        handleChangeField(field, appDeadLine);
      }
      // texaran editing

      if (
        field === "summary" ||
        field === "description" ||
        field === "advantages" ||
        field === "other" ||
        field === "qualifications"
      ) {
        textAreaDispatch({
          payload: {
            value: dataToEdit[field],
            id: field,
          },
          type: "update",
        });
        handleChangeField(field, "");
      }

      let periodTable = [];
      if (
        (field === "period" ||
          field === "periodAu" ||
          field === "periodDu" ||
          field === "periodMinTime" ||
          field === "periodMaxTime" ||
          field === "periodPayment") &&
        dataToEdit["period"]
      ) {
        periodTable = dataToEdit["period"]?.split(` `);
        setFormContent((prevContent) => ({
          ...prevContent,
          periodMinTime: {
            ...prevContent.periodMinTime,
            value: periodTable[1],
          },
          periodMaxTime: {
            ...prevContent.periodMaxTime,
            value: periodTable[3],
          },
          periodPayment: {
            ...prevContent.periodPayment,
            value: periodTable[5],
          },
          periodDu: { ...prevContent.periodDu, value: periodTable[7] },
          periodAu: { ...prevContent.periodAu, value: periodTable[9] },
        }));
      }

      if (field === "periodMinTime") {
      }
      if (field === "periodMaxTime") {
        setFormContent((prevContent) => ({
          ...prevContent,
          [field]: { ...prevContent[field], value: periodTable[3] },
        }));
      }
      if (field === "periodPayment") {
        setFormContent((prevContent) => ({
          ...prevContent,
          [field]: { ...prevContent[field], value: periodTable[6] },
        }));
      }
      if (field === "periodDu") {
        setFormContent((prevContent) => ({
          ...prevContent,
          [field]: { ...prevContent[field], value: periodTable[8] },
        }));
      }
      if (field === "periodAu") {
        setFormContent((prevContent) => ({
          ...prevContent,
          [field]: { ...prevContent[field], value: periodTable[9] },
        }));
      }
    }
  };
  //----------employement status-------------------------

  const handleEmployementStatus = (table = []) => {
    let contratString = table?.map((text) => text.replace(/\n/g, "")).join(";");
    setFormContent((prevState) => ({
      ...prevState,
      employmentStatus: {
        value: contratString,
        didEdit: table.length > 0 ? true : false,
      },
    }));
          setEmployementStatusEdit(table);

  };
  // ----edit employementStatus
  // useEffect(() => {
  //   if (isEditing) {
  //     setEmployementStatusEdit([]);
  //     // HandleDataToEdit({},false)
  //   }
  // }, [isEditing]);

  // values to provide

  const values = {
    validStepOk: {
      isFirstStepOk,
      isSecondStepOk,
      isThirdStepOk,
      isFourStepOk,
      isFiveStepOk,
    },
    validEachInput: {
      isNotAvantages,
      isNotApplicationDeadline,
      isNotCategory,
      isNotCompanyId,
      isNotCurrency,
      isNotDescription,
      isNotEmploymentStatus,
      isNotJobName,
      isNotOther,
      isNotRequiredHeadCount,
      isNotSalary,
      isNotSalaryRate,
      isNotSummary,
      isNotQualifications,
    },
    formContent,
    textAreaState,
    step,
    employementStatusEdit,
    handleBlurField,
    handleEmployementStatus,
    handleChangeField,
    HandleTextareaDispatch,
    initDiDedit,
    handleStep,
    HandleDataToEdit,
    isEditing,
    jobId,
  };

  return (
    <AddJobContext.Provider value={values}>{children}</AddJobContext.Provider>
  );
};
export default AddJobContextProvider;
