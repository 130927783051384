import React from "react";
import ManageBord from "../../components/Admin_Cpt/ManageBord/ManageBord";
const ManagePage = () => {
  return (
    <main className="bg-[#F9F9F9] px-10">
      <ManageBord />
    </main>
  );
};

export default ManagePage;
