import React, { Fragment } from "react";
import {
  FaUser,
  FaCog,
  FaSignOutAlt,
  FaCaretDown,
  FaEnvelope,
  FaUserGraduate,
  FaUserCircle,
  FaFileAlt,
  FaTachometerAlt,
  FaTasks,
} from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { logout, selectCurrentUser } from "../../../store/slice/loginSlice";
import { useTranslation, Trans } from "react-i18next";

const UserCpt = ({ userRole }) => {
  // const currentUser = useSelector(selectCurrentUser).user;

  const currentUserData = useSelector(selectCurrentUser);
  const currentUser = currentUserData ? currentUserData.user.userName : null;
  // console.log('File: User.jsctyytctctyytc', currentUserData.user.lastName);
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
  };
  const { t } = useTranslation();

  let initialUser;

  switch (userRole) {
    case "Admin":
      initialUser = [
        {
          name: t("navbar.board"),
          icon: <FaTachometerAlt />,
          link: "/admin/DashBord",
          event: "",
        },
        {
          name: t("navbar.menu"),
          icon: <FaTasks />,
          link: "/admin/MenuGestion",
          event: "",
        },
        {
          name: "Newsletter",
          icon: <FaEnvelope  />,
          link: "/admin/NewsLetter",
          event: "",
        },
        {
          name: t("navbar.discon"),
          icon: <FaSignOutAlt />,
          link: "/Connexion",
          event: "deconnexion",
        },
      ];
      break;
    case "Manager":
      initialUser = [
        {
          name: t("navbar.board"),
          icon: <FaTachometerAlt />,
          link: "/manager/DashBord",
          event: "",
        },
        {
          name: t("navbar.menu"),
          icon: <FaTasks />,
          link: "/manager/MenuGestion",
          event: "",
        },
        {
          name: "Newsletter",
          icon: <FaEnvelope  />,
          link: "/manager/NewsLetter",
          event: "",
        },
        {
          name: t("navbar.discon"),
          icon: <FaSignOutAlt />,
          link: "/Connexion",
          event: "deconnexion",
        },
      ];
      break;
    case "Client":
      initialUser = [
        {
          name: t("navbar.application"),
          icon: <FaUserGraduate />,
          link: "/client/MesCandidatures",
          current: false,
        },
        {
          name: t("navbar.cv"),
          icon: <FaFileAlt />,
          link: "/client/MesCv",
          current: false,
        },
        {
          name: t("navbar.profil"),
          icon: <FaUser />,
          link: "/client/MonProfil",
          event: "",
        },
        {
          name: t("navbar.discon"),
          icon: <FaSignOutAlt />,
          link: "/Connexion",
          event: "deconnexion",
        },
      ];
      break;
    default:
      initialUser = [];
  }
  const handleClic = (name) => {
    if (name === "deconnexion") {
      handleLogout();
    }
  };

  return (
    <Menu as="div" className="relative">
      <Menu.Button className="flex items-center w-full text-sm font-semibold text-black bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-blue-500 border border-blue-500">
        <FaUserCircle
          className="hidden w-8 h-8 md:block  text-[#DFF4FF] bg-blue-500 rounded-full"
          aria-hidden="true"
        />
        <div className="hidden py-2 ml-1 md:inline-block lg:text-sm 2xl:text-xl xl:text-[12px] text-sm ">
          {currentUser}
        </div>

        <FaUserCircle
          className="w-8 h-8 md:hidden text-[#DFF4FF] bg-blue-500 rounded-full"
          aria-hidden="true"
        />

        <FaCaretDown className="w-4 h-4 text-blue-500" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 w-48 py-1 mt-2 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none md:left-auto md:right-0">
          {initialUser.map((item) => (
            <Menu.Item key={item.name}>
              <NavLink
                exact={"true"}
                to={item.link}
                // className=""
                onClick={() => handleClic(item.event)}
                className={`block px-4 py-2 text-sm hover:text-orange-600 ${
                  window.location.pathname === item.link ? "activeUserItem" : ""
                }`}
              >
                <div className="flex items-center">
                  <span className="mr-2">{item.icon}</span>
                  <span className="lg:text-sm 2xl:text-xl xl:text-[15px] text-sm">
                    {item.name}
                  </span>
                </div>
              </NavLink>
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default UserCpt;
