import React, { useState } from "react";
import GraphComponent from "./MonthlyItem";

const App = () => {
  const [selectedMonthInscrits, setSelectedMonthInscrits] = useState("janvier");
  const [selectedMonthPourcentages, setSelectedMonthPourcentages] =
    useState("janvier");

  const months = ["janvier", "février", "mars"];

  const dataByMonth = {
    janvier: [
      { semaine: "Semaine1", inscrits: 100 },
      { semaine: "Semaine2", inscrits: 150 },
      { semaine: "Semaine3", inscrits: 250 },
      { semaine: "Semaine4", inscrits: 300 },
    ],
    février: [
      { semaine: "Semaine1", inscrits: 120 },
      { semaine: "Semaine2", inscrits: 180 },
      { semaine: "Semaine3", inscrits: 200 },
      { semaine: "Semaine4", inscrits: 280 },
    ],
    mars: [
      { semaine: "Semaine1", inscrits: 90 },
      { semaine: "Semaine2", inscrits: 130 },
      { semaine: "Semaine3", inscrits: 220 },
      { semaine: "Semaine4", inscrits: 260 },
    ],
  };

  const visitorByMonth = {
    janvier: [
      { semaine: "Semaine1", visitors: 200 },
      { semaine: "Semaine2", visitors: 350 },
      { semaine: "Semaine3", visitors: 450 },
      { semaine: "Semaine4", visitors: 500 },
    ],
    février: [
      { semaine: "Semaine1", visitors: 620 },
      { semaine: "Semaine2", visitors: 780 },
      { semaine: "Semaine3", visitors: 800 },
      { semaine: "Semaine4", visitors: 980 },
    ],
    mars: [
      { semaine: "Semaine1", visitors: 190 },
      { semaine: "Semaine2", visitors: 230 },
      { semaine: "Semaine3", visitors: 320 },
      { semaine: "Semaine4", visitors: 460 },
    ],
  };

  const handleMonthChangeInscrits = (event) => {
    setSelectedMonthInscrits(event.target.value);
  };

  const handleMonthChangePourcentages = (event) => {
    setSelectedMonthPourcentages(event.target.value);
  };

  const calculatePercentages = (data, visitors) => {
    return data.map(({ semaine, inscrits }, index) => ({
      semaine,
      pourcentage: ((inscrits / visitors[index].visitors) * 100).toFixed(2),
    }));
  };

  return (
    <div className="flex flex-container mx-auto justify-center xl:justify-none lg:mx-none">
      <div className="lg:flex lg:flex-row lg:justify-around xl:gap-x-40">
        <GraphComponent
          data={dataByMonth[selectedMonthInscrits]}
          dataKey="inscrits"
          fill="#EA580C"
          months={months}
          selectedMonth={selectedMonthInscrits}
          handleMonthChange={handleMonthChangeInscrits}
          width="xl:692px"
          barSize={12}
          title={"Nombre d'inscrits par semaine sur un mois "}
        />
        <GraphComponent
          data={calculatePercentages(
            dataByMonth[selectedMonthPourcentages],
            visitorByMonth[selectedMonthPourcentages]
          )}
          dataKey="pourcentage"
          fill="#0284C7"
          months={months}
          selectedMonth={selectedMonthPourcentages}
          handleMonthChange={handleMonthChangePourcentages}
          width="xl:417px"
          barSize={8}
          title={"Pourcentage d'inscrits par semaine sur un mois"}
        />
      </div>
    </div>
  );
};

export default App;
