import React, { useContext, useEffect, useRef, useState } from "react";
import CvCard from "./CvCard.js";
import { showAlert } from "../Cv/alertUtils";
import { useMutation } from "@tanstack/react-query";
import { postCv } from "../../services/HttpRequest/User/cvRequest/post";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { queryClient } from "../../services/HttpRequest/queryClientProvider/queryClientProvider";
import PendingUI from "../UI/RequestUI/PendingUI.js";
import LoadDataFailUI from "../UI/RequestUI/LoadDataFailUI.js";
import CvContext from "../../context/CvContext/Context.js";
import { FaFilePdf } from "react-icons/fa";
import { Loader } from "react-feather";
import EmptyData from "../UI/RequestUI/EmptyData.js";
import { useTranslation, Trans } from "react-i18next";
  
  const icons = {
   iconLoader: (
    <Loader size={60} className={`text-[#5190B7] w-full animate-spin   `} />
  ),
  iconPdf: <FaFilePdf size={60} className="text-red-500 self-center my-2" />,
};
const FileUploader = () => {
  const { t } = useTranslation();
  const [files, setFiles] = useState([]);
  const [selectedFileId, setSelectedFileId] = useState(null);
  const lastCreatedFile = useRef(null);

  // cv context

  const { allDocsData, allDocsStatus, viewDoc } = useContext(CvContext);

  // mutation query
  const { mutate, status } = useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getAllCv"] });
    },
    mutationFn: postCv,
  });

  //
  useEffect(() => {
    if (allDocsStatus === "success") {
      const values = allDocsData?.data.map((cv) => ({
        id: cv.id,
        name: cv.fileName,
        content: cv.id,
      }));
      setFiles(values);
    } else {
      setFiles([]);
    }
  }, [allDocsData, allDocsStatus]);

  //

  const isFileValid = (file) => {
    const allowedTypes = ["application/pdf"];
    return allowedTypes.includes(file.type);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      if (isFileValid(selectedFile)) {
        if (files.length < 5) {
          const fileId = Date.now().toString();

          // sending the data via Mutation
          // console.log("selectedFile", selectedFile);

          let checkFile = allDocsData?.data?.filter(
            (file) => file.fileName === selectedFile.name
          );
          const isFileExist = checkFile?.length > 0;
          if (isFileExist > 0) {
            toast.error(t("mycv.error"), {
              position: "bottom-right",
              autoClose: 5000,
              bodyClassName: "font-bold",
              className: "w-auto",
              toastId: "error-toast",
            });
          } else {
            mutate({ data: selectedFile });
          }

          // Si aucun fichier n'est sélectionné, définissez le nouveau fichier comme fichier par défaut
          if (!selectedFileId) {
            setSelectedFileId(fileId);
          }
        } else {
          showAlert(t("mycv.alert"));
          setTimeout(() => {}, 3000);
        }
      } else {
        showAlert(t("mycv.alert2"));
      }
    }
  };

  // end submit

  // TOAST

  // view cv toast

  useEffect(() => {
    if (viewDoc.status === "error") {
      toast.error(t("mycv.error2"), {
        position: "bottom-right",
        autoClose: 5000,
        bodyClassName: "font-bold",
        className: "w-auto",
        toastId: "error-toast",
      });
    }
  }, [viewDoc]);

  //add cv toast
  useEffect(() => {
    if (status === "success") {
      toast.success(t("mycv.success"), {
        position: "bottom-right",
        autoClose: 5000,
        bodyClassName: "font-bold",
        className: "w-auto",
        toastId: "success-toast",
      });
    }
    if (status === "error") {
      toast.error(t("mycv.error3"), {
        position: "bottom-right",
        autoClose: 5000,
        bodyClassName: "font-bold",
        className: "w-auto",
        toastId: "error-toast",
      });
    }
  }, [status]);

  //

  const handleDelete = (fileId) => {
    const updatedFiles = files.filter((file) => file.id !== fileId);
    setFiles(updatedFiles);

    if (selectedFileId === fileId) {
      if (updatedFiles.length > 0) {
        const lastFile = updatedFiles[updatedFiles.length - 1];

        setSelectedFileId(lastFile.id);
        lastCreatedFile.current = lastFile.id;
      } else {
        setSelectedFileId(null);
        lastCreatedFile.current = null;
      }
    }
  };

  const handleSelect = (fileId) => {
    // Changez le fichier sélectionné au clic
    setSelectedFileId(fileId);
  };

  // const handleView = (file) => {
  //   // const blobURL = URL.createObjectURL(file);
  //   // window.open(blobURL, "_blank");
  //   let files = new Blob([file], { type: 'application/pdf' });
  //   let fileURL = URL.createObjectURL(files);
  //   window.open(fileURL, "_blank");
  // };


  let cvList;

  switch (allDocsStatus) {
    case "pending":
      cvList = (
        <div className="">
          <PendingUI />
        </div>
      );
      break;

    case "success":
      cvList = (
        <div className="flex flex-wrap gap-3 justify-center">
          {status === "pending" && (
            <CvCard
              file={"Cv"}
              onDelete={""}
              isSelected={false}
              onSelect={""}
              icon={icons.iconLoader}
              onView={"handleView"}
              lastCreatedFile={lastCreatedFile}
            />
          )}
          {files.map((file) => (
            <CvCard
              key={file.id}
              file={file}
              icon={icons.iconPdf}
              onDelete={() => handleDelete(file.id)}
              isSelected={selectedFileId === file.id}
              onSelect={handleSelect}
              onView={"handleView"}
              lastCreatedFile={lastCreatedFile}
            />
          ))}
        </div>
      );
      break;
    case "error":
      cvList = (
        <div>
          <LoadDataFailUI />
        </div>
      );
      break;

    default:
      break;
  }

  return (
    <div className="container py-4 mx-auto">
      <ToastContainer />
      <div className="flex justify-between py-8 ">
        <div className="flex justify-start">
          <h1 className="text-3xl font-semibold  ">{t("mycv.title")}</h1>
          {/* <p className="text-[#667085]">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
        </div>

        <div className="flex justify-end ">
          <label htmlFor="fileInput" className="relative">
            <input
              id="fileInput"
              type="file"
              onChange={handleFileChange}
              className="hidden"
              accept="image/*, application/pdf"
            />
            <span className="bg-[#EA580C] text-white font-bold py-2 px-6 rounded-full cursor-pointer">
              {t("mycv.add_btn")}
            </span>
          </label>
        </div>
      </div>

      <div className=" ">
        <div className="flex  justify-start">
          {allDocsData?.data?.length === 0 && status !== "pending" ? (
            <EmptyData>{t("mycv.emptydata")}</EmptyData>
          ) : (
            cvList
          )}
        </div>
        {/* <div className="mb-10"></div> */}
      </div>
    </div>
  );
};

export default FileUploader;
