import {
  ALL_CAT_URL,
  CREATE_CAT_URL,
  DELETE_CATEGORY_URL,
  EDIT_CATEGORIE_URL,
  GET_ONE_CAT,
} from "../../api";
import aXios from "../../axiosConfig";
import { axiosUseToken } from "../../axiosConfig";

export const createCategory = async ({ data }) => {
  const response = await axiosUseToken.post(CREATE_CAT_URL, data);

  return response;
};

export const getCategories = async () => {
  const response = await aXios.get(ALL_CAT_URL);
  return response;
};

export const deleteCategory = async ({ data }) => {
  const response = await axiosUseToken.delete(DELETE_CATEGORY_URL + data);
  return response;
};

export const editCategory = async ({ data }) => {
  const response = await axiosUseToken.post(
    EDIT_CATEGORIE_URL + data.id,
    data.data
  );
  return response;
};

export const getOneCategory = async (id) => {

    const response = await aXios(GET_ONE_CAT + id);
    return response;

};
