import React, { useEffect, useState } from "react";
import { Card, Input, Button, Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import ChangePwd from "./ChangePwd/ChangePwd";
import { useSelector } from "react-redux";
import {
  getUserInfo,
  selectCurrentUser,
} from "../../../store/slice/loginSlice";
import { FaCheck, FaEdit, FaExclamationTriangle } from "react-icons/fa";
// import { userEditProfil } from "../../../services/HttpRequest/User/userEditProfil";
// import { useMutation } from "@tanstack/react-query";
import TextFieldUI from "../../UI/TextFieldUI";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@tanstack/react-query";
import { editUser } from "../../../services/HttpRequest/clientRequest/editUser";
import PendingModal from "../../QuickApply_Cpt/UI/PendingModal";
import ModalInfos from "../../UI/RequestUI/ModalInfos";
import { getCurrentUser } from "../../../services/HttpRequest/adminRequest/userRequest";
import { queryClient } from "../../../services/HttpRequest/queryClientProvider/queryClientProvider";
import PhoneNumberUI from "../../UI/PhoneNumberUI";

const PersoInfo = () => {
  const { t } = useTranslation();
  const currentUserData = useSelector(selectCurrentUser);
  const currentUser = currentUserData ? currentUserData.user : null;
  // console.log('eeeeeeeeeeeeee', currentUser);
  const [isRequestModal, setIsRequestModal] = useState(false);

  const { status: qStatus, data: qData } = useQuery({
    queryKey: ["getCurrentUser"],
    queryFn: getCurrentUser,
  });
  const [formData, setFormData] = useState({
    firstName: currentUser.firstName,
    lastName: currentUser.lastName,
    address: currentUser.address,
    phoneNumber: currentUser.phoneNumber,
    email: currentUser.email,
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    if (qStatus === "success") {
      setFormData({
        firstName: qData?.data?.user.firstName,
        lastName: qData?.data?.user.lastName,
        address: qData?.data?.user.address,
        phoneNumber: qData?.data?.user.phoneNumber,
        email: qData?.data?.user.email,
      });
    }
  }, [qStatus]);

  const [isEditing, setIsEditing] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  console.log("dqata", qData?.data?.user);

  const {
    mutate,
    data,
    status: mStatus,
  } = useMutation({
    mutationFn: editUser,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["getCurrentUser"] });
    },
  });
  const handleChangePhoneNumber = (phone) => {
    setFormData((prevState) => ({
      ...prevState,
      phoneNumber: phone,
    }));
  };

  // console.log("status", status);
  // console.log("data", data);
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsRequestModal(true);
    // Validation des champs
    if (
      formData.firstName.trim() === "" ||
      formData.lastName.trim() === "" ||
      formData.address.trim() === "" ||
      formData.phoneNumber.trim() === "" ||
      formData.email.trim() === ""
    ) {
      setErrorMessage(t("myprofile.error_msg1"));
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      setErrorMessage(t("myprofile.error_msg2"));
      return;
    }

    const phoneRegex = /^\d{3}-\d{4}$/;
    // if (!phoneRegex.test(formData.phoneNumber)) {
    //   setErrorMessage(
    //     "Veuillez entrer un numéro de téléphone valide (ex: 555-1234)."
    //   );
    //   return;
    // }

    setErrorMessage("");
    mutate({ data: formData });
    // console.log(formData);
    setIsEditing(false);
  };

  // useEffect(() => {
  //   if (mStatus === "error") {
  //     setFormData({
  //       firstName: currentUser.firstName,
  //       lastName: currentUser.lastName,
  //       address: currentUser.address,
  //       phoneNumber: currentUser.phoneNumber,
  //       email: currentUser.email,
  //     });
  //   }
  // }, [mStatus,]);
  return (
    <div className="bg-[#F9F9F9]">
      <PendingModal isOpen={mStatus === "pending"} />
      <ModalInfos
        isOpen={mStatus === "error" && isRequestModal}
        message={t("myprofile.error_msg3")}
        icon={<FaExclamationTriangle className="size-8 text-orange-500" />}
        autoClose={() => setIsRequestModal(false)}
      />
      <ModalInfos
        // containerClass={'w-96'}
        isOpen={mStatus === "success" && isRequestModal}
        message={t("myprofile.success_msg")}
        icon={<FaCheck className="size-10 text-green-500" />}
        autoClose={() => setIsRequestModal(false)}
      />
      <div className="flex justify-between mb-5 border-b ">
        <div className="justify-start">
          <h1 className="text-3xl font-semibold  ">{t("myprofile.title")}</h1>
          <p className="text-[#667085]">{t("myprofile.subtitle")}</p>
        </div>

        <div className=" justify-end ">
          {!isEditing && (
            <button
              className="px-2 py-2 [font-family:'Inter-Bold',Helvetica] font-bold  text-white hover:border-2 hover:text-[orange-500] hover:border-white duration-500 bg-[#EA580C] rounded-[25px] btn md:ml-2 md:static shadow-[4px_7px_4.7px_-6px_#00000040]  hover:bg-indigo-500 text-center"
              onClick={handleEditClick}
            >
              {t("myprofile.edit")}
            </button>
          )}
        </div>
      </div>
      <div className=" lg:px-20  sm:px-4 bg-[#F9F9F9] border-none">
        <Card className="max-w-full  flex w-full justify-center border-none bg-[#F9F9F9]">
          <form onSubmit={handleSubmit} className="p-6  border-none">
            {/* Afficher le message d'erreur */}
            {errorMessage && (
              <p className="mb-4 text-sm text-center text-red-500 border  border-b-2 border-red-500 border-solid">
                {errorMessage}
              </p>
            )}
            <div className="flex flex-col ">
              <div className="flex justify-between border-b py-5 items-center ">
                <label
                  htmlFor="firstName"
                  className="text-sm justify-start w-full text-[#667085] "
                >
                  {t("myprofile.name_field")}
                </label>
                <div className="justify-end w-full ">
                  <div className="flex justify-between space-x-5 w-full ">
                    <TextFieldUI
                      type="text"
                      id="firstName"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      placeholder={t("myprofile.firstname_placeholder")}
                      classname={`  w-full ${
                        !isEditing ? " bg-white" : "border-[#457AA8] bg-white"
                      }  h-[40px]`}
                      disabled={!isEditing}
                    />
                    <TextFieldUI
                      type="text"
                      id="lastName"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      placeholder={t("myprofile.lastname_placeholder")}
                      classname={`  w-full ${
                        !isEditing ? " bg-white" : "border-[#457AA8] bg-white"
                      }  h-[40px]`}
                      disabled={!isEditing}
                    />
                  </div>
                </div>
              </div>

              <div className="flex justify-between border-b py-5  items-center">
                <label
                  htmlFor="firstName"
                  className="text-sm justify-start w-full text-[#667085]"
                >
                  {t("myprofile.address_field")}
                </label>
                <div className="justify-end w-full">
                  <div className="flex justify-between">
                    <TextFieldUI
                      type="text"
                      id="address"
                      name="address"
                      value={formData.address}
                      onChange={handleChange}
                      placeholder={t("myprofile.address_placeholder")}
                      classname={`  w-full ${
                        !isEditing ? " bg-white" : "border-[#457AA8] bg-white"
                      }  h-[40px]`}
                      disabled={!isEditing}
                    />
                  </div>
                </div>
              </div>

              <div className="flex justify-between border-b py-5 items-center">
                <label
                  htmlFor="phoneNumber"
                  className="text-sm justify-start w-full text-[#667085] "
                >
                  {t("myprofile.phonenumber_field")}
                </label>
                <div className="justify-end w-full">
                  <div className="flex justify-between">
                    <PhoneNumberUI
                      type="text"
                      id="phoneNumber"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={(phone) => handleChangePhoneNumber(phone)}
                      placeholder={t("myprofile.phonenumber_placeholder")}
                      classname={`  w-full ${
                        !isEditing ? " bg-white" : "border-[#457AA8] bg-white"
                      }  h-[40px]`}
                      disabled={!isEditing}
                    />
                  </div>
                </div>
              </div>

              <div className="flex justify-between border-b py-5 items-center">
                <label
                  htmlFor="email"
                  className="text-sm justify-start w-full text-[#667085]"
                >
                  {t("myprofile.email_field")}
                </label>
                <div className="justify-end w-full">
                  <div className="flex justify-between">
                    <TextFieldUI
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder={t("myprofile.email_placeholder")}
                      classname={`  w-full ${
                        !isEditing ? " bg-white" : "border-[#457AA8] bg-white"
                      }  h-[40px]`}
                      disabled={!isEditing}
                    />
                  </div>
                </div>
              </div>

              <div>
                <Typography
                  variant="small"
                  color="blue"
                  className="items-center text-sm font-normal mt-9 underline text-[#457AA8] cursor-pointer hover:text-[#68afed]"
                  onClick={openModal}
                >
                  {t("myprofile.link_modal")}
                </Typography>
                {isModalOpen && <ChangePwd onClose={closeModal} />}
              </div>
            </div>

            {isEditing && (
              <div className="flex mt-10 justify-end space-x-4">
                <Button
                  type="button"
                  variant="filled"
                  color="red"
                  className="pt-4 opacity-80 rounded-[28px]"
                  onClick={() => setIsEditing(false)}
                >
                  {t("myprofile.cancel")}
                </Button>
                <Button
                  type="submit"
                  variant="filled"
                  color="blue"
                  className="pt-4 opacity-80 rounded-[28px]"
                >
                  {t("myprofile.save")}
                </Button>
              </div>
            )}
          </form>
        </Card>
      </div>
    </div>
  );
};

export default PersoInfo;
