import React, { useState, useEffect } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { RxDotFilled } from "react-icons/rx";
import carrousel4 from "../../../assets/carousel_home_page/1.jpeg";
import carrousel3 from "../../../assets/carousel_home_page/2.jpeg";
import carrousel2 from "../../../assets/carousel_home_page/3.jpeg";
import carrousel1 from "../../../assets/carousel_home_page/4.jpeg";
import carrousel5 from "../../../assets/carrousel5.jpg";
import "./SectionOne.css";
import { useTranslation, Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

function SectionOne() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/Inscription");
  };

  useEffect(() => {
    AOS.init({
      duration: 1000, // Durée de l'animation en millisecondes
    });
  }, []);

  const data = [
    {
      id: 1,
      url: carrousel1,
      alt: "dentist",
      Title: t("hP-sectionOne.titleOfCarrousel3"),
      text: t("hP-sectionOne.subtitleOfCarrousel3"),
    },
    {
      id: 2,
      url: carrousel2,
      alt: "employee at desktop",
      Title: t("hP-sectionOne.titleOfCarrousel2"),
      text: t("hP-sectionOne.subtitleOfCarousel2"),
    },
    {
      id: 3,
      url: carrousel3,
      alt: "male worker",
      Title: t("hP-sectionOne.titleOfCarrousel4"),
      text: t("hP-sectionOne.subtitleOfCarrousel4"),
    },
    {
      id: 4,
      url: carrousel4,
      alt: "female worker",
      Title: t("hP-sectionOne.titleOfCarrousel1"),
      text: t("hP-sectionOne.subtitleOfCarrousel1"),
    },
    {
      id: 5,
      url: carrousel5,
      alt: "nurses",
      Title: t("hP-sectionOne.titleOfCarrousel5"),
      text: t("hP-sectionOne.subtitleOfCarrousel5"),
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    const isLastSlide = currentIndex === data.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  useEffect(() => {
    const SlideAuto = setInterval(() => {
      nextSlide();
    }, 7000);
    return () => {
      clearInterval(SlideAuto);
    };
  }, [currentIndex]);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? data.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  return (
    <div
      className="relative lg:h-[798px] md:h-96 h-[600px] w-full transition"
      data-aos="fade-down"
    >
      <img
        src={data.at(currentIndex).url}
        alt={data.at(currentIndex).alt}
        className="w-full h-full object-cover"
      />
      <div className="absolute inset-0 bg-gradient-to-r from-custom-orange to-transparent">
        <div className="flex justify-center py-5 gap-2 items-center absolute bottom-4 w-full">
          {data.map((slide, slideIndex) => (
            <div
              key={slideIndex}
              onClick={() => goToSlide(slideIndex)}
              className={`cursor-pointer w-3 h-3  bg-[#decbc1] rounded-full flex items-center ${
                slideIndex === currentIndex ? "p-2" : "bg-opacity-50 "
              }`}
            ></div>
          ))}
        </div>
        <div className="absolute lg:top-52 lg:left-28 top-10 left-10 gap-5  ">
          <p
            className="head-text"
            data-aos="fade-left"
            data-aos-delay={`${currentIndex * 1000}`}
          >
            {data.at(currentIndex).Title}
          </p>
          <p
            className=" text-white"
            data-aos="fade-right"
            data-aos-delay={`${currentIndex * 1000}`}
          >
            {data.at(currentIndex).text}
          </p>
          <button
            className="bg-transparent text-white border border-white px-4 mt-5 py-2 rounded-full font-inter text-base font-semibold leading-6 text-left"
            onClick={handleClick}
          >
            {t("hP-sectionOne.buttonUpdated")}
          </button>
        </div>
      </div>
    </div>
  );
}
export default SectionOne;
