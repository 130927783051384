import React from "react";
import AddCompForm from "../../../../Form/AddCompForm";
import { motion } from "framer-motion";

const ModalAddUser = ({
  isOpen,
  onCancel,
  onConfirm,
  editedCompanyData,
  formTitle,
}) => {
  const handleFormCancel = () => {
    onCancel();
  };

  const handleFormConfirm = () => {
    onConfirm();
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <motion.div
      initial={{ y: 30, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 30, opacity: 0 }}
      className={`fixed overflow-y-auto top-0 left-0 w-screen h-screen flex items-center justify-center ${
        isOpen ? "block" : "hidden"
      }`}
      style={{ zIndex: 120000 }} 
    >
      <div
        className="absolute w-full h-full bg-black opacity-50"
        onClick={handleFormCancel}
      ></div>
      <div
        className="max-h-[80rem] w-[60rem] p-4 bg-white rounded-md shadow-md"
        onClick={stopPropagation}
        style={{ zIndex: 120001 }} 
      >
        <div className="flex items-center justify-between pb-2 mb-2 border-b-2">
          <h2 className="text-xl font-bold">{formTitle}</h2>
          <button className="text-2xl" onClick={handleFormCancel}>
            &times;
          </button>
        </div>
        <div className="mb-2">
          <AddCompForm
            onCancel={handleFormCancel}
            onConfirm={handleFormConfirm}
            editedCompanyData={editedCompanyData}
          />
        </div>
        <div className="flex justify-center"></div>
      </div>
    </motion.div>
  );
};

export default ModalAddUser;
