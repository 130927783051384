import React, { useEffect, useContext } from "react";
import PendingUI from "../../../UI/RequestUI/PendingUI";
import LoadDataFailUI from "../../../UI/RequestUI/LoadDataFailUI";
import CompTable from "./CompTable/CompTable";
import CompContext from "../../../../context/CompContext";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../../../../services/HttpRequest/queryClientProvider/queryClientProvider";
import { deleteCompagny } from "../../../../services/HttpRequest/adminRequest/compRequest";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation, Trans } from "react-i18next";

const CompManage = () => {
  const { t } = useTranslation();

  const { allComps: comp, statusComp } = useContext(CompContext);

  const { mutate, status } = useMutation({
    mutationFn: deleteCompagny,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["getAllCompany"] });
    },
  });

  useEffect(() => {
    if (status === "success") {
      toast.success(t("compManager.success"), {
        position: "top-center",
        autoClose: 5000,
        bodyClassName: "font-bold",
        className: "w-[200%]",
        toastId: "success-toast-comp",
      });
    }
    if (status === "error") {
      toast.error(t("compManager.error"), {
        position: "top-center",
        autoClose: 5000,
        bodyClassName: "font-bold",
        className: "w-[200%]",
        toastId: "error-toast-comp",
      });
    }
  }, [status]);

  const handleDelete = (comp) => {
    mutate({ data: comp.id });
  };

  const handleEdit = (comp, editedJob) => {
    // console.log("Edited cat:", editedJob);
  };

  const handleBlock = (comp) => {
    // console.log("Block user:", comp);
  };

  let compContent;

  switch (statusComp) {
    case "pending":
      compContent = <PendingUI />;
      break;
    case "error":
      compContent = <LoadDataFailUI />;
      break;
    case "success":
      compContent = (
        <CompTable
          data={comp}
          onDelete={handleDelete}
          onEdit={handleEdit}
          onBlock={handleBlock}
        />
      );
      break;
    default:
      break;
  }

  // traduction des elments textuels de ce composant
  // Gestion des Compagies - Company Management

  return (
    <div className="container mx-auto mt-8">
      <ToastContainer />
      <h1 className="mb-4 text-3xl font-bold text-[#457AA8]">{t("compManager.title")}</h1>
      {compContent}
    </div>
  );
};

export default CompManage;
