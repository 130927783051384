import CvListItem from "./CvListItem";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Loader } from "react-feather";
import { FaPlug, FaPlusCircle, FaSave } from "react-icons/fa";
import LoadDataFailUI from "../../UI/RequestUI/LoadDataFailUI";
import { useNavigate } from "react-router-dom";
import useDragDrop from "../../Hooks/useDocPicker";
import ButtonStudentUI from "../../UI/ButtonUI";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../../../services/HttpRequest/queryClientProvider/queryClientProvider";
import { postCv } from "../../../services/HttpRequest/User/cvRequest/post";
import PendingUI from "../../UI/RequestUI/PendingUI";
import FormTextUI from "../../UI/FormTextUI";
import toastUtils from "../../Utils/toastUtils";
import { ToastContainer } from "react-toastify";

const files = [
  { name: "Cv A", size: 304, id: 1 },
  { name: "Cv B", size: 259, id: 2 },
  { name: "Cv C", size: 290, id: 3 },
  { name: "Cv D", size: 620, id: 4 },
];

export const CvList = ({ onGetDocId, mData, mStatus }) => {
  const navigate = useNavigate();
  const allUserCv = useSelector((state) => state.cvUser.allDocsData);
  const [id, setId] = useState();

  const status = useSelector((state) => state.cvUser.allDocsStatus);
  //mutate cv
  const { content: formContent, formFile } = useDragDrop();

  const handleSubmitDoc = () => {
    if (formFile) {
      // mutate({ data: formFile });
    }
  };
  // end mutation
  const handleGetId = (id) => {
    onGetDocId(id);
    // console.log("id", id);
    setId(id);
  };
  // console.log("allUserCv ", allUserCv);

  useEffect(() => {
    onGetDocId(id, formFile);
  }, [formFile]);

  let content = <></>;
  switch (status) {
    case "pending":
      content = (
        <div className="size-14 mt-4 self-center ">
          <Loader className="size-7 lg:size-10 ml-3 text-[#457aa8]  animate-spin" />
        </div>
      );
      break;
    case "success":
      const data = allUserCv?.map((file) => (
        <div key={file.id} onClick={() => handleGetId(file.id)}>
          <CvListItem file={file} id={id} />
        </div>
      ));
      content = (
        <>
          {data}
          <li
            className={`font-mono text-[#457AA8] hover:cursor-pointer  gap-x-3 border-dashed  rounded-xl flex items-center justify-center border-2 h-10 lg:h-12 m-3 mt-1 hover:text-white hover:bg-[#457AA8] transition-all duration-500  ease-out `}
            onClick={() => navigate("/client/MesCv")}
          >
            <span className="text-sm  ">Nouveau CV</span>
            <FaPlusCircle className="size-5 " />
          </li>
        </>
      );
      break;
    case "error":
      content = (
        <LoadDataFailUI containerClassname={"mb-4 p-2"}>
          Une erreur est survenue lors du chargement des CV. Veuillez réessayer
          plus tard.
        </LoadDataFailUI>
      );
      break;

    default:
      break;
  }

  useEffect(() => {
    if (mStatus === "error") {
      toastUtils(mStatus, "Echec de l'enregistrement du CV...!");
    }
  }, [mStatus]);

  if (allUserCv.length === 0 && status === "success") {
    return (
      <>
        {mStatus === "pending" ? (
          <PendingUI
            lableClass={"-mt-8 -ml-9 "}
            classname={"mt-4 lg:ml-3"}
            containerClass={" self-center -mb-14"}
          >
            <FormTextUI classname={""}>Enregistrement en cours...</FormTextUI>{" "}
          </PendingUI>
        ) : (
          formContent
        )}
        {/* <ButtonStudentUI
          classname={`mt-3  lg:mt-4 max-lg:w-32 self-center mx-auto ${
            (!formFile || mStatus === "pending") && "bg-gray-500 "
          } `}
          type="button"
          onClick={handleSubmitDoc}
          disabled={mStatus === "pending"}
        >
          <div className="flex gap-x-2 text-center items-center justify-center  ">
            Enregistrer le CV
            <FaSave className="size-4 " />
          </div>
        </ButtonStudentUI> */}
      </>
    );
  }

  return (
    <div className="flex w-full justify-center border rounded-lg">
      <ToastContainer />
      <ul className="flex w-80   max-sm:w-52 flex-col">{content}</ul>
    </div>
  );
};
