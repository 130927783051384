import React from "react";

import SectionTwo from ".././components/Cpt_AboutPage/SectionTwo/SectionTwo";
import SectionOne from "../components/Cpt_AboutPage/SectionOne/SectionOne";
import SectionThree from "../components/Cpt_AboutPage/SectionThree/SectionThree";

const AboutPage = () => {
  return (
    <main className="">
      <SectionOne />
      {/* <SectionTwo /> */}
      <SectionThree />
    </main>
  );
};

export default AboutPage;
