import TextFieldUI from "../UI/TextFieldUI";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Typography,
} from "@mui/material";

import { useEffect, useState } from "react";

import { useMutation } from "@tanstack/react-query";
import useCheckBoxRegion from "./FormApply/useCheckBoxRegion";
import { useNavigate } from "react-router-dom";
import PendingUI from "../UI/RequestUI/PendingUI";
import RequestSuccess from "../UI/RequestUI/RequestSucess";
import useDocPicker from "../Hooks/useDocPicker";
import { sendApplyRequest } from "../../services/HttpRequest/clientRequest/sendApplyRequest";
import {
  isEmailValid,
  isPhoneValid,
  isTextValid,
} from "../Utils/ValidFormElement";
import ModalUI from "../UI/ModalUI";
import RequestFailPanel from "../UI/RequestUI/RequestFailPanel";
import { AnimatePresence } from "framer-motion";
import LoadDataFailUI from "../UI/RequestUI/LoadDataFailUI";
import formApplyImg from "../../assets/FormApply.png";
import { CvList } from "./FormApplyListCv/CvList";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../store/slice/loginSlice";
import SelectUI from "../UI/SelectUI";
import { disponibility } from "./RegistrationJobFormStep/TableDate";
import WorkTime from "./FormApplyListCv/WorkTime";
import PhoneNumberUI from "../UI/PhoneNumberUI";
import { queryClient } from "../../services/HttpRequest/queryClientProvider/queryClientProvider";

import { useParams } from "react-router-dom";
import ButtonStudentUI from "../UI/ButtonUI";
import { postCv } from "../../services/HttpRequest/User/cvRequest/post";
import InvalidInputControl from "../UI/InvalidInputControl";
import RadioUI from "../UI/RequestUI/RadioUI";
import { useTranslation } from "react-i18next";
import FormTextUI from "../UI/FormTextUI";

const FormApply = () => {
  // role selection

  const user = useSelector(selectCurrentUser);
  const userRole = user?.roles[0];

  const { jobId } = useParams();
  const { t } = useTranslation();
  // state
  const jobIdNumber = parseInt(jobId.match(/\d+/)[0], 10);

  const allJobs = useSelector((state) => state.jobs.allJobs);
  const allUserCv = useSelector((state) => state.cvUser.allDocsData);
  const [agree, setAgree] = useState([]);
  const [isNewCv, setIsNewCv] = useState(true);
  const [didEditWorkTime, setDidEditWorkTime] = useState(false);
  const [isTrySubmit, setIsTrySubmit] = useState(false);
  const [postCvdata, setPostCvData] = useState({
    cvStatus: "",
    cvData: {},
  });

  const [cvId, setCvId] = useState(null);
  const { region, regionContent } = useCheckBoxRegion();
  const [workTime, setWorkTime] = useState([]);
  const [docUser, setDocUser] = useState(null);
  const [canadianWorkingPermit, setCanadianWorkingPermit] = useState(false);
  // context
  const activeJob = allJobs.find((job) => job.id === jobIdNumber);
  // const activeJob = useSelector((state) => state.jobs.activeJob);
  // dock picker
  const { content, formFile } = useDocPicker({
    label: "Selectionner un doccument PDF",
    name: "monCvPdf",
  });
  const handleGetCvId = (id, file) => {
    setCvId(id);
    setDocUser(file);
  };

  const handleIsResident = (value) => {
    setCanadianWorkingPermit(value);
  };

  // fix validation
  const [formCheck, setFormCheck] = useState({
    resume: { value: formFile, didEdit: false },
    email: { value: "", didEdit: false },
    firstName: { value: "", didEdit: false },
    lastName: { value: "", didEdit: false },
    address: { value: "", didEdit: false },
    address2: { value: "" },
    town: { value: "" },
    phoneNumber: { value: "", didEdit: false },
    disponibility: { value: "", didEdit: false },
  });
  const handleChangeField = (id, value) => {
    setFormCheck((prevCheck) => ({
      ...prevCheck,
      [id]: { value: value, didEdit: false },
    }));
  };

  const handleBlurFied = (id) => {
    setFormCheck((prevCheck) => ({
      ...prevCheck,
      [id]: { ...prevCheck[id], didEdit: true },
    }));
  };

  //validBloc
  const isFirstNameNotOk =
    formCheck.firstName.didEdit && !isTextValid(formCheck.firstName.value);
  const isLastNameNotOk =
    formCheck.lastName.didEdit && !isTextValid(formCheck.lastName.value);
  const isPhoneNumberNotOk =
    formCheck.phoneNumber.didEdit && !isPhoneValid(formCheck.phoneNumber.value);
  const isEmailNotOk =
    formCheck.email.didEdit && !isEmailValid(formCheck.email.value);
  const isAdresseNotOk =
    formCheck.address.didEdit && !isTextValid(formCheck.address.value);
  const isDisponibilityNotOk =
    formCheck.disponibility.didEdit &&
    !isTextValid(formCheck.disponibility.value);

  const isCvNotValid = !(formFile || cvId) && isTrySubmit;
  //
  const verifySubmit =
    region.length > 0 &&
    agree.length > 0 &&
    workTime.length > 0 &&
    (formFile || userRole === "Client");
  //
  const isValidSubmitOk =
    (isTextValid(formCheck.firstName.value) &&
      isTextValid(formCheck.lastName.value) &&
      isPhoneValid(formCheck.phoneNumber.value) &&
      isEmailValid(formCheck.email.value) &&
      isTextValid(formCheck.address.value) &&
      isTextValid(formCheck.disponibility.value) &&
      workTime.length !== 0) ||
    (userRole === "Client" && verifySubmit);

  // en valid

  // console.log("handlechange", formCheck);

  const navigate = useNavigate();

  const [modalClose, setModalClose] = useState(true);

  //  mutation

  const {
    mutate,
    data: mData,
    error: mError,
    status: mStatus,
  } = useMutation({
    mutationFn: sendApplyRequest,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["getMyCandidacy"] });
    },
  });
  // console.log("errrrrrrrrrrrrrrrrrr", mError);

  //
  const handleModalCLose = (modal) => {
    setModalClose(false);

    if (modal === "success") {
      navigate("/Job");
    }
  };

  // new cv Side Effect for connected User
  useEffect(() => {
    if (userRole === "Client") {
      setIsNewCv(false);
    }
  }, []);

  // workTime

  const handleWorkTime = (time) => {
    setWorkTime(time);
  };

  // regions

  const handleAgree = (event) => {
    const value = event.target.value;

    const agreeIndex = agree.indexOf(value);

    if (agreeIndex === -1) {
      setAgree([value]);
    } else {
      setAgree([]);
    }
  };

  // --------------------------------------------------

  const handleSubmit = async () => {
    for (const field in formCheck) {
      setFormCheck((prevState) => ({
        ...prevState,
        [field]: { ...prevState[field], didEdit: true },
      }));
    }

    setDidEditWorkTime(true);

    const applyData = {
      resume: userRole === "Client" ? cvId : formFile,
      email: userRole === "Client" ? user.user.email : formCheck.email.value,
      firstName:
        userRole === "Client" ? user.user.firstName : formCheck.firstName.value,
      lastName:
        userRole === "Client" ? user.user.lastName : formCheck.lastName.value,
      address: [
        userRole === "Client" ? user.user.address : formCheck.address.value,
        userRole === "Client" ? "" : formCheck?.address2.value,
        userRole === "Client" ? "" : formCheck?.town.value,
      ],
      phoneNumber:
        userRole === "Client"
          ? user.user.phoneNumber
          : formCheck.phoneNumber.value,
      jobId: activeJob.id,
      interestRegion: region,
      canadianWorkingPermit: canadianWorkingPermit,
      disponibility: formCheck.disponibility.value,
      workTime: workTime,
    };

    setModalClose(true);
    // console.log("applyData", applyData);

    if (verifySubmit && (cvId || formFile)) {
      if (isValidSubmitOk) {
        mutate({ data: applyData, role: userRole });
      }
    }
  };
  // handle the privacy modal

  const handleIsModal = (value) => {
    navigate("/termAndCondition");
  };
  // toogle Cv
  // console.log("didEditWorkTime", didEditWorkTime);
  const handleIsNewCv = (value) => {
    setIsNewCv(value);
  };

  const handleSubmitForNewUser = async (event) => {
    event.preventDefault();

    setIsTrySubmit(true);
    if (allUserCv.length > 0 || userRole !== "Client") {
      return handleSubmit();
    }

    if (!docUser) {
      return;
    }

    try {
      setPostCvData((prevState) => ({
        ...prevState,
        cvData: {},
        cvStatus: "pending",
      }));
      const response = await postCv({ data: docUser });
      queryClient.invalidateQueries({ queryKey: ["getAllCv"] });
      setPostCvData((prevState) => ({
        ...prevState,
        cvData: response.data,
        cvStatus: "success",
      }));

      setCvId(response.data.document.id);

      // handleSubmit()
    } catch (error) {
      // console.log("error", error);
      setPostCvData((prevState) => ({
        ...prevState,
        cvData: {},
        cvStatus: "error",
      }));
    }
  };

  useEffect(() => {
    if (postCvdata.cvStatus === "success") {
      handleSubmit();
    }
  }, [postCvdata]);

  return (
    <main className=" border-gray-100 border-2 ">
      <div
        className="w-full items-center flex   mx-auto mb-5 shadow-md h-[350px] "
        style={{
          backgroundImage: `linear-gradient(rgba(2, 132, 199, 0.3), rgba(1, 64, 97, 20)), url(${formApplyImg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center top 0%",
        }}
      >
        <div className="text-white  text-center mx-auto  flex-wrap justify-center space-y-3">
          <h1 className="font-bold">Formulaire d'application rapide</h1>
          <p className="font-semibold text-center">
            Rejoignez l'équipe et faites partie de nos plus de 350
            professionnels qui définissent leurs propres conditions de travail !
          </p>
        </div>
      </div>
      <div className="w-full xl:py-10 sm:py-4 mx-auto   flex justify-center ">
        <form
          onSubmit={(event) => handleSubmitForNewUser(event)}
          id="formApplication"
          className="  bg-white border-2   lg:max-w-[1000px] max-w-[500px] px-5 lg:px-10 w-full p-10 lg:p-24 border-gray-200 border-1 rounded-xl"
        >
          <div className="p-4">
            {userRole !== "Client" && (
              <div className=" justify-between lg:space-x-4 lg:flex ">
                <div className="justify-start w-full">
                  <div>
                    <TextFieldUI
                      label="Nom"
                      classname="w-[95%]"
                      id="nom"
                      placeholderValue="Entrer votre nom"
                      isRequired
                      type="text"
                      value={formCheck.firstName.value}
                      onBlur={() => handleBlurFied("firstName")}
                      onChange={(event) =>
                        handleChangeField("firstName", event.target.value)
                      }
                    />
                    <h3 className=" text-red-500 px-2">
                      {isFirstNameNotOk && "veuillez entrer un nom valide"}
                    </h3>
                  </div>
                  <div className="mt-[-2px] ">
                    <PhoneNumberUI
                      label="Numero de telephone"
                      id="telPhone"
                      defaultCountry={"ca"}
                      isRequired
                      value={formCheck.phoneNumber.value}
                      onBlur={() => handleBlurFied("phoneNumber")}
                      onChange={(phone) =>
                        handleChangeField("phoneNumber", phone)
                      }
                    />
                    <h3 className=" text-red-500 px-2">
                      {isPhoneNumberNotOk &&
                        "veuillez entrer un numero de telephone valide"}
                    </h3>
                  </div>
                  <div>
                    {" "}
                    <TextFieldUI
                      label="Adresse"
                      classname="w-[95%]"
                      id="adresse"
                      placeholderValue="Entrer votre Adresse"
                      isRequired
                      type="text"
                      value={formCheck.address.value}
                      onBlur={() => handleBlurFied("address")}
                      onChange={(event) =>
                        handleChangeField("address", event.target.value)
                      }
                    />
                    <h3 className=" text-red-500 px-2">
                      {isAdresseNotOk && "veuillez entrer une Adresse valide"}
                    </h3>
                  </div>
                  <div>
                    <TextFieldUI
                      label="Ville"
                      classname="w-[95%]"
                      id="town"
                      placeholderValue="Entrer votre Ville"
                      type="text"
                      value={formCheck.town.value}
                      onChange={(event) =>
                        handleChangeField("town", event.target.value)
                      }
                    />
                  </div>
                  <div className="w-[97%]">
                    <SelectUI
                      data={disponibility}
                      label="Disponibilité"
                      classname="w-full "
                      id="disponibility"
                      isRequired
                      type="text"
                      value={formCheck.disponibility.value}
                      onBlur={() => handleBlurFied("disponibility")}
                      onChange={(event) =>
                        handleChangeField("disponibility", event.target.value)
                      }
                    />
                    <h3 className=" text-red-500 px-2">
                      {isDisponibilityNotOk &&
                        "veuillez selectionner une valeur"}
                    </h3>
                  </div>
                </div>
                <div className="justify-end w-full">
                  <div>
                    {" "}
                    <TextFieldUI
                      type="text"
                      label="prenom"
                      classname="w-[95%]"
                      id="prenom"
                      placeholderValue="Entrer votre prenom"
                      isRequired
                      value={formCheck.lastName.value}
                      onBlur={() => handleBlurFied("lastName")}
                      onChange={(event) =>
                        handleChangeField("lastName", event.target.value)
                      }
                    />
                    <h3 className=" text-red-500 px-2">
                      {isLastNameNotOk && "veuillez entrer un prenom valide"}
                    </h3>
                  </div>
                  <div>
                    <TextFieldUI
                      label="Adresse Courriel"
                      classname="w-[95%]"
                      id="email"
                      placeholderValue="mail@exemple.com"
                      type="email"
                      isRequired
                      value={formCheck.email.value}
                      onBlur={() => handleBlurFied("email")}
                      onChange={(event) =>
                        handleChangeField("email", event.target.value)
                      }
                    />
                    <h3 className=" text-red-500 px-2">
                      {isEmailNotOk &&
                        "veuillez entrer une adresse Email valide"}
                    </h3>
                  </div>
                  <div className="">
                    <TextFieldUI
                      label="Adresse 2"
                      classname="w-[95%]"
                      id="adresse2"
                      value={formCheck.address2.value}
                      placeholderValue="Entrer votre Adresse N°2"
                      onChange={(event) =>
                        handleChangeField("address2", event.target.value)
                      }
                    />
                  </div>
                  <div>
                    <TextFieldUI
                      label="Emploi indiqué"
                      classname="w-[95%] text-[#667085] bg-[#EFF0F1] border-none forced-color-adjust-none "
                      id="emploi"
                      value={
                        activeJob
                          ? activeJob.jobName
                          : "Aucun emploi indiquer. "
                      }
                      placeholderValue="Emploi"
                      readOnly={true}
                    />
                  </div>
                  {/*  */}

                  <div>
                    <WorkTime
                      didEdit={didEditWorkTime}
                      onChangeTime={handleWorkTime}
                      classname={"mt-2 "}
                    />
                  </div>

                  {/*  */}
                </div>
              </div>
            )}

            {userRole === "Client" && (
              <div>
                <div>
                  <TextFieldUI
                    label="Emploi indiqué"
                    classname="w-[95%] text-[#667085] bg-[#EFF0F1] border-none forced-color-adjust-none "
                    id="emploi"
                    value={
                      activeJob ? activeJob.jobName : "Aucun emploi indiquer. "
                    }
                    placeholderValue="Emploi"
                    readOnly={true}
                  />
                </div>
                <div className="lg:flex  space-x-4 w-full">
                  <div className="w-[97%] ml-1 ">
                    <SelectUI
                      data={disponibility}
                      label="Disponibilité"
                      classname="w-full "
                      id="disponibility"
                      isRequired
                      type="text"
                      value={formCheck.disponibility.value}
                      onBlur={() => handleBlurFied("disponibility")}
                      onChange={(event) =>
                        handleChangeField("disponibility", event.target.value)
                      }
                    />
                    <h3 className=" text-red-500 px-2">
                      {isDisponibilityNotOk &&
                        "veuillez selectionner une valeur"}
                    </h3>
                  </div>
                  <div className="w-[95%] mt-2 ">
                    <WorkTime
                      didEdit={didEditWorkTime}
                      onChangeTime={handleWorkTime}
                      classname={"max-lg:mt-3"}
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="mt-3 mb-3  ">
              <div className=" font-[600] flex w-full text-sm ">
                {regionContent}
              </div>

              <FormTextUI classname={"my-4"}>
                {t("quickApply.applyform_statut_field_msg")}{" "}
                <span className="text-red-500">*</span>
                <div className="flex justify-center gap-x-6">
                  <RadioUI
                    id={"r1"}
                    label={t("quickApply.applyform_statut_field_valueone")}
                    radioName={"citoyen"}
                    onSelect={() => handleIsResident(true)}
                    defaultValue={true}
                  />
                  <RadioUI
                    id={"r2"}
                    label={t("quickApply.applyform_statut_field_valuetwo")}
                    radioName={"citoyen"}
                    onSelect={() => {
                      handleIsResident(false);
                    }}
                  />
                </div>
                {/* <InvalidInputControl>
               veullez sélectionner un case
              </InvalidInputControl> */}
              </FormTextUI>
              <div className="mx-auto w-full flex justify-center">
                <FormControl className=" text-center">
                  {userRole !== "Client" && (
                    <p className="text-sm font-[600]   absolute ">
                      Cliquez ci dessous pour entrer votre cv
                      <span className="text-red-500">*</span>
                    </p>
                  )}
                  <div className="col-span-2 my-10 ml-3">
                    <FormControl className="ml-[40%] row-span-full w-full">
                      {userRole === "Client" && (
                        <div>
                          <h4 className="text-sm font-[600] my-3 text-center ">
                            Veuillez selectionner un Cv
                            {/* Voulez vous utiliser un nouveau CV{" "} */}
                            <span className="text-red-500">*</span>
                          </h4>
                        </div>
                      )}
                      {/* {userRole === "Client" && ( */}
                      {/* <div className="flex pt-7 pb-3 justify-center cursor-pointer"> */}
                      {/* <label htmlFor="cvRadioYes">
                    <input type="radio" id="cvRadioYes" className="size-25 p" name="cvRadio"  value="yes" />
                  </label>
                  <label   htmlFor="cvRadioNO">
                    <input type="radio" id="cvRadioNo" name="cvRadio" value="yes" />
                  </label> */}

                      {/* <div
                            className={`w-20 text-center   ${
                              isNewCv ? "bg-[#2c4c68]" : "bg-[#457AA8]"
                            }  rounded-l-xl shadow-lg hover:opacity-70 text-gray-200 h-9 flex items-center justify-center border-r py-1 border-r-gray-600 `}
                            onClick={() => handleIsNewCv(true)}
                          >
                            <span className="hover:text-white">Oui</span>{" "}
                          </div> */}
                      {/* <div
                            className={`w-20 text-center ${
                              !isNewCv ? "bg-[#2c4c68]" : "bg-[#457AA8]"
                            }  rounded-r-xl shadow-lg hover:opacity-70 text-gray-200 h-9 flex items-center justify-center  border-l py-1 border-l-gray-600`}
                            onClick={() => handleIsNewCv(false)}
                          >
                            <span className="hover:text-white">Non</span>{" "}
                          </div> */}
                      {/* </div> */}
                      {/* )} */}
                      <div>
                        {" "}
                        {userRole === "Client" ? (
                          <CvList onGetDocId={handleGetCvId} />
                        ) : (
                          content
                        )}
                        {isCvNotValid && (
                          <InvalidInputControl>
                            Veuillez remplir ce champs{" "}
                          </InvalidInputControl>
                        )}
                      </div>
                      {/* <div className="">{isNewCv ? content : <CvList />}</div> */}
                    </FormControl>
                  </div>
                </FormControl>
              </div>

              <div className="flex justify-between w-full mt-8 justify-items-center">
                <div className="justify-start">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={agree.includes("agree")}
                        onChange={handleAgree}
                        value="agree"
                        id="agree"
                      />
                    }
                  />
                </div>
                <Typography
                  variant="small"
                  color="#7F7C7C"
                  className="justify-end font-normal "
                >
                  <span>J'accepte </span> &nbsp;
                  <span
                    className="underline cursor-pointer hover:text-[#457AA8] "
                    onClick={() => handleIsModal()}
                  >
                    les conditions d'utilisation,
                  </span>
                  &nbsp;
                  <span
                    className="underline cursor-pointer hover:text-[#457AA8]"
                    onClick={() => navigate("/privatePolicy")}
                  >
                    la politique de confidentialité les paramètres de
                    notification
                  </span>
                  &nbsp;
                  <span> par défaut de La Proue .</span>
                  {agree.length < 0 && (
                    <p className="text-red-500 opacity-100">
                      Veuillez accepter les conditions d'utilisation
                    </p>
                  )}{" "}
                </Typography>
              </div>
            </div>
            {/* <div className="h-[0.16rem] w-full bg-blue-300 mt-9  col-span-full"></div> */}
            <div className="content-center w-full  col-span-2 mt-5 text-center rounded ">
              {mStatus === "pending" || postCvdata.cvStatus === "pending" ? (
                <PendingUI />
              ) : mStatus === "success" ? (
                <RequestSuccess />
              ) : verifySubmit ? (
                <div className="w-full ">
                  <Button
                    type="submit"
                    variant="contained"
                    color="warning"
                    className="rounded-xl font-bold"
                    disabled={mStatus === "pending"}
                  >
                    Postuler
                  </Button>
                </div>
              ) : (
                <div className=" text-sm text-gray-500   font-[600] ">
                  <p>
                    Veuillez vous assurer que tous les champs portant un
                    astérisque rouge (
                    <span className="text-sm text-red-500">*</span>) sont
                    correctement remplis avant de soumettre votre candidature.
                  </p>
                </div>
              )}
            </div>
          </div>
        </form>
        <AnimatePresence>
          <ModalUI
            autoclose={() => handleModalCLose("success")}
            classname={"p-4  content-center"}
            open={mData?.status === 201 && modalClose}
          >
            <div className="justify-center ">
              <RequestSuccess classname={""} />
              <p className="text-center py-4 mt-[-5rem]">
                Nous vous contacterons par mail.
              </p>
              <div className="w-full  flex justify-center py-3 ">
                {" "}
                <ButtonStudentUI
                  classname={"bg-green-500 "}
                  onClick={() => handleModalCLose("success")}
                >
                  Ok
                </ButtonStudentUI>
              </div>
            </div>
          </ModalUI>
        </AnimatePresence>
        <AnimatePresence>
          {/* <ModalUI
            autoclose={() => handleModalCLose("existData")}
            classname={"p-4  content-center"}
            open={mError?.response?.status === 400 && modalClose}
          >
            <div className="justify-center ">
              <RequestFailPanel classname={""} />
              <p className="text-center py-4 mt-[-4rem]">
                Vous avez deja postuler avec votre adresse email actuel
              </p>
              <Button
                variant="contained"
                color="warning"
                size="medium"
                className="justify-center w-full"
                onClick={() => handleModalCLose("existData")}
              >
                Ok
              </Button>
            </div>
          </ModalUI> */}
        </AnimatePresence>
        <AnimatePresence>
          <ModalUI
            autoclose={() => handleModalCLose("existData")}
            classname={"p-4 max-w-96 content-center"}
            open={mStatus === "error" && modalClose}
          >
            <div className="justify-center ">
              <LoadDataFailUI classname={"font-semibold"}>
                Nous avons rencontré une erreur lors du traitement de votre
                demande! Il est possible que vous ayez déjà postulé avec cette
                adresse e-mail. Si ce n'est pas le cas, veuillez contacter un
                administrateur pour obtenir de l'aide.
              </LoadDataFailUI>
              <div className="w-full  flex justify-center py-3 ">
                {" "}
                <ButtonStudentUI
                  classname={"bg-red-500 "}
                  onClick={() => handleModalCLose("existData")}
                >
                  Ok
                </ButtonStudentUI>
              </div>
            </div>
          </ModalUI>
        </AnimatePresence>
      </div>
    </main>
  );
};
export default FormApply;
