import React from 'react';
import Contact from "../components/Contact/Contact";
import SectionOne from '../components/Contact/SectionOne.js/SectionOne';
const ContactPage = () => {
    return (
        <main className="">
            <SectionOne/>
             <Contact />
        </main>
    );
};

export default ContactPage;