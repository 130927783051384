import React, { useEffect } from "react";
import { AiFillBulb } from "react-icons/ai";
import { FiUsers } from "react-icons/fi";
import { useTranslation, Trans } from "react-i18next";
import { FaHandshake } from 'react-icons/fa';
import AOS from 'aos';
import 'aos/dist/aos.css';

const SectionNine = () => {
  const { t } = useTranslation();
  let statistique = [
    {
      titre: t("hP-sectionNine.cardtitle1"),
      icon: <AiFillBulb size={50} className="text-[#0284C7]  mx-auto" />,
      text: t("hP-sectionNine.cardtext1"),
    },
    {
      titre: t("hP-sectionNine.cardtitle2"),
      icon: (
        <FaHandshake
          size={50}
          className="text-[#0284C7]  mx-auto"
        />
      ),
      text: t("hP-sectionNine.cardtext2"),
    },
    {
      titre: t("hP-sectionNine.cardtitle3"),
      icon: <FiUsers size={50} className="text-[#0284C7]  mx-auto" />,
      text: t("hP-sectionNine.cardtext3"),
    },
  ];

  useEffect(() => {
    AOS.init({
      duration: 1000, // Durée de l'animation en millisecondes
    });
  }, []);

  return (
    <section className="w-full p-6 mx-auto overflow-hidden shadow-md md:p-10 bg-white">
      <div className="text-center"  data-aos="fade-down">
        <h1 className="mb-6 text-4xl font-semibold">
          {t("hP-sectionNine.title")}

          <span className="text-[#0284C7]">
            {" "}
            {t("hP-sectionNine.avantages")}{" "}
          </span>
          {t("hP-sectionNine.suite")}
        </h1>
        <p className="text-lg text-center text-[#667085]">
          {t("hP-sectionNine.textcontent")}
        </p>
      </div>

      <div
        className="grid grid-cols-1 gap-4 mt-8 md:grid-cols-2 lg:grid-cols-3"
        data-aos="fade-up"
      >
        {statistique.map((StatVal, index) => (
          <div
            key={index}
            data-aos="fade-down"
            data-aos-delay={`${index * 500}`}
            className="flex flex-col items-center p-4 bg-[#ECF8FF] rounded-xl"
          >
            <div className="w-full h-20 mb-2">
              {/* Contenu icon ou image */}
              {StatVal.icon}
            </div>
            <p className="mb-2 text-[20px] font-semibold text-[#0284C7] ">
              {StatVal.titre}
            </p>
            <div className="px-4 py-2 rounded-xl">
              <h4 className="flex-1 text-base leading-6 text-justify text-black ">
                {StatVal.text}
              </h4>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default SectionNine;
