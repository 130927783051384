import { RefreshCcw } from "react-feather";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { Link } from "react-router-dom";

const RequestError = () => {
  return (
    // <div className="w-full">
    <div className="pt-32 flex justify-center ">
      <div className="text-center border rounded-lg border-collapse  border-gray-600 bg-red-50 my-7 p-6 ">
        <p className=" font-[600] text-6xl border-b-2 border-gray-600/50 text-red-600 pb-2"> Error</p>
        <p className="text-2xl my-5 w-96">  Something went wrong</p>
        <p className="text-xl"> {'entrer le message de l\'erreur'}</p>
        {/* <Link to='/error' className="justify-center flex m-4 text-[#5190B7] hover:text-blue-400 "><RefreshCcw className=" " size={100}/></Link> */}
        <p className=" font-[600] text-4xl  py-2"> CODE</p>

      </div>
    {/* </div> */}
    </div>
  );
};

export default RequestError;
