import { Checkbox, FormControlLabel } from "@mui/material";
import React from "react";

const CheckBoxUI = ({ label, id, isChecked, ...props }) => {
  return (
    <FormControlLabel
      label={label}
      control={<Checkbox checked={isChecked} id={id} name={id} {...props} />}
    />
  );
};

export default CheckBoxUI;
