import React, { useMemo, useState, useEffect, useContext } from "react";
import { useTable } from "react-table";
import { FaEdit, FaTrash } from "react-icons/fa";
import { Button } from "@material-tailwind/react";
import Modal from "../../Modal/Modal";
import ModalUI from "../../../../UI/ModalUI";
import { Link, useNavigate } from "react-router-dom";
import ModalAddJob from "../ModalAddJob/ModalAddJob";
import Pagination from "../../../../Pagination/Pagination";
import Description from "../Description/Description";
import AddJobContext from "../../../../../context/AddJobContext/context";
import ManageBanner from "../../ManageBanner/ManageBanner.js";
import { useTranslation } from "react-i18next";
import { getOneCategory } from "../../../../../services/HttpRequest/catRequest/catRequest.js";
import PendingModal from "../../../../QuickApply_Cpt/UI/PendingModal.js";
import ModalStatusMessage from "../../../../Contact/SectionOne.js/ModalStatusMessage.js";
import { getOneComp } from "../../../../../services/HttpRequest/adminRequest/compRequest.js";

import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../../store/slice/loginSlice";

const JobTable = ({ data, onDelete, onEdit, onBlock, allCats, allComps }) => {
  const { t } = useTranslation();
  const { HandleDataToEdit,handleStep } = useContext(AddJobContext);

  const [currentPage, setCurrentPage] = useState(1);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isSaveModalOpen, setSaveModalOpen] = useState(false);
  const [isAddJobModalOpen, setAddJobModalOpen] = useState(false);
  const [jobToEdit, setJobToEdit] = useState({
    selectedJob: {},
    isEdit: false,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const [modalDescription, setModalDescription] = useState(false);
  const [jobForModalDescription, setJobForModalDescription] = useState({});

  const HandleOpenModalDescription = (selectedJob) => {
    setModalDescription(true);
    setJobForModalDescription(selectedJob);
  };

  const handleCloseModalUser = (open = false) => {
    setAddJobModalOpen(open);
  };

  const HandleCloseModalDescription = () => {
    setModalDescription(false);
  };

  const currentUser = useSelector(selectCurrentUser);
  // const [preEditStatut, setPreEditStatus] = useState("idle");
  // const [controlPreEditError, setControlPreEditError] = useState(false);
  // const [errorMessage, setErrorMessage] = useState(
  //   "Nous avons rencontré un problème. Veuillez contacter le service de maintenance."
  // );
  const navigate = useNavigate();
  const HandleEditJob = async (job) => {
    // try {
    //   setPreEditStatus("pending");
    //   const catResponse = await getOneCategory(job.categoryId);
    //   HandleDataToEdit(job, true, catResponse.data, {});
    //   const compResponse = await getOneComp(job.companyId);
    //   setPreEditStatus("success");
    //   // navigate("/admin/editJob/"+job.id);
    //   HandleDataToEdit(job, true, catResponse.data, compResponse.data);
    // } catch (error) {

    //   setPreEditStatus("error");
    //   // setControlPreEditError(true);
    //   }
    // console.log("voivi le rome")
    let role = currentUser.roles ? currentUser.roles[0] : null;
// console.log("voivi le rome", role)
    if (role === "Admin") {
      navigate("/admin/editJob/" + job.id);
    } else if (role === "Manager") {
      navigate("/manager/editJob/" + job.id);
    }

    // setIsEditing(true);
  };

  const columns = useMemo(() => {
    if (!data || data.length === 0) {
      return [];
    }

    return [
      ...Object.keys(data[0])
        .map((key) => {
          if (
            key === "description" ||
            key === "summary" ||
            key === "qualifications" ||
            key === "advantages" ||
            key === "isRegistered" ||
            key === "archived" ||
            key === "archivedDate" ||
            key === "archivedByUserId" ||
            key === "salaryRate" ||
            key === "categoryId" ||
            key === "employmentStatus" ||
            key === "companyId" ||
            key === "other" ||
            key === "period" ||
            key === "jobIsActif"
          ) {
            return null;
          }

          return {
            Header:
              key === "creationDate"
                ? "Creation Date"
                : key === "applicationDeadLine"
                ? "Application Deadline"
                : key,
            accessor: key,
            Cell: ({ row }) => (
              <div>
                <div
                  className="hover:text-[#457AA8] cursor-pointer"
                  onClick={() => HandleOpenModalDescription(row.original)}
                >
                  {key === "creationDate" || key === "applicationDeadLine"
                    ? row.original[key]
                      ? new Date(row.original[key]).toLocaleDateString()
                      : "N/A"
                    : row.original[key]}
                </div>
              </div>
            ),
          };
        })
        .filter(Boolean),
      {
        Header: t("jobtable.action"),
        accessor: "actions",
        Cell: ({ row }) => (
          <div className="flex justify-center space-x-2">
            <button
              onClick={() => HandleEditJob(row.original)}
              className="text-blue-500 hover:text-blue-700"
            >
              <FaEdit />
            </button>
            <button
              onClick={() => handleDelete(row.original)}
              className="text-red-500 hover:text-red-700"
            >
              <FaTrash />
            </button>
          </div>
        ),
      },
    ];
  }, [data, isEditing, jobToEdit, editedData]);

  const pageSize = 10;
  const pageCount = Math.ceil(filteredData.length / pageSize);

  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return filteredData.slice(startIndex, endIndex);
  }, [currentPage, filteredData]);

  const onPageChange = (pageNumber) => {
    const pageToSave = JSON.stringify(pageNumber);
    localStorage.setItem("jobTable", pageToSave);
    setCurrentPage(pageNumber);
  };

  const getSavePage = localStorage.getItem("jobTable");
  useEffect(() => {
    if (getSavePage) {
      const activePage = JSON.parse(getSavePage);
      setCurrentPage(activePage);
    }
  }, [getSavePage, data]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: paginatedData,
    });

  useEffect(() => {
    setFilteredData(
      data.filter((user) =>
        user.jobName.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  }, [data, searchText]);

  const onSaveEdit = () => {
    setSaveModalOpen(true);
  };

  const onCancelEdit = () => {
    setIsEditing(false);
    setJobToEdit(null);
    setEditedData({});
  };

  const handleDelete = (user) => {
    setJobToEdit(user);
    setDeleteModalOpen(true);
  };

  const confirmDelete = () => {
    onDelete(jobToEdit);
    setDeleteModalOpen(false);
  };

  const cancelDelete = () => {
    setJobToEdit(null);
    setDeleteModalOpen(false);
  };

  const confirmSave = () => {
    onEdit(jobToEdit, editedData);
    setSaveModalOpen(false);
    setIsEditing(false);
    setJobToEdit(null);
    setEditedData({});
  };
  // const navigate = useNavigate()
  const cancelSave = () => {
    setSaveModalOpen(false);
  };

  const handleAddJob = () => {
    handleStep(1,true)
    HandleDataToEdit({},false);
    // console.log("voivi le rome")
    let role = currentUser.roles ? currentUser.roles[0] : null;
// console.log("voivi le rome", role)
    if (role === "Admin") {
      navigate("/admin/editJob/newJob");
    } else if (role === "Manager") {
      navigate("/manager/editJob/newJob");
    }
    // navigate("/admin/editJob/newJob");

  };

  return (
    <div>
      {/* <PendingModal isOpen={preEditStatut === "pending"} autoclose={() => {}} />
      <ModalStatusMessage
        message={t("jobtable.error")}
        status={preEditStatut}
        open={preEditStatut === "error" && controlPreEditError}
        autoclose={() => {
          setControlPreEditError(false);
        }}
      /> */}
      <ManageBanner
        handleClick={handleAddJob}
        buttonText={t("jobtable.add_btn")}
        searchText={searchText}
        setSearchText={setSearchText}
      />

      <div className="overflow-x-auto">
        <table
          {...getTableProps()}
          className="w-full mt-4 border border-collapse border-[#457AA8] min-w-[12rem]"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    className="p-2 bg-[#457AA8] border  border-[#457AA8] text-white"
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className="hover:bg-gray-200 bg-white  border-[#457AA8] cursor-pointer"
                >
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      className="p-2 text-center border border-[#457AA8]"
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <Pagination
        currentPage={currentPage}
        totalPages={pageCount}
        onPageChange={onPageChange}
      />

      {isDeleteModalOpen && (
        <Modal
          title={t("jobtable.title_modal")}
          message={`${t("jobtable.msg_modal")} ${jobToEdit?.jobName}`}
          onCancel={cancelDelete}
          onConfirm={confirmDelete}
        />
      )}
      {isSaveModalOpen && (
        <Modal
          title={t("jobtable.title_modal")}
          message={`${t("jobtable.msg_modal")} ${jobToEdit?.jobName}`}
          onCancel={cancelSave}
          onConfirm={confirmSave}
        />
      )}

      {/* {isEditing && (
        <ModalAddJob
          isOpen={isEditing}
          onCancel={() => {
            setIsEditing(false);
          }}
          onConfirm={() => setIsEditing(false)}
        />
      )} */}

      {/* {isAddJobModalOpen && (
        <ModalAddJob
          isOpen={isAddJobModalOpen}
          onCancel={() => {
            setAddJobModalOpen(false);
          }}
          onConfirm={() => setAddJobModalOpen(false)}
        />
      )} */}
      <Description
        handleClose={HandleCloseModalDescription}
        openValue={modalDescription}
        job={jobForModalDescription}
      />
    </div>
  );
};

export default JobTable;
