import { useState, useEffect, useContext } from "react";
import AddJobContext from "../../../context/AddJobContext/context";

const ToogleSelect = ({ data, getTable = () => {}, activeTable }) => {
  const { isEditing } = useContext(AddJobContext);
  const [table, setTable] = useState(activeTable);
  const [checker, setChecker] = useState(false);
  const handleSelect = (value) => {
    const regionIndex = table?.indexOf(value);

    if (regionIndex === -1) {
      setTable((prevValue) => [...prevValue, value]);
    } else {
      setTable((prevState) =>
        prevState?.filter((nameRegion) => nameRegion !== value)
      );
    }

    // getTable(table)
    setChecker((prev) => !prev);
  };

  useEffect(() => {
      getTable(table);
    
  }, [checker]);

  // useEffect(() => {
  //   if (activeTable) {
  //     setTable(activeTable);
  //   } employementStatus
  // }, [activeTable]);

  const content = data.map((data) => (
    <li
      key={data}
      onClick={() => handleSelect(data)}
      className={` cursor-pointer border border-[#457AA8] m-1 overflow-x-hidden   rounded-xl px-1  hover:opacity-85  text-gray-800 ${
        table?.indexOf(data) > -1 && "bg-[#457AA8] text-white"
      }`}
    >
      {data}
    </li>
  ));
  return (
    <div>
      <ul className=" h-28 overflow-y-scroll    gap-2">{content}</ul>
    </div>
  );
};

export default ToogleSelect;
