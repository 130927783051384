import React from "react";
import InscriptionCpt from "../components/Inscription/Inscription";

const Inscription = () => {
  return (
    <main >
      <InscriptionCpt />
    </main>
  );
};

export default Inscription;
