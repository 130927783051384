import React, { useMemo, useState, useEffect } from "react";
import { useTable } from "react-table";
import { FaEdit, FaTrash, FaFolder } from "react-icons/fa";
import { Button, Input, Typography } from "@material-tailwind/react";
import Modal from "../../Modal/Modal";
import ModalAddCat from "../ModalAddCat/ModalAddCat";
import Pagination from "../../../../Pagination/Pagination";
import ManageBanner from "../../ManageBanner/ManageBanner.js";
import { FaSearch } from "react-icons/fa";
import MenuUI from "../../../../UI/MenuUI.js";
import { useTranslation, Trans } from "react-i18next";

const CategoryTable = ({ data, onDelete, onEdit }) => {
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isAddCatModalOpen, setAddCatModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [formTitle, setFormTitle] = useState("");

  const pageSize = 10;

  useEffect(() => {
    setCurrentPage(1);
  }, [data]);

  const pageCount = Math.ceil(data.length / pageSize);

  const filteredData = useMemo(() => {
    return data.filter((category) =>
      category.categoryName.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [data, searchText]);

  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return filteredData.slice(startIndex, endIndex);
  }, [currentPage, filteredData]);

  const columns = useMemo(() => {
    return [
      {
        Header: t("cat_table.id"),
        accessor: "id",
      },
      {
        Header: t("cat_table.category"),
        accessor: "categoryName",
      },
      {
        Header: t("cat_table.action"),
        accessor: "actions",
        Cell: ({ row }) => (
          <div className="flex justify-center space-x-2">
            <button
              onClick={() => handleEdit(row.original)}
              className="text-blue-500 hover:text-blue-700"
            >
              <FaEdit />
            </button>
            <button
              onClick={() => handleDelete(row.original)}
              className="text-red-500 hover:text-red-700"
            >
              <FaTrash />
            </button>
          </div>
        ),
      },
    ];
  }, []);

  const handleAddCat = () => {
    setSelectedCategory(null);
    setFormTitle(t("cat_table.form_title1"));
    setAddCatModalOpen(true);
  };

  const handleDelete = (category) => {
    if (category) {
      setSelectedCategory(category);
      setDeleteModalOpen(true);
    }
  };

  const handleEdit = (category) => {
    if (category) {
      setSelectedCategory(category);
      setFormTitle(t("cat_table.form_title2"));
      setAddCatModalOpen(true);
    }
  };

  const confirmDelete = () => {
    if (selectedCategory) {
      onDelete(selectedCategory);
      setDeleteModalOpen(false);
    }
  };

  const cancelDelete = () => {
    setSelectedCategory(null);
    setDeleteModalOpen(false);
  };

  const confirmSave = () => {
    onEdit(selectedCategory);
    setAddCatModalOpen(false);
    setSelectedCategory(null);
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: paginatedData,
    });

  const onPageChange = (pageNumber) => {
    const pageToSave = JSON.stringify(pageNumber);
    localStorage.setItem("catPage", pageToSave);
    setCurrentPage(pageNumber);
  };

  const getSavePage = localStorage.getItem("catPage");
  useEffect(() => {
    if (getSavePage) {
      const activePage = JSON.parse(getSavePage);
      setCurrentPage(activePage);
    }

    // return () => {
    //   localStorage.removeItem('catPage')
    // };
  }, [getSavePage, data]);

  return (
    <div>
      <ManageBanner
        handleClick={handleAddCat}
        buttonText={t("cat_table.add_btn")}
        searchText={searchText}
        setSearchText={setSearchText}
      />

      <div className="overflow-x-auto">
        <table
          {...getTableProps()}
          className="w-full mt-4 border border-collapse border-[#457AA8 min-w-max"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    className="p-2 bg-[#457AA8] border  border-[#457AA8] text-white"
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className="hover:bg-gray-200 bg-white  border-[#457AA8] cursor-pointer "
                >
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      className="p-2 text-center border border-[#457AA8]"
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <ModalAddCat
        isOpen={isAddCatModalOpen}
        onCancel={() => setAddCatModalOpen(false)}
        onConfirm={confirmSave}
        formTitle={formTitle}
        editedCategoryData={selectedCategory}
      />
      {isDeleteModalOpen && (
        <Modal
          title="Confirmation"
          message={t("cat_table.drop_msg") +` ${selectedCategory?.categoryName}?`}
          onCancel={cancelDelete}
          onConfirm={confirmDelete}
        />
      )}
      <Pagination
        currentPage={currentPage}
        totalPages={pageCount}
        onPageChange={onPageChange}
      />
    </div>
  );
};

export default CategoryTable;
