import React, { useState, useRef, useEffect } from "react";
import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import proueLogo from "../../assets/logo.png";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaHome, FaTasks, FaInfoCircle, FaEnvelope } from "react-icons/fa";
import { useSelector } from "react-redux";
import {
  selectLoginStatus,
  selectCurrentUser,
} from "../../store/slice/loginSlice";
import DropDown from "./DropDown/DropDown";
import Language from "./LanguageSelector/LanguageSelector";
import User from "./User/User";
import Navbar from "./NavBar/Navbar";
import "./style.css";
import { FiEdit, FiPlusSquare } from "react-icons/fi";
import { FaUserGraduate } from "react-icons/fa";
import useIsMobile from "../Hooks/useIsMobile";

import AOS from "aos";
import "aos/dist/aos.css";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NavBar() {
  const { t } = useTranslation();
  const loginStatus = useSelector(selectLoginStatus);
  const currentUser = useSelector(selectCurrentUser);
  const navigate = useNavigate();
  const location = useLocation();
  const panelRef = useRef();
  const isMobile = useIsMobile();

  useEffect(() => {
    AOS.init({
      duration: 1000, // Durée de l'animation en millisecondes
    });
  }, []);
  // const items = [
  //   {
  //     text: t("header.Quick application"),
  //     icon: FiEdit,
  //     link: "/quickApply",
  //   },
  //   {
  //     text: "Application étudiants",
  //     icon: FaUserGraduate,
  //     link: "/studentContact",
  //   },
  // ];
  const items = [
    ...(loginStatus !== "succeeded"
      ? [
          {
            text: t("header.Quick application"),
            icon: FiEdit,
            link: "/quickApply",
          },
        ]
      : []),
    {
      text: t("header.Students"),
      icon: FaUserGraduate,
      link: "/studentContact",
    },
  ];

  const [selectedLink, setSelectedLink] = useState("#");
  const handleSelectLink = (link) => {
    setSelectedLink(link);
  };

  const navigation = [
    { name: t("header.Home"), icon: <FaHome />, link: "/", current: false },
    { name: t("header.Job"), icon: <FaTasks />, link: "/Job", current: false },
    ...(loginStatus === "succeeded"
      ? []
      : !isMobile
      ? [
          {
            name: (
              <DropDown
                name="Applications"
                items={items}
                onSelect={handleSelectLink}
              />
            ),
            icon: <FaTasks />,
            link: selectedLink,
            current: false,
          },
        ]
      : [
          {
            name: t("header.Quick application"),
            icon: <FiEdit />,
            link: "/quickApply",
            current: false,
          },
          {
            name: t("header.Students"),
            icon: <FiPlusSquare />,
            link: "/studentContact",
            current: false,
          },
        ]),
    {
      name: t("header.About"),
      icon: <FaInfoCircle />,
      link: "/About",
      current: false,
    },
    {
      name: t("header.Contact"),
      icon: <FaEnvelope />,
      link: "/Contact",
      current: false,
    },
    // { name: t("header.Connexion"), icon: <FaEnvelope />, link: "/Connexion", current: false },
  ];

  const specificPages = [
    "/admin/DashBord",
    "/admin/NewsLetter",
    "/admin/MenuGestion",
    "/manager/DashBord",
    "/manager/MenuGestion",
    "/manager/NewsLetter",
    "/client/MesCandidatures",
    "/client/MesCv",
    "/client/MonProfil",
    // "/manager/editJob/:id",
    // "/admim/editJob/:id",
  ];

  const isSpecificPage = specificPages.includes(location.pathname);

  const closeMenu = () => {
    if (panelRef.current) {
      panelRef.current.click();
    }
  };
  let content;
  if (isMobile) {
    content = (
      <a href="/" target="_blank" rel="noopener noreferrer">
        <img src={proueLogo} className="w-16 h-16 ml-10" alt="logo" />
      </a>
    )
  } else {
    content = (
      <a href="/" target="_blank" rel="noopener noreferrer">
        <img src={proueLogo} className="w-16 h-16 " alt="logo" />
      </a>
    )
  }

  return (
    <Disclosure
      as="nav"
      className={` ${
        isSpecificPage ? "mb-[118px]" : "z-50 w-full mx-auto bg-white"
      }`}
    >
      {({ open }) => (
        <div className="fixed z-50 w-full mx-auto bg-white max-w-screen-2xl">
          <div className="shadow-sm">
            <div className="relative flex items-center justify-between w-full">
              <div className="absolute inset-y-0 left-0 flex items-center md:hidden">
                <Disclosure.Button
                  ref={panelRef}
                  className="relative inline-flex items-center justify-center p-2 text-gray-400 rounded-md hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                >
                  <span className="sr-only">{t("header.Menu")}</span>
                  {open ? (
                    <XMarkIcon className="block w-6 h-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block w-6 h-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex items-center justify-between w-full sm:items-stretch">
                <div className="flex items-center flex-shrink-0 lg:px-10 xl:px-10 md:px-2  space-x-10">
                  {content}

                  <div className="hidden lg:flex sm:ml-12 md:block space-x-4 lg:space-x-6 xl:space-x-8">
                    <div className="flex items-center md:space-x-2 lg:space-x-6 xl:space-x-8">
                      {navigation.map((item) => (
                        <NavLink
                          key={item.name}
                          to={item.link}
                          className={`text-black hover:underline transition duration-300 ease-in-out rounded-md px-1 py-2 text-sm font-medium flex items-center justify-center ${
                            window.location.pathname === item.link
                              ? "activeHeader"
                              : ""
                          }`}
                          onClick={closeMenu}
                        >
                          <span className="lg:text-sm text-sm">
                            {item.name}
                          </span>
                        </NavLink>
                      ))}
                    </div>
                  </div>
                </div>
                {loginStatus === "succeeded" ? (
                  <div
                    className="absolute inset-y-0 right-0 flex items-center px-4 space-x-4 bg-white sm:static sm:inset-auto"
                    data-aos="fade-left"
                  >
                    <Language />
                    {currentUser && (
                      <span className="px-1">
                        <User
                          userRole={
                            currentUser.roles ? currentUser.roles[0] : null
                          }
                        />
                      </span>
                    )}
                  </div>
                ) : (
                  <div
                    className="absolute inset-y-0 right-0 flex items-center px-4 space-x-4 bg-white sm:static sm:inset-auto"
                    data-aos="fade-left"
                  >
                    <Language />
                    <button
                      className="px-2 py-2 text-sm font-semibold text-[#EA580C] bg-white border border-[#EA580C] rounded-full hover:text-blue-500 hover:border-blue-500 btn"
                      onClick={() => {
                        navigate("/Connexion");
                        // closeMenu();
                      }}
                    >
                      {t("header.Connect")}
                    </button>
                    <button
                      className="px-2 py-2 text-sm font-semibold text-white bg-[#EA580C] rounded-full hover:text-blue-500 hover:border-blue-500 hover:bg-white btn"
                      onClick={() => {
                        navigate("/Inscription");
                        // closeMenu();
                      }}
                    >
                      {t("header.Account")}
                    </button>
                  </div>
                )}
              </div>
            </div>
            <Disclosure.Panel className="md:hidden">
              <div className="flex flex-col items-center justify-center px-2 pt-2 pb-3 space-y-1">
                {navigation.map((item) => (
                  <NavLink
                    key={item.name}
                    to={item.link}
                    className={`text-black hover:underline transition duration-300 ease-in-out rounded-md px-1 py-2 text-sm font-medium flex items-center justify-center ${
                      window.location.pathname === item.link
                        ? "activeHeader"
                        : ""
                    }`}
                    onClick={closeMenu}
                  >
                    <span>{item.name}</span>
                  </NavLink>
                ))}
              </div>
            </Disclosure.Panel>
          </div>
          {loginStatus === "succeeded" && currentUser && isSpecificPage && (
            <div className="flex items-center justify-center py-2 bg-[#F9F9F9]">
              <Navbar
                userRole={currentUser.roles ? currentUser.roles[0] : null}
              />
            </div>
          )}
        </div>
      )}
    </Disclosure>
  );
}
