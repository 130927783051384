import React, { useContext, useEffect, useState } from "react";
import CatMenu from "../../Cpt_Emploi/Banner/CatMenu";
import { JobCard } from "./JobCard";
import JobContext from "../../../context/JobContext";
import EmptyData from "../../UI/RequestUI/EmptyData";
import CatContext from "../../../context/CatContext";
import PendingUI from "../../UI/RequestUI/PendingUI";
import LoadDataFailUI from "../../UI/RequestUI/LoadDataFailUI";
import { Link } from "react-router-dom";
import CatSection from "./CatSection";
import { useSelector } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
const SectionTwo = () => {
  const { t } = useTranslation();
  const { handleJobForCatId, statusJob } = useContext(JobContext);
  const statusCat = useSelector((state) => state.category.statusCat);
  const allCatWithJob = useSelector((state) => state.category.allCatWithJob);
  const [jobForCat, setJobForCat] = useState([]);
  const [isIdValid, setIsIdValide] = useState(false);
  const [isSeeMore, setIsSeeMore] = useState(false);
  const handleCatId = (id) => {
    setJobForCat(handleJobForCatId(id)?.slice(0, 8));
    if (id) {
      setIsIdValide(true);
    }
  };
  // effet
  useEffect(() => {
    if (!isIdValid) {
      setJobForCat(handleJobForCatId(undefined)?.slice(0, 8));
    }
  }, [handleJobForCatId, isIdValid]);

  const jobTableLength = jobForCat?.length;
  let content;
  //--------------toogle all cat------------------

  const toogleSeeMore = () => {
    setIsSeeMore((prevState) => !prevState);
  };
  // case for updating the UI base on JobRequest and CatRequest
  switch (statusCat && statusJob) {
    case "pending":
      content = <PendingUI />;
      break;
    case "error":
      content = <LoadDataFailUI />;
      break;
    case "success":
      content = (
        <div className="flex  justify-center w-full">
          {" "}
          <div className=" gap-x-12 flex  textlg px-2 flex-wrap">
            {jobTableLength === 0 ? (
              <div className="flex w-full justify-center">
                <EmptyData>
                  <h1>{t("hP-sectionTwo.categorymsg")}</h1>
                </EmptyData>
              </div>
            ) : (
              jobForCat?.map((job) => <JobCard job={job} key={job.id} />)
            )}
          </div>
        </div>
      );
      break;

    default:
      break;
  }

  return (
    <div className="w-full flex   px-14 justify-center ">
      {" "}
      <div className="flex flex-col w-full   ">
        <div className="  max-sm:space-y-2 sm:flex justify-between pb-8 pt-20">
          <div className=" gap-5">
            <h1 className="font-semibold mb-1   text-[#3D3D3D] ">
              {t("hP-sectionTwo.title")}
            </h1>
            <p className=" max-w-[610px]  text-[#667085]">
              {t("hP-sectionTwo.textcontent")}{" "}
            </p>
          </div>
        </div>
        <CatSection seeMore={isSeeMore} />
        {/* <div
          className={`flex w-full  justify-center ${
            jobTableLength !== 0 && "self-start"
          } `}
        >
          <div> {content}</div>
        </div> */}
        {allCatWithJob.length > 8 && (
          <div className="flex  justify-center">
            <button
              onClick={toogleSeeMore}
              className="md:w-24 w-20 h-8 text-lg text-white rounded-3xl hover:opacity-90 hover:scale-105 duration-150 ease-in-out hover:shadow-md font-semibold md:h-12 text-center relative shadow-lg cursor-pointer bg-[#EA580C]"
            >
              {isSeeMore
                ? t("hP-sectionTwo.button1")
                : t("hP-sectionTwo.button2")}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
export default SectionTwo;
