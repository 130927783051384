import React from "react";
import ModalUI from "../../UI/ModalUI";

const ModalStatusMessage = ({ open, autoclose, message, status }) => {
  let bgButton;
  switch (status) {
    case "error":
      bgButton = "bg-red-500";
      break;

    case "success":
      bgButton = "bg-[#0284C7] ";

      break;

    default:
      break;
  }
  return (
    <ModalUI open={open} autoclose={autoclose} classname={"p-2"}>
      <div className="h-32 w-56 flex flex-col justify-around ">
        <h3 className="text-center  text-gray-600 text-sm xl:text-lg">
          {message}
        </h3>
        <button
          className={` ${bgButton} h-8 rounded text-white font-bold w-[90%] self-center shadow`}
          onClick={autoclose}
        >
          Ok
        </button>
      </div>
    </ModalUI>
  );
};
export default ModalStatusMessage;
