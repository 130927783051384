import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { UpdatePassword } from "../../../../services/HttpRequest/User/pwdRequest/pwdRequest";
import { useMutation } from "@tanstack/react-query";
import { logout } from "../../../../store/slice/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ChangePwd = ({ onClose }) => {
  const { t } = useTranslation();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const navigate = useNavigate();
  // const mutation = useMutation(UpdatePassword, {
  //   onSuccess: () => {
  //     setShowSuccessModal(true);
  //   },
  //   onError: (error) => {
  //     setError(error.message);
  //   },
  // });
  const { mutate, status } = useMutation({
    mutationFn: UpdatePassword,
    onSuccess: () => {
      setShowSuccessModal(true);
    },
    onError: (error) => {
      setError(error.message);
    },
  });

  const dispatch = useDispatch();
  const handleResetPassword = () => {
    if (!currentPassword || !newPassword || !confirmPassword) {
      setError(t("modal.error_msg2"));
      return;
    }
    if (newPassword !== confirmPassword) {
      setError(t("modal.error_msg3"));
      return;
    }

    mutate({
      oldPassword: currentPassword,
      newPassword: newPassword,
    });
  };

  const handleLogout = () => {
    // Appeler la fonction de déconnexion
    dispatch(logout());
  };

  return (
    <div>
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className="relative z-50 p-8 bg-white w-96 rounded-xl">
          <p className="mb-4 text-2xl font-bold">{t("modal.title2")}</p>
          {error && (
            <p className="mb-2 text-sm font-bold text-center text-red-500">
              {error}
            </p>
          )}
          <div className="mb-4">
            <label
              htmlFor="currentPassword"
              className="block mb-2 text-sm font-medium text-gray-700"
            >
              {t("modal.label1")}
            </label>
            <input
              type="password"
              id="currentPassword"
              name="currentPassword"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              className="w-full border-1 focus:border-2 border-gray-200 p-2 focus:border-[#457AA8] focus:outline-none focus:ring-0 rounded-xl xl:h-[50px] bg-blue-100"
              placeholder={t("modal.label1_placeholder")}
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="newPassword"
              className="block mb-2 text-sm font-medium text-gray-700"
            >
              {t("modal.label2")}
            </label>
            <input
              type="password"
              id="newPassword"
              name="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="w-full border-1 focus:border-2 border-gray-200 p-2 focus:border-[#457AA8] focus:outline-none focus:ring-0 rounded-xl xl:h-[50px] bg-blue-100"
              placeholder={t("modal.label2_placeholder")}
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="confirmPassword"
              className="block mb-2 text-sm font-medium text-gray-700"
            >
              {t("modal.label3")}
            </label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="w-full border-1 focus:border-2 border-gray-200 p-2 focus:border-[#457AA8] focus:outline-none focus:ring-0 rounded-xl xl:h-[50px] bg-blue-100"
              placeholder={t("modal.label3_placeholder")}
            />
          </div>
          <div className="flex justify-center space-x-4">
            <button
              type="button"
              className="px-4 py-2 mr-2 text-gray-800 bg-gray-200 rounded-md hover:bg-gray-300"
              onClick={onClose}
            >
              {t("modal.cancel")}
            </button>
            <button
              type="button"
              className="px-4 py-2 text-white bg-[#457AA8] rounded-md hover:bg-gray-700"
              onClick={handleResetPassword}
            >
              {t("modal.complete")}
            </button>
          </div>
        </div>
      </div>

      {/* Modal de succès */}
      {showSuccessModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="relative z-50 p-8 bg-white w-96 rounded-xl">
            <p className="mb-4 text-2xl font-bold">{t("modal.stitle")}</p>
            <p>{t("modal.success_msg")}</p>
            <button
              type="button"
              className="px-4 py-2 text-white bg-[#457AA8] rounded-md hover:bg-gray-700"
              onClick={() => {
                handleLogout();
                navigate("/Connexion");
                setShowSuccessModal(false);
              }}
            >
              {t("modal.complete")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChangePwd;
