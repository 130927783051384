import { USER_CREATE_CV } from "../../../api";
import { axiosUseToken } from "../../../axiosConfig";

export const postCv = async ({ data }) => {
  const formData = new FormData();
  formData.append("DocumentType", "PDF");
  formData.append("file", data);
  // console.log("data", formData);

  try {
    const response = await axiosUseToken.post(USER_CREATE_CV, formData, {
      headers: { "Content-Type": "multimultipart/form-data" },
    });
    // console.log("response", response);
    return response;
  } catch (error) {
    // console.log("responseerror", error)
    throw error;
  }
};
