import React, { useContext, useEffect } from "react";
import JobTable from "./JobTable/JobTable";
import PendingUI from "../../../UI/RequestUI/PendingUI";
import LoadDataFailUI from "../../../UI/RequestUI/LoadDataFailUI";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../../../../services/HttpRequest/queryClientProvider/queryClientProvider";
import { deleteJob } from "../../../../services/HttpRequest/adminRequest/jobRequest";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CompContext from "../../../../context/CompContext";

const JobManage = () => {
  const job = useSelector((state) => state.jobsAdmin.allJobs);
  const statusJob = useSelector((state) => state.jobsAdmin.statusJob);
  const { mutate, status } = useMutation({
    mutationFn: deleteJob,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey:["getJob"] });
    },
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (status === "success") {
      toast.success(t("jobManage.success"), {
        position: "top-center",
        autoClose: 5000,
        bodyClassName: "font-bold",
        className: "w-[200%]",
        toastId: "success-toast-comp",
      });
    }
    if (status === "error") {
      toast.error(t("jobManage.error"), {
        position: "top-center",
        autoClose: 5000,
        bodyClassName: "font-bold",
        className: "w-[200%]",
        toastId: "error-toast-comp",
      });
    }
  }, [status]);

  const handleDelete = (job) => {
    mutate({ data: job.id });
  };

  const handleEdit = (editedJob) => {};

  const handleBlock = (blockedJob) => {};

  const {allComps} = useContext(CompContext)
  const allCats = useSelector(state=>state.category.allCats)

  let jobContent;

  switch (statusJob) {
    case "pending":
      jobContent = <PendingUI />;
      break;
    case "error":
      jobContent = <LoadDataFailUI />;
      break;
    case "success":
      jobContent = (
        <JobTable
          data={job}
          onDelete={handleDelete}
          onEdit={handleEdit}
          onBlock={handleBlock}
          allCats={allCats}
          allComps={allComps}
        />
      );
      break;
    default:
      break;
  }

  // traduction
  // Gestion des Emplois - Job Management

  return (
    <div className="container mx-auto mt-8">
      <ToastContainer />
      <h1 className="mb-4 text-3xl font-bold text-[#457AA8]">{t("jobManage.title")}</h1>
      {jobContent}
    </div>
  );
};
export default JobManage;
