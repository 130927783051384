import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { dateFormatterShort } from "../../Utils/dateFormatter";
import LoadDataFailUI from "../../UI/RequestUI/LoadDataFailUI";
import EmptyData from "../../UI/RequestUI/EmptyData";
import PendingUI from "../../UI/RequestUI/PendingUI";
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { jobsSliceAction } from "../../../store/slice/jobsSlice";
import usePagination from "../../Hooks/usePagination";
import "./ListJob.css";
import useIsMobile from "../../Hooks/useIsMobile";
import { useTranslation } from "react-i18next";

function ListJob({ switchState, withPagination = true, numItemsPerPage = 10 }) {
  const { t } = useTranslation();
  const allJobs = useSelector((state) => state.jobs.allJobs);
  const statusJob = useSelector((state) => state.jobs.statusJob);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClickToJob = (selectedJob) => {
    dispatch(jobsSliceAction.setActiveJob({ job: selectedJob }));
    if (switchState) {
      dispatch(
        jobsSliceAction.filterJobsByCatId({ categoryId: selectedJob.categoryId })
      );
    }
    navigate("/Job/JobDescription/idJob=" + selectedJob.id);
  };

  const isMobile = useIsMobile();

  const { currentPage, handlePageChange, paginatedData, totalPages } = usePagination(allJobs, numItemsPerPage);

  const handlePostulerClick = (selectedJob) => {
    navigate("/Application/idJob=" + selectedJob.id);
  };

  const [pageInput, setPageInput] = useState(currentPage);

  const handlePageInputChange = (e) => {
    setPageInput(e.target.value);
  };

  const handlePageInputSubmit = (e) => {
    e.preventDefault();
    const pageNumber = parseInt(pageInput, 10);
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
      handlePageChange(pageNumber);
    } else {
      setPageInput(currentPage);
    }
  };

  const dataFormat = paginatedData.map((emploi) => (
    <div
      key={emploi.id}
      id={emploi.id}
      className={`${
        isMobile ? "w-full" : "w-1/2 column-item"
      }  px-4 py-2 z-0 mx-auto rounded-xl hover:text-white text-[#667085] overflow-hidden overflow-y-auto max-h-[100vh] transition duration-300 ease-in-out transform border-[#EAECF0] cursor-pointer hover:bg-[#EA580C] md:max-w-full border-2 hover:scale-95 bg-white mt-2`}
      onClick={() => handleClickToJob(emploi)}
      data-aos="fade-left"
    >
      <p className="flex justify-between">
        <span
          className="justify-start text-base font-bold md:text-lg font-Inter-regular"
          id={emploi.id}
        >
          <span className="text-[#18191C]"> {emploi.jobName}</span>
        </span>
        <span className="justify-end font-extralight">
          {t("listjob.publish")} {dateFormatterShort(emploi.creationDate)}
        </span>
      </p>
      <p className="font-medium flex items-center text-[#EA580C]">
        <span className="bg-[#F6EDE7] p-1 rounded-[5px] font-semibold">
          {emploi.employmentStatus}
        </span>
      </p>
      <p className="flex items-center"></p>
      <p className="">
        {t("listjob.time")} {emploi.period}
      </p>
      <div className="justify-between flex items-center">
        <div className="justify-start">
          <div className="">
            {emploi?.salary && (
              <p className="font-medium flex items-center">
                {t("listjob.salary")}{" "}
                {emploi.salary && `${emploi.salary} ${emploi.currency}`}/
                {emploi.salaryRate}
              </p>
            )}
          </div>
        </div>
        <button
          className="justify-end underline italic w-[104px] h-[35px] text-[16px] hover:font-semibold hover:border-2"
          onClick={() => handlePostulerClick(emploi)}
        >
          {t("listjob.view")}
        </button>
      </div>
    </div>
  ));

  let content;

  if (statusJob === "pending") {
    content = <PendingUI classname={"mt-9"} />;
  }
  if (statusJob === "success") {
    if (allJobs.length === 0)
      content = <EmptyData> {t("listjob.empty")}</EmptyData>;
    else {
      content = (
        <div>
          <div className="result-list flex flex-wrap">{dataFormat}</div>
          {withPagination && (
            <nav className="flex justify-center items-center mt-4">
              <ul
                className="flex pagination justify-center items-center"
                style={{ padding: "0.5rem", borderRadius: "0.25rem" }}
              >
                <li className="page-item m-1">
                  <button
                    onClick={() => handlePageChange(1)}
                    disabled={currentPage === 1}
                    className="page-link"
                    aria-label={t("listjob.firstpage")}
                  >
                    <FaAngleDoubleLeft className="hover:text-blue-500 text-[#0284C7]" />
                  </button>
                </li>
                <li className="page-item m-1">
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="page-link"
                    aria-label={t("listjob.previewpage")}
                  >
                    <FaAngleLeft className="hover:text-blue-500 text-[#0284C7]" />
                  </button>
                </li>
                {[...Array(Math.min(totalPages, 3))].map((_, index) => (
                  <li key={index} className="page-item m-1">
                    <button
                      onClick={() => handlePageChange(index + 1)}
                      className={`page-link ${
                        currentPage === index + 1
                          ? "size-8 p-1 text-center rounded-full bg-[#0284C7] text-white"
                          : "hover:bg-gray-200 size-8 p-1 text-center rounded-full"
                      }`}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
                <li className="page-item m-1">
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="page-link"
                    aria-label={t("listjob.nextpage")}
                  >
                    <FaAngleRight className="hover:text-blue-500 text-[#0284C7]" />
                  </button>
                </li>
                <li className="page-item m-1">
                  <button
                    onClick={() => handlePageChange(totalPages)}
                    disabled={currentPage === totalPages}
                    className="page-link"
                    aria-label={t("listjob.lastpage")}
                  >
                    <FaAngleDoubleRight className="hover:text-blue-500 text-[#0284C7]" />
                  </button>
                </li>
              </ul>
              <form onSubmit={handlePageInputSubmit} className="flex justify-center items-center ml-4">
                <input
                  type="number"
                  value={pageInput}
                  onChange={handlePageInputChange}
                  className="page-input border rounded-lg p-2 border-[#D0D5DD] text-[#344054]"
                  style={{ width: "3rem", textAlign: "center" }}
                />
                <button
                  type="submit"
                  className="page-link bg-white border rounded-lg p-2 border-[#D0D5DD] text-[#344054]"
                >
                  {t("pagination.go")}
                </button>
              </form>
            </nav>
          )}
        </div>
      );
    }
  }

  if (statusJob === "error") {
    content = <LoadDataFailUI />;
  }
  return <div className="z-0">{content}</div>;
}

export default ListJob;
