import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

const TermAndCondidtionPage = () => {
  const { t } = useTranslation();
  //   handle open an close modal
  const ulStyle = "";
  const liStyle = "";
  const titleStyle = "font-semibold text-[#101828] mt-4 mb-2  ";
  const pStyle = "text-[#667085] my-1 ";

  // const titleStyle = "w-full font-semibold my-10    text-black my-1 text-xl";
  // const pStyle = "text-left ";
  return (
    <div className="flex mt-24 h-full w-full  justify-center text-gray-600 px-4 ">
      <div className=" max-w-[1000px] ">
        <div className="flex">
          <h1 className="text-nowrap text-center w-full font-bold">
            {t("termAndCondition.title")}{" "}
            <span className="text-orange-500">
              {t("termAndCondition.title2")}
            </span>
          </h1>
          <motion.span
            whileHover={{ scale: 1.5 }}
            className="m-4 text-2xl cursor-pointer hover:text-red-600 text-[#457AA8] "
          ></motion.span>
        </div>
        <hr className="bg-slate-700 my-2" />
        <h2 className={titleStyle}>{t("termOfUse.title")}</h2>
        <p>{t("termOfUse.namecompany")}</p>
        <p>{t("termOfUse.addresscompany")}</p>
        <p>{t("termOfUse.emailcompany")}</p>
        <p>{t("termOfUse.phonecompany")}</p>
        {/* <p>Statut juridique : [SARL, SAS, etc.]</p> */}
        <h2 className={titleStyle}>{t("objectives.title")}</h2>{" "}
        <p className={pStyle}>{t("objectives.content")}</p>{" "}
        <p className={pStyle}>{t("objectives.content2")}</p>
        <h2 className={titleStyle}>{t("services.title")}</h2>
        <p>{t("services.content")}</p>
        <p className={pStyle}>{t("services.content2")}</p>
        <h2 className={titleStyle}> {t("conditions.title")}</h2>
        <p className={pStyle}>{t("conditions.description")}</p>
        <p className={pStyle}> {t("conditions.content")}</p>
        <p className={pStyle}>{t("conditions.content2")}</p>
        <p className={pStyle}>{t("conditions.content3")}</p>
        <h2 className={titleStyle}>{t("responsibilities.title")}</h2>
        <p className={pStyle}>{t("responsibilities.content")}</p>
        <p className={pStyle}>{t("responsibilities.content2")}</p>
        <p className={pStyle}>{t("responsibilities.content3")}</p>
        <h2 className={titleStyle}>{t("privacy.title")}</h2>
        <p className={pStyle}> {t("privacy.content")}</p>
        <Link to={"/privatePolicy"}>
          {" "}
          <h5 className={"text-[#457AA8] hover:text-[#496c8a] cursor-pointer "}>
            {t("privacy.link")}
          </h5>
        </Link>
        <h2 className={titleStyle}>{t("property.title")}</h2>
        <p className={pStyle}>{t("property.content")}</p>
        <h2 className={titleStyle}>{t("payment.title")}</h2>
        <p className={pStyle}>{t("payment.content")}</p>
        <h2 className={titleStyle}>{t("cancellation.title")}</h2>
        <p className={`text-red-600 ${pStyle}`}>
          {t("cancellation.content")}{" "}
        </p>{" "}
        <h2 className={titleStyle}>{t("discrim.title")}</h2>{" "}
        <div className="w-full flex  float-start py-3 justify-center ">
          {" "}
          {/* <button
              className=" bg-[#457AA8] h-10 w-24 text-white rounded hover:scale-105 shadow-md transition duration-100 ease-in-out focus-visible:shadow-none lowercase first-letter:uppercase "
            >
              Fermer
            </button> */}
        </div>
      </div>
    </div>
  );
};

export default TermAndCondidtionPage;
