import React, { createContext, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getAllCompany } from "../services/HttpRequest/adminRequest/compRequest";

const CompContext = createContext({
  allComps: [],
  activeComp: {},
  statusComp: "",
  error: {},
  handleAddActiveComp: (activeComp) => {},
  numberOfCompagny: 0 
});

const handleActiveJob = () => {
  const getActiveComp = localStorage.getItem("activeComp");
  const comp = JSON.parse(getActiveComp);

  if (comp) {
    return comp;
  }
  return {};
};

export const CompContextProvider = ({ children }) => {
  const [activeComp, setActiveComp] = useState({});
  const [allComps, setAllComps] = useState([]);

  const { data, status, error } = useQuery({
    queryKey: ['getAllCompany'],
    queryFn: getAllCompany,
  });

  useEffect(() => {
    if (status === "success") {
      setAllComps(data.data);
    }
    setActiveComp(handleActiveJob());
  }, [data]);

  const handleAddActiveComp = (activeJob) => {
    localStorage.setItem("activeComp", JSON.stringify(activeJob));
    setActiveComp(handleActiveJob());
  };

  const numberOfCompagny = allComps.length; 

  const values = {
    error,
    statusComp : status,
    allComps,
    activeComp,
    handleAddActiveComp,
    numberOfCompagny 
  };

  return <CompContext.Provider value={values}>{children}</CompContext.Provider>;
};
export default CompContext;
