import {
  CREATE_ADMIN_URL,
  ADMIN_DELETE_SUSER,
  CREATE_MANAGER_URL,
  AMDIN_EDIT_SUPER_USER_BY_ID,
  ADMIN_GETSUPER_SUSER,
  USER_EDIT,
  GET_CURRENT_USER,
} from "../../api";
import { axiosUseToken } from "../../axiosConfig";

// creater Super user
export const createUserRequest = async ({ data }) => {
  // manager
  // console.log("je suis les données du create user dans request", data);
  if (data.role === "Manager") {
    // console.log("je suis les données du create Manager dans request", data);
    const response = await axiosUseToken.post(CREATE_MANAGER_URL, {
      email: data.email,
      // role: data.role,
      password: data.password,
    });
    // console.log("reponse du create manager", response);
    return response;
  }
  // admin
  if (data.role === "Administrateur") {
    // console.log(
    //   "je suis les données du create Administrateur dans request",
    //   data
    // );
    const response = await axiosUseToken.post(CREATE_ADMIN_URL, {
      email: data.email,
      // role: data.role,
      password: data.password,
    });
    // console.log("reponse du create admin", response);

    return response;
  }
};

//ok
export const editUserRequest = async ({ data }) => {
  // console.log("je suis les données du edit user dans data", data.id);
  const editData = data.data;
  const reconstructData = {
    email: editData.email,
    role: editData.role,
  };
  // console.log(
  //   "je suis les données du edit user reconstructData dans request",
  //   reconstructData
  // );

  const response = await axiosUseToken.post(
    AMDIN_EDIT_SUPER_USER_BY_ID + data.id,
    {
      email: editData.email,
      role: editData.role,
    }
  );
  // console.log("je suis les response editions du muserdans request", response);
  return response;
};

// get Admin ok
// export const getAllAdmin = async () => {
//   const response = axiosUseToken.post(GET_ALL_ADMIN);
//   return response;
// };

export const getAllSuperUser = async () => {
  const response = axiosUseToken.get(ADMIN_GETSUPER_SUSER);
  // console.log("je suis le resultat", response);
  return response;
};

// get Manager ok
// export const getAllManager = async () => {
//   const response = axiosUseToken.post(GET_ALL_MANAGER);
//   return response;
// };

//ne passe pas encore
// export const deleteSuperUser = async ({ data }) => {
//   // console.log("donnes à supprimer", data);
//   const response = await axiosUseToken.delete(ADMIN_DELETE_SUSER + data);
//   return response;
// }
//ne passe pas encore
export const deleteSuperUser = async (data) => {
  // console.log("data", data);
  const response = await axiosUseToken.delete(ADMIN_DELETE_SUSER + data);
  // console.log("response", response);
  return response;
};

export const getCurrentUser = async () => {
  const response = await axiosUseToken(GET_CURRENT_USER);
  return response;
};
