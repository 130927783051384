import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { jobsSliceAction } from "../../../store/slice/jobsSlice";
import { categorySliceAction } from "../../../store/slice/categorySlice";

import AOS from "aos";
import "aos/dist/aos.css";

function CatItem({ icon, title, description, id }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSelectCat = () => {
    dispatch(jobsSliceAction.filterJobsByCatId({ categoryId: id }));
    dispatch(categorySliceAction.getCatByIdForFilter({ selectedCatId: id }));

    navigate("/Job");
  };
  useEffect(() => {
    AOS.init({
      duration: 1000, // Durée de l'animation en millisecondes
    });
  }, []);
  return (
    <div
      onClick={handleSelectCat}
      className={`w-full h-24 flex flex-row  relative justify-center hover:bg-white hover:cursor-pointer hover:scale-105 ease-in-out transition-all duration-300 items-center gap-2 mb-8 hover:shadow-lg rounded-lg `}
      data-aos="fade-up"
      data-aos-delay={`${id * 100}`}
    >
      <div
        className={`w-16 h-16 relative rounded-lg justify-between  bg-[#E7F0FA] transition  `}
      >
        <span className={`h-6 w-6 relative top-3 left-3   text-[#0284C7]`}>
          {icon}
        </span>
      </div>
      <div className="xl:w-44 bg-inherit gap-y-2  flex flex-col ">
        <p
          className={`font-inter text-lg font-medium leading-7 text-left hover:text-[#0284C7] text-black`}
        >
          {title}
        </p>
        <p className="text-gray-500  font-inter text-md font-medium leading-4 text-left">
          {description}
        </p>
      </div>
    </div>
  );
}

export default CatItem;
