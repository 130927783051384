import React from "react";
import image from "../../assets/quickApply/image.png";
import { useTranslation, Trans } from "react-i18next";
const SectionOne = () => {
  const { t } = useTranslation();
  return (
    <section className="mt-16">
      <div className="flex items-end w-full justify-around bg-gradient-to-tr from-[#0284C7]  to-[#0D50A0] lg:h-[479px] h-60 ">
        <div className="text-2xl basis-1/2  lg:text-5xl self-center text-white text-center font-bold">
          {t("quickApply.title")}
        </div>
        <div
          className="lg:w-[800px] basis-1/2 lg:h-[450px]  w-[400px] h-[225px] "
          style={{
            backgroundImage: `url(${image})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center top 0%",
          }}
        ></div>
      </div>
    </section>
  );
};

export default SectionOne;
