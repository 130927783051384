import { FaEye, FaTrash, FaRegStar, FaStar, FaFilePdf } from "react-icons/fa";
import ModalUI from "../UI/ModalUI";
import { useContext, useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { deleteCv } from "../../services/HttpRequest/User/cvRequest/delete";
import { queryClient } from "../../services/HttpRequest/queryClientProvider/queryClientProvider";
import CvContext from "../../context/CvContext/Context";
import { Loader } from "react-feather";
import ButtonStudentUI from "../UI/ButtonUI";
import { useTranslation, Trans } from "react-i18next";

const CvCard = ({ file, icon, isSelected, onSelect, lastCreatedFile }) => {
  const { t } = useTranslation();
  const { viewDoc } = useContext(CvContext);
  const { handleViewDoc } = useContext(CvContext);

  // delete mutation

  const { status: deleteStatus, mutate: deleteMutate } = useMutation({
    mutationFn: deleteCv,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["getAllCv"] });
    },
  });

  // console.log('viewStatus', viewStatus)
  useEffect(() => {
    if (deleteStatus === "success") {
      toast.success(t("cvcard.success"), {
        position: "bottom-right",
        autoClose: 5000,
        bodyClassName: "font-bold",
        className: "",
        toastId: "success-toast",
      });
    }
    if (deleteStatus === "error") {
      toast.error(t("cvcard.error"), {
        position: "bottom-right",
        autoClose: 5000,
        bodyClassName: "font-bold",
        className: "",
        toastId: "error-toast",
      });
    }
  }, [deleteStatus]);

  const handleDeleteClick = (event) => {
    // Empêcher la propagation de l'événement pour éviter la sélection du fichier lors du clic sur le bouton "Supprimer"
    event.stopPropagation();

    // Appeler la fonction de suppression
    // console.log("file.id", file.id);
    deleteMutate({ id: file.id });
    // console.log(t("cvcard.alert"), deleteStatus);
    handleCloseModal(); // Ferme la modal après la suppression

    if (isSelected) {
      onSelect(lastCreatedFile.current);
    }
  };
  const handleIconClick = (event) => {
    // Empêcher la propagation de l'événement pour éviter la sélection du fichier lors du clic sur l'icône
    event.stopPropagation();

    // Appeler la fonction de sélection
    onSelect(file.id);
  };

  const handleViewClick = (event) => {
    // Empêcher la propagation de l'événement pour éviter la sélection du fichier lors du clic sur le bouton "Voir"
    event.stopPropagation();
    handleViewDoc(file.id);
    // Appeler la fonction pour voir le fichier
    // const viewRes = await viewCv({id:file.id})
    // console.log('viewf', viewRes)
    // onView(viewRes.data)
    // handleViewDoc(viewRes)
  };
  // appeller et utiliser une modal
  const [modalOpen, setModalOpen] = useState(false);

  const handleButtonClick = (event) => {
    event.stopPropagation();
    setModalOpen(true);
  };

  const handleCloseModal = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setModalOpen(false);
  };

  const isButtonDisable =
    viewDoc.status === "pending" && viewDoc.id === file.id;

  return (
    <div className="bg-[#0284C7] p-2 self-center rounded-[25px]">
      <div className="break-words bg-white shadow-lg rounded-xl w-[250px] ">
        <div
          className={`file-card ${isSelected ? "bg-stone-200" : ""}`}
          onClick={() => onSelect(file.id)}
        >
          <div className="flex p-4 justify between">
            <p className="mr-auto">
              {isSelected ? t("cvcard.selecttext") : t("cvcard.selecttext2")}
            </p>
            {isSelected ? (
              <FaStar
                color="#346198"
                className="w-6 h-6 ml-auto"
                onClick={handleIconClick}
              />
            ) : (
              <FaRegStar
                color="#CCCCCC"
                className="w-6 h-6 ml-auto"
                onClick={handleIconClick}
              />
            )}
          </div>
          <hr />
          <div className="p-4 file-card">
            <div className="mb-2 flex-col flex  ">
              {isButtonDisable ? (
                <Loader
                  size={60}
                  className={`text-[#5190B7] w-full animate-spin   `}
                />
              ) : (
                icon
              )}
              <span>{file.name}</span>
            </div>
            <div className="flex justify-between ">
              <div className="">
                <ModalUI open={modalOpen} classname="w-80 ">
                  <div className="p-4 space-y-4">
                    {" "}
                    <h2 className="pb-2">
                      {t("cvcard.modaltext")}{" "}
                      <span className="font-bold">{file.name}</span>
                    </h2>
                    {/* Autres éléments de la modal */}
                    <div className="flex gap-x-3 justify-evenly item">
                      <ButtonStudentUI
                        classname={"bg-blue-600"}
                        onClick={(event) => handleCloseModal(event)}
                      >
                        {t("cvcard.modalcancel")}
                      </ButtonStudentUI>
                      <ButtonStudentUI
                        classname={"bg-red-500"}
                        onClick={(event) => handleDeleteClick(event)}
                      >
                        {t("cvcard.modaldelete")}
                      </ButtonStudentUI>
                    </div>
                  </div>
                </ModalUI>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" flex justify-between mt-2">
        <button
          disabled={isButtonDisable}
          onClick={handleViewClick}
          className="mr-4 flex items-center justify-start bg-[#E8451D] text-white py-2 px-4 rounded-2xl "
        >
          <FaEye className="flex-shrink-0 w-4 h-4 " />
          <span className="ml-1 text-xs">{t("cvcard.cv_view")}</span>
        </button>

        <button
          onClick={handleButtonClick}
          disabled={isButtonDisable}
          className={`  ${
            deleteStatus === "pending" && " bg-[#39485a]"
          } hover:bg-[#346aac] flex items-center justify-end bg-[#346198] text-white py-2 px-4 rounded-2xl  w-[110px] overflow-hidden`}
        >
          <span>
            {" "}
            <FaTrash className="w-3 h-3 " />
          </span>

          <span className="ml-1 text-xs">
            {" "}
            {deleteStatus === "pending"
              ? t("cvcard.cv_delete")
              : t("cvcard.cv_delete2")}
          </span>
        </button>
      </div>
    </div>
  );
};
export default CvCard;
