import React from "react";
import { useTranslation } from "react-i18next";
import { FaPlus } from "react-icons/fa";

const TextAreaButton = ({ classname, ...props }) => {
  const { t } = useTranslation();
  return (
    <button
      type="button"
      {...props}
      className={`opacity-80 h-5 bg-[#457AA8] lg:h-7 text-white   mt-[2.8rem] justify-center items-center p-2 gap-x-2 py-4 flex lowercase rounded-sm first-letter:uppercase ${classname} `}
    >
      <FaPlus size={25} className="text-white lg:size-5 " />
      <div className="max-md:hidden">{t("stepTwo.add_btn")}</div>
    </button>
  );
};

export default TextAreaButton;
