import React, { useEffect } from "react";
import hpSecSix from "../../../assets/hp-secSix.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AOS from 'aos';
import 'aos/dist/aos.css';

const SectionSix = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({
      duration: 1000, // Durée de l'animation en millisecondes
    });
  }, []);

  const handleClick = () => {
    navigate("/job");
  };

  return (
    <section
      data-aos="fade-left"
      className="w-full mx-auto overflow-hidden bg-white px-2 py-2 rounded-xl flex flex-col-reverse lg:flex-row items-center"
    >
      <div className="w-full lg:w-auto p-4">
        <img
          src={hpSecSix}
          className="w-full lg:w-[500px] object-cover"
          alt="imag"
        />
      </div>
      <div className="w-full lg:w-auto p-2 mx-auto flex items-center">
        <div className="p-4 w-full">
          <h1 className="text-[26px] font-bold text-black text-center lg:text-left">
            {t("hP-sectionSix.title")}
          </h1>
          <div className="justify-content">
            <p className="pt-5 text-[20px] lg:w-[618px] 2xl:w-[90%] text-justify  lg:text-left">
              {t("hP-sectionSix.content")}
            </p>
          </div>
          <div className="pt-5 flex justify-center lg:justify-start">
            <button
              className="px-2 py-2 hover:border-2 hover:text-orange-500 hover:border-white font-bold text-white duration-500 bg-gradient-to-r from-[#5190B7] to-[#457AA8] rounded-full btn shadow-[4px_7px_4.7px_-6px_#00000040] hover:bg-indigo-500"
              onClick={handleClick}
            >
              {t("hP-sectionSix.button")}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionSix;
