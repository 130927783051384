import React, { useEffect, useContext } from "react";
import UserTable from "./UserTable/UserTable";
import LoadDataFailUI from "../../../UI/RequestUI/LoadDataFailUI";
import PendingUI from "../../../UI/RequestUI/PendingUI";
// import { useUserContext } from './UserContext';
import { toast } from "react-toastify";
import UserContext from "../../../../context/AdminContext/UserContext";
import { useTranslation, Trans } from "react-i18next";

const UsersManage = () => {
  const { t } = useTranslation();
  const {
    usersData,
    statusUser,
    numberOfUsers,
    handleDelete,
    handleEdit,
    handleBlock,
    deleteStatus,
  } = useContext(UserContext);

  useEffect(() => {
    if (deleteStatus === "success") {
      toast.success(t("userManage.success_msg"), {
        position: "top-center",
        autoClose: 5000,
        bodyClassName: "font-bold",
        className: "w-[200%]",
        toastId: "success-delete-toast",
      });
    }
    if (deleteStatus === "error") {
      toast.error(t("userManage.fail_msg"), {
        position: "top-center",
        autoClose: 5000,
        bodyClassName: "font-bold",
        className: "w-[200%]",
        toastId: "error-delete-toast",
      });
    }
  }, [deleteStatus]);

  let content;
  switch (statusUser) {
    case "error":
      content = <LoadDataFailUI />;
      break;
    case "loading":
      content = <PendingUI />;
      break;
    case "success":
      content = (
        <UserTable
          data={usersData}
          onDelete={handleDelete}
          onEdit={handleEdit}
          onBlock={handleBlock}
        />
      );
      break;
    default:
      content = null;
      break;
  }

  return (
    <div className="container w-full mx-auto mt-8">
      <h1 className="mb-4 text-3xl font-bold text-[#457AA8]">
        {t("userManage.title")} 
      </h1>
      {content}
    </div>
  );
};

export default UsersManage;
