import { isTextValid } from "../../components/Utils/ValidFormElement";

export const textAreaReducer = (state, action) => {
  switch (action.type) {
    case "add":
      if (isTextValid(action.payload.value)) {
        let selectedField = state[action.payload.id];

        selectedField.table = [action.payload.value, ...selectedField.table];
        selectedField.stringValue = selectedField.table
          .map((text) => text.replace(/\n/g, ""))
          .join(";");

        return { ...state, [action.type.id]: selectedField };
      }

      return state;

    case "del":
      let selectedField = state[action.payload.id];
      selectedField.table = selectedField.table.filter(
        (text) => text !== action.payload.value
      );
      selectedField.stringValue = selectedField.table
        .map((text) => text.replace(/\n/g, ""))
        .join(";");

      return { ...state, [action.type.id]: selectedField };

    // update case

    case "update":
      let activeField = state[action.payload.id];

      if (action.payload.value?.trim().length > 0) {
        activeField.table = action.payload.value?.split(";");
      }
      else{
        activeField.table=[]
      }
      activeField.stringValue = activeField.table
        .map((text) => text.replace(/\n/g, ""))
        .join(";");

      return { ...state, [action.type.id]: activeField };
      case 'edit':

      return {...state,[action.type.id]: activeField}

    default:
      return state;
  }
};
