import React from "react";
import ModalUI from "../../UI/ModalUI";
import { Loader } from "react-feather";
import { useTranslation, Trans } from "react-i18next";

const PendingModal = ({ isOpen, autoclose, children }) => {
  const { t } = useTranslation();
  return (
    <ModalUI
      autoclose={autoclose}
      open={isOpen}
      classname={" size-36 flex flex-col  items-center justify-center "}
    >
      <div className="lg:size-16 size-14">
        {" "}
        <Loader className="size-14 lg:size-16 animate-spin text-[#457AA8]" />
      </div>
      <div className="text-center mt-3 text-gray-700 ">
        {children ? children : t("userdetails.progress")}
      </div>
    </ModalUI>
  );
};

export default PendingModal;
