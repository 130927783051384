import React, { useState } from "react";
import QuickApplyContext from "./QuickApplyontext";
import {
  isEmailValid,
  isPhoneValid,
  isTextValid,
} from "../../Utils/ValidFormElement";

const QuickApplyContextProvider = ({ children }) => {
  const [quickApplyData, setQuickApplyData] = useState({
    firstName: { value: "", didEdit: false },
    lastName: { value: "", didEdit: false },
    phoneNumber: { value: "", didEdit: false },
    email: { value: "", didEdit: false },
    adresse: { value: "", didEdit: false },
    adresse2: { value: "", didEdit: false },
    town: { value: "", didEdit: false },
    province: { value: "", didEdit: false },
    postalCode: { value: "", didEdit: false },
    poste: { value: "", didEdit: false },
  });
  const [isAgree, setIsAgree] = useState(false);
  const [isResident, setIsResident] = useState(true);

  const handleChangeField = (id, value) => {
    setQuickApplyData((prevCheck) => ({
      ...prevCheck,
      [id]: { value: value, didEdit: false },
    }));
  };

  const handleBlurField = (id) => {
    setQuickApplyData((prevCheck) => ({
      ...prevCheck,
      [id]: { ...prevCheck[id], didEdit: true },
    }));
  };
  // -------------------valid form element


const handleIsResident =(value)=>{
  setIsResident(value)
}
const handleIsAgree =()=>{


  setIsAgree(prevState=>!prevState)


}


const initField=()=>{
  for (let field in quickApplyData) {
    handleBlurField(field);
  }
}

  // validData

  const isEmailNotOk =
    quickApplyData.email.didEdit && !isEmailValid(quickApplyData.email.value);
  const isPhoneNumberNotOk =
    quickApplyData.phoneNumber.didEdit &&
    !isPhoneValid(quickApplyData.phoneNumber.value);
  const isFirstNotNameOk =
    quickApplyData.firstName.didEdit &&
    !isTextValid(quickApplyData.firstName.value);
  const isLastNameNotOk =
    quickApplyData.lastName.didEdit &&
    !isTextValid(quickApplyData.lastName.value);
  const isAdresseNotOk =
    quickApplyData.adresse.didEdit &&
    !isTextValid(quickApplyData.adresse.value);
  const isPosteNotOk =
    quickApplyData.poste.didEdit && !isTextValid(quickApplyData.poste.value);
  const isFormDataOk =
    isTextValid(quickApplyData.firstName.value) &&
    isTextValid(quickApplyData.lastName.value) &&
    isEmailValid(quickApplyData.email.value) &&
    isPhoneValid(quickApplyData.phoneNumber.value) &&
    isTextValid(quickApplyData.poste.value) &&
    isResident &&
    isAgree;

  const values = {
    fieldData:{isResident,isAgree ,...quickApplyData},
    handleChangeField,
    handleBlurField,
    handleIsAgree,
    handleIsResident,
    isFormDataOk,
    initField,
    FieldValidation: {
      isFirstNotNameOk,
      isLastNameNotOk,
      isPosteNotOk,
      isEmailNotOk,
      isAdresseNotOk,
      isPhoneNumberNotOk,
    },
  };

  return (
    <QuickApplyContext.Provider value={values}>
      {children}
    </QuickApplyContext.Provider>
  );
};

export default QuickApplyContextProvider;
