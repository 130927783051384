import { ADMIN_GET_ALL_APPLICATION,ADMIN_GET_JOB_APPLICATION } from "../../api";
import { axiosUseToken } from "../../axiosConfig";

// export const getJobApplication = async () => {
//   const response = await axiosUseToken.get(ADMIN_GET_JOB_APPLICATION);
//   return response;
// };

export const getAllApplication = async () => {
  const response = await axiosUseToken.get(ADMIN_GET_ALL_APPLICATION);
  return response;
};
