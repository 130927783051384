import React from "react";
import { useTranslation } from "react-i18next";
import imgc1 from "../../../assets/contact.jpg";

export default function SectionOne() {
  const { t } = useTranslation();
  return (
    <section className="pt-[55px] mx-auto h-[550px] overflow-hidden">
      <div
        className="w-full items-center  h-full flex flex-wrap justify-center"
        style={{
          backgroundImage: `linear-gradient(rgba(64, 174, 223, 0.5), rgba(176, 63, 4, 0.7)), url(${imgc1})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center top -1%",
          height:"100%",
        }}
      >
        {/* <h1 className="text-[42px] [font-family:'Inter-Bold',Helvetica] font-bold text-white text-center w-full">
          {t("Contact.text12")}
        </h1> */}
        <div className="justify-center flex w-full">
          <div className="relative flex justify-center w-[44%] h-[184px] items-center shadow-lg rounded-[35px]" data-aos="fade-down">
            <div className="absolute inset-0 bg-gray-100 opacity-10 rounded-[35px]"></div>
            <div className="relative flex  text-center  w-full mx-auto">
              <h1 className="font-bold text-white text-6xl text-center w-full ">
              {t("Contact.text12")}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
