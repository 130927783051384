import React from "react";
import DashItem from "./DashItem";

let itemTable = [
  {
    title: "Nombre de Candidatures",
    periode: "Hier",
    totalUsers: 100,
    percentage: 20,
    totalApplicants: 80,
    color: "bg-[#457AA8]",
  },
  {
    title: "Comptes Utilisateurs",
    periode: "Lundi",
    totalUsers: 300,
    percentage: 250,
    totalApplicants: 50,
    color: "bg-[#F2B33F]",
  },
  {
    title: "Comptes Utilisateurs",
    periode: "Lundi",
    totalUsers: 300,
    percentage: 250,
    totalApplicants: 50,
    color: "bg-[#EF6C00]",
  },
];

const DailyItem = () => {
  return (
    <div className="flex flex-wrap justify-center gap-7 w-full mb-4">
      <div className="w-full  h-[52px] mb-6">
        <h1 className="font-bold flex justify-center xl:justify-start">
          Tableau de bord
        </h1>
        <p className="text-[#667085] text-sm flex text-center justify-center xl:text-start xl:justify-start">
          À votre disposition, une vue synthétique des données essentielles pour
          la prise de décision.
        </p>
      </div>
      {itemTable.map((item, index) => (
        <div key={index} className="w-full md:w-1/2 lg:w-1/4 xl:w-1/4 p-2">
          <DashItem
            title={item.title}
            totalPersons={item.totalUsers}
            DailyPersons={item.totalApplicants}
            referencePercentage={item.percentage}
            day={item.periode}
            barcolor={item.color}
          />
        </div>
      ))}
    </div>
  );
};

export default DailyItem;
