// alertUtils.js

import ReactDOM from "react-dom";
import React from "react";
import { useTranslation, Trans } from "react-i18next";

const Alert = ({ message, onClose }) => {
  const { t } = useTranslation();
  return (
    <div className="fixed z-50 inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-8 rounded shadow-lg">
        <p>{message}</p>
        <button
          className="flex items-center justify-center bg-[#346198] text-white py-2 px-4 rounded-2xl mt-1 mb-4 w-[110px] overflow-hidden"
          onClick={onClose}
        >
          {t("alertutils.btn")}
        </button>
      </div>
    </div>
  );
};

export const showAlert = (message) => {
  const customAlert = document.createElement("div");
  document.body.appendChild(customAlert);

  const handleClose = () => {
    customAlert.remove();
  };

  const alertComponent = <Alert message={message} onClose={handleClose} />;

  // Utilisez React pour rendre le composant Alert dans le DOM
  ReactDOM.render(alertComponent, customAlert);
};
