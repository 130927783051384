import { FaSearch } from "react-icons/fa";
import { Input } from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import { jobsSliceAction } from "../../../store/slice/jobsSlice";
import { useTranslation, Trans } from "react-i18next";

function SearchBar() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const searchText = useSelector((state) => state.jobs.searchText);
  const handleSearchJob = (text) => {
    dispatch(jobsSliceAction.searchJobs({ searchText: text }));
  };

  return (
    <div className=" flex flex-col items-center relative w-full max-w-screen-sm mx-auto">
      <form className="flex items-center   h-full p-2  space-x-2 justify-between  w-full">
        <div className="relative items-center w-full justify-start h-[40px] ">
          <Input
            type="text"
            id="searchTextWord"
            name="searchTextWord"
            size="md"
            value={searchText}
            onChange={(event) => handleSearchJob(event.target.value)}
            placeholder={t("search.placeholder")}
            aria-label={t("search.label")}
            className="border pl-10 border-white rounded-full  placeholder-white rounded-r-none focus:border-[#457AA8] focus:outline-none focus:ring-0  text-white h-full"
          />
          <span className="absolute left-3 top-1/2 transform -translate-y-1/2">
            <FaSearch className="text-white" />
          </span>
        </div>
        <button className="px-4 justify-end rounded-l-none text-sm font-semibold text-[#EA580C] bg-white border border-[#EA580C] rounded-full hover:text-blue-500 hover:border-blue-500 hover:border-2  md:static lg:text-lg 2xl:text-xl xl:text-lg h-full">
          {t("search.btn")}
        </button>

      
      </form>
    </div>
  );
}

export default SearchBar;
