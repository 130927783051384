import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const GraphComponent = ({
  data,
  dataKey,
  fill,
  months,
  selectedMonth,
  handleMonthChange,
  width,
  barSize,
  title,
}) => {
  return (
    <div className="flex justify-center lg:justify-start items-center mb-8 lg:mr-4 shadow-lg">
      <div className={`w-full ${width === "xl:692px" ? "xl:w-[692px]" : "xl:w-[417px]"}`}>
        <div className="mb-1">
          <select
            value={selectedMonth}
            onChange={handleMonthChange}
            className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 rounded shadow leading-tight focus:outline-none focus:shadow-outline max-w-[90px]"
          >
            {months.map((month) => (
              <option key={month} value={month}>
                {month}
              </option>
            ))}
          </select>
        </div>
        <p className="text-lg text-gray-400 mt-4 mb-1">{title}</p>
        <div className="w-full xl:w-auto" style={{ height: 360 }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={data}
              layout="vertical"
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <XAxis type="number" />
              <YAxis dataKey="semaine" type="category" />
              <Tooltip />
              <Legend />
              <Bar
                dataKey={dataKey}
                fill={fill}
                radius={[0, 10, 10, 0]}
                barSize={barSize}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default GraphComponent;
