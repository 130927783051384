import { Button } from "@material-tailwind/react";
import SelectUI from "../../UI/SelectUI";
import TextFieldUI from "../../UI/TextFieldUI";
import {
  FaArrowCircleLeft,
  FaArrowCircleRight,
  FaEdit,
  FaPlus,
  FaSave,
  FaTrash,
} from "react-icons/fa";
import { motion } from "framer-motion";
import { useContext, useEffect, useState } from "react";
import TextAreaUI from "../../UI/TextAreaUI";
import { employementsTypeText, itemVariant } from "./TableDate";
import AddJobContext from "../../../context/AddJobContext/context";
import { useTranslation, Trans } from "react-i18next";
import ToogleSelect from "./ContratType";
import TextAreaButton from "./TextAreaButton";
import ModalInfos from "../../UI/RequestUI/ModalInfos";

const StepTwo = () => {
  const { t } = useTranslation();

  const {
    handleChangeField,
    HandleTextareaDispatch,
    formContent,
    textAreaState,
    validEachInput,
    handleBlurField,
    handleStep,
    validStepOk,
    isEditing,
    handleEmployementStatus,
    employementStatusEdit,
  } = useContext(AddJobContext);

  // console.log("textAreaState", textAreaState);
  const [descriptionValue, setDesValue] = useState(null);
  const [fieldController, setFielController] = useState(false);
  const [isEditDesc, setIsEditDesc] = useState(false);
  useEffect(() => {
    if (descriptionValue) {
     
        handleChangeField("description", descriptionValue);
        setIsEditDesc(false);
      
    }
  }, [descriptionValue, fieldController]);

  return (
    <>
      <div className="grid gap-4">
        <ModalInfos
          autoClose={() => {
            setIsEditDesc(false);
          }}
          isOpen={isEditDesc}
          message={t("jobpage.textarea_error")}
        />
        <div>
          <TextFieldUI
            label={t("stepTwo.num_label")}
            isRequired
            value={formContent.requiredHeadCount.value}
            placeholderValue={1}
            type="number"
            min={0}
            id="requiredHeadCount"
            onChange={(event) =>
              handleChangeField("requiredHeadCount", event.target.value)
            }
            onBlur={() => handleBlurField("requiredHeadCount")}
            classname="w-full p-2 mt-1 border rounded-md focus:outline-none"
          />

          <h3 className="ml-2 text-red-500">
            {validEachInput.isNotRequiredHeadCount && t("stepTwo.num_error")}
          </h3>
        </div>
      </div>
      <div className="grid md:grid-cols-2 gap-4">
        {/* <div>
          <SelectUI
            classname="w-full p-2 mt-1 border rounded-md focus:outline-none"
            id="employementStatus"
            isRequired
            data={employmentTypes}
            value={formContent.employmentStatus.value}
            label={t("stepTwo.status_label")}
            placeholderValue={t("stepTwo.status_placeholder")}
            onChange={(event) =>
              handleChangeField("employmentStatus", event.target.value)
            }
            onBlur={() => handleBlurField("employmentStatus")}
          />

          <h3 className="ml-2 text-red-500">
            {validEachInput.isNotEmploymentStatus &&
              t("stepTwo.status_placeholder")}
          </h3>
        </div> */}
        <div>
          <label className="mb-3 block text-sm font-medium mt-1 text-gray-700">
            Type de contrat
          </label>
          <ToogleSelect
            data={employementsTypeText}
            activeTable={employementStatusEdit}
            getTable={handleEmployementStatus}
          />
          <h3 className="ml-2 text-red-500">
            {validEachInput.isNotEmploymentStatus &&
              employementStatusEdit.length === 0 &&
              "Veuillez selectionner au moins un type de contrat"}
          </h3>
        </div>
        <div>
          <TextFieldUI
            type="date"
            min="2024-03-04"
            label={t("stepTwo.offer_label")}
            isRequired
            value={formContent.applicationDeadLine.value}
            id="applicationDeadLine"
            onChange={(event) =>
              handleChangeField("applicationDeadLine", event.target.value)
            }
            onBlur={() => handleBlurField("applicationDeadLine")}
            classname=" p-2 mt-1 border rounded-md"
          />
          {/* <h3 className="ml-2 text-red-500">
            {validEachInput.isNotApplicationDeadline &&
              "veuillez selectionner une durée, au moins 24 heures"}
          </h3> */}
        </div>
      </div>
      <div className="grid gap-4">
        <div>
          <div className="flex w-full">
            {" "}
            <TextAreaUI
              label={t("stepTwo.desc_label")}
              isRequired
              value={formContent.description.value}
              placeholderValue={t("stepTwo.desc_placeholder")}
              type="textarea"
              id="description"
              onChange={(event) =>
                handleChangeField("description", event.target.value)
              }
              onBlur={() => handleBlurField("description")}
              classname="w-full h-24 lg:h-32 p-2 mt-1 border rounded-md focus:outline-none"
            />
            <TextAreaButton
              onClick={() => HandleTextareaDispatch("description", "add")}
            ></TextAreaButton>
          </div>
          <h3 className="ml-2 text-red-500">
            {validEachInput.isNotDescription && t("stepTwo.desc_error")}
          </h3>
        </div>
        <motion.ul layout className="flex  overflow-y-scroll flex-wrap">
          {textAreaState?.description?.table &&
            textAreaState?.description?.table.map(
              (text) =>
                text !== "" && (
                  <li
                    key={Math.random()}
                    className="w-[19rem]  p-1 h-14 lg:h-20 pl-1 flex m-1  text-white bg-[#457AA8] rounded-sm  shadow-2xl border-opacity-75 "
                  >
                    <h3 className="w-72 overflow-x-hidden scroll-p-0 hover:scroll-auto  ">
                      {text}
                    </h3>
                    <div className="flex flex-col items-center justify-around ml-1 -mr-1 ">
                      <motion.span
                        variants={itemVariant}
                        whileHover="animeHover"
                        className=" hover:text-green-500 mr-2 pt-1"
                      >
                        <FaEdit
                          size={15}
                          onClick={() => {
                            if (text) {
                              if (
                                formContent.description.value.trim().length > 0
                                ) {
                                  setIsEditDesc(true);
                                  } else {
                                handleChangeField("description", text);
                                setDesValue(text);
                                setFielController((prev) => !prev);
                              HandleTextareaDispatch("description", "del", text);
                              }
                            }
                          }}
                        />
                      </motion.span>
                      <motion.span
                        variants={itemVariant}
                        whileHover="animeHover"
                        className=" hover:text-red-500 mr-2 pt-1"
                      >
                        <FaTrash
                          size={15}
                          onClick={() => {
                            HandleTextareaDispatch("description", "del", text);
                          }}
                        />
                      </motion.span>
                    </div>
                  </li>
                )
            )}
        </motion.ul>
      </div>
      <div className="flex w-full justify-evenly">
        <Button
          onClick={() => handleStep(1)}
          variant="filled"
          color="blue"
          className={`flex bg-opacity-85 justify-center w-auto h-12 items-center ml-4 hover:bg-opacity-70  `}
        >
          <span className="justify-end text-md">
            <FaArrowCircleLeft className=" size-4" />
          </span>
          <span className="justify-start max-md:hidden mx-1">
            <div></div>
            {t("stepTwo.back")}
          </span>
        </Button>
        {isEditing && (
          <Button
            type="submit"
            variant="filled"
            color={"green"}
            className={`flex bg-opacity-85 justify-center w-auto h-12 items-center ml-4 hover:bg-opacity-70  `}
          >
            <span className="justify-start mx-1 max-md:hidden ">
              <div></div>
              {t("stepTwo.term")}
            </span>
            <span className="justify-end text-md">
              <FaSave className="size-4" />
            </span>
          </Button>
        )}

        <Button
          onClick={() => handleStep(3, validStepOk.isSecondStepOk)}
          variant="filled"
          color={"blue"}
          className={`flex bg-opacity-85 justify-center w-auto h-12 items-center ml-4 hover:bg-opacity-70  `}
        >
          <span className="justify-start mx-1 max-md:hidden">
            <div></div>
            {t("stepTwo.next")}
          </span>
          <span className="justify-end text-md">
            <FaArrowCircleRight className=" size-4" />
          </span>
        </Button>
      </div>
    </>
  );
};
export default StepTwo;
