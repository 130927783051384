import React, { useState } from "react";
import { sendVerifCode } from "../../../services/HttpRequest/User/pwdRequest/pwdRequest";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const ResetPwdModal = ({ onClose, onSuccess }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  
  const { mutate, status } = useMutation({
    mutationFn: sendVerifCode,
    onSuccess: () => {
      onSuccess(email);
    },
    onError: (error) => {
      setError(error.message);
    },
  });

  const handleResetPassword = () => {
    if (!email || !email.includes("@")) {
      setError(t("resetpwd.error"));
      return;
    }

    // console.log("Email:", email);

    mutate({
      email: email,
    });
  };

  return (
    <div className="fixed inset-0 z-10 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen">
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative z-20 p-8 bg-white w-96 rounded-xl">
          <p className="mb-4 text-2xl font-bold">{t("resetpwd.title")} </p>
          {error && (
            <p className="mb-2 text-sm font-bold text-center text-red-500 ">
              {error}
            </p>
          )}
          
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-700"
            >
              {t("resetpwd.field")}
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full border-1 focus:border-2 border-gray-200 p-2 focus:border-[#457AA8] focus:outline-none focus:ring-0 rounded-xl xl:h-[50px] bg-blue-100"
              placeholder={t("resetpwd.placeholder")}
            />
          </div>
          <div className="flex justify-center space-x-4">
            <button
              type="button"
              className="px-4 py-2 mr-2 text-gray-800 bg-gray-200 rounded-md hover:bg-gray-300"
              onClick={onClose}
            >
              {t("resetpwd.btn1")}
            </button>
            <button
              type="button"
              className="px-4 py-2 text-white bg-[#457AA8] rounded-md hover:bg-gray-700"
              onClick={handleResetPassword}
            >
              {t("resetpwd.btn2")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPwdModal;
