import { useQuery } from "@tanstack/react-query";

import { useEffect, useReducer } from "react";
import CvContext from "./Context";
import {
  getAllDocs,
  viewCv,
} from "../../services/HttpRequest/User/cvRequest/get";
import { useDispatch } from "react-redux";
import { cvUserSliceAction } from "../../store/slice/cvUserSclice";

//

const allCvInitialState = {
  status: "pending" || "error" || "success",
  data: [],
  viewDoc: {
    status: "idle",
    fileName: "",
    file: "",
    id: "",
  },
};

const getAllCvReducer = (state, action) => {
  switch (action.type) {
    // ------------------ get All document case----------------------------

    case "getAllCv":
      const newState = {
        status: action.payload.status,
        data: action.payload.data,
      };
      return { ...state, ...newState };

    case "getOneCv":
      break;

    // ------------------ view doc case----------------------------
    case "viewDoc":
      const status = action.payload.status;
      // pending
      if (status === "pending") {
        return {
          ...state,
          viewDoc: { ...state.viewDoc, status: status, id: action.payload.id },
        };
      }
      // error
      if (status === "error") {
        return { ...state, viewDoc: { ...state.viewDoc, status: status } };
      }
      // success
      if (status === "success") {
        const doc = action.payload.file;

        const decodedBase64 = atob(doc);

        const filename = action.payload.fileName;
        const mime = "application/pdf";
        const u8arr = new Uint8Array(decodedBase64.length);
        for (let i = 0; i < decodedBase64.length; i++) {
          u8arr[i] = decodedBase64.charCodeAt(i);
        }
        const files = new File([u8arr], filename, { type: mime });
        //     let files = new Blob([decoded], { type: 'application/pdf' });
        let fileURL = URL.createObjectURL(files);
        const link = document.createElement("a");
        link.href = fileURL;
        link.download = action.payload.fileName;
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(fileURL);

        //window.open(fileURL, "_blank");

        // let files = new Blob([doc], {
        //   type: "application/pdf",
        // });
        // let fileURL = URL.createObjectURL(files);
        // window.open(fileURL);

        // let files = new Blob([doc], {
        //       type: "application/pdf",
        //     });      const url = URL.createObjectURL(files);

        //     const link = document.createElement('a');
        //     link.href = url;
        //     link.download = 'Cv.pdf';
        //     document.body.appendChild(link);
        //     link.click();

        //     URL.revokeObjectURL(url);
        return { ...state, viewDoc: { ...state.viewDoc, status: status } };
      }
      // success

      return state;

    default:
      return state;
  }
};

const CvContextProvider = ({ children }) => {
  // state
  // const [docId, setDocId] = useState();
  // get All cv Reducer
  const [allDocsState, allDocsDispatch] = useReducer(
    getAllCvReducer,
    allCvInitialState
  );

  // view cv mutation

  //
  // const { refetch:viewRefetch,status: viewStatus, data: viewData } = useQuery({
  //   queryFn: ({ signal }) => {
  //     viewCv({ id: docId ,signal});
  //   },
  //   enabled: false,
  // });
  // query

  const {
    data: allDocsData,
    error: allDocsError,
    status: allDocsStatus,
  } = useQuery({
    queryKey: ["getAllCv"],
    queryFn: getAllDocs,
  });

  // view a cv function
  // console.log('docId', viewData)

  const handleViewDoc = async (id) => {
    try {
      allDocsDispatch({
        type: "viewDoc",
        payload: {
          status: "pending",
          fileName: "",
          file: "",
          id,
        },
      });

      const response = await viewCv(id);

      allDocsDispatch({
        type: "viewDoc",
        payload: {
          status: "success",
          fileName: response.data.fileName,
          file: response.data.file,
          id,
        },
      });
    } catch (error) {
      allDocsDispatch({
        type: "viewDoc",
        payload: {
          status: "error",
          fileName: "",
          file: error,
          id,
        },
      });
    }
  };

  // doc side effet

  // * view doc side effet
  // useEffect(() => {
  //   if (viewStatus === "success") {
  //     console.log("firstjjjjjjjjjjjjjjjjjjjj");
  //     allDocsDispatch({
  //       type: "viewDoc",
  //       payload: {
  //         doc: viewData.data,
  //         status: viewStatus,
  //       },
  //     });
  //   }
  // }, [viewStatus, viewData]);

  // //* getAllDocs side effet

  useEffect(() => {
    allDocsDispatch({
      type: "getAllCv",
      payload: {
        data: allDocsData ? allDocsData : allDocsError,
        status: allDocsStatus,
      },
    });
  }, [allDocsData, allDocsStatus, allDocsError]);

  // value to provide

  const values = {
    allDocsStatus: allDocsState?.status,
    allDocsData: allDocsState?.data,
    handleViewDoc,
    viewDoc: allDocsState?.viewDoc,
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      cvUserSliceAction.getUserCv({
        status: allDocsStatus,
        allDocs: allDocsStatus === "success" ? allDocsData.data : [],
      })
    );
  }, [allDocsStatus, allDocsData, dispatch]);

  return <CvContext.Provider value={values}>{children}</CvContext.Provider>;
};

export default CvContextProvider;
