import { createContext } from "react";

const QuickApplyContext = createContext({
   fieldData : {

        firstName:{value:'',didEdit:false},
        lastName:{value:'',didEdit:false},
        phoneNumber:{value:'',didEdit:false},
        email:{value:'',didEdit:false},
        adresse:{value:'',didEdit:false},
        adresse2:{value:'',didEdit:false},
        town:{value:'',didEdit:false},
        province:{value:'',didEdit:false},
        postalCode:{value:'',didEdit:false},
        poste:{value:'',didEdit:false},
        isAgree:false,
        isResident:false


    },
    isFormDataOk:false,
    FieldValidation: {
        isFirstNotNameOk:true,
        isLastNameNotOk:true,
        isPosteNotOk:true,
        isEmailNotOk:true,
        isAdresseNotOk:true,
        isPhoneNumberNotOk:true,
      },
    handleChangeField:(id,value)=>{},
    handleBlurField:(id)=>{},
    handleIsAgree:()=>{},
    handleIsResident:()=>{},
    initField:()=>{},
})
export default QuickApplyContext