import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import RootRoutes from "../src/rootes/rootes";
import { selectLoginStatus, selectCurrentUser } from "./store/slice/loginSlice";
import { useSelector } from "react-redux";
import { JobContextProvider } from "./context/JobContext";
import QueryClientProviders from "./services/HttpRequest/queryClientProvider/queryClientProvider";
import { CatContextProvider } from "./context/CatContext";
import { CompContextProvider } from "./context/CompContext";
import { UserContextProvider } from "./context/AdminContext/UserContext";

import { CandidacyContextProvider } from "./context/AdminCandidacyContext/AppliContext";
import "./scrollBar.css";
import CvContextProvider from "./context/CvContext/Provider";

import AOS from "aos";
import "aos/dist/aos.css";
import AddJobContextProvider from "./context/AddJobContextProvider";
import ScrollToTop from "./ScrollToTop";

function App() {
  const loginStatus = useSelector(selectLoginStatus);
  const currentUser = useSelector(selectCurrentUser);
  let role;

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  if (currentUser) {
    if (currentUser.roles && currentUser.roles.length > 0) {
      role = currentUser.roles[0];
    }
    // else {
    //   role = "Client";
    // }
  } else {
    role = null;
  }

  return (
    <QueryClientProviders>
      <Router>
      <ScrollToTop />
        <div className="w-screen mx-auto my-auto max-w-screen-2xl">
          <div className="flex flex-col justify-between ">
            <div className="flex flex-col justify-start ">
              <Header />
            </div>
            <div className="h-full mb-2 overflow-hidden min-h-svh">
              <CatContextProvider>
                <JobContextProvider>
                  <AddJobContextProvider>
                    {loginStatus === "succeeded" && role === "Admin" && (
                      <CompContextProvider>
                        <UserContextProvider>
                          <CandidacyContextProvider>
                            <RootRoutes />
                          </CandidacyContextProvider>
                        </UserContextProvider>
                      </CompContextProvider>
                    )}
                    {loginStatus === "succeeded" && role === "Manager" && (
                      <CompContextProvider>
                        <CandidacyContextProvider>
                          <RootRoutes />
                        </CandidacyContextProvider>
                      </CompContextProvider>
                    )}
                    {loginStatus === "succeeded" && role === "Client" && (
                      <CvContextProvider>
                        <RootRoutes />
                      </CvContextProvider>
                    )}
                    {role !== "Admin" &&
                      role !== "Manager" &&
                      role !== "Client" && <RootRoutes />}
                  </AddJobContextProvider>
                </JobContextProvider>
              </CatContextProvider>
            </div>
            <div className="flex flex-col justify-end shadow-inner">
              <Footer />
            </div>
          </div>
        </div>
      </Router>
    </QueryClientProviders>
  );
}

export default App;
