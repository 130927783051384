import React, { useEffect, useState } from "react";
import TextFieldUI from "../UI/TextFieldUI";
import TextAreaUI from "../UI/TextAreaUI";
import {
  isEmailValid,
  isPhoneValid,
  isTextValid,
} from "../Utils/ValidFormElement";
import ButtonStudentUI from "./UI/ButtonStudentUI";
import PhoneNumberUI from "../UI/PhoneNumberUI";
import { useTranslation, Trans } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { postStudentContact } from "../../services/HttpRequest/User/contactStudentRequest";
import PendingModal from "../QuickApply_Cpt/UI/PendingModal";
import ModalInfos from "../UI/RequestUI/ModalInfos";
import { FaCheck, FaExclamationCircle } from "react-icons/fa";

const SectionTwo = () => {
  const { t } = useTranslation();
  const [studentData, setStudentData] = useState({
    firstName: { value: "", didEdit: false },
    lastName: { value: "", didEdit: false },
    email: { value: "", didEdit: false },
    phoneNumber: { value: "", didEdit: false },
    message: { value: "", didEdit: false },
  });
  const [isModalStatus, setIsModalStatus] = useState(false);
  const handleChangeStudentField = (id, value) => {
    setStudentData((prevData) => ({
      ...prevData,
      [id]: { value, didEdit: true },
    }));
  };
  const handleBlurStudentField = (id) => {
    setStudentData((prevData) => ({
      ...prevData,
      [id]: { value: prevData[id].value, didEdit: true },
    }));
  };
  //

  const { status, mutate, data, error } = useMutation({
    mutationFn: postStudentContact,
  });

  //
  // console.log("error", error);
  // validData

  const isEmailNotOk =
    studentData.email.didEdit && !isEmailValid(studentData.email.value);
  const isPhoneNumberNotOk =
    studentData.phoneNumber.didEdit &&
    !isPhoneValid(studentData.phoneNumber.value);
  const isFirstNotNameOk =
    studentData.firstName.didEdit && !isTextValid(studentData.firstName.value);
  const isLastNameNotOk =
    studentData.lastName.didEdit && !isTextValid(studentData.lastName.value);
  const isMessageNotOk =
    studentData.message.didEdit && !isTextValid(studentData.message.value);
  const isContactDataOk =
    isEmailValid(studentData.email.value) &&
    isTextValid(studentData.message.value) &&
    isTextValid(studentData.firstName.value) &&
    isTextValid(studentData.lastName.value) &&
    isPhoneValid(studentData.phoneNumber.value);
  //submit
  const handleSubmit = (event) => {
    event.preventDefault();
    for (let field in studentData) {
      handleBlurStudentField(field);
    }
    //data to send
    const dataToSend = {
      Email: studentData.email.value,
      FirstName: studentData.firstName.value,
      LastName: studentData.lastName.value,
      Message: studentData.message.value,
      PhoneNumber: studentData.phoneNumber.value,
    };
    if (isContactDataOk) {
      mutate({ data: dataToSend });
      // console.log(dataToSend);
    }
    setIsModalStatus(true);
  };

  useEffect(() => {
    if (status === "success") {
      setStudentData({
        firstName: { value: "", didEdit: false },
        lastName: { value: "", didEdit: false },
        email: { value: "", didEdit: false },
        phoneNumber: { value: "", didEdit: false },
        message: { value: "", didEdit: false },
      });
    }
  }, [status]);

  return (
    <section className="my-16 flex flex-col items-center w-full ">
      <PendingModal isOpen={status === "pending"} autoclose={() => {}} />
      <ModalInfos
        isOpen={status === "error" && isModalStatus}
        message={t("studentForm.modal_error")}
        icon={<FaExclamationCircle className="size-7 text-red-500" />}
        autoClose={() => {
          setIsModalStatus(false);
        }}
      />
      <ModalInfos
        isOpen={status === "success" && isModalStatus}
        message={t("studentForm.modal_success")}
        icon={<FaCheck className="size-7 text-green-500" />}
        autoClose={() => {
          setIsModalStatus(false);
        }}
      />
      <div className="flex  justify-center flex-col items-center">
        <h1 className="font-bold w-full text-center text-2xl md:text-4xl ">
          {t("studentForm.titleform")}
        </h1>
        <p className="font-[450]">{t("studentForm.titleform_msg")}</p>
      </div>

      <form
        className="w-full lg:max-w-[1000px] max-w-[500px] px-5 lg:px-10  border py-9 lg:py-12 rounded-xl   mt-14 "
        onSubmit={handleSubmit}
      >
        <div className="md:flex w-full">
          <div className="w-full">
            {" "}
            <TextFieldUI
              id={"firstName"}
              label={t("studentForm.name_field_label")}
              placeholderValue={t("studentForm.name_field_value")}
              value={studentData.firstName.value}
              onBlur={() => handleBlurStudentField("firstName")}
              onChange={(event) =>
                handleChangeStudentField("firstName", event.target.value)
              }
            />
            {isFirstNotNameOk && (
              <h3 className="ml-3 -mt-2 mb-2 text-red-500">
                {t("studentForm.name_field_msg")}
              </h3>
            )}{" "}
          </div>
          <div className="w-full">
            {" "}
            <TextFieldUI
              id={"lastName"}
              label={t("studentForm.surname_field_label")}
              placeholderValue={t("studentForm.surname_field_value")}
              value={studentData.lastName.value}
              onBlur={() => handleBlurStudentField("lastName")}
              onChange={(event) =>
                handleChangeStudentField("lastName", event.target.value)
              }
            />
            {isLastNameNotOk && (
              <h3 className="ml-3 -mt-2 mb-2 text-red-500">
                {t("studentForm.surname_field_msg")}
              </h3>
            )}
          </div>
        </div>
        <div className="md:flex   w-full">
          <div className="w-full mt-[-2px] ">
            {" "}
            <PhoneNumberUI
              id={"phoneNumber"}
              containerStyles={{ marginBottom: "-4px" }}
              defaultCountry={"ca"}
              label={t("studentForm.phonenumber_field_label")}
              value={studentData.phoneNumber.value}
              onBlur={() => handleBlurStudentField("phoneNumber")}
              onChange={(phone) =>
                handleChangeStudentField("phoneNumber", phone)
              }
            />
            {isPhoneNumberNotOk && (
              <h3 className="ml-3 mb-2 -mt-2 text-red-500">
                {t("studentForm.phonenumber_field_msg")}
              </h3>
            )}
          </div>
          <div className="w-full">
            <TextFieldUI
              id={"email"}
              label={t("studentForm.email_field_label")}
              placeholderValue={t("studentForm.email_field_value")}
              value={studentData.email.value}
              onBlur={() => handleBlurStudentField("email")}
              onChange={(event) =>
                handleChangeStudentField("email", event.target.value)
              }
            />
            {isEmailNotOk && (
              <h3 className="ml-3 mb-2 text-red-500">
                {t("studentForm.email_field_msg")}
              </h3>
            )}
          </div>
        </div>
        <div className="md:flex space-b-2  w-full">
          <div className="w-full">
            <TextAreaUI
              classname={"h-32"}
              id={"message"}
              label={t("studentForm.need_field_label")}
              placeholderValue={t("studentForm.need_field_value")}
              value={studentData.message.value}
              onBlur={() => handleBlurStudentField("message")}
              onChange={(event) =>
                handleChangeStudentField("message", event.target.value)
              }
            />
            {isMessageNotOk && (
              <h3 className="ml-3  mb-2 text-red-500">
                {t("studentForm.need_field_msg")}
              </h3>
            )}
          </div>
        </div>
        <div className="flex w-full mt-2 justify-center">
          <ButtonStudentUI type={"submit"} classname={" lg:text-xl"}>
            {t("studentForm.submit_field_label")}
          </ButtonStudentUI>
        </div>
      </form>
    </section>
  );
};

export default SectionTwo;
