import React, { useState } from "react";
import TextFieldUI from "../UI/TextFieldUI";
import { isEmailValid } from "../Utils/ValidFormElement";
import ButtonStudentUI from "./UI/ButtonStudentUI";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { newsletterPost } from "../../services/HttpRequest/User/contactStudentRequest";
import PendingModal from "../QuickApply_Cpt/UI/PendingModal";
import ModalInfos from "../UI/RequestUI/ModalInfos";
import { FaCheck, FaExclamationCircle } from "react-icons/fa";

const SectionTree = () => {
  const { t } = useTranslation();
  const [newsLetterData, setNewsLetterData] = useState({
    value: "",
    didEdit: false,
  });

  const handleChangeNewsLetterData = (value) => {
    setNewsLetterData((prevData) => ({
      value: value,
      didEdit: true,
    }));
  };
  const handleBlurNewsLetterData = () => {
    setNewsLetterData((prevData) => ({
      value: prevData.value,
      didEdit: true,
    }));
  };
  const [isModalStatus, setIsModalStatus] = useState(false);

  const { status, mutate } = useMutation({
    mutationFn: newsletterPost,
  });

  const isEmailNotOk =
    newsLetterData.didEdit && !isEmailValid(newsLetterData.value);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isEmailNotOk) {
      // console.log("je suis le emaiol", newsLetterData.value)
      mutate({email:newsLetterData.value})
      
    }
    setIsModalStatus(true);
  };

  return (
    <section className="flex p-3 justify-center w-full ">
      <PendingModal isOpen={status === "pending"} autoclose={() => {}} />
      <ModalInfos
        isOpen={status === "error" && isModalStatus}
        message={
          t("studentForm.modal_error")        }
        icon={<FaExclamationCircle className="size-7 text-red-500" />}
        autoClose={() => {
          setIsModalStatus(false);
        }}
      />
      <ModalInfos
        isOpen={status === "success" && isModalStatus}
        message={ t("studentForm.modal_success") }
        icon={<FaCheck className="size-7 text-green-500" />}
        autoClose={() => {
          setIsModalStatus(false);
        }}
      />
      <div className="bg-[#F9FAFB] my-10 p-10 rounded-xl shadow w-[84%]">
        <div className="flex flex-col md:flex-row md:flex-wrap xl:flex-nowrap">
          <div className="w-full md:w-1/2 mb-6 md:mb-0">
            <div className="text-xl lg:text-2xl font-semibold">
              {t("newsletter.title")}
            </div>
            <div className="text-lg text-[#667085]">{t("newsletter.text")}</div>
          </div>
          <div className="w-full md:w-1/2">
          <form
            className="flex lg:pt-4 flex-col items-center justify-center lg:flex-row"
            onSubmit={handleSubmit}
          >
            <div className="w-full">
              <TextFieldUI
                classname={"max-lg:-mb-2"}
                label={""}
                id={"email"}
                onChange={(event) =>
                  handleChangeNewsLetterData(event.target.value)
                }
                onBlur={handleBlurNewsLetterData}
                placeholderValue={t("newsletter.email_field_value")}
              />

                <h3 className="w-full h-4 text-center mb-3 text-red-500">
                  {isEmailNotOk && t("newsletter.email_field_msg")}
                </h3>
              </div>

              <div className=" ">
                <ButtonStudentUI type="submit" classname={"mb-5"}>
                  {t("newsletter.submit_field_label")}
                </ButtonStudentUI>
              </div>
            </form>
          </div>
        </div>
        <div className="text-[#667085] text-sm lg:text-lg mt-1">
          {t("newsletter.policy_field_msg")}
          <Link className="underline" to={"/privatePolicy"}>
            {" "}
            {t("newsletter.policy_field_msg2")}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default SectionTree;
