import { motion } from "framer-motion";
import { Loader } from "react-feather";
import { useTranslation, Trans } from "react-i18next";

const PendingUI = ({ classname, containerClass, lableClass, children }) => {
  const { t } = useTranslation();
  return (
    <>
      <motion.div
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: [-10, 0], opacity: 1 }}
        className={`justify-center flex flex-col  mt mx-auto items-center text-center w-full ${containerClass}`}
      >
        <motion.div className="size-24 rounded-full ">
          {" "}
          <Loader
            className={`text-[#5190B7] w-full animate-spin size-8 lg:size-12 ${classname} `}
          />
        </motion.div>

        <h1
          className={`mb-20 font-mono text-sm lg:text-lg text-gray-900  ${lableClass}`}
        >
          {children ? children : t("pending.title")}
        </h1>
      </motion.div>
    </>
  );
};

export default PendingUI;
