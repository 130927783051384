import {} from "@heroicons/react/24/outline";
import {
  Card,
  CardHeader,
  Typography,
  Button,
  CardBody,
  CardFooter,
  Avatar,
  IconButton,
  Tooltip,
  Input,
} from "@material-tailwind/react";
import {
  FaArchive,
  FaExclamationCircle,
  FaExclamationTriangle,
  FaEye,
  FaStar,
  FaTrash,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import MenuListCandidacy from "./MenuListCandidacy";
import MenuUI from "../UI/MenuUI";
import { candidacyActions } from "../../store/slice/candidacySlice";
import { useContext, useEffect, useState } from "react";
import DeleteModal from "./DeleteModal";
import usePagination from "../Hooks/usePagination";
import { selectCurrentUser } from "../../store/slice/loginSlice";
import PendingUI from "../UI/RequestUI/PendingUI";
import LoadDataFailUI from "../UI/RequestUI/LoadDataFailUI";
import proueLogo from "../../assets/logo.png";
import PendingModal from "../QuickApply_Cpt/UI/PendingModal";
import toastUtils from "../Utils/toastUtils";
import { useMutation } from "@tanstack/react-query";
import { deleteApplication } from "../../services/HttpRequest/clientRequest/deleteApplication";
import { queryClient } from "../../services/HttpRequest/queryClientProvider/queryClientProvider";
import { ToastContainer } from "react-toastify";
import EmptyData from "../UI/RequestUI/EmptyData";
import CandidacyContext from "../../context/CandidacyContext/CandidacyContext";
import FormTextUI from "../UI/FormTextUI";
import Pagination from "../Pagination/Pagination";
import candidacyData from "./CandidacyData";
import TextFieldUI from "../UI/TextFieldUI";
import useViewPdf from "../Hooks/useViewPdf";
import ModalInfos from "../UI/RequestUI/ModalInfos";
import Description from "../Admin_Cpt/ManageBord/JobManage/Description/Description";
import { useTranslation, Trans } from "react-i18next";

// "Lieu",
// "Date",
// "Favoris",
// "status",

const DropCandidacy = [
  // {
  //   dropText: "Suprimer des Favoris",
  //   id: "c1",
  //   icon: <FaStackOverflow size={20} className="mr-2 text-orange-500" />,
  // },
  {
    dropText: "Ajouter au Archiver",
    id: "c2",
    icon: <FaArchive size={20} className="text-[#5190B7] mr-2" />,
  },
];

const filterData = [
  { id: 0, name: "Tous" },
  // { id: 1, name: "Favoris" },
  // { id: 2, name: "Archiver" },
  // { id: 3, name: "Recruté" },
  // { id: 4, name: "Offre Reçue" },
  // { id: 5, name: "Non retourné par l'employeur" },
];

export function UserApplication() {
  const { t } = useTranslation();
  const TABLE_HEAD = [
    t("table_header.company"),
    t("table_header.job"),
    t("table_header.Contract_Type"),
    t("table_header.resume"),
    "",
  ];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pageData, setPageData] = useState([]);
  const dispatch = useDispatch();
  const applicationData = useSelector((state) => state.candidacy.filteredData);
  const allJobs = useSelector((state) => state.jobs.saveAllobs);
  const getDataStatus = useSelector((state) => state.candidacy.status);
  const { status: viewDocStatus, handleViewDoc } = useViewPdf();
  const [isModalDoc, setIsModalDoc] = useState(false);

  const [isModalJob, setIsModalJob] = useState(false);
  const [jobFormModal, setJobForModal] = useState({});
  //

  const { onFilter } = useContext(CandidacyContext);
  // pagination

  const tableData = applicationData ? applicationData : [];

  const { currentPage, totalPages, paginatedData, handlePageChange } =
    usePagination(tableData, 10);
  useEffect(() => {
    if (paginatedData) {
      setPageData(paginatedData);
    }
  }, [paginatedData]);
  const onChangePage = (action) => {
    if (action === "next") {
      handlePageChange(currentPage + 1);
    }
    if (action === "prev") {
      handlePageChange(currentPage - 1);
    }
  };

  const handleFilter = (items) => {
    dispatch(candidacyActions.filterByCategorie({ categorie: items }));
    onFilter(items);
    handlePageChange(1);
  };
  // handle search

  const handleSearch = (event) => {
    const searchText = event.target.value;
    dispatch(candidacyActions.searchCandidacy({ searchText }));
  };

  // handle Remove
  const { status, mutate } = useMutation({
    mutationFn: deleteApplication,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getMyCandidacy"] });
    },
  });
  const handleDelete = (candidacy) => {
    // console.log('candidacy', candidacy)
    // dispatch(candidacyActions.removeCandidacy({ candidacy }));
    mutate({ id: candidacy.id });
    handleToogleModal(false);
  };

  useEffect(() => {
    if (status === "success") {
      toastUtils(status, "Terminer...!");
    }
    if (status === "error") {
      toastUtils(status, "Echec de la suppression...!");
    }
  }, [status]);

  // handleToogleFavorite
  // const handleToogleFavorite = (candidacy, action) => {
  //   if (action === "add") {
  //     dispatch(candidacyActions.moveToFavoris({ candidacy }));
  //   }
  //   if (action === "remove") {
  //     dispatch(candidacyActions.moveToMessage({ candidacy }));
  //   }
  // };
  const [deleteName, setDeleteName] = useState("");
  // toogle modal
  const handleToogleModal = (value, name) => {
    setIsModalOpen(value);
    if (name) {
      setDeleteName(name);
    }
  };
  // UI update

  //  console.log('pageData', pageData)
  const onDownloadCv = async (id) => {
    // console.log('data', data)
    if (id !== "N/A") {
      await handleViewDoc(id);
    } else {
    }
    setIsModalDoc(true);
  };

  const handleJobFormModal = (jobId) => {
    setJobForModal(allJobs.find((job) => job.id === jobId));
    setIsModalJob(true);
  };

  let content = <></>;

  switch (getDataStatus) {
    case "pending":
      content = (
        <div className="px-4">
          <PendingUI
            classname={"size-8 mt-8 mb-6 lg:size-12"}
            containerClass={"lg:ml-[15%]"}
            lableClass={"mb-8 "}
          />
        </div>
      );
      break;
    case "success":
      content =
        pageData.length === 0 ? (
          <div className=" ml-[65%] my-2 ">
            <EmptyData> {t("application.empty_data")}</EmptyData>
          </div>
        ) : (
          <tbody>
            {pageData.map((candidacy, index) => {
              const isLast = index === applicationData?.length - 1;
              const classes = isLast
                ? "p-1 border-b border-blue-gray-50  max-w-32"
                : "p-1 border-b border-blue-gray-50  max-w-32";
              // let candidacyState = <div></div>;
              // save note
              return (
                <tr
                  key={candidacy.id}
                  className="cursor-pointer   hover:bg-slate-200 duration-150 transition-all"
                >
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <Avatar
                        src={proueLogo}
                        alt={"Proue"}
                        size="md"
                        className="border border-blue-gray-50 bg-blue-gray-50/50 object-contain p-1"
                      />
                      {/* <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-bold"
                    >
                      {candidacy.entreprise}
                    </Typography> */}
                    </div>
                  </td>
                  <td
                    className={classes}
                    onClick={() => handleJobFormModal(candidacy.jobId)}
                  >
                    <div
                      variant="small"
                      color="blue-gray"
                      className="font-normal text-sm  lg:text-lg hover:text-[#457AA8] "
                    >
                      {candidacy.jobName}
                    </div>
                  </td>
                  <td className={classes}>
                    <div
                      variant="small "
                      color="blue-gray"
                      className="font-normal text-sm lg:text-lg   "
                    >
                      {candidacy.jobStatus}
                    </div>
                  </td>
                  <td className={classes}>
                    <button
                      className=" flex  items-center   text-blue-500 hover:text-blue-700 mt-2 py-1  rounded-full"
                      onClick={() => onDownloadCv(candidacy.docId)}
                    >
                      <FaEye className="size-4 mr-2" />
                      <span className="hidden  md:inline">
                        {" "}
                        {t("application.see_resume")}
                      </span>
                    </button>
                  </td>
                  {/* <td className={classes}>
              <Typography
                variant="small"
                color="blue-gray"
                className="font-normal"
              >
                {candidacy.localisation}
              </Typography>
            </td> */}
                  {/* <td className={classes}>
              <div className="w-max">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal"
                >
                  {candidacy.date}
                </Typography>
              </div>
            </td> */}

                  {/* <td className={`${classes}`}>
              <Tooltip>{candidacyState}</Tooltip>
            </td> */}
                  <td className={`${classes} `}>
                    <div className="flex    justify-center  cursor-pointer">
                      {/* <Tooltip content="Supprimer"> */}
                      <FaTrash
                        onClick={() => handleToogleModal(true, candidacy)}
                        className=" h-4 w-4 mt-1  md:h-5  hover:text-red-500 "
                      />
                      {/* </Tooltip> */}
                      {/* <MenuListCandidacy
                        key={candidacy.idcandidacy}
                        dataCandidacy={DropCandidacy}
                        candidacy={candidacy}
                        situation={candidacy.situation}
                      /> */}
                    </div>
                  </td>
                </tr>
              );
            })}
            <Description
              handleClose={() => setIsModalJob(false)}
              openValue={isModalJob}
              job={jobFormModal}
            />
          </tbody>
        );
      break;
    case "error":
      content = (
        <LoadDataFailUI
          labelClassname={" "}
          containerClassname={"max-lg:w-64 max-lg:ml-[16%]"}
        />
      );
      break;

    default:
      break;
  }
  return (
    <main className="h-full min-h-svh">
      <div className="rounded-none bg-[#F9FAFB]">
        <ToastContainer />
        <PendingModal isOpen={status === "pending"} autoclose={() => {}} />
        <div className="mb-4 flex max-w-[1400px] mx-auto flex-col justify-between gap-8 md:flex-row md:items-center">
          <div>
            <div className="text-gray-800 font-bold my-8 text-xl lg:text-2xl">
              {t("application.application_title")}
            </div>
            <FormTextUI> </FormTextUI>
            <div
              color="gray"
              as={"div"}
              className="mt-1 hover:cursor-pointer h-auto font-normal"
            >
              {/* <MenuUI onSelect={handleFilter} items={filterData} /> */}
            </div>
          </div>
          <div className="flex w-full shrink-0 gap-2 md:w-max">
            <div className="w-full md:w-72 flex">
              <TextFieldUI
                placeholder={t("application.search")}
                onChange={handleSearch}
                classname=" rounded-xl "
              />
            </div>
            {/* <Button  color="blue" className="flex items-center bg_[#5190B7] gap-3" size="sm">
              <ArrowDownTrayIcon strokeWidth={2} className="h-4 w-4" /> Download
            </Button> */}
          </div>
        </div>
      </div>
      <Card className="h-full max-w-[1400px] mx-auto flex justify-center ">
        <CardBody className="overflow-x-scroll  p-0">
          {/* ddddddddddddddddddddddddddddddddddddddddd */}
          {
            <table className="w-full min-w-max  text-left">
              <thead>
                <tr>
                  {TABLE_HEAD.map((head) => (
                    <th
                      key={head}
                      className="border-y bg-[#457AA8] shadow-md text-white px-1 border-blue-gray-100 bg-blue-gray-50/50 py-4"
                    >
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none opacity-95"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>

              {content}
            </table>
          }
        </CardBody>
        <CardFooter className="flex items-center  justify-between border-t border-blue-gray-50 p-4">
          <Button
            variant="outlined"
            onClick={() => onChangePage("prev")}
            size="sm"
            disabled={currentPage === 1}
            className="lowercase font-normal first-letter:uppercase "
          >
            {t("table_navigation.previous")}
          </Button>
          <div className="flex items-center gap-2">
            {/* <IconButton variant="text" className="lowercase first-letter:uppercase" size="sm">
            Page
          </IconButton> */}
            <IconButton variant="outlined" size="sm">
              {currentPage && currentPage}
            </IconButton>
            <IconButton
              variant="text"
              className="lowercase font-normal first-letter:uppercase "
              size="sm"
            >
              {t("application.of")}
            </IconButton>
            <IconButton variant="text" size="sm">
              {totalPages > 0 ? totalPages : 1}
            </IconButton>
          </div>
          <Button
            variant="outlined"
            onClick={() => onChangePage("next")}
            size="sm"
            className="lowercase font-normal first-letter:uppercase"
            disabled={totalPages === currentPage}
          >
            {t("table_navigation.next")}
          </Button>
        </CardFooter>
        {/* <Pagination
          currentPage={currentPage}
          totalPages={totalPages === 0 ? 1 : totalPages}
          onPageChange={handlePageChange}
        /> */}
        <DeleteModal
          candidacy={deleteName}
          handleDelete={handleDelete}
          isModalOpen={isModalOpen}
          handleToogleModal={handleToogleModal}
        />
      </Card>

      <PendingModal isOpen={false} autoclose={() => {}} />

      <PendingModal autoclose={() => {}} isOpen={viewDocStatus === "pending"} />
      <ModalInfos
        autoClose={() => setIsModalDoc(false)}
        icon={<FaExclamationTriangle className=" size-9 text-[#c77851]" />}
        isOpen={isModalDoc && viewDocStatus === "error"}
        message={
          t("application.view_cv_error")

        }
      />
      <ModalInfos
        autoClose={() => setIsModalDoc(false)}
        // isOpen={true}
        icon={<FaExclamationCircle className=" size-9 text-[#457AA8]" />}
        isOpen={isModalDoc && viewDocStatus === ""}
        message={t("application.view_cv_empty")}
      />
    </main>
  );
}
export default UserApplication;

//                   switch (candidacy.etat) {
//   case "candidature consultée":
//     candidacyState = (
//       <p className="mt-2">
//         {" "}
//         <span
//           className={` max-lg:hidden p-2 text-green-500 font-[500] bg-green-200/80 rounded-3xl   `}
//         >
//           {" "}
//           {candidacy.etat}
//         </span>
//         <span className="p-2 text-green-500 rounded-full bg-green-200/80 lg:hidden ">
//           {" "}
//           NR{" "}
//         </span>{" "}
//       </p>
//     );

//     break;
//   case "offre reçue":
//     candidacyState = (
//       <p className="mt-2">
//         {" "}
//         <span
//           className={` max-lg:hidden p-2 text-green-500 font-[500] bg-green-200/80 rounded-3xl    `}
//         >
//           {" "}
//           {candidacy.etat}
//         </span>
//         <span className="p-2 text-green-500 rounded-full bg-green-200/80 lg:hidden ">
//           {" "}
//           OR{" "}
//         </span>{" "}
//       </p>
//     );

//     break;
//   case "non retourné par l'employeur":
//     candidacyState = (
//       <p className="mt-2">
//         <span
//           className={` max-lg:hidden p-2 text-red-500 font-[500] bg-red-200/80 rounded-3xl    `}
//         >
//           {candidacy.etat}
//         </span>
//         <span className="p-2 text-red-500 rounded-full bg-red-200/80 lg:hidden ">
//           NR
//         </span>
//       </p>
//     );

//     break;
//   case "recruté":
//     candidacyState = (
//       <p className="mt-2">
//         <span
//           className={` max-lg:hidden p-2 bg-green-500 font-[500] text-white/80 rounded-3xl    `}
//         >
//           {" "}
//           {candidacy.etat}
//         </span>
//         <span className="p-2 bg-green-500 rounded-full text-green-200/80 lg:hidden ">
//           {" "}
//           RC{" "}
//         </span>{" "}
//       </p>
//     );

//     break;
//   case "candidature envoyé":
//     candidacyState = (
//       <p className="mt-2">
//         {" "}
//         <span
//           className={` max-lg:hidden p-2 text-blue-500 font-[500] bg-blue-200/80 rounded-3xl    `}
//         >
//           {candidacy.etat}
//         </span>
//         <span className="p-2 text-green-500 rounded-full bg-green-200/80 lg:hidden ">
//           {" "}
//           CE{" "}
//         </span>{" "}
//       </p>
//     );

//     break;
//   default:
//     break;
// }
