import { Stack } from "@mui/material";

const TextFieldUI = ({
  label,
  id,
  isRequired,
  classname,
  placeholderValue,
  readOnly,
  ...props
}) => {
  const classe = ` text-sm p-2 border-gray-300 hover:border-blue-500 focus:border-gray-300 focus:ring  mt-1 p-1 border rounded-md w-full focus:outline-none ${classname} `;

  return (
    <Stack className="space-x-2   lg:my-1  lg:text-2xl text-xl xl:mb-2 md:mb-1 w-full  ">
      <div className="mx-2">
        <label
          htmlFor={id}
          className="mb-1 block text-sm font-medium text-gray-700"
        >
          {label}
          {isRequired ? (
            <span className="text-red-600 ">*</span>
          ) : (
            <span className=""></span>
          )}
        </label>
        <input
          type="text"
          label={placeholderValue}
          id={id}
          name={id}
          placeholder={placeholderValue}
          className={classe}
          {...props}
          readOnly={readOnly}
        />
      </div>
    </Stack>

    // <div className="grid ">
    //     <label htmlFor={id} className="">
    //         {label}

    //     </label>

    //     <input name={id} id={id} className="border w-36 border-gray-400  p-2 rounded-md focus:h-[3.2rem] h-[3rem] text-xl focus:outline-none focus:border-blue-500" {...props}/>
    // </div>
  );
};

export default TextFieldUI;
