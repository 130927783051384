import React, { createContext, useEffect, useState, useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { getAllApplication } from "../../services/HttpRequest/adminRequest/AppliRequest";
import { useSelector } from "react-redux";
import UserContext from "../AdminContext/UserContext";

const AppliContext = createContext({
  appliForJob: [],
  filterJob: [],
  activeAppli: {},
  statusAppli: "",
  error: {},
  handleAddActiveAppli: (activeAppli) => {},
  numberOfAppli: 0,
});

const handleActiveApplication = () => {
  const getActiveAppli = localStorage.getItem("activeAppli");
  const appli = JSON.parse(getActiveAppli);

  if (appli) {
    return appli;
  }
  return {};
};

export const CandidacyContextProvider = ({ children }) => {
  const [activeAppli, setActiveAppli] = useState({});
  const [allAppli, setAllAppli] = useState([]);
  const [filterJob, setFilterJob] = useState([]);
  const [appliForJob, setAppliForJob] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [numberOfAppli, setNumberOfAppli] = useState(0);

  // const {
  //   usersData,
  //   statusUser,
  //   numberOfUsers,
  // } = useContext(UserContext);

  const { data, status, error } = useQuery({
    queryKey: ["getAllApplication"],
    queryFn: getAllApplication,
  });
  const job = useSelector((state) => state.jobsAdmin.allJobs);
  const statusJob = useSelector((state) => state.jobsAdmin.statusJob);

  useEffect(() => {
    if (status === "success") {
      // console.log("les données retournées:", data?.data.length);
      const myData = data?.data;
      setNumberOfAppli(myData.total_applications);
      //  console.log("les données retournées:", myData);
      // total_applications
      const allJobIds = myData.data.map((application) => application.jobId);

      if (statusJob === "success") {
        if (job) {
          const filteredJobs = job.filter((job) => allJobIds.includes(job.id));
          // console.log("les filteredJobs retournées:", filteredJobs);
          setFilterJob(filteredJobs);
        }
      }

      setAllAppli(data.data);
    }
    setActiveAppli(handleActiveApplication());
  }, [data]);

  useEffect(() => {
    if (activeAppli) {
      // console.log("je suis la ligne active dans le contexte", activeAppli);
      // console.log("je suis  allAppli dans le contexte", allAppli);

      const applications =
        allAppli && allAppli.data
          ? allAppli.data.filter(
              (application) => application.jobId === activeAppli.id
            )
          : [];

      if (applications) {
        setAppliForJob(applications);
        // console.log("je suis l'application", applications);

        // if(statusUser === "success") {
        // console.log("les données du users", usersData);
        // const usersIds = applications.map(item => item.userId);
        //     const filtered = usersData.filter(user => usersIds.includes(user.id));
        // setFilteredUsers(filtered);

        // console.log("je suis le user", filtered);
        // }
      } else {
        console.log("Aucune application trouvée pour l'id de activeJob.");
      }
    }
  }, [activeAppli, allAppli]);

  const handleAddActiveAppli = (activeApp) => {
    localStorage.setItem("activeAppli", JSON.stringify(activeApp));
    setActiveAppli(handleActiveApplication());
  };

  // const numberOfAppli =  appliForJob.length;
  // console.log("les numberOfAppli:", numberOfAppli);
  const values = {
    error,
    statusAppli: status,
    appliForJob,
    filterJob,
    activeAppli,
    handleAddActiveAppli,
    numberOfAppli: numberOfAppli,
  };

  return (
    <AppliContext.Provider value={values}>{children}</AppliContext.Provider>
  );
};
export default AppliContext;
