import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  saveAllobs: [],
  allJobs: [],
  //allJobs is the data to use
  filteredJobs: [],
  activeJob: {},
  statusJob: "",
  error: {},
  searchText: "",
};

const jobsSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    // -------------------get all job------------------
    fetchAllJobs(state, action) {
      const status = action.payload.status;
      state.statusJob = status;
      if (status === "success") {
        state.saveAllobs = action.payload.data.data;
        state.allJobs = action.payload.data.data;
        state.filteredJobs = action.payload.data.data;
        if (state.filteredJobs.length === 0) {
          const getActiveJob = localStorage.getItem("activeJob");
          if (getActiveJob) {
            state.activeJob = JSON.parse(getActiveJob);
          }
        } else {
          state.allJobs = state.filteredJobs;
        }
      } else {
        state.error = action.payload.error;
      }
    },

    // ----------------------search Job----------------------------------

    searchJobs(state, action) {
      const searchText = action.payload.searchText;
      state.searchText = searchText;
      if (searchText && searchText !== "") {
        state.allJobs = state.filteredJobs.filter((job) =>
          job?.jobName.toLowerCase().includes(searchText.toLowerCase())
        );
      } else {
        state.allJobs = state.filteredJobs;
      }
    },
    // ---------------------filter job By cat Id-----------------------------
    filterJobsByCatId(state, action) {
      const catId = action.payload.categoryId;
      if (catId) {
        const filteredData = state.saveAllobs.filter(
          (job) => job.categoryId === action.payload.categoryId
        );
        state.allJobs = filteredData;
        state.filteredJobs = filteredData;
      } else {
        state.allJobs = state.saveAllobs;
        state.filteredJobs = state.saveAllobs;
      }
      const searchText = state.searchText;
      if (searchText && searchText !== "") {
        state.allJobs = state.filteredJobs.filter((job) =>
          job?.jobName.toLowerCase().includes(searchText.toLowerCase())
        );
      }
    },
    // -------------------------------------------------------------------

    // getOnJobByCatId(state,action){
    //     state.saveAllobs.find(job=>job.categoryId = action.payload.categoryId)
    // }

    // ---------------------------set active job---------------------------------------

    setActiveJob(state, action) {
      localStorage.setItem("activeJob", JSON.stringify(action.payload.job));
      state.activeJob = action.payload.job;
    },
  },
});

export const jobsSliceAction = jobsSlice.actions;
export default jobsSlice.reducer;
