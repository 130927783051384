import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FaFilePdf, FaTrash } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation, Trans } from "react-i18next";

function useDragDrop() {
  const { t } = useTranslation();
  const [formFile, setFormFile] = useState([]);

  const removeFile = () => {
    setFormFile([]);
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (
      acceptedFiles[0].type === "application/pdf" &&
      acceptedFiles.length === 1
    ) {
      setFormFile(acceptedFiles);
    } else {
      // console.log("acceptedFiles[0].type", acceptedFiles[0].type);
      if (acceptedFiles.length > 1) {
        toast.warn(t("quickApply.applyform_doc_field_error"), {
          position: "bottom-right",
          autoClose: 3000,
          bodyClassName: "font-bold",
          className: "w-[100%] ",
          toastId: "toastUploadFail",
        });
      }
      if (acceptedFiles[0].type !== "application/pdf") {
        toast.error(t("quickApply.applyform_doc_field_error2"), {
          position: "bottom-right",
          autoClose: 3000,
          bodyClassName: "font-bold",
          className: "w-[100%] ",
          toastId: "toastUploadFail2",
        });
      }
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  // console.log('isDragAccept', isDragAccept)

  const content = (
    <div className="flex flex-col items-center justify-center">
      <ToastContainer />
      <div className="flex flex-col items-center justify-center  ">
        <div className="mb-4 text-center">
          {/* <p
            className={`text-${
              isDragActive ? "green-800" : "gray-800"
            } text-2xl font-bold `}
          >
          </p> */}
          <p
            className={`text-${
              isDragActive ? "green-600 text-center" : "gray-600 text-center"
            } text-sm`}
          >
            {t("quickApply.applyform_doc_field_info")}
          </p>
          {/* {errorMessage && (
              <p className="mt-2 text-sm text-red-500">{errorMessage}</p>
            )} */}
        </div>

        <div
          {...getRootProps()}
          className={`w-full p-8 border-dashed border-2 ${
            isDragActive ? "border-green-500" : "border-gray-300"
          } text-center hover:bg-green-100 transition-colors duration-300 cursor-pointer`}
        >
          <input {...getInputProps()} id="cvFile" accept="application/pdf" />
          <div className="flex flex-col items-center">
            <FaFilePdf
              size="3em"
              className={`text-${
                isDragActive || formFile[0] ? "green-500" : "gray-600"
              } mb-2`}
            />
            <p
              className={`text-${
                isDragActive || formFile.length > 0 ? "green-800" : "gray-800"
              } text-2xl font-bold mb-2`}
            >
              {formFile.length > 0
                ? t("quickApply.applyform_doc_field_input_title")
                : t("quickApply.applyform_doc_field_input_title2")}
            </p>
            <p
              className={`text-${
                isDragActive || formFile.length > 0 ? "green-600" : "gray-600"
              } text-sm`}
            >
              {formFile.length > 0
                ? t("quickApply.applyform_doc_field_input_msg")
                : t("quickApply.applyform_doc_field_input_msg2")}
            </p>
          </div>
        </div>

        {formFile.map((file, index) => (
          <div
            key={index}
            className="flex items-center justify-between w-full px-4 py-2 mb-2 bg-gray-100 rounded"
          >
            <div className="flex items-center space-x-2">
              <p className="font-bold text-gray-800">{file.name}</p>
              <p className="text-gray-600">
                {(+file.size / 1024).toFixed()} Ko{" "}
              </p>
            </div>
            <button
              onClick={() => removeFile(index)}
              className="flex items-center text-red-600 hover:text-red-800 focus:outline-none"
            >
              <FaTrash size="1em" className="mr-1" />
              {t("quickApply.applyform_doc_field_button")}
            </button>
          </div>
        ))}
      </div>
    </div>
  );

  return { content, formFile: formFile[0] };
}

export default useDragDrop;

// import { useRef, useState } from "react";
// import { useDropzone } from "react-dropzone";
// import { FaCheck, FaFilePdf } from "react-icons/fa";

// const useDocPicker = ({ label, name }) => {
//   const [pickedDoc, setPickedDoc] = useState();
//   const pickRef = useRef();
//   const [formFile, setFormFile] = useState();
//   const handlePickClick = () => {
//     pickRef.current.click();
//   };
//   const handleDocChange = (event) => {
//     const file = event.target.files[0];
//     setFormFile(file);
//     if (!file) {
//       setPickedDoc(null);
//       return;
//     }

//     const fileReader = new FileReader();
//     fileReader.onload = () => {
//       setPickedDoc(fileReader.result);
//     };
//     fileReader.readAsDataURL(file);
//   };
//   // console.log("filesusehook", pickedDoc);

//  const {}= useDropzone({})

//   const handleDrop=(event)=>{

//     console.log('value', event.target.value)
//   }

//   const content = (
//     <div className="flex-1">
//       <div className="justify-center flex w-full">
//         <label className={"text-center my-4"} htmlFor={name}>
//           {label}
//         </label>
//         {/* <p> click ici pour selectionner le document</p>  */}
//       </div>
//       <input

//         ref={pickRef}
//         className="hidden"
//         type="file"
//         id={name}
//         accept="application/pdf,application/vnd.ms-excel"
//         name={name}
//         onChange={handleDocChange}
//       />
//       <div className="justify-center flex w-full"
//       onDropCapture={(event)=>handleDrop(event)}
//       >
//         <button
//           className={`h-32 w-72 border-spacing-4 border-2 rounded-xl hover:scale-x-105 transition  ease-in-out hover:bg-opacity-75 ${
//             pickedDoc ? " bg-opacity-45" : "bg-white"
//           }`}
//           onClick={handlePickClick}
//           type="button"
//         >
//           <div className="mx-8 py-3 border-spacing-x-72 border  o "

//           >

//             {!pickedDoc && (
//               <p className="mx-[40%]">
//                 <FaFilePdf className="text-[#5190B7] transition  ease-in-out hover:text-opacity-85 hover:scale-110 text-opacity-70" size={50} />
//               </p>
//             )}
//             {pickedDoc && (
//               <p className="mx-[40%] ">
//                 <FaCheck size={50} className="text-green-300 " />
//               </p>
//             )}
//           </div>
//         </button>

//       </div>   <p className="text-black my-2 text-center">{formFile?.name&&`Nom: ${formFile.name}`}</p>
//     </div>
//   );
//   return { content, pickedDoc, formFile };
// };
// export default useDocPicker;
