import React from "react";

const ButtonStudentUI = ({ children, classname, ...props }) => {
  const classes = ` bg-[#EA580C]  hover:opacity-75 scale-105 font-semibold hover h-8 rounded-md text-white w-32 ${classname}`;

  return (
    <button className={`${classes}`} {...props}>
      {children}
    </button>
  );
};

export default ButtonStudentUI;
