import React from "react";

const RadioUI = ({
  id,
  label,
  radioName,
  labelClass,
  radioClass,
  defaultValue,
  containerClass,
  onSelect,
  ...props
}) => {
  return (
    <div className={`flex gap-x-4 my-2 ${containerClass}`}>
      <input
        type="radio"
        name={radioName}
        id={id}
        className={radioClass}
        defaultChecked={defaultValue}
        onSelect={onSelect}
        {...props}
      />
      <label htmlFor={id} className={` font-semibold ${labelClass}`}>
        {label}
      </label>
    </div>
  );
};

export default RadioUI;
