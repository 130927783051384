import React, { useState } from "react";
import { FaPlus, FaSearch } from "react-icons/fa";
import { Input } from "@material-tailwind/react";
import { useTranslation, Trans } from "react-i18next";

const ManageBanner = ({
  handleClick,
  buttonText,
  searchText,
  setSearchText,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex justify-between py-3">
      <div className="relative items-center justify-start h-[40px] w-1/4">
        <Input
          type="text"
          value={searchText}
          size="md"
          onChange={(e) => setSearchText(e.target.value)}
          placeholder={t("managebanner.search_placeholder")}
          aria-label={t("managebanner.search_label")}
          className="border pl-10 border-[#D0D5DD] rounded-full bg-white placeholder-[#667085] focus:border-[#457AA8] text-[#667085] h-full"
        />
        <span className="absolute left-3 top-1/2 transform -translate-y-1/2">
          <FaSearch className="text-[#667085]" />
        </span>
      </div>

      <div className="justify-end">
        {buttonText ? (
          <button
            className="px-2 text-sm flex py-2 space-x-2 font-bold text-white hover:border-2 hover:text-[orange-500] hover:border-white duration-500 bg-[#EA580C] rounded-[25px] btn md:ml-2 md:static shadow-[4px_7px_4.7px_-6px_#00000040] hover:bg-[#457AA8] text-center"
            onClick={handleClick}
          >
            <span className="justify-start text-md">
              <FaPlus className="size-6" />
            </span>
            <span className="justify-end">{buttonText}</span>
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default ManageBanner;
