import { Checkbox, FormControlLabel } from "@mui/material";
import { useState, useEffect } from "react";
const workTimeTable = [
  { value: "Jour", id: 1 },
  { value: "Soir", id: 2 },
  { value: "Nuit", id: 3 },
];

const WorkTime = ({ onChangeTime, classname }) => {
  const [workTime, setWorkTime] = useState([]);
  const [didEdit, setDidEdit] = useState(false);

  const handleSelectTime = (value) => {
    setDidEdit(true);
    if (!value) {
      return;
    }
    const isValue = workTime.indexOf(value);
    if (isValue !== -1) {
      setWorkTime((prevTime) => prevTime.filter((time) => time !== value));
    } else {
      setWorkTime([...workTime, value]);
    }
  };

  useEffect(() => {
    onChangeTime(workTime);
  }, [workTime, onChangeTime]);

  const isTimeNotOk = didEdit && workTime.length === 0;

  const content = workTimeTable.map((time) => (
    <div
      key={time.id}
      onClick={(event) => handleSelectTime(event.target.value)}
      className="flex space-x-2"
    >
      <FormControlLabel
        label={time.value}
        control={
          <Checkbox
            checked={workTime.includes(time.value)}
            onChange={(event) => handleSelectTime(event.target.value)}
            value={time.value}
            id={time.id+'' }
          />
        }
      />
    </div>
  ));

  //  <input type="checkbox"  name={time.value} className="size-4 border " value={time.value} id={time.id}/>
  //   <label className="mb-1 block text-sm font-medium text-gray-700" htmlFor={time.id}>{time.value}</label>
  return (
    <div className={`text-sm font-[500] text-center w-full ${classname} `}>
      <div className=" text-sm pt-4">
        Quel type d'horaire vous intéresse? Plusieurs choix possibles
        <span className="text-red-500">*</span>
      </div>
      <div className="flex w-full justify-center space-x-2">{content}</div>
      {isTimeNotOk && (
        <h3 className="text-center text-red-500 text-sm">
          Veuillez cocher au moins une case
        </h3>
      )}
    </div>
  );
};

export default WorkTime;
