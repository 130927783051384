import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  saveAllobs: [],
  allJobs: [],
  //allJobs is the data to use
  filteredJobs: [],
  activeJob: {},
  statusJob: "",
  error: {},
  searchText: "",
  numberOfJobs: 0,
};

const jobsSliceAdmin = createSlice({
  name: "jobsSliceAdmin",
  initialState,
  reducers: {
    // -------------------get all job------------------
    fetchAllJobs(state, action) {
      const status = action.payload.status;
      state.statusJob = status;
      if (status === "success") {
        state.numberOfJobs = action.payload.data.data.length;
        // alert(action.payload.data.data.length)
        state.saveAllobs = action.payload.data.data;
        state.allJobs = action.payload.data.data;
        if (state.filteredJobs.length === 0) {
          // state.filteredJobs = action.payload.data.data;
          state.allJobs = action.payload.data.data;
        } else {
          state.allJobs = state.filteredJobs;
        }
      } else {
        state.error = action.payload.error;
      }
    },
    // ----------------------search Job----------------------------------

    searchJobs(state, action) {
      const searchText = action.payload.searchText;
      state.searchText = searchText;
      if (searchText && searchText !== "") {
        state.allJobs = state.filteredJobs.filter((job) =>
          job?.jobName.toLowerCase().includes(searchText.toLowerCase())
        );
      } else {
        state.allJobs = state.filteredJobs;
      }
    },
  },
});

export const jobsSliceAdminAction = jobsSliceAdmin.actions;
export default jobsSliceAdmin.reducer;
