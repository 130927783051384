import React from "react";
import Banner from "../components/Cpt_Emploi/Banner/Banner";
import ListJob from "../components/Cpt_Emploi/ListJob/ListJob";
import JobFooter from "../components/Cpt_Emploi/JobFooter/JobFooter";
import JobSecOne from "../components/Cpt_Emploi/JobSecOne/JobSecOne";
import { useState } from "react";

const JobPage = () => {
  const [reversedData, setReversedData] = useState(false);
  const getData = (d) => {
    setReversedData(d);
  };
  return (
    <main className="flex items-center flex-wrap space-y-5 overflow-x-hidden">
      <JobSecOne />

      <div className="mx-10 space-y-5">
        <Banner sendData={getData} />

        <ListJob
          switchState={true}
          withPagination={true}
          numItemsPerPage={15}
        />

        <JobFooter />
      </div>
    </main>
  );
};

export default JobPage;
