import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaEnvelope,
  FaGlobe,
  FaLinkedin,
  FaTwitter,
  FaFacebook,
  FaInstagram,
} from "react-icons/fa";
import imgc1 from "../../assets/imgcontact1.jpg";
import imgc2 from "../../assets/cont.jpg";
import Contactinfo from "./Contactinfo";
import { useMutation } from "@tanstack/react-query";

import { useTranslation } from "react-i18next";
import TextFieldUI from "../UI/TextFieldUI";
import TextAreaUI from "../UI/TextAreaUI";
import { isEmailValid, isTextValid } from "../Utils/ValidFormElement";
import { postContact } from "../../services/HttpRequest/ContactRequest";
import { Loader } from "react-feather";
import toastUtils from "../Utils/toastUtils";
import { showAlert } from "../Cv/alertUtils";
import ModalStatusMessage from "./SectionOne.js/ModalStatusMessage";

const social = {
  linkedin: process.env.REACT_APP_SOCIAL_LINKEDIN,
  twitter: process.env.REACT_APP_SOCIAL_TWITTER,
  facebook: process.env.REACT_APP_SOCIAL_FACEBOOK,
  instagram: process.env.REACT_APP_SOCIAL_INSTAGRAM,
};

const Contact = () => {
  const { mutate, status } = useMutation({
    mutationFn: postContact,
  });
  //mutation
  const { t } = useTranslation();

  const agreeRef = useRef();
  const [isAgree, setIsAgree] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  //state

  const [contactData, setContactData] = useState({
    firstName: { value: "", didEdit: false },
    lastName: { value: "", didEdit: false },
    email: { value: "", didEdit: false },
    message: { value: "", didEdit: false },
  });

  const handleChangeContact = (id, value) => {
    setContactData((prevData) => ({
      ...prevData,
      [id]: { value, didEdit: true },
    }));
  };
  const handleBlurContact = (id) => {
    setContactData((prevData) => ({
      ...prevData,
      [id]: { value: prevData[id].value, didEdit: true },
    }));
  };
  // validData

  const isEmailNotOk =
    contactData.email.didEdit && !isEmailValid(contactData.email.value);
  const isFirstNotNameOk =
    contactData.firstName.didEdit && !isTextValid(contactData.firstName.value);
  const isLastNameNotOk =
    contactData.lastName.didEdit && !isTextValid(contactData.lastName.value);
  const isMessageNotOk =
    contactData.message.didEdit && !isTextValid(contactData.message.value);
  const isContactDataOk =
    isEmailValid(contactData.email.value) &&
    isTextValid(contactData.firstName.value) &&
    isTextValid(contactData.lastName.value) &&
    isTextValid(contactData.message.value) &&
    isAgree;
  //submit
  const handleSubmit = (event) => {
    event.preventDefault();
    setIsModalOpen(true);
    for (let field in contactData) {
      handleBlurContact(field);
    }
    //data to send
    const dataToSend = {
      email: contactData.email.value,
      firstName: contactData.firstName.value,
      lastName: contactData.lastName.value,
      message: contactData.message.value,
    };
    if (isContactDataOk) {
      mutate({ data: dataToSend });
    }
  };
  const handleToggleAgree = () => {
    setIsAgree((prevState) => !prevState);
  };
  //------side efect to init the form
  useEffect(() => {
    if (status === "success") {
      // console.log('status', status)
      setIsAgree(false);
      for (let field in contactData) {
        setContactData((prevData) => ({
          ...prevData,
          [field]: { value: "", didEdit: false },
        }));
      }
    }
  }, [status]);
  // handleModal
  const handleModal = () => {
    setIsModalOpen(false);
  };
  // ---------message for modal-------------------
  let message = "";
  if (status === "success") {
    message = "Message Envoyer";
  } else {
    message = "Echec de l'envoi";
  }
  // -------------opening the modal------
  const openModal = (status === "success" || status === "error") && isModalOpen;

  return (
    <div className=" w-full px-14 justify-center flex">
      <ToastContainer />
      {/* one contact */}
      <div className="flex flex-col w-full   ">
        <div
          className="mt-8 mb-4 lg:w-[725px] lg:h-[108px] mx-auto"
          data-aos="fade-down"
        >
          <h1 className="lg:w-[725px] lg:h-[44px] font-bold text-center">
            {t("Contact.text1")}
          </h1>
          <p className="lg:w-[676px] lg:h-[48px] text-center">
            {t("Contact.text2")}
          </p>
          <br />
        </div>

        {/* three contact */}
        <div
          className="lg:flex flex-col  max-w-xs mx-auto mt-8 md:max-w-md max-lg:max-h-[700px] lg:max-w-full  lg:flex-row"
          onSubmit={handleSubmit}
        >
          <div
            className="order-2 w-full lg:w-[576px]  lg:order-1  mx-auto"
            data-aos="fade-right"
          >
            <form className="mx-auto rounded lg:w-[480px] lg:h-[478px]  ">
              <h2 className="font-bold lg:w-[480px] lg:h-[44px] mb-8">
                {t("Contact.text3")}
              </h2>
              <div className="flex flex-wrap mb-6">
                <div className="w-full mb-4 lg:w-1/2 lg:pr-2 lg:mb-2">
                  <TextFieldUI
                    type="text"
                    value={contactData.firstName.value}
                    onBlur={() => handleBlurContact("firstName")}
                    onChange={(event) =>
                      handleChangeContact("firstName", event.target.value)
                    }
                    id="firstName"
                    label={t("Contact.text4")}
                    classname="w-full p-2 border"
                  />
                  {isFirstNotNameOk && (
                    <h3 className="ml-3 text-red-500">
                      Veuillez remplir ce champs
                    </h3>
                  )}
                </div>
                <div className="w-full lg:w-1/2 lg:pl-2 mb-2 ">
                  <TextFieldUI
                    value={contactData.lastName.value}
                    onBlur={() => handleBlurContact("lastName")}
                    onChange={(event) =>
                      handleChangeContact("lastName", event.target.value)
                    }
                    label={t("Contact.text5")}
                    type="text"
                    id="lastName"
                    name="nom"
                    classname="w-full p-2 border"
                  />
                  {isLastNameNotOk && (
                    <h3 className="ml-3 text-red-500">
                      Veuillez remplir ce champs
                    </h3>
                  )}
                </div>
              </div>
              <div className="mb-6">
                <TextFieldUI
                  label={t("Contact.text6")}
                  value={contactData.email.value}
                  onBlur={() => handleBlurContact("email")}
                  onChange={(event) =>
                    handleChangeContact("email", event.target.value)
                  }
                  type="email"
                  id="email"
                  name="email"
                  classname="w-full p-2 border"
                />
                {isEmailNotOk && (
                  <h3 className="ml-3 text-red-500">
                    Veuillez entrer une addresse email valide.
                  </h3>
                )}
              </div>
              <div className="mb-6">
                <TextAreaUI
                  value={contactData.message.value}
                  onBlur={() => handleBlurContact("message")}
                  onChange={(event) =>
                    handleChangeContact("message", event.target.value)
                  }
                  label={t("Contact.text7")}
                  id="message"
                  name="message"
                  classname="w-full p-2 border h-40"
                />
                {isMessageNotOk && (
                  <h3 className="ml-3 text-red-500">
                    Veuillez remplir ce champs
                  </h3>
                )}
              </div>
              <div className="checkbox flex items-center mb-6">
                <input
                  checked={isAgree}
                  required
                  onChange={handleToggleAgree}
                  id="link-checkbox"
                  type="checkbox"
                  ref={agreeRef}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-600">
                  {t("Contact.text13")}{" "}
                  <a
                    href="link"
                    className="text-blue-600 dark:text-blue-500 hover:underline"
                  >
                    {t("Contact.text14")}
                  </a>
                </label>
              </div>
              <button
                type="submit"
                disabled={status === "pending"}
                className={` flex justify-center text-center ${
                  status === "pending" ? "bg-slate-600" : "bg-[#E8451D]"
                } text-white font-semibold py-2 px-4 rounded w-full`}
              >
                {status === "pending" ? (
                  <Loader className="text-white size-5 animate-spin" />
                ) : (
                  t("Contact.text8")
                )}
              </button>
            </form>
          </div>

          <div
            className="order-1 mt-4   lg:mt-0 lg:order-2 lg:w-[576px] sm:h-[700px] md:h-0 lg:h-[600px]"
            data-aos="fade-left"
          >
            <img
              src={imgc2}
              alt="contact"
              className="hidden object-cover w-full lg:block"
              style={{ height: "565px" }}
            />
          </div>
        </div>

        {/* four contact */}
        <div
          className="grid grid-cols-1 mx-4 md:grid-cols-2 lg  lg:grid-cols-4 my-10 mt-16 "
          data-aos="fade-up"
        >
          {/* Contactinfo ici */}
          <Contactinfo
            icon={<FaMapMarkerAlt className="text-xl text-white" />}
            title={<p className="text-[#0284C7] mb-2">{t("Contact.text9")}</p>}
            text="36 Rue du Marigot, Gatineau, QC J9J 0C4"
          />
          <Contactinfo
            icon={<FaPhoneAlt className="text-xl text-white" />}
            title={<p className="text-[#0284C7] mb-2">{t("Contact.text10")}</p>}
            text={<a href="tel:+1 833 527-7683">+1 833 527-7683</a>}
          />
          <Contactinfo
            icon={<FaEnvelope className="text-xl text-white" />}
            title={<p className="text-[#0284C7] mb-2">{t("Contact.text11")}</p>}
            text={
              <a href="mailto:contact@laproueinc.com">contact@laproueinc.com</a>
            }
          />
          <Contactinfo
            icon={<FaGlobe className="text-xl text-white" />}
            text={
              <div>
                <div className="flex flex-row">
                  <p className="m-2 text-gray-500 hover:text-[#0284C7]">
                    <a
                      href={social.facebook}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFacebook />
                    </a>
                  </p>
                  <p className="m-2 text-gray-500 hover:text-[#0284C7]">
                    <a
                      href={social.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaLinkedin />
                    </a>
                  </p>
                  <p className="m-2 text-gray-500 hover:text-[#0284C7]">
                    <a
                      href={social.twitter}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaTwitter />
                    </a>
                  </p>

                  <p className="m-2 text-gray-500 hover:text-[#0284C7]">
                    <a
                      href={social.instagram}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaInstagram />
                    </a>
                  </p>
                </div>
              </div>
            }
          />
        </div>
      </div>
      {/* <div className="relative w-screen bg-center bg-cover bg-gradient-to-t from-[#3D3D3DA6] to-[#EA580CA6] h-[479px] overflow-hidden">
        <img
          src={imgc1}
          alt="hero"
          className="absolute w-full h-full object-cover mix-blend-overlay top-14"
        />
        <div className="flex items-center justify-center h-full text-9xl">
          <div className="">
            <h1 className="font-bold text-white">{t("Contact.text12")}</h1>
          </div>
        </div>
      </div> */}

      {/* two contact */}
      <ModalStatusMessage
        open={openModal}
        message={message}
        status={status}
        autoclose={handleModal}
      />
    </div>
  );
};

export default Contact;
