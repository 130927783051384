import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { performRequest } from "./requestSlice";
import { LOGIN_URL, GET_USER_URL } from "../../services/api";
import axios from "../../services/axiosConfig";
import dayjs from "dayjs";
import { createSelector } from 'reselect';

// Action asynchrone pour la connexion
export const loginUser = createAsyncThunk(
  "account/login",
  async (credentials, { dispatch }) => {
    try {
      const response = await axios.post(LOGIN_URL, JSON.stringify(credentials));
      
      if(response.status === 200){
        response.data.expiresIn += dayjs().unix()
        localStorage.setItem('user', JSON.stringify(response.data))
      }

      // Dispatch loginUser.fulfilled action
      dispatch(loginUser.fulfilled(response.data));

      // getUserInfo après la connexion réussie
      dispatch(getUserInfo());

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getUserInfo = createAsyncThunk(
  'account/getUserInfo',
  async (_, { dispatch, getState }) => {
    try {
      const response = await dispatch(performRequest({
        method: 'GET',
        url: GET_USER_URL,
        requiresAuth: true,
      }));
      
      dispatch(loginSlice.actions.updateUser(response));

      return response;
    } catch (error) {
      console.error('Error while fetching user info:', error.message);
      throw error;
    }
  }
);

export const selectCurrentUser = createSelector(
  state => state.loginInfo.user,
  user => {
    if (user && user.payload) {
      const { roles, user: userInfo } = user.payload;
      // Ajouter le rôle "client" si l'utilisateur a des rôles
      const updatedRoles = roles ? [...roles, "Client"] : ["Client"];
      return { roles: updatedRoles, user: userInfo };
    } else {
      return null;
    }
  }
);

const loginSlice = createSlice({
  name: "loginInfo",
  initialState: {
    status: "idle",
    user: null,
    error: null,
    accessToken: null,
    refreshToken: null,
    tokenExpiration: null,
  },

  reducers: {
    logout: (state) => {
      state.status = "idle";
      state.user = null;
      state.accessToken = null;
      state.refreshToken = null;
      state.tokenExpiration = null;
      state.error = null;
      localStorage.clear('user');
      localStorage.removeItem("activeAppli");
    localStorage.removeItem("activeTab");

    },
    updateUser: (state, action) => {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.user = action.payload.user;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.tokenExpiration =
        new Date().getTime() + action.payload.expiresIn * 1000;
        // localStorage.setItem('accessTokenByStore', state.accessToken)
      state.error = null;
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.status = "failed";
      state.user = null;
      state.accessToken = null;
      state.refreshToken = null;
      state.tokenExpiration = null;
      state.error = action.error.message || "Email ou mot de passe incorrect";
    });
  },
});

export const { logout } = loginSlice.actions;
export const getUserAccess = (state) => state.loginInfo;
export const selectLoginStatus = (state) => state.loginInfo.status;
export const selectLoginError = (state) => state.loginInfo.error;

export default loginSlice.reducer;
