export const dateFormatterShort = (date) => {
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  const newdate = Date.parse(date);
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
    newdate
  );

  return formattedDate;
};

export const dateFormatterLong = (date) => {
  const options = { year: "numeric", month: "long", day: "2-digit" };
  const newdate = Date.parse(date);
  const formattedDate = new Intl.DateTimeFormat("fr-FR", options).format(
    newdate
  );

  return formattedDate;
};

export const dateFormatterInput = (dateStr) => {
  const date = new Date(dateStr);

  const formattedDate = date.toISOString().split("T")[0].replace(/-/g, "-");

  return formattedDate;
};
