import { createSlice } from "@reduxjs/toolkit";

const cvUserSlice = createSlice({
  name: "cvUserSlice",
  initialState: {
    allDocsStatus: "pending" || "error" || "success",
    allDocsData: [],
    viewDoc: {
      status: "idle",
      fileName: "",
      file: "",
      id: "",
    },
  },
  reducers: {
    getUserCv(state, action) {
      state.allDocsStatus = action.payload.status;
      state.allDocsData = action.payload.allDocs;
      // console.log(" action.payload.status", action.payload.status);
    },
  },
});

export const cvUserSliceAction = cvUserSlice.actions;
export default cvUserSlice.reducer;
