import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { Stack } from "@mui/material";
import InvalidInputControl from "./InvalidInputControl";

const PhoneNumberUI = ({
  label,
  id,
  isRequired,
  classname,
  defaultCountry,
  isInputNotValide,
  invalidMessage,
  readOnly,
  containerStyles,
  inputStyles,
  buttonStyles,
  ...props
}) => {
  // const classe = ` text-sm p-2 border-gray-300 hover:border-blue-500 focus:border-gray-300 focus:ring  mt-1 p-1 border rounded-md w-full focus:outline-none ${classname} `;

  return (

      <Stack className="space-x-2 max-lg:mt-2    lg:my-1  lg:text-2xl text-xl mb-4 w-full  ">
        <div className="mx-2">
          <label
            htmlFor={id}
            className="mb-1 block text-sm font-medium text-gray-700"
          >
            {label}
            {isRequired ? (
              <span className="text-red-600 ">*</span>
            ) : (
              <span className=""></span>
            )}
          </label>
          <PhoneInput
            containerStyle={{width:'100%',marginTop:'9px',...containerStyles}}
            inputStyle={{width:'100%',height:'40px',...inputStyles}}
            buttonStyle={{borderRight:'0px',backgroundColor:'white',...buttonStyles}}
            inputClass="w-full"
            country={defaultCountry}
            id={id}
            name={id}
            {...props}
            readOnly={readOnly}
          />
        </div>{" "}
        <InvalidInputControl classname={"ml-2 "}>
          {isInputNotValide && invalidMessage}
        </InvalidInputControl>
      </Stack>


    // <div className="grid ">
    //     <label htmlFor={id} className="">
    //         {label}

    //     </label>

    //     <input name={id} id={id} className="border w-36 border-gray-400  p-2 rounded-md focus:h-[3.2rem] h-[3rem] text-xl focus:outline-none focus:border-blue-500" {...props}/>
    // </div>
  );
};

export default PhoneNumberUI;

// import React, { useState } from "react";

// const inputStylePhone = {
//   border: "1px solid #e2e8f0",
//   width: "100%",
//   padding: "0.5rem",
//   outline: "none",
//   transition: "border-color 0.2s",
//   borderRadius: "0.87rem",
//   height: "50px",
//   paddingLeft: "50px",
// };

// export default function PhoneNumberUI({ country, formData, setFormData }) {
//   const [phoneNumber, setPhoneNumber] = useState("");

//   return (
//     <div>
//       <PhoneInput
//         country={country}
//         value={formData.phonenumber || phoneNumber}
//         onChange={(phone) => {
//           setPhoneNumber(phone);
//           setFormData({ ...formData, phonenumber: phone });
//         }}
//         inputStyle={inputStylePhone}
//       />
//       {/* <p>Phone number: +{phoneNumber}</p> */}
//     </div>
//   );
// }
