import React, { useState } from "react";
import Caroussel from "./Caroussel";
import { Link, useNavigate } from "react-router-dom";
import { Card, Input, Button, Typography } from "@material-tailwind/react";
import proueLogo from "../../assets/logo.png";
import ConnexionImg from "../../assets/connexion1.jpg";
import { useDispatch, useSelector } from "react-redux";
import {
  loginUser,
  selectLoginStatus,
  selectCurrentUser,
} from "../../store/slice/loginSlice";
import { useTranslation } from "react-i18next";

import Loader from "../Loader/Loader";
import ModalRPWD from "./ModalRPWD/ModalRPWD";
import TextFieldUI from "../UI/TextFieldUI";
const Connexion = () => {
  const { t } = useTranslation();
  const loginStatus = useSelector(selectLoginStatus);
  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const submitActionGroup = (event) => {
    event.preventDefault();
    // Ralentir avant d'envoyer les données
    // setTimeout(() => {
    dispatch(loginUser({ email: formData.email, password: formData.password }));
    // }, 20000); // Ralentir pendant 2 secondes
  };

  return (
    <section
      className="relative  max-w-md mx-auto  shadow-md md:max-w-full xl:text-xl text-sm min-h-svh p-10 "
      style={{
        backgroundImage: `linear-gradient( rgba(3, 2, 2, 0.5), rgba(176, 63, 4, 0.5)) , url(${ConnexionImg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        // backgroundPosition: "center",
        overlay: "none",
      }}
    >
      <div className=" lg:flex md:my-20 sm:my-5 lg:my-20 xl:my-20 h-screen ">
        <div className="hidden p-2 lg:block lg:w-1/2  h-screen  items-center justify-center">
          <Caroussel />
        </div>
        <div className="h-screen flex items-center justify-center  max-w-md p-2 mx-auto md:shrink-0 md:max-w-full lg:w-1/2 ">
          <Card
            color="white"
            shadow={false}
            className="relative p-5 lg:px-20   rounded-[25px]"
          >
            {loginStatus === "loading" && <Loader />}{" "}
            <div className="flex mx-auto ">
              <img
                src={proueLogo}
                className="xl:w-[100%] xl:h-[100%] w-16 h-16"
                alt="imag"
              />
            </div>
            <Typography
              variant="h4"
              color="blue-gray"
              className="[font-family:'Inter-Semi-Bold',Helvetica] text-center mt-5 border-b-2 border-blue-500 rounded-full   py-2"
            >
              <span className="font-bold text-2xl max-sm:text-lg text-[#EA580C]">
                {t("connection.connectionPageTitle")}
              </span>
            </Typography>
            <div className="xl:mt-5 mt-0"></div>
            {loginStatus === "failed" && (
              <>
                <p className="mt-4 font-bold text-center text-red-500 bg-red-100">
                  {t("connection.connectionErrorText")}
                </p>
              </>
            )}
            <div className="xl:mt-5 mt-0"></div>
            <form
              className="max-w-md mt-5 mb-2 md:max-w-full"
              onSubmit={submitActionGroup}
            >
              <div className="flex flex-col max-w-md gap-6 mb-1 md:max-w-full ">
                <TextFieldUI
                  label={t("connection.emailInputText")}
                  id="email"
                  name="email"
                  size="lg"
                  isRequired={true}
                  placeholder="name@mail.com"
                  value={formData.email}
                  onChange={handleChange}
                  // labelProps={{
                  //   className: "before:content-none after:content-none",
                  // }}
                />

                <TextFieldUI
                  label={t("connection.passwordInputText")}
                  id="password"
                  name="password"
                  type="password"
                  size="lg"
                  placeholder="********"
                  isRequired={true}
                  value={formData.password}
                  onChange={handleChange}
                  // labelProps={{
                  //   className: "before:content-none after:content-none ",
                  // }}
                />
              </div>
              <div className="mt-10"></div>
              <Button
                className="mt-4 text-lg bg-[#EA580C] text-white  py-2 rounded-full px-4 lowercase"
                color="white"
                size="md"
                fullWidth
                type="submit"
              >
                {t("connection.loginButtonText")}
              </Button>
              <div className="xl:mt-5 mt-0"></div>

              {loginStatus === "failed" && (
                <>
                  <Typography
                    variant="h4"
                    color="blue"
                    className="items-center mt-2 text-sm font-normal "
                  >
                    <button
                      className="underline text-[#457AA8]"
                      onClick={openModal}
                      type="button">
                      {t("connection.forgottenPasswordText")}
                    </button>
                    {isModalOpen && <ModalRPWD onClose={closeModal} />}
                  </Typography>
                </>
              )}
              <div className="xl:mt-5 mt-0"></div>

              <Typography
                variant="h4"
                color="light-blue"
                className="items-center mt-4 text-sm font-normal text-[#667085]"
              >
                {t("connection.noAccountText")}{" "}
                <Link
                  to="/inscription"
                  className="underline text-[#457AA8] text-s"
                >
                  {t("connection.linkToAccountText")}
                </Link>
              </Typography>
            </form>
          </Card>
        </div>
      </div>
    </section>
  );
};

export default Connexion;
