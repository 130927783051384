import React from "react";
import { NavLink } from "react-router-dom";
import {
  FaInfoCircle, FaTachometerAlt,
  FaTasks,FaUser,
  FaStar,FaFileAlt, FaUserGraduate,
  FaEnvelope
} from "react-icons/fa";
import { useTranslation, Trans } from "react-i18next";

const Navbar = ({ userRole }) => {
  let nav;

const { t } = useTranslation();

 
  switch (userRole) {
    case "Admin":
      nav = [
        { 
          name: t("navbar.board"),
          icon: <FaTachometerAlt />,
          link: "/admin/DashBord",
          current: false,
        },
        {
          name: t("navbar.menu"),
          icon: <FaTasks />,
          link: "/admin/MenuGestion",
          current: false,
        },
        {
          name: "Newsletter",
          icon: <FaEnvelope  />,
          link: "/admin/NewsLetter",
          current: false,
        },
       
      ];
      break;
    case "Manager":
      nav = [
        {
          name: t("navbar.board"),
          icon: <FaTachometerAlt />,
          link: "/manager/DashBord",
          current: false,
        },
        {
          name: t("navbar.menu"),
          icon: <FaTasks />,
          link: "/manager/MenuGestion",
          current: false,
        },
        {
          name: "Newsletter",
          icon: <FaEnvelope  />,
          link: "/manager/NewsLetter",
          current: false,
        },
       
      ];
      break;
    case "Client":
      nav = [
        {
          name: t("navbar.application"),
          icon: <FaUserGraduate />,
          link: "/client/MesCandidatures",
          current: false,
        },
        {
          name: t("navbar.cv"),
          icon: <FaFileAlt />,
          link: "/client/MesCv",
          current: false,
        },
     
        {
          name: t("navbar.profile"),
          icon: <FaUser />,
          link: "/client/MonProfil",
          current: false,
        },
       
      ];
      break;
    default:
      nav = [];
  }

  return (
    <nav
      className={` ${
        userRole
          ? " bg-[#EAF0F3] lg:w-[50%] shadow-md border-2 sm:w-100 rounded-md "
          : "lg:mb-[50px] sm:mb-[50px]"
      }`}
    >
      <div className=" flex items-center justify-center  mx-auto h-full ">
        <div className="flex items-center space-x-8 w-full ">
          {nav.map((item) => (
            <NavLink
              key={item.name}
              exact={"true"}
              to={item.link}
              className={`flex text-[15px] items-center px-2 lg:p-2  sm:p-2 justify-center transition duration-300 ease-in-out w-full h-full ${
                window.location.pathname === item.link ? 'activeNavBar' : '' 
              }`}>
              <span className="mr-2">{item.icon}</span>
              <span className="">{item.name}</span>
            </NavLink>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
