import React from "react";

const FormContainerUI = ({ classname, children }) => {
  return (
    <div
      className={`w-full lg:max-w-[1000px] max-w-[500px] px-5 lg:px-10 ${classname} `}
    >
      {children}
    </div>
  );
};

export default FormContainerUI;
