import React, { useState, useEffect, useContext } from "react";
import CandidatureTable from "./CandidatureTable/CandidatureTable";
import DétailUserAppli from "./DétailUserAppli/DétailUserAppli"; // Assurez-vous que le chemin est correct
import AppliContext from "../../../../context/AdminCandidacyContext/AppliContext";
import PendingUI from "../../../UI/RequestUI/PendingUI";
import LoadDataFailUI from "../../../UI/RequestUI/LoadDataFailUI";
import { useSelector } from "react-redux";
import { useTranslation, Trans } from "react-i18next";

const AppliManage = () => {
  const { t } = useTranslation();

  const {
    activeAppli,
    appliForJob,
    filterJob,
    statusAppli,
    numberOfAppli,

    handleAddActiveAppli,
  } = useContext(AppliContext);
  const statusJob = useSelector((state) => state.jobsAdmin.statusJob);
  // console.log("je suis le numberOfAppli", numberOfAppli);
  // console.log("les jobs", filterJob);

  const handleClick = (selectedRow) => {
    if (selectedRow) {
      // console.log("je suis la ligne active", selectedRow);
      handleAddActiveAppli(selectedRow);
      window.location.reload();

      // navigate("/admin/MenuGestion/CandidacyManage");
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: t("applimanage.table_field1"),
        accessor: "id",
      },
      {
        Header: t("applimanage.table_field2"),
        accessor: "jobName",
      },
      {
        Header: t("applimanage.table_field3"),
        accessor: "companyId",
      },

      {
        Header: t("applimanage.table_field4"),
        accessor: "creationDate",
        Cell: ({ value }) => (
          <span >
            {/* {value} */}
           {new Date(value)
            .toISOString()
            .split("T")[0]}
          </span>
        ),
      },
      {
        Header: t("applimanage.table_field5"),
        accessor: "requiredHeadCount",
      },

      {
        Header: t("applimanage.table_field6"),
        accessor: "actions",
        Cell: ({ row }) => (
          <div className="flex justify-center space-x-2">
            <button
              className="bg-[#EA580C] hover:bg-[#457AA8] text-white font-semibold  py-1 px-2 rounded-full"
              onClick={() => handleClick(row.original)}
            >
              {t("applimanage.details_btn")}
            </button>
          </div>
        ),
      },
      // {
      //   Header: "",
      //   accessor: "delete",
      //   Cell: ({ row }) => (
      //     <button
      //       onClick={() => handleDelete(row.original)}
      //       className="text-red-500 hover:text-red-700"
      //     >
      //       <FaTrash />
      //     </button>
      //   ),
      // },
    ],
    []
  );
  // const dateEnd = new Date(application?.applicationDate)
  //           .toISOString()
  //           .split("T")[0];
  const appliData = React.useMemo(
    () =>
      Array.isArray(appliForJob)
        ? appliForJob.map((application) => ({
            id: application.id,
            userMail: application.userMail || "N/A",
            // firstName: application.firstName || "N/A",
            userName: application.userName || "N/A",
            phoneNumber: application.phoneNumber || "N/A",
            address: application.address || "N/A",
            applicationDate:
              new Date(application?.applicationDate)
                .toISOString()
                .split("T")[0] || "N/A",
            canadianWorkingPermit: application?.canadianWorkingPermit || "N/A",

            interestRegion: application.interestRegion || "N/A",
            // jobId: application.jobId || "N/A",
            // jobStatus: application.jobStatus || "N/A",

            resume: application.docId || "N/A",
            userId: application.id || "N/A",
          }))
        : [],
    [appliForJob]
  );

  const columnsDetail = React.useMemo(
    () => [
      {
        Header: t("applimanage.tab_field1"),
        accessor: "id",
      },
      {
        Header: t("applimanage.tab_field2"),
        accessor: "userMail",
      },
      {
        Header: t("applimanage.tab_field3"),
        accessor: "applicationDate",
      },
      // {
      //   Header: "Prénom",
      //   accessor: "firstName",
      // },
      {
        Header: t("applimanage.tab_field4"),
        accessor: "userName",
      },
      {
        Header: t("applimanage.tab_field5"),
        accessor: "phoneNumber",
      },
      {
        Header: t("applimanage.tab_field6"),
        accessor: "address",
      },

      {
        Header: t("applimanage.tab_field7"),
        accessor: "canadianWorkingPermit",
      },

      {
        Header: t("applimanage.tab_field8"),
        accessor: "jobId",
      },
      {
        Header: t("applimanage.tab_field9"),
        accessor: "jobStatus",
      },
      {
        Header: t("applimanage.tab_field10"),
        accessor: "userId",
      },
      {
        Header: t("applimanage.tab_field11"),
        accessor: "interestRegion",
      },
      // {
      //   Header: "Curriculum Vitae",
      //   accessor: "resume",
      // },
    ],
    []
  );

  const handleDelete = (item) => {
    // console.log("Delete item", item);
  };
  let jobContent = [];
  let DétailUserAppliContent = [];
  switch (statusJob) {
    case "pending":
      jobContent = <PendingUI />;
      break;
    case "error":
      jobContent = <LoadDataFailUI />;
      break;
    case "success":
      jobContent = (
        <CandidatureTable
          data={filterJob}
          columns={columns}
          onDelete={handleDelete}
        />
      );
      break;
    default:
      break;
  }

  switch (statusAppli) {
    case "pending":
      DétailUserAppliContent = <PendingUI />;
      break;
    case "error":
      DétailUserAppliContent = <LoadDataFailUI />;
      break;
    case "success":
      DétailUserAppliContent = (
        <DétailUserAppli
          data={appliData}
          columns={columnsDetail}
          onDelete={handleDelete}
        />
      );
      break;
    default:
      break;
  }
  return (
    <div className="container mx-auto mt-8">
      <div className="container mx-auto mt-8">
        <div className="flex justify-between">
          <h1 className="mb-4 text-3xl font-bold text-[#457AA8]">
            {t("applimanage.title")}
          </h1>
        </div>

        {activeAppli.id ? <>{DétailUserAppliContent}</> : <>{jobContent}</>}
      </div>
    </div>
  );
};

export default AppliManage;
