import React, { useContext } from "react";
import proueLogo from "../../../assets/carrousel1.jpg";
import TextItems from "../../Cpt_Emploi/DetailJob/TextItems";
import JobContext from "../../../context/JobContext";
import { Link } from "react-router-dom";

// grayColor
export const colorsGrey = {
  gray100: "#F9FAFB",
  gray400: "#667085",
  gray950: "#3D3D3D",
};
// blue Color

export const colorsBlue = {
  blue350: "#0284C7",
};
// orange Color
export const colorsOrange = {
  orange600: "#EA580C",
};

export const JobCard = ({ job }) => {
  const { handleAddActiveJob } = useContext(JobContext);

  return (
    <Link to={"/JobDescription"}>

      <div
        onClick={() => {
          handleAddActiveJob(job);
        }}
        className="flex my-5   hover:bg-slate-50 flex-col h-[492px]   hover:scale-105 hover:shadow-xl transition-all ease-in-out duration-200    shadow   w-[280px]"
      >
        <div
          className=" basis-3/4 mb-2 bg-cover rounded-2xl  "
          style={{ backgroundImage: `url(${proueLogo})` }}
        ></div>
        <div className="basis-1/4 pb-2 p-1">
          <div className="text-xl text-[#3D3D3D] font-semibold">
            {job?.jobName}
          </div>
          <div className="text-xl font-[450] text-[#0284C7] mt-1 mb-2">
            Quebec
          </div>
          <div className="text-[#667085] text-[16px] h-12 text-wrap overflow-y-hidden">
            {" "}
            {job?.description &&
              job.description.split(";").map((text) => text + ", ")}
          </div>
          <div className="text-xl  font-semibold  text-[#EA580C]">
            <span>{job?.salary} </span>
            <span>{job?.currency}</span>{" "}
            <span className="first-letter:uppercase">/{job?.salaryRate}</span>
          </div>
        </div>
      </div>
    </Link>
  );
};
