import React, { useState, useEffect } from 'react';

const Loader = () => {
  const [dots, setDots] = useState(''); // État pour stocker les points

  useEffect(() => {
    // Utilisation de setInterval pour mettre à jour les points à intervalles réguliers
    const interval = setInterval(() => {
      setDots(prevDots => {
        // S'il y a moins de 3 points, ajoute un point, sinon réinitialise à un point
        return prevDots.length < 5 ? prevDots + '.' : '.';
      });
    }, 500); // Intervalle de 500 millisecondes

    // Nettoie l'intervalle lors du démontage du composant
    return () => clearInterval(interval);
  }, []); // Utilise un tableau vide pour n'exécuter cet effet qu'une seule fois à l'initialisation du composant

  return (
    <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-50">
      <div className="relative flex flex-col items-center justify-center p-8 bg-transparent rounded-xl">
        <div className="relative w-20 h-20 mb-10">
          <div className="w-full h-full overflow-hidden rounded-full">
            <div className="w-full h-full bg-white rounded-full"></div>
          </div>
          <div className="absolute top-0 left-0 w-full h-full">
            <div className="w-full h-full border-r-8 border-blue-500 border-solid rounded-full animate-spin"></div>
          </div>
          <div className="absolute top-0 w-full h-full transform -translate-x-1/2 left-1/2">
            <div className="w-full h-full border-l-8 border-orange-500 border-solid rounded-full animate-spin"></div>
          </div>
        </div>
        <div className="text-xl font-extrabold text-center text-white">En cours de chargement{dots}</div>
      </div>
    </div>
  );
};

export default Loader;
