import React, { useState, useEffect, useRef } from "react";
import Caroussel from "../Conexion/Caroussel";
import { Link, useNavigate } from "react-router-dom";
import { Card, Input, Button, Typography } from "@material-tailwind/react";
import proueLogo from "../../assets/logo.png";
import {} from "../../store/action/requestAction";
import { useMutation } from "@tanstack/react-query";
import { singUp } from "../../services/HttpRequest/User/singUp";
import PendingUI from "../UI/RequestUI/PendingUI";
import { AnimatePresence, motion } from "framer-motion";
import LoadDataFailUI from "../UI/RequestUI/LoadDataFailUI";
import RequestSuccess from "../UI/RequestUI/RequestSucess";
import { useTranslation } from "react-i18next";
import ConnexionImg from "../../assets/connexion1.jpg";
import PhoneNumberUI from "../UI/PhoneNumberUI";
import TextFieldUI from "../UI/TextFieldUI";

const Inscription = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { mutate, status, error } = useMutation({
    mutationFn: singUp,
  });

  const [step, setStep] = useState(1);

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    address: "",
    phonenumber: "",
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({
    firstname: "",
    lastname: "",
    email: "",
    address: "",
    phonenumber: "",
    password: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    setErrors({
      ...errors,
      [e.target.name]: "", // Réinitialiser l'erreur lorsqu'un champ change
    });
  };

  const handleNext = (event) => {
    event.preventDefault();

    // Vérification des champs
    let valid = true;
    const newErrors = { ...errors };

    if (step === 1) {
      if (!formData.firstname) {
        newErrors.firstname = "Veuillez saisir votre prénom";
        valid = false;
      }

      if (!formData.lastname) {
        newErrors.lastname = "Veuillez saisir votre nom de famille";
        valid = false;
      }

      if (!formData.address) {
        newErrors.address = "Veuillez saisir votre adresse";
        valid = false;
      }

      if (!formData.phonenumber) {
        newErrors.phonenumber = "Veuillez saisir votre numéro de téléphone";
        valid = false;
      }
      // else if (!/^\(\+[0-9]{1,3}\) [0-9]{9,11}$/.test(formData.phonenumber)) {
      //   newErrors.phonenumber = "Veuillez saisir un numéro de téléphone valide";
      //   valid = false;
      // }
    } else if (step === 2) {
      if (!formData.email) {
        newErrors.email = "Veuillez saisir votre email";
        valid = false;
      }
      if (
        !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(formData.email)
      ) {
        newErrors.email = "Veuillez saisir un email valide";
        valid = false;
      }

      if (!formData.password) {
        newErrors.password = "Veuillez saisir votre mot de passe";
        valid = false;
      } else if (
        !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{4,}$/.test(
          formData.password
        )
      ) {
        newErrors.password =
          "Le mot de passe doit contenir au moins 8 caractères, dont des lettres, des chiffres et des caractères spéciaux";
        valid = false;
      }

      if (formData.password !== formData.confirmPassword) {
        newErrors.confirmPassword =
          "Le mot de passe et la confirmation du mot de passe ne correspondent pas";
        valid = false;
      }
    }

    setErrors(newErrors);

    if (valid) {
      if (step === 1) {
        setStep(2);
      } else if (step === 2) {
        // console.log("Inscription réussie avec les données suivantes :");
        const allData = {
          email: formData.email,
          password: formData.password,
          firstName: formData.firstname,
          lastName: formData.lastname,
          address: formData.address,
          phoneNumber: "+" + formData.phonenumber,
        };

        mutate({ data: allData });
        if (status === "success") {
        }
      } else {
      }
    }
  };
  const handleBack = () => {
    setStep(1);
  };

  const timerRedirect = useRef();

  useEffect(() => {
    if (status === "success") {
      timerRedirect.current = setTimeout(() => {
        navigate("/Connexion");
      }, 3000);

      return () => {
        clearTimeout(timerRedirect.current);
      };
    }
  }, [status, navigate]);

  const anime = {
    hidden: { x: 30, opacity: 0 },
    visible: { x: 0, opacity: 1 },
    final: { x: -30, opacity: 0 },
    visibleFromLEft: { x: -30, opacity: 0 },
  };
  // console.log("status:", status);

  return (
    <section
      className="relative  max-w-md mx-auto  shadow-md md:max-w-full xl:text-xl text-sm min-h-svh p-10 "
      style={{
        backgroundImage: `linear-gradient( rgba(3, 2, 2, 0.5), rgba(176, 63, 4, 0.5)) , url(${ConnexionImg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        // backgroundPosition: "center",
        overlay: "none",
      }}
    >
      <div className=" lg:flex md:my-20 sm:my-5 lg:my-20 xl:my-20 h-screen ">
        <div className="hidden p-2 lg:block lg:w-1/2  h-screen  items-center justify-center">
          <Caroussel />
        </div>
        <div className="h-screen flex items-center justify-center  max-w-md p-2 mx-auto md:shrink-0 md:max-w-full lg:w-1/2 ">
          <Card
            color="white"
            shadow={false}
            className="relative p-5 lg:px-10   rounded-[25px] "
          >
            <div className="flex mx-auto ">
              <img
                src={proueLogo}
                className="xl:w-[100%] xl:h-[100%] w-16 h-16"
                alt="imag"
              />
            </div>
            <Typography
              variant="h4"
              color="blue-gray"
              className="[font-family:'Inter-Semi-Bold',Helvetica] text-center xl:mt-5 md:mt-1 border-b-2 border-blue-500 rounded-full py-2 mb-5 md:mb-1"
            >
              <span className="font-bold text-2xl max-sm:text-lg text-[#EA580C]">
                {t("inscription.inscriptionTitle")}
              </span>
            </Typography>

            {/* <div className="md:mt-2 sm:mt:0 xl:mt-5"></div> */}
            <div className="md:mt-2 sm:mt:0 xl:mt-5"></div>
            {status === "pending" ? (
              <Typography
                color="gray"
                className="[font-family:'Inter-Regular',Helvetica]  text-center  md:mt-2 sm:mt:0 xl:mt-10 lg:mt-10"
              >
                {t("inscription.inscriptionWaitingText")}
              </Typography>
            ) : (
              <Typography
                color="gray"
                className="[font-family:'Inter-Regular',Helvetica] text-center sm:mt-2 md:mt-5 xl:mt-10 lg:mt-10  "
              >
                {!error?.code === "ERR_NETWORK"
                  ? "Veuillez remplir ces champs pour vous inscrire !"
                  : status === "success" && "Incription reussite"}
              </Typography>
            )}
            {step === 1 ? (
              <motion.form
                variants={anime}
                initial="visibleFromLEft"
                animate="visible"
                exit="final"
                className="max-w-md md:mt-2 sm:mt:0 xl:mt-5 mb-2 md:max-w-full"
                onSubmit={handleNext}
              >
                <div className="flex flex-wrap md:mb-1 xl:mb-6 -mx-3 sm:mt:2 mt-0">
                  <div className="flex flex-col w-full max-w-md gap-6 px-3 mb-1 md:w-1/2">
                    <TextFieldUI
                      label={t("inscription.firstname")}
                      isRequired={true}
                      type="text"
                      placeholder="Jane"
                      id="firstname"
                      name="firstname"
                      size="lg"
                      value={formData.firstname}
                      onChange={handleChange}
                      // labelProps={{
                      //   className: "before:content-none after:content-none",
                      // }}
                    />
                    {errors.firstname && (
                      <h3 className={`text-red-500 text-xs -mt-5`}>
                        {errors.firstname}
                      </h3>
                    )}
                  </div>
                  <div className="flex flex-col w-full max-w-md gap-6 px-3 mb-1 md:w-1/2">
                    <TextFieldUI
                      label={t("inscription.lastname")}
                      type="text"
                      placeholder="Doe"
                      id="lastname"
                      name="lastname"
                      size="lg"
                      value={formData.lastname}
                      onChange={handleChange}
                      isRequired={true}
                      // labelProps={{
                      //   className: "before:content-none after:content-none",
                      // }}
                    />
                    {errors.lastname && (
                      <h3 className={`text-red-500 text-xs -mt-5`}>
                        {errors.lastname}
                      </h3>
                    )}
                  </div>
                </div>
                <div className="flex flex-wrap md:mb-1 xl:mb-6 -mx-3">
                  <div className="flex flex-col w-full max-w-md gap-6 px-3 mb-1 md:w-1/2">
                    <TextFieldUI
                      label={t("inscription.address")}
                      type="text"
                      isRequired={true}
                      id="address"
                      name="address"
                      placeholder="123 Gatineau, CA"
                      value={formData.address}
                      size="lg"
                      onChange={handleChange}
                      // labelProps={{
                      //   className: "before:content-none after:content-none",
                      // }}
                    />
                    {errors.address && (
                      <h3 className={`text-red-500 text-xs -mt-5`}>
                        {errors.address}
                      </h3>
                    )}
                  </div>
                  <div className="flex flex-col w-full max-w-md gap-6 px-3 mb-1 md:w-1/2">
                    {/* <Input
                      type="text"
                      id="phonenumber"
                      name="phonenumber"
                      placeholder="(+1) 1234567890"
                      value={formData.phonenumber}
                      size="lg"
                      onChange={handleChange}
                      className="border-1 focus:border-2 border-gray-200 p-2  focus:border-[#457AA8] focus:outline-none focus:ring-0 rounded-xl xl:h-[50px]"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    /> */}
                    <PhoneNumberUI
                      isRequired={true}
                      label={t("inscription.phoneNumber")}
                      country={"ca"}
                      value={formData.phonenumber}
                      onChange={(phone) => {
                        setFormData({ ...formData, phonenumber: phone });
                      }}
                    />
                    {errors.phonenumber && (
                      <h3 className={`text-red-500 text-xs -mt-5`}>
                        {errors.phonenumber}
                      </h3>
                    )}
                  </div>
                </div>

                <div className="md:mt-2 sm:mt:0 xl:mt-10 lg:mt-10"></div>

                <Button
                  className="md:mt-2 sm:mt:0 xl:mt-4 text-lg bg-[#EA580C] rounded-full text-white  py-2"
                  color="white"
                  size="sm"
                  fullWidth
                  type="submit"
                >
                  {t("inscription.next")}
                </Button>
              </motion.form>
            ) : (
              <>
                <AnimatePresence key={2}>
                  {status === "pending" ? (
                    <PendingUI />
                  ) : (
                    <>
                      {error?.code === "ERR_NETWORK" ? (
                        <LoadDataFailUI />
                      ) : (
                        <>
                          {status === "success" ? (
                            <AnimatePresence key={3}>
                              <RequestSuccess classname={"my-20"} />
                            </AnimatePresence>
                          ) : (
                            <motion.form
                              variants={anime}
                              initial="hidden"
                              animate="visible"
                              exit="final"
                              className="max-w-md md:mt-2 sm:mt:0 xl:mt-5 mb-2 md:max-w-full"
                              onSubmit={handleNext}
                            >
                              <div className="flex flex-col max-w-md gap-6 mb-1 md:max-w-full">
                                <TextFieldUI
                                  label={t("inscription.email")}
                                  id="email"
                                  name="email"
                                  size="lg"
                                  isRequired={true}
                                  placeholder="name@mail.com"
                                  value={formData.email}
                                  onChange={handleChange}
                                  // labelProps={{
                                  //   className:
                                  //     "before:content-none after:content-none",
                                  // }}
                                />
                                {errors.email && (
                                  <h3 className={`text-red-500 text-xs -mt-5`}>
                                    {errors.email}
                                  </h3>
                                )}
                                {!errors.email &&
                                  error?.response?.status === 400 && (
                                    <h3
                                      className={`text-red-500 text-xs -mt-5`}
                                    >
                                      {
                                        (errors.email =
                                          "Un compte utilise déjà cette adresse e-mail")
                                      }
                                    </h3>
                                  )}
                              </div>
                              <div className="md:mb-2  xl:mb-6 -mx-3">
                                <div className="flex flex-col w-full max-w-md gap-6 px-3 mb-1 ">
                                  <TextFieldUI
                                    label={t("inscription.password")}
                                    type="password"
                                    name="password"
                                    size="lg"
                                    isRequired={true}
                                    placeholder="********"
                                    value={formData.password}
                                    onChange={handleChange}
                                    // labelProps={{
                                    //   className:
                                    //     "before:content-none after:content-none ",
                                    // }}
                                  />

                                  {errors.password && (
                                    <h3
                                      className={`text-red-500 text-xs -mt-5`}
                                    >
                                      {errors.password}
                                    </h3>
                                  )}
                                </div>
                                <div className="flex flex-col w-full max-w-md gap-6 px-3 mb-1 ">
                                  <TextFieldUI
                                    label={t("inscription.passwordConfirm")}
                                    type="password"
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    size="lg"
                                    isRequired={true}
                                    placeholder="********"
                                    value={formData.confirmPassword}
                                    onChange={handleChange}
                                    // labelProps={{
                                    //   className:
                                    //     "before:content-none after:content-none ",
                                    // }}
                                  />

                                  {errors.confirmPassword && (
                                    <h3
                                      className={`text-red-500 text-xs -mt-5`}
                                    >
                                      {errors.confirmPassword}
                                    </h3>
                                  )}
                                </div>
                              </div>
                              <div className=" md:mt-1 xl:mt-10 lg:mt-10"></div>

                              <button
                                type="button"
                                onClick={handleBack}
                                className="flex items-center justify-center px-4 py-2 mt-4 text-sm font-medium text-gray-700 bg-gray-100 border border-gray-300 rounded-md shadow-sm hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              >
                                <svg
                                  className="w-5 h-5 mr-2 -ml-1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M7.707 14.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L4.414 8H13a1 1 0 110 2H4.414l3.293 3.293a1 1 0 010 1.414z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                {t("inscription.backButtonText")}
                              </button>

                              <Button
                                className="mt-4 text-lg bg-[#EA580C] rounded-full text-white  py-2"
                                color="white"
                                size="lg"
                                fullWidth
                                type="submit"
                              >
                                {t("inscription.registerButtonText")}
                              </Button>
                            </motion.form>
                          )}
                        </>
                      )}
                    </>
                  )}
                </AnimatePresence>
              </>
            )}

            <div className="xl:mt-5 mt-0"></div>

            <Typography
              variant="h4"
              color="light-blue"
              className="items-center mt-4 text-sm font-normal text-[#667085]"
            >
              {t("inscription.accountText")},
              <Link to="/connexion" className="underline text-[#457AA8]">
                {t("inscription.connectionLink")}
              </Link>
            </Typography>
          </Card>
        </div>
      </div>
    </section>
  );
};

export default Inscription;
