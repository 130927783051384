import CatMenu from "./CatMenu";
import { useTranslation } from "react-i18next";

function Banner({ sendData }) {
  const { t } = useTranslation();
  return (
    <div className="max-md:flex-col mb-10 flex justify-between w-full md:h-[50px] lg:h-[50px] h-full bg-white items-center">
      <div>
        <h1 className="flex text-nowrap  items-center text-2xl font-medium text-black  font-Inter">
          {t("banner.title")}
        </h1>
        <p className="max-w-[70%] text-[#667085] mt-2">
          {t("banner.description")}
        </p>
      </div>

      <div className="flex  items-center  content-center mb-3 max-sm:justify-center space-x-4">
        <div className="mt-3">
          <CatMenu classname={"w-52"} />
        </div>
      </div>
    </div>
  );
}

export default Banner;
