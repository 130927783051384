import React from 'react'
import NewsLetter from '../../components/Admin_Cpt/NewsLetter/NewsLetter';

export default function NewsLetterPage() {
  return (
    <main className="bg-[#F9F9F9] px-10">
      <NewsLetter/>
    </main>
  )
}
