import { Box, Checkbox, FormControl, FormControlLabel } from "@mui/material";
import React, { useState } from "react";
import FormTextUI from "../../UI/FormTextUI";
import { useTranslation } from "react-i18next";

const dataRegion = [
  "Capitale-Nationale",
  "Saguenay-Lac-Saint-Jean",
  "Chaudière-Appalaches",
  "Lanaudière",
  "Îles-de-la-Madeleine",
  "Gaspésie",
  "Montérégie-Est",
  "Abitibi",
  "Montérégie-Ouest",
  "Bas-Saint-Laurent",
  "Laurentides",
  "Outaouais",
  "Mauricie-Centre-du-Québec",
  "Baie-James",
  "Laval",
  "Côte-Nord",
  "Montérégie-Centre",
  "Montréal",
  "Autres régions",
];

const useCheckBoxRegion = () => {
  const { t } = useTranslation();
  const [region, setRegion] = useState([]);

  //Select region

  const handleRegionChange = (event) => {
    const value = event.target.value;

    const regionIndex = region.indexOf(value);

    if (regionIndex === -1) {
      setRegion([...region, value]);
    } else {
      setRegion(region.filter((nameRegion) => nameRegion !== value));
    }
  };

  let regionContent = (
    <Box className="mt-5 text-sm">
      <FormControl>
        <FormTextUI classname={"text-start my-4"}>
          {t("quickApply.applyform_checkbox_field_label")}({" "}
          <span className="text-[#667085]">
            {" "}
            {t("quickApply.applyform_checkbox_field_msg")}{" "}
          </span>
          ) <span className="text-red-500 text-sm ">*</span>
        </FormTextUI>
        <div className="grid  grid-cols-3 max-lg:grid-cols-2 gap-x-20">
          {dataRegion.map((reg, index) => (
            <FormControlLabel
              key={index}
              label={reg}
              control={
                <Checkbox
                  checked={region.includes(reg)}
                  onChange={handleRegionChange}
                  value={reg}
                  id={reg}
                />
              }
            />
          ))}
        </div>
      </FormControl>
    </Box>
  );
  return { regionContent, region };
};

export default useCheckBoxRegion;
