import React from "react";
import TextFieldUI from "../../UI/TextFieldUI";
import InvalidInputControl from "../../UI/InvalidInputControl";

const InputUI = ({
  classname,
  id,
  isRequired,
  placeholderValue,
  readOnly,
  isInputNotValide,
 invalidMessage,
  label: labels,
  ...props
}) => {
  return (
    <div className="w-full my-2">
      <TextFieldUI
        classname={classname}
        id={id}
        isRequired={isRequired}
        label={labels}
        placeholderValue={placeholderValue}
        readOnly={readOnly}
        {...props}
      />
      <InvalidInputControl classname={"ml-2 max-lg:-mt-3"}>
        {isInputNotValide && invalidMessage}
      </InvalidInputControl>
    </div>
  );
};

export default InputUI;
