import React, { useState, useEffect } from "react";
import { Button } from "@material-tailwind/react";
import { FaSave, FaTrash } from "react-icons/fa";

import { motion } from "framer-motion";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../../services/HttpRequest/queryClientProvider/queryClientProvider";
import {
  isEmailValid,
  isPhoneValid,
  isTextValid,
} from "../Utils/ValidFormElement";
import {
  createCompany,
  editCompany,
} from "../../services/HttpRequest/adminRequest/compRequest";
import { useTranslation, Trans } from "react-i18next";
import InvalidInputControl from "../UI/InvalidInputControl";
import PhoneNumberUI from "../UI/PhoneNumberUI";
import TextFieldUI from "../UI/TextFieldUI";
// import {  } from "../../services/HttpRequest/adminRequest/compRequest";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

const AddCompForm = ({ onCancel, onConfirm, editedCompanyData, isEditing }) => {
  const { t } = useTranslation();
  const mutationFn = editedCompanyData ? editCompany : createCompany;
  const { mutate, error, status } = useMutation({
    mutationFn: mutationFn,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["getAllCompany"] });
    },
  });

  const [formContent, setFormContent] = useState({
    companyName: { value: "", didEdit: false },
    companyAddress: { value: "", didEdit: false },
    nameOfPersonOfContact: { value: "", didEdit: false },
    companyEmail: { value: "", didEdit: false },
    phoneNumber: { value: "", didEdit: false },
  });

  useEffect(() => {
    if (editedCompanyData) {
      setFormContent({
        companyName: {
          value: editedCompanyData.companyName || "",
          didEdit: false,
        },
        companyAddress: {
          value: editedCompanyData.companyAddress || "",
          didEdit: false,
        },
        nameOfPersonOfContact: {
          value: editedCompanyData.nameOfPersonOfContact || "",
          didEdit: false,
        },
        companyEmail: {
          value: editedCompanyData.companyEmail || "",
          didEdit: false,
        },
        phoneNumber: {
          value: editedCompanyData.phoneNumber || "",
          didEdit: false,
        },
      });
    }
  }, [editedCompanyData]);

  const isCompanyNameOk =
    formContent.companyName.didEdit &&
    !isTextValid(formContent.companyName.value);
  const isCompanyAdressNotOk =
    formContent.companyAddress.didEdit &&
    !isTextValid(formContent.companyAddress.value);
  const isNameOfPersonContactOk =
    formContent.nameOfPersonOfContact.didEdit &&
    !isTextValid(formContent.nameOfPersonOfContact.value);
  const isCompanyEmail =
    formContent.companyEmail.didEdit &&
    !isEmailValid(formContent.companyEmail.value);
  const isPhoneNumberNotOk =
    formContent.phoneNumber.didEdit &&
    !isPhoneValid(formContent.phoneNumber.value);

  const handleChangeField = (id, value) => {
    setFormContent((prevContent) => ({
      ...prevContent,
      [id]: { value, didEdit: true },
    }));
  };
  const handleBlurField = (id) => {
    setFormContent((prevContent) => ({
      ...prevContent,
      [id]: { value: prevContent[id].value, didEdit: true },
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    for (const field in formContent) {
      console.log("field", field);
      setFormContent((prevState) => ({
        ...prevState,
        [field]: { ...prevState[field], didEdit: true },
      }));
    }

    const dataToSend = {
      companyName: formContent.companyName.value,
      companyAddress: formContent.companyAddress.value,
      nameOfPersonOfContact: formContent.nameOfPersonOfContact.value,
      companyEmail: formContent.companyEmail.value,
      phoneNumber: formContent.phoneNumber.value,
    };
console.log('dataToSend', dataToSend)
    if (
      isTextValid(dataToSend.companyName) &&
      isTextValid(dataToSend.companyAddress) &&
      isTextValid(dataToSend.nameOfPersonOfContact) &&
      isEmailValid(dataToSend.companyEmail) &&
      isPhoneValid(dataToSend.phoneNumber)
    ) {
      if (editedCompanyData) {
        mutate({ data: { id: editedCompanyData.id, data: dataToSend } });
      } else {
        mutate({ data: dataToSend });
      }
    }
  };

  return (
    <motion.div
      initial={{ y: -30, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ delay: 0.3 }}
      className="p-4 mx-auto overflow-y-auto rounded-lg bg-gray-500/5"
    >
      {/* <ToastContainer /> */}
      {error?.response?.status === 400 && (
        <p className="mb-2 text-sm font-extrabold text-center text-red-500 border border-t-0 border-b-2 border-red-500 border-solid">
          {t("addcompform.error1")}
        </p>
      )}
      {error?.response?.status && error?.response?.status !== 400 && (
        <p className="mb-2 text-sm font-extrabold text-center text-red-500 border border-t-0 border-b-2 border-red-500 border-solid">
          {t("addcompform.error2")}
        </p>
      )}

      <form onSubmit={handleSubmit} className="space-y-2 overflow-y-auto">
        <div className="grid gap-4 md:grid-cols-2">
          <div>
            {/* <label
              htmlFor="companyName"
              className="block text-sm font-medium text-gray-700"
            >
              {t("addcompform.label")}
              <span className="text-red-500">*</span>
            </label> */}
            <TextFieldUI
            isRequired={true}
            label={t("addcompform.label")}
              disabled={status === "pending"}
              type="text"
              name="companyName"
              id="companyName"
              value={formContent.companyName.value}
              onChange={(event) =>
                handleChangeField("companyName", event.target.value)
              }
              onBlur={(event) => handleBlurField("companyName")}
            />
            <InvalidInputControl classname={'ml-3'}>
              {isCompanyNameOk && t("addcompform.comp_error")}
            </InvalidInputControl>
          </div>
          <div>
            {/* <label
              htmlFor="companyAddress"
              className="block text-sm font-medium text-gray-700"
            >
              {t("addcompform.address")} <span className="text-red-500">*</span>
            </label> */}
            <TextFieldUI
            label={t("addcompform.address")}
              disabled={status === "pending"}
              la
              type="text"
              name="companyAddress"
              id="companyAddress"
              value={formContent.companyAddress.value}
              onChange={(event) =>
                handleChangeField("companyAddress", event.target.value)
              }
              onBlur={(event) => handleBlurField("companyAddress")}
            />
            <InvalidInputControl classname={'ml-3'}>
              {isCompanyAdressNotOk && t("addcompform.address_error")}
            </InvalidInputControl>
          </div>
        </div>
        <div className="grid gap-4 md:grid-cols-2">
          <div>
            {/* <label
              htmlFor="nameOfPersonOfContact"
              className="block text-sm font-medium text-gray-700"
            >
              {t("addcompform.contact")}
              <span className="text-red-500">*</span>
            </label> */}
            <TextFieldUI
            isRequired={true}
            label={t("addcompform.contact")}
              disabled={status === "pending"}
              type="text"
              name="nameOfPersonOfContact"
              id="nameOfPersonOfContact"
              value={formContent.nameOfPersonOfContact.value}
              onChange={(event) =>
                handleChangeField("nameOfPersonOfContact", event.target.value)
              }
              onBlur={(event) => handleBlurField("nameOfPersonOfContact")}
            />
            <InvalidInputControl classname={'ml-3'}>
              {isNameOfPersonContactOk && t("addcompform.contact_error")}
            </InvalidInputControl>
          </div>
          <div>
            {/* <label
              htmlFor="companyEmail"
              className="block text-sm font-medium text-gray-700"
            >
             
              <span className="text-red-500">*</span>
            </label> */}
            <TextFieldUI
            isRequired={true}
            label= {t("addcompform.email")}
              disabled={status === "pending"}
              type="text"
              name="companyEmail"
              id="companyEmail"
              value={formContent.companyEmail.value}
              onChange={(event) =>
                handleChangeField("companyEmail", event.target.value)
              }
              onBlur={(event) => handleBlurField("companyEmail")}
              />
            <InvalidInputControl classname={'ml-3'}>
              {isCompanyEmail && t("addcompform.email_error")}
            </InvalidInputControl>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-4">
          <div>
            {/* <label
              htmlFor="phoneNumber"
              className="block text-sm font-medium text-gray-700"
            >
              
              <span className="text-red-500">*</span>
            </label> */}
            <PhoneNumberUI
            isRequired={true}
            label={t("addcompform.phone")}
              disabled={status === "pending"}
              type="text"
              name="phoneNumber"
              id="phoneNumber"
              value={formContent.phoneNumber.value}
              onChange={(phone) =>
                handleChangeField("phoneNumber", phone)
              }
              onBlur={(event) => handleBlurField("phoneNumber")}
            />
            <InvalidInputControl classname={'ml-2 -mt-5'}>
              {isPhoneNumberNotOk && t("addcompform.phone_error")}
            </InvalidInputControl>
          </div>
        </div>

        {status === "pending" ? (
          <div className="text-center my-28 justify-evenly ">
            {" "}
            <motion.span
              animate={{ scale: 1.6, opacity: [1, 0.7, 0.5, 0.3, 0.5, 0.7] }}
              transition={{ repeat: 100, duration: 3 }}
              className="justify-start text-2xl font-bold text-center text-green-500 "
            >
              {t("addcompform.pending")}
            </motion.span>{" "}
          </div>
        ) : (
          <div className="flex justify-evenly">
            <Button
              type="reset"
              variant="filled"
              color="red"
              className="flex justify-center h-12 pt-4 opacity-80 hover:bg-opacity-70"
              onClick={onCancel}
              disabled={status === "pending"}
            >
              <span className="justify-start max-sm:hidden text-md mx-1">
                {t("addcompform.cancel")}
              </span>
              <span className="justify-end text-md">
                <FaTrash className=" size-4" />
              </span>
            </Button>
            <Button
              type="submit"
              variant="filled"
              color={status === "pending" ? "gray" : "green"}
              onClick={status === "success" ? onCancel() : null}
              disabled={status === "pending"}
              className={`flex justify-center  h-12 pt-4 ml-4 hover:bg-opacity-70  `}
            >
              <span className="justify-start max-sm:hidden mx-1 ">
                {t("addcompform.save")}
              </span>
              <span className="justify-end text-md">
                <FaSave className="size-4" />
              </span>
            </Button>
          </div>
        )}
      </form>
    </motion.div>
  );
};

export default AddCompForm;
