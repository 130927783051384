export const LOGIN_URL = "/user/login";
export const SIGN_UP_USER = "/user/register";
export const GET_USER_URL = "/user/getUser";
export const REFRESH_TOKEN_URL = "/user/refresh";
export const DELETE_CATEGORY_URL = "/category/deleteCategory/";
export const DELETE_COMPANY_URL = "/company/deleteCompany/";
export const DELETE_JOB_URL = "/job/deleteJob/";
export const EDIT_COMPANY_URL = "/company/updateCompany/";
export const EDIT_CATEGORIE_URL = "/category/updateCategory/";
export const GET_ALL_MANAGER = "user/getAllManagers";
export const GET_ALL_ADMIN = "user/getAllAdmins";
export const ALL_JOB_URL = "/job/getAllJobs";
export const UPDATE_JOB_URL = "/job/updateJob/";
export const ALL_CAT_URL = "/category/getAllCategories";
export const ALL_COMPANIES = "/company/getAllCompanies";
export const COMPANY_WITH_JOBS_LIST = "/company/getAllCompaniesWithJobList";
export const ADMIN_GET_ALL_APPLICATION = "/jobApplication/getAllApplications";
export const ADMIN_GET_JOB_APPLICATION = "/jobApplication/getAllApplications";

export const ADMIN_DELETE_SUSER = "/admin/deleteSuperUser/";

export const ADMIN_GETSUPER_SUSER = "/admin/getSuperUsers";
/*
    Confirm user mail
    @param: userId: string, code: string, changedMail: string
    @output:  *
    @method: GET
*/
export const UPDATE_PASSWORD = "/user/updatePassword";
export const CONFIRM_MAIL = "/user/confirmEmail";

export const NEWSLETTER_POST = "/contact/newsletter";

export const NEWSLETTER_GET = "/contact/getNewsLetterContact";

export const SEND_VERIFICATION_CODE = "/user/sendVerificationCode";

export const CHANGE_PWD_WITH_CODE = "/user/changePasswordWithCode";

export const USER_EDIT = "/user/updateUser";

export const AMDIN_EDIT_SUPER_USER_BY_EMAIL = "/admin/editSuperUserWithEmail/";
export const AMDIN_EDIT_SUPER_USER_BY_ID = "/admin/editSuperUser/";
// /editSuperUserWithEmail/{email}
/*/deleteSuperUserWithEmail/{email}
    Resend confirmation mail
    @param: *
    @output:  *
    @method: POST
    @body: {email: string}
*/
export const RESEND_CONFIRMATION_MAIL = "/user/resendConfirmationEmail";

/*
    User forgot password
    @param: *
    @output:  {type: string, title: string, status: number, detail: string, instance: "string"}
    @method: POST
    @body: {email: string}
*/
export const FORGOT_PASSWORD = "/user/forgotPassword";

/*
    Reset password
    @param: *
    @output:  {type: string, title: string, status: number, detail: string, instance: "string"} or
    { sharedKey: string, recoveryCodesLeft: 0, recoveryCodes: string[], isTwoFactorEnabled: boolean,
        isMachineRemembered: boolean}
    @method: POST
    @body: { enable: boolean, twoFactorCode: string, resetSharedKey: boolean, resetRecoveryCodes: boolean,forgetMachine: boolean}
*/
export const RESET_PASSWORD = "/user/manage/2fa";

/*
    User mange info
    @param: *
    @output:  {type: string, title: string, status: number, detail: string, instance: "string"} or { email: string, isEmailConfirmed: boolean}
    @method: GET
*/
export const USER_MANAGE_INFO = "/user/manage/info";
export const CREATE_USER_URL = "/user/createUser";

/*
    Create admin 
    @param: *
    @output:  *
    @method: POST, 
    @body: { email: string, password: string }
*/
export const CREATE_ADMIN_URL = "/user/createAdmin";

/*
    Create manager 
    @param: *
    @output:  *
    @method: POST, 
    @body: { email: string, password: string }
*/
export const CREATE_MANAGER_URL = "/user/createManager";

/*
    Update password
    @param: *
    @output:  *
    @method: POST, 
    @body: { oldPassword: string,newPassword: string }

/*
    UpLoad resume
    @param: *
    @output:  *
    @method: POST, 
    @body: file: string($binary)
*/
export const UPLOAD_RESUME = "/user/uploadResume";
export const RETRIEVE_RESUME = "/user/getResume";
export const CREATE_CAT_URL = "/category";
export const CREATE_COMPANY = "/company";
export const CREATE_COMPANY_BY_LA_PROUE = "/company/createCompanyByLaproue";
export const CREATE_JOB_FORM_COMPANY = "/job/createJobForCompany";
export const JOB_APPLICATION_ACCESS_URL = "/jobApplication";

// send Application

// body:{resume:file,email:string,firstName:string,lastName:string,address:string,phoneNumber:sting,jobId:string,interestRegion:string}

export const USER_APPLICATION_URL = "/unregisterUserApplication";

export const USER_CREATE_CV = "/user/uploadDocument";

/** 
    user create cv
   * @param: *
   * @output : *
   * @method: POST, 
   * @body: {file}
*/

export const USER_GET_ALL_CV = "/user/getAllUserDocumentId";

/** 
   gall all cvId
   * @param: *
   * @output : *
   * @method: GET, 
   * @body:[id,id,id]
*/
export const USER_DELETE_CV = "/user/deleteDocument/";

/** 
delete a cv
   * @param: *
   * @output : *
   * @method: DELETE, 
   * @body:id
*/

export const USER_VIEW_CV = "/user/getDocumentById/";

/** 
delete a cv
   * @param: *
   * @output : *
   * @method: GET, 
   * @body:id
*/
// get all appliction for an user

export const USER_GET_ALL_APPLICATION = "/jobApplication/allUserApplications/";
export const USER_GET_ALL_MY_APPLICATION =
  "/jobApplication/getAllMyApplications";
export const REGISTERED_USER_APP = "/userApplication";
export const UNREGISTERED_USER_APP = "/unregisterUserApplication";

// contact laproue
export const POST_CONTACT_US = "/contact/postContact/";
export const USER_ARCHIVES_MY_APPLICATION =
  "/jobApplication/archiveUserApplication/";
export const USER_DELETE_MY_APPLICATION =
  "/jobApplication/deleteUserApplication/";
export const USER_LOGOUT = " /user/logout";
export const GET_ONE_COMPANY = "/company/getCompanyById/";
export const GET_ONE_CAT = "/category/getCategoryById/";
export const GET_CURRENT_USER = "/user/getUser";
export const CONTACT_POST_STUDENTS = "/contact/postStudent";
export const POST_QUICK_APPLY = "jobApplication/quickApplication";
