import React from "react";
import sec2img1 from "../../../assets/first.png";
import sec2img2 from "../../../assets/sec2img2.png";
import sec2img3 from "../../../assets/mil.jpeg";
import sec2img4 from "../../../assets/sec2img4.png";

import { Link, useNavigate } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";


const SectionThree = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/Contact");
  };

  const handleClickEmploi = () => {
    navigate("/Job");
  };

  return (
    <section className="max-w-md mx-auto overflow-hidden bg-white md:max-w-full ">
      <div className=" mt-7 ">
        <div className="space-x-3 shadow-sm md:flex ">
          <div className="p-2 px-6 mx-auto " data-aos="fade-right">
            <h1 className="text-[26px] [font-family:'Inter-Bold',Helvetica] font-bold ">
              <span className="text-[black]">
                {t("Ap-sectionThree.text1")}{" "}
              </span>
              <span className="">{t("Ap-sectionThree.text2")}</span>
            </h1>
            <p className="text-justify text-[#667085] pt-5">
              {t("Ap-sectionThree.text3")}
            </p>
            <div className="pt-8">
              <button
                className="px-2 py-2 hover:border-2 hover:text-orange-500 hover:border-white [font-family:'Inter-Bold',Helvetica] font-bold  text-white  duration-500 bg-gradient-to-r from-[#5190B7] to-[#457AA8] rounded-full btn md:ml-2 md:static shadow-[4px_7px_4.7px_-6px_#00000040]  hover:bg-indigo-500 "
                onClick={handleClickEmploi}
              >
                {t("hP-sectionSix.button")}
              </button>
            </div>
            {/* <button
              className="px-2 py-2 hover:border-2 hover:text-orange-500 hover:border-white font-bold text-white duration-500 bg-gradient-to-r from-[#5190B7] to-[#457AA8] rounded-full btn shadow-[4px_7px_4.7px_-6px_#00000040] hover:bg-indigo-500"
              onClick={handleClickEmploi}
            >
              {t("hP-sectionSix.button")}
            </button> */}
          </div>
          <div className="p-2 md:shrink-0 " data-aos="fade-left">
            <img
              src={sec2img1}
              className="lightgray 50% / cover no-repeat] flex-shrink-0 w-[500px] h-auto px-10"
              alt="imag"
            />
            <div className="pt-5 flex justify-center lg:justify-start"></div>
          </div>
        </div>

        <div className="mt-7">
          {/* <h2 className="font-bold  p-3 text-start flex">
            Les valeurs qui nous définissent:
          </h2> */}
          <div className="space-x-16 sm:space-x-2 md:mx-20 sm:gap-4 md:flex ">
            <div
              className=" items-center justify-center "
              data-aos="fade-right"
            >
              <h2 className="font-bold text-orange-600 p-3">
                {t("Ap-sectionThree.text8")}
              </h2>
              <img
                src={sec2img2}
                className="lightgray 50% / cover no-repeat] flex-shrink-0 w-full md:w-auto h-auto"
                alt="imag"
              />
            </div>
            <div className=" items-center justify-center " data-aos="fade-up">
              <h2 className="font-bold text-blue-600 p-3">
                {t("Ap-sectionThree.text9")}
              </h2>
              <img
                src={sec2img3}
                className="lightgray 50% / cover no-repeat] flex-shrink-0 w-full md:w-auto h-auto"
                alt="imag"
              />
            </div>
            <div className=" items-center justify-center " data-aos="fade-left">
              <h2 className="font-bold text-orange-600 p-3">
                {t("Ap-sectionThree.text10")}
              </h2>
              <img
                src={sec2img4}
                className="lightgray 50% / cover no-repeat] flex-shrink-0 w-full md:w-auto h-auto"
                alt="imag"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="w-full mt-10 flex justify-between items-center h-40 px-10"
        style={{
          backgroundImage: `linear-gradient(to right, #0077FF, #FFA500)`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
        data-aos="fade-up"
      >
        <div className="text-white justify-start" data-aos="fade-right">
          <p className="text-3xl font-bold">
            {t("Ap-sectionThree.text5")}
            {t("Ap-sectionThree.text6")}
          </p>
        </div>

        <div className="justify-end" data-aos="fade-left">
          <button
            className="px-2 py-2  [font-family:'Inter-Bold',Helvetica] font-bold  text-[#EA580C] hover:border-2 border-[#EA580C] hover:text-white hover:border-white duration-500 bg-white rounded-full btn md:ml-2 md:static shadow-[4px_7px_4.7px_-6px_#00000040]  hover:bg-indigo-500 text-center"
            onClick={handleClickEmploi}
          >
            {t("Ap-sectionThree.text7")}
          </button>
        </div>
      </div>
    </section>
  );
};

export default SectionThree;
