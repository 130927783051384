import React, { useState } from "react";
import { FaAlignCenter, FaAngleDown } from "react-icons/fa";
import { CatItem } from "./CatItem";
import { motion } from "framer-motion";
import { Disclosure, Menu } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { categorySliceAction } from "../../../store/slice/categorySlice";
import { jobsSliceAction } from "../../../store/slice/jobsSlice";
import { useTranslation, Trans } from "react-i18next";

// grayColor
export const colorsGrey = {
  gray100: "#F9FAFB",
  gray400: "#667085",
  gray950: "#3D3D3D",
};
// blue Color

export const colorsBlue = {
  blue350: "#0284C7",
};
// orange Color
export const colorsOrange = {
  orange600: "#EA580C",
};

function CatMenu({ classname, getCatId }) {
  // ----------------redux action ------------------------
  const allCatWithJob = useSelector((state) => state.category.allCatWithJob);
  const activeCat = useSelector((state) => state.category.activeCat);
  const dispatch = useDispatch();
  // -------------------------------------------------------
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedCat, setSelectedCat] = useState({
    id: undefined,
  });
  // handle selected cat
  const handleSelectedCat = (id) => {
    setSelectedCat({ id });
    dispatch(categorySliceAction.getCatByIdForFilter({ selectedCatId: id }));
    // dispatch(categorySliceAction.activeOneCat({selectedCatId:id}))
    // console.log("id", id)
    dispatch(jobsSliceAction.filterJobsByCatId({ categoryId: id }));
    if (getCatId) {
      getCatId(id);
    }
  };
  // handle toogle cat
  const handleToggleCatMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // icon style
  const iconStyle = `text text-[#667085]`;

  // ---------------- redux State ----------------

  const selectedCatValue = useSelector((state) => state.category.CatForOneJob);

  // ------------------------------------------------

  const { t } = useTranslation();

  return (
    <Disclosure
      as={"div"}
      onClick={handleToggleCatMenu}
      className="z-10  float-left  cursor-pointer "
    >
      {({ open }) => (
        <>
          <Menu as={"div"} className={"relative"}>
            <Menu.Button
              className={` border-solid  border border-opacity-50 bg-white px-2 rounded-md shadow-md border-spacing-2 items-center h-[44px] cont flex justify-between border-[#667085] ${classname}`}
            >
              <div className="flex h-full items-center space-x-2 text-[#667085]">
                <FaAlignCenter size={15} className={iconStyle} />
                <span className="overflow-hidden ">
                  {selectedCatValue
                    ? selectedCatValue.categoryName
                    : t("catmenu.label1")}
                </span>
              </div>
              <motion.div
                // animate={{ rotate: isMenuOpen ? 180 : 0 }}
                className="text-[#667085]"
              >
                <FaAngleDown size={15} className={iconStyle} />
              </motion.div>
            </Menu.Button>

            <Menu.Items>
              {({ open }) => (
                <motion.ul
                  initial={{ opacity: 0 }}
                  animate={{ y: [-20, 10, 0], opacity: 1 }}
                  transition={{ duration: 0.3, opacity: 0 }}
                  exit={{ y: -10, animationDuration: 0.2 }}
                  className={`absolute  z-10 bg-white text-[#3D3D3D]  max-h-96 overflow-y-scroll  mt-2  rounded-md shadow-xl border  border-opacity-20 items-center  border-[#667085] ${classname}`}
                >
                  <CatItem
                    cat={{ id: undefined, categoryName: t("catmenu.label2") }}
                    onActiveCat={handleSelectedCat}
                    classname={"h-[44px] "}
                    activeCatId={selectedCat?.id}
                  />
                  {allCatWithJob?.map((cat, index) => (
                    <CatItem
                      cat={cat}
                      onActiveCat={handleSelectedCat}
                      classname={"h-9"}
                      activeCatId={selectedCat?.id}
                      key={cat.id}
                    />
                  ))}
                </motion.ul>
              )}
            </Menu.Items>
          </Menu>
        </>
      )}
    </Disclosure>
  );
}

export default CatMenu;
