import { Fragment, useEffect, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { FaCaretDown } from "react-icons/fa";
import { US, FR } from "country-flag-icons/react/3x2";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function LanguageSelector() {
  const { t } = useTranslation();
  const [activeLanguage, setActiveLanguage] = useState({
    langue: t("langselector.fr"),
    flag: <FR className="h-4 w-4" />,
  });

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
      if (savedLanguage === "en") {
        setActiveLanguage({
          langue: t("langselector.en"),
          flag: <US className="h-4 w-4" />,
        });
      } else if (savedLanguage === "fr") {
        setActiveLanguage({
          langue: t("langselector.fr"),
          flag: <FR className="h-4 w-4" />,
        });
      }
    }
  }, []);

  const handleActiveLanguage = (selectedLanguage) => {
    i18n.changeLanguage(selectedLanguage);
    if (selectedLanguage === "en") {
      setActiveLanguage({
        langue: t("langselector.en"),
        flag: <US className="h-4 w-4" />,
      });
    } else if (selectedLanguage === "fr") {
      setActiveLanguage({
        langue: t("langselector.fr"),
        flag: <FR className="h-4 w-4" />,
      });
    }
  };

  return (
    <Disclosure
      as="nav"
      className="z-50 relative cursor-pointer focus:ring-1 focus:ring-white focus:ring-offset-1 focus:ring-offset-white mt-1 text-sm [font-family:'Inter-Bold',Helvetica] font-bold tracking-[0] text-white leading-[normal] mx-auto flex py-1 px-1  bg-white rounded-md shadow-sm focus:outline-none focus:border-indigo-500 sm:text-sm"
    >
      {({ open }) => (
        <>
          <div className="flex items-center">
            <Menu as="div" className="relative">
              <div>
                <Menu.Button className="flex">
                  <span className="text-[#0284C7] py-1 text-sm font-medium flex items-center">
                    <span className="hidden lg:flex items-center">
                      <span className="mr-2 flex items-center justify-center">
                        {activeLanguage.flag}
                      </span>
                      <span>{activeLanguage.langue}</span>
                    </span>
                    <span className="lg:hidden flex items-center">
                      {activeLanguage.flag}
                    </span>
                    <FaCaretDown className="h-4 w-4" />
                  </span>
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  className={`absolute w-25 right-0 z-10 mt-1  origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
                >
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`px-4 w-full h-full py-1 hover:text-orange-600 text-sm text-gray-700 ${
                          activeLanguage.langue === t("langselector.en") &&
                          "bg-[#DFF4FF]"
                        } flex rounded`}
                        value="en"
                        onClick={() => handleActiveLanguage("en")}
                      >
                        <span className="mr-2">
                          <US className="h-4 w-4" />
                        </span>
                        <span className="hidden md:inline-block">
                          {t("language.English")}
                        </span>
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`px-4 w-full hover:text-orange-600 h-full py-1 text-sm text-gray-700 ${
                          activeLanguage.langue === t("langselector.fr") &&
                          "bg-[#DFF4FF]"
                        } flex rounded`}
                        value="fr"
                        onClick={() => handleActiveLanguage("fr")}
                      >
                        <span className="mr-2">
                          <FR className="h-4 w-4" />
                        </span>
                        <span className="hidden md:inline-block">
                          {t("language.French")}
                        </span>
                      </button>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </>
      )}
    </Disclosure>
  );
}
