import React, { useEffect } from "react";
import hpSecFive from "../../../assets/hp-secFive.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AOS from 'aos';
import 'aos/dist/aos.css';

const SectionFive = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({
      duration: 1000, // Durée de l'animation en millisecondes
    });
  }, []);

  const handleClick = () => {
    navigate("/Contact");
  };

  return (
    <section
      data-aos="fade-right"
      className="w-full mx-auto overflow-hidden bg-white px-2 py-2 rounded-xl flex items-center"
    >
      <div className="space-x-5 lg:flex">
        <div className="p-2 mx-auto flex items-center">
          <div className="p-4">
            <h1 className="text-[26px] [font-family:'Inter-Bold',Helvetica] font-bold text-black text-center lg:text-left">
              {t("hP-sectionFive.title")}
            </h1>
            <div className="justify-content">
              <p className="pt-5 text-[20px] lg:w-[618px] 2xl:w-[90%] text-justify  lg:text-left">
                {t("hP-sectionFive.content")}
              </p>
            </div>
            <div className="pt-5 text-center lg:text-left">
              <button
                className="px-2 py-2 hover:border-2 hover:text-orange-500 hover:border-white [font-family:'Inter-Bold',Helvetica] font-bold text-white duration-500 bg-gradient-to-r from-[#5190B7] to-[#457AA8] rounded-full btn md:ml-2 md:static shadow-[4px_7px_4.7px_-6px_#00000040] hover:bg-indigo-500"
                onClick={handleClick}
              >
                {t("hP-sectionFive.button")}
              </button>
            </div>
          </div>
        </div>
        <div className="md:shrink-0 p-4">
          <img
            src={hpSecFive}
            className="lightgray 50% / cover no-repeat flex-shrink-0 lg:w-[500px]"
            alt="imag"
          />
        </div>
      </div>
    </section>
  );
};

export default SectionFive;
