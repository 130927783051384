import Cvcpt from "../components/Cv/Cv";

const CvPage = () => {
  return (
    <main className="">
      <Cvcpt />
    </main>
  );
};

export default CvPage;
