import React from "react";
import SectionOne from "../../components/QuickApply_Cpt/SectionOne";
import SectionTwo from "../../components/QuickApply_Cpt/SectionTwo";
import QuickApplyContextProvider from "../../components/QuickApply_Cpt/context/QuickApplyContextProvider";

const QuickApplyPage = () => {
  return (
    <>
      <SectionOne />
      <QuickApplyContextProvider>
        <SectionTwo />
      </QuickApplyContextProvider>
    </>
  );
};

export default QuickApplyPage;
