import React, { useContext, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import DetailJob from "../components/Cpt_Emploi/DetailJob/DetailJob";
import ListJob from "../components/Cpt_Emploi/ListJob/ListJob";
import { useNavigate } from "react-router-dom";
import CatContext from "../context/CatContext";
import JobContext from "../context/JobContext";
import { useTranslation, Trans } from "react-i18next";

const JobDescPage = ({ jobDataz }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const navigate = useNavigate();

  const handleJob = () => {
    navigate("/Job");
  };
  return (
    <main className="overflow-x-hidden ">
      <h1 className="mt-3 text-2xl font-bold text-center border-b border-l border-r border-blue-500">
        {t("jobpage.title1")}
      </h1>
      <div className="flex justify-center p-8">
        <DetailJob />
      </div>
      <div className="flex-1 overflow-y-auto">
        <h2 className="mb-4 text-xl text-black p-2 font-bold border border-b border-[#f0f2f3] rounded-md">
          {t("jobpage.title2")}
        </h2>
        <div className="px-2">
          <ListJob
            switchState={true}
            withPagination={false}
            numItemsPerPage={3}
          />
        </div>
        <div className="mt-4 text-center">
          <button
            className="px-4 py-2 [font-family:'Inter-Bold',Helvetica] font-bold hover:border-2 hover:text-orange-500 hover:border-white  text-white  duration-500 bg-gradient-to-r from-[#5190B7] to-[#457AA8] rounded-xl btn md:ml-2 md:static shadow-[4px_7px_4.7px_-6px_#00000040]"
            onClick={handleJob}
          >
            {t("jobpage.btn1")}
          </button>
        </div>
      </div>
      {/* {isMobile && (
        <div className="mt-4 text-center">
          <button
            className="px-4 py-2 [font-family:'Inter-Bold',Helvetica] font-bold hover:border-2 hover:text-orange-500 hover:border-white  text-white  duration-500 bg-gradient-to-r from-[#5190B7] to-[#457AA8] rounded-xl btn md:ml-2 md:static shadow-[4px_7px_4.7px_-6px_#00000040] "
            onClick={handleJob}
          >
            Voir plus d'emplois
          </button>
        </div>
      )} */}
    </main>
  );
};

// Exportation du composant
export default JobDescPage;
