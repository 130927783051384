import { USER_EDIT } from "../../api";
import { axiosUseToken } from "../../axiosConfig";

export const editUser = async ({ data }) => {
    // console.log('data', data)

      const response = await axiosUseToken.post(USER_EDIT, data);
    // console.log('response', response)
      return response;


};
