import { motion } from "framer-motion";
import { Loader } from "react-feather";
import { FaCheck } from "react-icons/fa";
import { useTranslation, Trans } from "react-i18next";

const RequestSuccess = ({ classname, children }) => {
  const { t } = useTranslation();
  return (
    <>
      <motion.div
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: [0, 10, 0], opacity: 1 }}
        exit={{ y: [0, 10, 0], opacity: 0 }}
        className="justify-center flex-1 mx-auto text-center w-full "
      >
        <FaCheck size={80} className={`text-[#5190B7] w-full  ${classname}`} />
        <h1 className="mb-20 font-mono text-gray-900">
          {children ? children : t("jobformreg.success")}
        </h1>
      </motion.div>
    </>
  );
};

export default RequestSuccess;
