import { isTextValid } from "../../Utils/ValidFormElement"

function TextItems({items}) {
  return (
    <ul className="pb-4 text-justify space-y-2 ">
    {items
      .split(";")
      .map((text, index) => (
        <li key={text + index}>{isTextValid(text)&&"-"+ text}</li>
      ))}
  </ul>

  )
}

export default TextItems