import {
  ALL_COMPANIES,
  CREATE_COMPANY_BY_LA_PROUE,
  DELETE_COMPANY_URL,
  EDIT_COMPANY_URL,
  GET_ONE_COMPANY,
} from "../../api";
import { axiosUseToken } from "../../axiosConfig";

// ADD COMPANY
export const createCompany = async ({ data }) => {
  const response = await axiosUseToken.post(CREATE_COMPANY_BY_LA_PROUE, data);
  // console.log("response", response);/
  return response;
};

// GET ALL COMPANY

export const getAllCompany = async () => {
  const response = await axiosUseToken.get(ALL_COMPANIES);
  return response;
};

export const deleteCompagny = async ({ data }) => {
  const response = await axiosUseToken.delete(DELETE_COMPANY_URL + data);
  // console.log('voici la reponse de la requete', response);
  return response;
};



export const editCompany = async ({ data }) => {
  // console.log('contenuaaaaaaaaaaaaaaaaaaaaa', data);
  const response = await axiosUseToken.post((EDIT_COMPANY_URL + data.id),data.data);
  // console.log('voici la reponse de la requete', response);
  return response;
}; 

export const getOneComp = async (id) => {

    const response = await axiosUseToken(GET_ONE_COMPANY + id);
    return response;

};
