import React, { useMemo, useState, useEffect, useContext } from "react";
import { useTable } from "react-table";
import {
  FaEdit,
  FaTrash,
  FaFastBackward,
  FaEye,
  FaExclamationTriangle,
  FaExclamationCircle,
} from "react-icons/fa";
import { Button } from "@material-tailwind/react";
import Modal from "../../Modal/Modal.js";
import ModalUI from "../../../../UI/ModalUI.js";
import Pagination from "../../../../Pagination/Pagination.js";
import AppliContext from "../../../../../context/AdminCandidacyContext/AppliContext";
import { AnimatePresence, motion } from "framer-motion";
// import { Button } from "@material-tailwind/react";
import { dateFormatterLong } from "../../../../Utils/dateFormatter";
import { FaPlus, FaSearch } from "react-icons/fa";
import { Input } from "@material-tailwind/react";
import TextItems from "../../../../Cpt_Emploi/DetailJob/TextItems";
import useViewPdf from "../../../../Hooks/useViewPdf.js";
import PendingModal from "../../../../QuickApply_Cpt/UI/PendingModal.js";
import ModalInfos from "../../../../UI/RequestUI/ModalInfos.js";
import { useTranslation, Trans } from "react-i18next";

const DétailUserAppli = ({ data, onDelete, onEdit, onBlock }) => {
  const { t } = useTranslation();

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isSaveModalOpen, setSaveModalOpen] = useState(false);
  const [isAddCompModalOpen, setAddCompModalOpen] = useState(false);
  const [selectedComp, setSelectedComp] = useState(null);
  const [searchText, setSearchText] = useState("");
  // const [filteredData, setFilteredData] = useState(data);
  const [modalDescription, setModalDescription] = useState(false);
  const [compForModalDescription, setCompForModalDescription] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const { status: viewDocStatus, handleViewDoc } = useViewPdf();
  const [isModalDoc, setIsModalDoc] = useState(false);
  const onDownloadCv = (data) => {
    setIsModalDoc(true);
    if (data.resume !== t("userdetails.n_a")) {
      handleViewDoc(data.resume);
    } else {
    }
  };
  // useEffect(() => {

  //   setFilteredData(data || []); // Ensure data is an array
  // }, [data]);

  const filteredData = useMemo(() => {
    return data.filter((candidat) =>
      candidat.userName.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [data, searchText]);
  const pageCount = Math.ceil(filteredData.length / pageSize);
  const { activeAppli, appliForJob } = useContext(AppliContext);
  // console.log("je suis activeAppli du job selectionner", activeAppli);
  // console.log("je suis le nombre",  appliForJob.length);
  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return filteredData.slice(startIndex, endIndex);
  }, [currentPage, filteredData, pageSize]);

  // handle page change
  const onPageChange = (pageNumber) => {
    const pageToSave = JSON.stringify(pageNumber);
    localStorage.setItem("compPage", pageToSave);
    setCurrentPage(pageNumber);
  };

  const getSavePage = localStorage.getItem("compPage");
  useEffect(() => {
    if (getSavePage) {
      const activePage = JSON.parse(getSavePage);
      setCurrentPage(activePage);
    }
  }, [getSavePage, data]);
  const [isSeeMore, setIsseeMore] = useState(false);

  const toogleSeeMore = () => {
    setIsseeMore((prevState) => !prevState);
  };
  //
  const HandleOpenModalDescription = (selectedComp) => {
    // setModalDescription(true);
    // setCompForModalDescription(selectedComp);
    // console.log("je suis la ligne", selectedComp);
  };

  const HandleCloseModalDescription = () => {
    setModalDescription(false);
  };

  const columns = useMemo(() => {
    if (!data || data.length === 0) {
      return [];
    }

    return [
      ...Object.keys(data[0])
        .map((key) => ({
          Header: key,
          accessor: key,
          Cell: ({ row }) => (
            <div
              className="hover:text-[#457AA8] cursor-pointer"
              onClick={() => HandleOpenModalDescription(row.original)}
            >
              {row.original[key]}
            </div>
          ),
        }))
        .filter(
          (column) =>
            column.accessor !== "resume" && column.accessor !== "userId"
        ),
      {
        Header: t("userdetails.action"),
        accessor: "actions",
        Cell: ({ row }) => (
          <div className="flex justify-center ">
            <button
              className=" flex items-center hover:text-white font-semibold space-x-2 text-[#EA580C]  hover:bg-[#457AA8]  mt-2 py-1 px-2 rounded-full"
              onClick={() => onDownloadCv(row.original)}
            >
              <FaEye />
              <span className="">{t("userdetails.cv_view")}</span>
            </button>
          </div>
        ),
      },
    ];
  }, [data]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: paginatedData,
    });

  // useEffect(() => {
  //   setFilteredData(
  //     data?.filter((comp) =>
  //       comp.companyName.toLowerCase().includes(searchText.toLowerCase())
  //     )
  //   );
  // }, [data, searchText]);

  const handleDelete = (comp) => {
    setSelectedComp(comp);
    setDeleteModalOpen(true);
  };

  const confirmDelete = () => {
    onDelete(selectedComp);
    setDeleteModalOpen(false);
  };

  const cancelDelete = () => {
    setSelectedComp(null);
    setDeleteModalOpen(false);
  };

  const confirmSave = () => {
    onEdit(selectedComp);
    setSaveModalOpen(false);
    setSelectedComp(null);
  };

  const cancelSave = () => {
    setSaveModalOpen(false);
  };
  const handleReturn = () => {
    localStorage.removeItem("activeAppli");
    window.location.reload();
  };

  //   const dateString = activeAppli.creationDate;
  // const dateObject = new Date(dateString);
  // const dateCreation = dateObject.toISOString().split('T')[0];

  // console.log(dateCreation); // Output: "2024-05-09"

  function extractDate(isoDateString) {
    const dateObject = new Date(isoDateString);
    const formattedDate = dateObject.toISOString().split("T")[0];
    return formattedDate;
  }

  // Exemple d'utilisation
  // const isoDateString = "2024-05-09T21:51:27.522";
  // const extractedDate = extractDate(activeAppli.creationDate);
  // console.log("active", activeAppli);

  return (
    <div>
      <div className="flex justify-between">
        <button
          className="bg-orange-500 flex items-center hover:bg-orange-700 text-white mt-2 py-1 px-2 rounded-full"
          onClick={() => handleReturn()}
        >
          {/* Retourner aux candidatures */}
          <span className="mr-2  md:inline ">
            <FaFastBackward />
          </span>
          <span className="md:inline"> {t("userdetails.return_btn")}</span>
        </button>
      </div>
      <div className="p-2 bg-[#F9FAFB] mt-10 text-[#667085] border border-collapse border-[#EAECF0]">
        {/* <th> */}
        {/* <label className="p-2 text-xl text-[#101828] font-semibold">
            Liste des candidats au poste de :
          </label> */}
        {/* </th> */}

        <div className="w-full px-10 py-2 z-0 mx-auto rounded-xl overflow-hidden overflow-y-auto max-h-[100vh]   border-[#EAECF0]  hover:bg-gray-50 md:max-w-full border-2  bg-white mt-2">
          <AnimatePresence>
            <motion.div
              initial={{ y: -20, opacity: 0.1 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 20, opacity: 0 }}
              ref={activeAppli}
              className="flex-1 px-1 "
            >
              <p className="text-[#101828] text-base text-center font-bold md:text-lg font-Inter-regular">
                <span className="font-semibold">
                  {t("userdetails.description_msg")}
                </span>
                : {activeAppli.jobName}
              </p>
              <p className="flex justify-between">
                <span className="justify-start text-base font-bold text-[#667085] md:text-lg font-Inter-regular">
                  <span className="text-[#667085] font-extralight">
                    {t("userdetails.publish")}{" "}
                    {extractDate(activeAppli.creationDate)}
                  </span>
                </span>
                <span className="justify-end text-[#667085] font-extralight">
                  {t("userdetails.expires")}{" "}
                  {activeAppli && activeAppli.applicationDeadLine
                    ? extractDate(activeAppli.applicationDeadLine)
                    : "NA"}
                </span>
              </p>
              <p className="text-[#667085] font-extralight flex justify-between">
                <span>
                  {t("userdetails.statut")} {activeAppli.jobIsActif}
                  <span
                    className={`font-meduim ${
                      activeAppli.jobIsActif ? "text-[#0284C7]" : "text-red-500"
                    }`}
                  >
                    {activeAppli.jobIsActif
                      ? t("userdetails.active")
                      : t("userdetails.expire")}
                  </span>
                </span>

                <span>
                  {t("userdetails.post")} {activeAppli.requiredHeadCount}
                </span>
              </p>

              <div className="justify-between flex items-center">
                <div className="justify-start">
                  <p className=" font-meduim flex items-center">
                    <span className="text-[#EA580C]">
                      {t("userdetails.applicant")}{" "}
                      {appliForJob ? appliForJob.length : "NA"}
                    </span>
                  </p>
                </div>
                {/* <button className="justify-center bg-[#0284C7] rounded-full px-2 text-white w-[224px] h-[35px] text-[16px] font-semibold hover:border-2 hover:border-[#EA580C] hover:bg-white hover:text-[#EA580C]">
              voir tous les détails de l'offre
            </button> */}
                <div className="justify-end">
                  <p className=" font-meduim flex items-center">
                    <span className="">
                      {t("userdetails.wage")}{" "}
                      {activeAppli && activeAppli.salary && activeAppli.currency
                        ? `${activeAppli.salary} ${activeAppli.currency}`
                        : "NA"}
                    </span>
                  </p>
                </div>
              </div>
              <div className="mb-4">
                <AnimatePresence>
                  {!isSeeMore ? (
                    <motion.div
                      initial={{ y: 29, opacity: 0 }}
                      animate={{ y: [29, -2, 0], opacity: 1 }}
                      exit={{ y: -300, opacity: 0 }}
                    >
                      {/* <Button
                  color="blue"
                  size="lg"
                  variant="text"
                  onClick={toogleSeeMore}
                  className="hover:scale-110"
                >
                  Voir plus
                </Button> */}
                      <div className="flex justify-center">
                        <button
                          className="justify-center bg-[#0284C7] rounded-full px-2 text-white w-[224px] h-[35px] text-[16px] font-semibold hover:border-2 hover:border-[#EA580C] hover:bg-white hover:text-[#EA580C]"
                          onClick={toogleSeeMore}
                        >
                          {t("userdetails.more")}
                        </button>
                      </div>
                      <div></div>
                    </motion.div>
                  ) : (
                    <motion.div
                      initial={{ y: -29, opacity: 0 }}
                      animate={{ y: [-29, 2, 0], opacity: 1 }}
                      exit={{ y: -300, opacity: 0 }}
                    >
                      <div className="">
                        <div className="flex justify-center">
                          <button
                            className="justify-center bg-[#0284C7] rounded-full px-2 text-white w-[224px] h-[35px] text-[16px] font-semibold hover:border-2 hover:border-[#EA580C] hover:bg-white hover:text-[#EA580C]"
                            onClick={toogleSeeMore}
                          >
                            {t("userdetails.less_top")}
                          </button>
                        </div>

                        <h2 className="p-2 mb-2 text-2xl font-bold ">
                          {t("userdetails.qualification")}
                        </h2>
                        {activeAppli.qualifications && (
                          <TextItems items={activeAppli.qualifications} />
                        )}

                        <div className="mb-2 text-gray-700  my-3 ">
                          <div className="mb-4">
                            <h2 className="p-2 mb-2 text-2xl font-bold ">
                              {t("userdetails.detail")}
                            </h2>
                            {activeAppli.description && (
                              <TextItems items={activeAppli.summary} />
                            )}
                          </div>
                          <div className="mb-2 text-gray-700  my-3 ">
                            <div className="mb-4">
                              <h2 className="p-2 mb-2 text-2xl font-bold ">
                                {t("userdetails.benefits")}
                              </h2>
                              {activeAppli.description && (
                                <TextItems items={activeAppli.description} />
                              )}
                            </div>
                          </div>
                          <div className="mb-2 text-gray-700  my-3 ">
                            <div className="mb-4">
                              <h2 className="p-2 mb-2 text-2xl font-bold ">
                                {t("userdetails.info")}
                              </h2>
                              {activeAppli.advantages && (
                                <TextItems items={activeAppli.advantages} />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <Button
                        color="blue"
                        size="lg"
                        variant="text"
                        onClick={toogleSeeMore}
                        className="hover:scale-110"
                      >
                        Voir Moin
                      </Button> */}
                      <div className="flex justify-center">
                        <button
                          className="justify-center bg-[#0284C7] rounded-full px-2 text-white w-[224px] h-[35px] text-[16px] font-semibold hover:border-2 hover:border-[#EA580C] hover:bg-white hover:text-[#EA580C]"
                          onClick={toogleSeeMore}
                        >
                          {t("userdetails.less_bottom")}
                        </button>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </motion.div>
          </AnimatePresence>
        </div>
        <div className="relative items-center justify-start h-[40px] lg:w-1/4 sm:w-2/4 mt-2">
          <Input
            type="text"
            value={searchText}
            size="md"
            onChange={(e) => setSearchText(e.target.value)}
            placeholder={t("userdetails.applicant_placeholder")}
            aria-label={t("userdetails.applicant_label")}
            className="border pl-10 border-[#D0D5DD] rounded-full bg-white placeholder-[#667085] focus:border-[#457AA8] text-[#667085] h-full"
          />
          <span className="absolute left-3  top-1/2 transform -translate-y-1/2">
            <FaSearch className="text-[#667085]" />
          </span>
        </div>
      </div>
      <div className="overflow-x-auto">
        <table
          {...getTableProps()}
          className="w-full border border-collapse border-[#457AA8] min-w-max"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    className="p-2 bg-[#457AA8] border  border-[#457AA8] text-white"
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className="hover:bg-gray-200 bg-white  border-[#457AA8] cursor-pointer"
                >
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      className="p-2 text-center border border-[#457AA8]"
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {isDeleteModalOpen && (
        <Modal
          title="Confirmation"
          message={t("userdetails.modal_msg1")` ${selectedComp?.companyName}?`}
          onCancel={cancelDelete}
          onConfirm={confirmDelete}
        />
      )}
      {isSaveModalOpen && (
        <Modal
          title="Confirmation"
          message={t("userdetails.modal_msg2")` ${selectedComp?.companyName}?`}
          onCancel={cancelSave}
          onConfirm={confirmSave}
        />
      )}

      {/* {isAddCompModalOpen && (
        <ModalAddUser
          isOpen={isAddCompModalOpen}
          onCancel={handleCloseModalCat}
          onConfirm={handleAddComp}
          editedCompanyData={selectedComp}
          formTitle={formTitle}
        />
      )} */}
      <ModalUI
        autoclose={HandleCloseModalDescription}
        open={modalDescription}
        classname="md:w-[40rem]"
      >
        <div className="p-4">
          {" "}
          <div className=" border-b-2 text-center text-xl text-[#457AA8] font-[500]">
            {compForModalDescription.companyName}
          </div>
          <div className="space-x-4 ">
            <div className="my-1 text-center ">
              <h1>
                {t("userdetails.modal_address")} <br />
                {compForModalDescription.companyAdress}
              </h1>
              <h1 className="text-gray-400">
                {compForModalDescription.companyEmail}
              </h1>
            </div>
            <div>
              <h1 className="text-md my-1 text-center font-mono text-[#1d3448]">
                {t("userdetails.description_label")}
              </h1>
              <div className="">
                <p>{t("userdetails.changes_msg")}</p>
                <p>{t("userdetails.msg")}</p>
              </div>
            </div>
          </div>
          <div className="flex justify-center my-4 ">
            <Button
              variant="filled"
              onClick={HandleCloseModalDescription}
              className="h-10 outline-none py-1 hover:opacity-75 text-lg bg-[#457AA8] w-28"
            >
              {t("userdetails.close")}
            </Button>
          </div>
        </div>
      </ModalUI>
      <Pagination
        currentPage={currentPage}
        totalPages={pageCount}
        onPageChange={onPageChange}
      />
      <PendingModal isOpen={false} autoclose={() => {}} />

      <PendingModal autoclose={() => {}} isOpen={viewDocStatus === "pending"} />
      <ModalInfos
        autoClose={() => setIsModalDoc(false)}
        icon={<FaExclamationTriangle className=" size-9 text-[#c77851]" />}
        isOpen={isModalDoc && viewDocStatus === "error"}
        message={t("userdetails.cv_error")}
      />
      <ModalInfos
        autoClose={() => setIsModalDoc(false)}
        // isOpen={true}
        icon={<FaExclamationCircle className=" size-9 text-[#457AA8]" />}
        isOpen={isModalDoc && viewDocStatus === ""}
        message={t("userdetails.cv_info")}
      />
    </div>
  );
};

export default DétailUserAppli;
