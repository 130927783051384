import { CONTACT_POST_STUDENTS, NEWSLETTER_POST } from "../../api";
import aXios from "../../axiosConfig";

export const postStudentContact = async ({ data }) => {
  const response = await aXios.post(CONTACT_POST_STUDENTS, data);

  return response;
};

export const newsletterPost = async ( data ) => {
  const response = await aXios.post(NEWSLETTER_POST, data);
//   console.log('NEWSLETTER_POST', response)
  return response;
};
