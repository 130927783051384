import React, { useContext, useEffect, useState } from "react";
import InputContainer from "./UI/InputContainer";
import InputUI from "./UI/InputUI";
import PhoneNumberUI from "../UI/PhoneNumberUI";
import { FaCheck, FaExclamationTriangle, FaMailBulk } from "react-icons/fa";
import RadioUI from "../UI/RequestUI/RadioUI";
import FormTextUI from "../UI/FormTextUI";
import SelectUI from "../UI/SelectUI";
import useCheckBoxRegion from "../Form/FormApply/useCheckBoxRegion";
import useDragDrop from "../Hooks/useDocPicker";
import CheckBoxUI from "../UI/CheckBoxUI";
import FormContainerUI from "../UI/FormContainerUI";
import QuickApplyContext from "./context/QuickApplyontext";
import InvalidInputControl from "../UI/InvalidInputControl";
import ButtonStudentUI from "../Students/UI/ButtonStudentUI";
import { useTranslation, Trans } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { quickApply } from "../../services/HttpRequest/clientRequest/sendApplyRequest";
import PendingModal from "./UI/PendingModal";
import { useSelector } from "react-redux";
import ModalInfos from "../UI/RequestUI/ModalInfos";
import { useNavigate } from "react-router-dom";

const SectionTwo = () => {
  // --------------------context---------------
  const allCats = useSelector((state) => state.category?.allCats);
  const catToMap = allCats?.map((cat) => ({
    nom: cat.categoryName,
    id: cat.id,
  }));

  const { t } = useTranslation();
  const [isModal, setIsModal] = useState(false);

  const {
    fieldData,
    handleBlurField,
    handleChangeField,
    FieldValidation,
    isFormDataOk,
    handleIsAgree,
    handleIsResident,
    initField,
  } = useContext(QuickApplyContext);
  // --------mutation--------
  const { data, status, mutate } = useMutation({
    mutationFn: quickApply,
  });

  // ------------------------------------------
  const navigate = useNavigate();
  const { region, regionContent } = useCheckBoxRegion();
  const { content: docContent, formFile } = useDragDrop();

  //-----------------------verifySubmit
  // console.log("formFile", fieldData);

  const isFormContentValide = region.length > 2 && isFormDataOk && !!formFile;

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsModal(true);
    initField();

    const dataToSend = {
      FirstName: fieldData.firstName,
      lastName: fieldData.lastName,
      isEmailNotOk: fieldData.email,
      PhoneNumber: fieldData.phoneNumber,
      Address: [fieldData.adresse, fieldData.adresse2],
      CanadiantWorkPermit: fieldData.isResident,
      Region: region,
      PostalCode: fieldData.postalCode,
      Town: fieldData.town,
      workingHour: ["day,night"],
      file: formFile,
      Job: fieldData.poste,
    };

    if (isFormContentValide) {
      // console.log("dataToSend", dataToSend);

      mutate({ data: dataToSend });
    }
  };

  return (
    <section className="w-full text-[#3D3D3D]">
      <PendingModal autoclose={() => {}} isOpen={status === "pending"} />
      <ModalInfos
        isOpen={status === "success" && isModal}
        message= {t("quickApply.applySucces")} 
        autoClose={() => {
          setIsModal(false);
          navigate("/");
        }}
        icon={<FaCheck className="text-green-500 size-8" />}
      />
      <ModalInfos   
        isOpen={status === "error" && isModal}
        message={t("quickApply.applyError")} 
        autoClose={() => {
          setIsModal(false);
        }}
        icon={<FaExclamationTriangle className="text-orange-500 size-8" />}
      />
      <div className="flex flex-col items-center w-full ">
        <div className="text-[#3D3D3D] lg:my-14 my-7">
          <div className="mb-3 text-xl font-bold text-center lg:text-2xl">
            {t("quickApply.applyform_title")}
          </div>
          <div className="text-sm  text-center max-w-[550px] ">
            {t("quickApply.applyform_description")}
          </div>
        </div>
        <FormContainerUI>
          <form
            className="w-full p-6 border shadow rounded-2xl lg:p-12 "
            onSubmit={handleSubmit}
          >
            <InputContainer>
              <InputUI
                id={"lastName"}
                invalidMessage={t("quickApply.applyform_lastname_field_msg")}
                isInputNotValide={FieldValidation.isLastNameNotOk}
                placeholderValue={t(
                  "quickApply.applyform_lastname_field_value"
                )}
                isRequired={true}
                label={t("quickApply.applyform_lastname_field_label")}
                value={fieldData.lastName.value}
                onChange={(event) =>
                  handleChangeField("lastName", event.target.value)
                }
                onBlur={() => handleBlurField("lastName")}
              />
              <InputUI
                id={"firstName"}
                invalidMessage={t("quickApply.applyform_firstname_field_msg")}
                isInputNotValide={FieldValidation.isFirstNotNameOk}
                placeholderValue={t(
                  "quickApply.applyform_firstname_field_value"
                )}
                isRequired={true}
                label={t("quickApply.applyform_firstname_field_label")}
                value={fieldData.firstName.value}
                onChange={(event) =>
                  handleChangeField("firstName", event.target.value)
                }
                onBlur={() => handleBlurField("firstName")}
              />
            </InputContainer>

            {/* ----------------------------------------------------------------------------- */}

            <InputContainer>
              <div className="w-full mt-[0.35rem]">
                <PhoneNumberUI
                  id={"phoneNumber"}
                  invalidMessage={t(
                    "quickApply.applyform_phonenumber_field_msg"
                  )}
                  isInputNotValide={FieldValidation.isPhoneNumberNotOk}
                  isRequired={true}
                  defaultCountry={"ca"}
                  label={t("quickApply.applyform_phonenumber_field_label")}
                  value={fieldData.phoneNumber.value}
                  onChange={(phone) => handleChangeField("phoneNumber", phone)}
                  onBlur={() => handleBlurField("phoneNumber")}
                />
              </div>

              <InputUI
                id={"email"}
                invalidMessage={t("quickApply.applyform_email_field_msg")}
                isInputNotValide={FieldValidation.isEmailNotOk}
                placeholderValue={t("quickApply.applyform_email_field_value")}
                isRequired={true}
                label={t("quickApply.applyform_email_field_label")}
                value={fieldData.email.value}
                onChange={(event) =>
                  handleChangeField("email", event.target.value)
                }
                onBlur={() => handleBlurField("email")}
              />
            </InputContainer>
            {/* ------------------------------------------------------------- */}
            <InputUI
              id={"adresse"}
              invalidMessage={t("quickApply.applyform_address_field_msg")}
              isInputNotValide={FieldValidation.isAdresseNotOk}
              placeholderValue={t("quickApply.applyform_address_field_value")}
              isRequired={true}
              label={t("quickApply.applyform_address_field_label")}
              value={fieldData.adresse.value}
              onChange={(event) =>
                handleChangeField("adresse", event.target.value)
              }
              onBlur={() => handleBlurField("adresse")}
            />
            <InputUI
              id={"adresse2"}
              invalidMessage={t("quickApply.applyform_address2_field_msg")}
              isInputNotValide={false}
              placeholderValue={t("quickApply.applyform_address2_field_value")}
              label={t("quickApply.applyform_address2_field_label")}
              value={fieldData.adresse2.value}
              onChange={(event) =>
                handleChangeField("adresse2", event.target.value)
              }
              onBlur={() => handleBlurField("adresse2")}
            />
            {/* ------------------------------------------------------------------ */}

            <InputContainer>
              <InputUI
                id={"town"}
                invalidMessage={t("quickApply.applyform_town_field_msg")}
                isInputNotValide={false}
                placeholderValue={t("quickApply.applyform_town_field_value")}
                value={fieldData.town.value}
                onChange={(event) =>
                  handleChangeField("town", event.target.value)
                }
                onBlur={() => handleBlurField("town")}
              />
              <InputUI
                id={"province"}
                invalidMessage={t("quickApply.applyform_province_field_msg")}
                isInputNotValide={false}
                placeholderValue={t(
                  "quickApply.applyform_province_field_value"
                )}
                value={fieldData.province.value}
                onChange={(event) =>
                  handleChangeField("province", event.target.value)
                }
                onBlur={() => handleBlurField("province")}
              />
            </InputContainer>
            <InputUI
              id={"postalCode"}
              invalidMessage={t("quickApply.applyform_postcode_field_msg")}
              isInputNotValide={false}
              placeholderValue={t("quickApply.applyform_postcode_field_value")}
              value={fieldData.postalCode.value}
              onChange={(event) =>
                handleChangeField("postalCode", event.target.value)
              }
              onBlur={() => handleBlurField("postalCode")}
            />
            {/* --------------------------------------------------------------------------- */}

            <FormTextUI classname={"my-4"}>
              {t("quickApply.applyform_statut_field_msg")}{" "}
              <span className="text-red-500">*</span>
              <div className="flex justify-center gap-x-6">
                <RadioUI
                  id={"r1"}
                  label={t("quickApply.applyform_statut_field_valueone")}
                  radioName={"citoyen"}
                  onSelect={() => handleIsResident(true)}
                  defaultValue={true}
                />
                <RadioUI
                  id={"r2"}
                  label={t("quickApply.applyform_statut_field_valuetwo")}
                  radioName={"citoyen"}
                  onSelect={() => {
                    handleIsResident(false);
                  }}
                />
              </div>
              {/* <InvalidInputControl>
               veullez sélectionner un case
              </InvalidInputControl> */}
            </FormTextUI>
            {/* -----------------dd------------------------------------------------------------------- */}
            <SelectUI
              data={catToMap}
              classname={"w-[99%] "}
              id={"poste"}
              label={t("quickApply.applyform_data_field_label")}
              value={fieldData.poste.value}
              onChange={(event) =>
                handleChangeField("poste", event.target.value)
              }
              onBlur={() => handleBlurField("poste")}
            />
            <InvalidInputControl>
              {FieldValidation.isPosteNotOk &&
                t("quickApply.applyform_data_field_msg")}
            </InvalidInputControl>
            <div className="w-full">
              <div className="flex justify-center w-full">{regionContent}</div>
            </div>
            <div className="w-full">
              <FormTextUI
                classname={"font-bold lg:text-xl text-lg lg:my-4 my-2  "}
              >
                {t("quickApply.applyform_doc_field_msg")}{" "}
                <span className="text-red-500">*</span>
              </FormTextUI>
              {docContent}
            </div>
            <div className="flex justify-center w-full mt-4 lg:mt-5">
              <CheckBoxUI
                id={"agree"}
                onClick={handleIsAgree}
                isChecked={fieldData.isAgree}
              />
              <FormTextUI
                classname={"max-w-[600px] lg:text-lg text-[#667085] "}
              >
                <span className="mr-1 underline">
                  {t("quickApply.applyform_policy_field_msg")}
                </span>{" "}
                <span className="text-xl text-red-500">*</span>
              </FormTextUI>
            </div>
            {isFormContentValide ? (
              <div className="flex justify-center w-full my-4 lg:my-7">
                <ButtonStudentUI>
                  {t("quickApply.applyform_button_text")}
                </ButtonStudentUI>
              </div>
            ) : (
              <FormTextUI classname={"mt-4 text- text-gray-500"}>
                {t("quickApply.applyform_info_text1")} "
                <span className="text-xl text-red-500">*</span>"{" "}
                {t("quickApply.applyform_info_text2")}
              </FormTextUI>
            )}
          </form>
        </FormContainerUI>
      </div>
    </section>
  );
};

export default SectionTwo;
