import React from "react";
import sec1img1 from "../../../assets/one.png";
import sec1img2 from "../../../assets/excellence.jpg";
import sec1img3 from "../../../assets/sec1img3.png";
import { FiTarget } from "react-icons/fi";
import { PiBagSimpleFill } from "react-icons/pi";
import { FaCalendar, FaUser } from "react-icons/fa";
import { useTranslation, Trans } from "react-i18next";

import { Link, useNavigate } from "react-router-dom";

const SectionOne = () => {
  const { t } = useTranslation();

  let statistique = [
    {
      icon: <FaUser className="mt-auto text-orange-600 size-10 w-14 h-14 " />,
      stat: "750",
      text: t("Ap-sectionOne.user"),
      id: 1,
    },
    {
      icon: (
        <FaCalendar className="mt-auto text-orange-600 size-10 w-14 h-14 " />
      ),
      stat: "423",
      text: t("Ap-sectionOne.job"),
      id: 2,
    },
    {
      icon: <FiTarget className="mt-auto text-orange-600 size-10 w-14 h-14 " />,
      stat: "2164",
      text: t("Ap-sectionOne.company"),
      id: 3,
    },
    {
      icon: (
        <PiBagSimpleFill className="mt-auto text-orange-600 size-10 w-14 h-14 " />
      ),
      stat: "2164",
      text: t("Ap-sectionOne.apply"),
      id: 4,
    },
  ];
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/Contact");
  };

  return (
    <div className="w-full mx-auto overflow-hidden bg-white shadow-md ">
      <div
        className="h-[519.31px] items-center flex mx-auto"
        style={{
          backgroundImage: `linear-gradient( rgba(64, 174, 223, 0.5), rgba(176, 63, 4, 0.7)) , url(${sec1img1})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center top 20%",
        }}
      >
        {/* p-36 text-center grid m-auto text-6xl */}
        <div className="justify-center flex w-full">
          <div
            className="relative flex justify-center w-[44%] h-[184px] items-center shadow-lg rounded-[35px]"
            data-aos="fade-down"
          >
            <div className="absolute inset-0 bg-gray-100 opacity-10 rounded-[35px]"></div>
            <div className="relative flex  text-center  w-full mx-auto">
              <h1 className="font-bold text-white text-6xl text-center w-full ">
                {t("Ap-sectionOne.title")}
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div className="space-x-3 md:flex px-10 py-10" data-aos="fade-left">
        <div className="p-2 mx-auto">
          <div className="px-4">
            <h1 className="text-[26px] [font-family:'Inter-Bold',Helvetica] font-bold text-black">
              {t("Ap-sectionOne.text1")}
            </h1>

            <div className="justify-content ">
              <p className="pt-3 text-justify">{t("Ap-sectionOne.text2")}</p>
              <p className="pt-3 text-justify">{t("Ap-sectionOne.text3")}.</p>
              <p className="pt-3 text-justify">{t("Ap-sectionOne.text4")}</p>
            </div>
            <div className="pt-8 flex justify-start">
              <button
                className="px-2 py-2 [font-family:'Inter-Bold',Helvetica] font-bold  text-white hover:border-2 hover:text-[orange-500] hover:border-white duration-500 bg-[#EA580C] rounded-full btn md:ml-2 md:static shadow-[4px_7px_4.7px_-6px_#00000040]  hover:bg-indigo-500 text-center"
                onClick={handleClick}
              >
                {t("Ap-sectionOne.text5")}
              </button>
            </div>
          </div>
        </div>
        <div className=" md:shrink-0">
          <img
            src={sec1img2}
            className="lightgray 50% / cover no-repeat] flex-shrink-0   w-[410px] rounded-3xl "
            alt="imag"
          />
        </div>
      </div>

      <div className="">
        <div className="relative w-full lg:h-56 sm:h-full bg-center bg-cover bg-gradient-to-b from-gray-800 to-gray-900">
          <img
            src={sec1img3}
            alt="hero"
            className="absolute object-cover w-full h-full mix-blend-overlay"
          />
          <div className="flex flex-wrap justify-center h-full max-w-full max-h-full lg:flex-nowrap">
            {statistique.map((StatVal) => (
              <div
                key={StatVal.id}
                className="flex  items-center justify-between p-4 sm:px-6 lg:mx-auto"
                data-aos="fade-down"
                data-aos-delay={`${StatVal.id * 300}`}
              >
                <div className="justify-start ">{StatVal.icon}</div>
                <div className="justify-end px-4">
                  <h1 className="text-start font-bold  text-white sm:text-xl lg:text-5xl">
                    {StatVal.stat}
                  </h1>
                  <p className="text-start mb-auto leading-7  text-white">
                    {StatVal.text}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionOne;
