import { useState, useMemo } from "react";


const usePagination = (data,PageSize=10) => {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = useMemo(() => Math.ceil(data.length / PageSize), [data]);

  const handlePageChange = (newPage) => {
    if (newPage < 1 || newPage>totalPages) {
      //bad page
    } else {
      setCurrentPage(newPage);
    }
  };

  const paginatedData = useMemo(() => {
    const startIdx = (currentPage - 1) * PageSize;
    const endIdx = Math.min(startIdx + PageSize, data.length);
    return data.slice(startIdx, endIdx);
  }, [currentPage, data]);

  return {
    currentPage,
    totalPages,
    paginatedData,
    handlePageChange,
  };
};

export default usePagination;
