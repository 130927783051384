import React from "react";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";

import GlobalErrorBoundary from "./components/ErrorBoundary/GlobalErrorBoundary";

import { createRoot } from "react-dom/client";

const AppRoot = () => (
  <React.StrictMode>
    <GlobalErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </GlobalErrorBoundary>
  </React.StrictMode>
);

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(<AppRoot />);
