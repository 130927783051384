import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allCats: [],
  allCatWithJob: [],
  activeCat: {},
  statusCat: "",
  isError: false,
  error: {},
  CatForOneJob: null,
};

const categorySlice = createSlice({
  name: "categorySlice",
  initialState,
  reducers: {
    fetchAllCategory(state, action) {
      state.statusCat = action.payload.status;
      if (action.payload.status === "success") {
        state.allCats = action.payload.data.data;
        // ------------------------------------------------

        const catWithValidJobs = action.payload.data.data.map((cat) => {
          const validJobs = cat.jobs.filter((job) => !job.archived);

          return { ...cat, jobs: validJobs };
        });

        const validCatWithJob = catWithValidJobs.filter((cat) => cat.jobs.length > 0);

        // const catWithJob = action.payload.data.data.filter((cat) =>
        //   cat.jobs.filter((job) => !job.archived)
        // );
        // console.log("action.payload.data.data", validCatWithJob);
        state.allCatWithJob = validCatWithJob.map((cat) => ({
          categoryName: cat.categoryName,
          jobQuantity: cat.jobs.filter((job) => !job.archived).length,
          id: cat.id,
        }));
        //   ------------------------------------------------
      } else {
        state.error = action.payload.error;
      }
    },

    getCatByIdForFilter(state, action) {
      const selectedCat = state.allCats.find(
        (cat) => cat.id === action.payload.selectedCatId
      );
      state.CatForOneJob = selectedCat;
      state.activeCat = selectedCat;
    },
    //--------------------- active a cat --------------

    //   activeOneCatForJob(state, action) {
    //     const selectedCat = state.allCats.find(
    //       (cat) => cat.id === action.payload.selectedCatId
    //     );
    //     state.activeCat = selectedCat;
    //   },
  },
});
export const categorySliceAction = categorySlice.actions;
export default categorySlice.reducer;
