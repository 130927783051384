import React from "react";

const FormTextUI = ({ children, classname}) => {
  return (
    <div className={`text-sm  text-center w-full font-semibold ${classname} `}>
      {children}
    </div>
  );
};

export default FormTextUI;
