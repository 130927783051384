import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import loginReducer from "./slice/loginSlice";
import candidacySlice from "./slice/candidacySlice";
import requestSlice from "./slice/requestSlice"; // Importez votre slice de requête
import categorySlice from "./slice/categorySlice";
import jobsSlice from "./slice/jobsSlice";
import jobsSliceAdmin from "./slice/jobsSliceAdmin";
import categorySliceAdmin from "./slice/categorySliceAdmin";
import cvUserSclice from "./slice/cvUserSclice";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["loginInfo"],
};

const rootReducer = {
  loginInfo: persistReducer(persistConfig, loginReducer),
  candidacy: candidacySlice.reducer,
  category: categorySlice,
  categoryAdmin: categorySliceAdmin,
  jobs: jobsSlice,
  jobsAdmin: jobsSliceAdmin,
  cvUser: cvUserSclice,
  request: requestSlice, // Ajoutez votre slice de requête au rootReducer
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Pour éviter l'avertissement concernant la sérialisation
    }),
});

const persistor = persistStore(store);

export { store, persistor };
