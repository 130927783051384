import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";

import transHeaderEN from "./locales/en/transHeader.json";
import transHeaderFR from "./locales/fr/transHeader.json";

import HpsectionOneEN from "./locales/en/homePage/sectionOne.json";
import HpsectionOneFR from "./locales/fr/homePage/sectionOne.json";

import HpsectionTwoEN from "./locales/en/homePage/sectionTwo.json";
import HpsectionTwoFR from "./locales/fr/homePage/sectionTwo.json";

import HpsectionThreeEN from "./locales/en/homePage/sectionThree.json";
import HpsectionThreeFR from "./locales/fr/homePage/sectionThree.json";

import HpsectionFourEN from "./locales/en/homePage/sectionFour.json";
import HpsectionFourFR from "./locales/fr/homePage/sectionFour.json";

import HpsectionFiveEN from "./locales/en/homePage/sectionFive.json";
import HpsectionFiveFR from "./locales/fr/homePage/sectionFive.json";

import HpsectionSixEN from "./locales/en/homePage/sectionSix.json";
import HpsectionSixFR from "./locales/fr/homePage/sectionSix.json";

import HpsectionSevenEN from "./locales/en/homePage/sectionSeven.json";
import HpsectionSevenFR from "./locales/fr/homePage/sectionSeven.json";

import HpsectionEightEN from "./locales/en/homePage/sectionEight.json";
import HpsectionEightFR from "./locales/fr/homePage/sectionEight.json";

import HpsectionNineEN from "./locales/en/homePage/sectionNine.json";
import HpsectionNineFR from "./locales/fr/homePage/sectionNine.json";

import HpsectionTenEN from "./locales/en/homePage/sectionTen.json";
import HpsectionTenFR from "./locales/fr/homePage/sectionTen.json";

import ApsectionOneEN from "./locales/en/aboutPage/sectionOne.json";
import ApsectionOneFR from "./locales/fr/aboutPage/sectionOne.json";

import ApsectionTwoEN from "./locales/en/aboutPage/sectionTwo.json";
import ApsectionTwoFR from "./locales/fr/aboutPage/sectionTwo.json";

import ApsectionThreeEN from "./locales/en/aboutPage/sectionThree.json";
import ApsectionThreeFR from "./locales/fr/aboutPage/sectionThree.json";

import contactEN from "./locales/en/contactPage/contact.json";
import contactFR from "./locales/fr/contactPage/contact.json";

import ContactEN from "./locales/en/contact/contact.json";
import ContactFR from "./locales/fr/contact/contact.json";

import FooterFR from "./locales/fr/footer/footer.json";
import FooterEN from "./locales/en/footer/footer.json";

import ConnectionEN from "./locales/en/connectionPage/connection.json";
import ConnectionFR from "./locales/fr/connectionPage/connection.json";

import InscriptionFR from "./locales/fr/inscription/inscription.json";
import InscriptionEN from "./locales/en/inscription/inscription.json";

import jobFooterEn from "./locales/en/jobFooter/jobFooter.json";
import jobFooterFR from "./locales/fr/jobFooter/jobFooter.json";

import jobSecOneEn from "./locales/en/jobSecOne/jobSecOne.json";
import jobSecOnerFR from "./locales/fr/jobSecOne/jobSecOne.json";

import quickApplyEn from "./locales/en/quickApply/quickApply.json";
import quickApplyFR from "./locales/fr/quickApply/quickApply.json";

import studentFormEn from "./locales/en/studentForm/studentForm.json";
import studentFormFr from "./locales/fr/studentForm/studentForm.json";

import termAndConditionEn from "./locales/en/termAndCondition/termAndCondition.json";
import termAndConditionFr from "./locales/fr/termAndCondition/termAndCondition.json";

import privatePolicyEn from "./locales/en/privacyPolicy/privacyPolicy.json";
import privatePolicyFr from "./locales/fr/privacyPolicy/privacyPolicy.json";

import myProfileEn from "./locales/en/myProfile/myProfile.json";
import myProfileFr from "./locales/fr/myProfile/myProfile.json";

import cvEn from "./locales/en/cv/cv.json";
import cvFr from "./locales/fr/cv/cv.json";

import userManageEn from "./locales/en/adminPage/userManage.json";
import userManageFr from "./locales/fr/adminPage/userManage.json";

import appliManageEn from "./locales/en/adminPage/appliManage.json";
import appliManageFr from "./locales/fr/adminPage/appliManage.json";

import catManageEn from "./locales/en/adminPage/catManage.json";
import catManageFr from "./locales/fr/adminPage/catManage.json";

import compMangeEn from "./locales/en/adminPage/compManager.json";
import compManageFr from "./locales/fr/adminPage/compManager.json";

import jobManageEn from "./locales/en/adminPage/jobManage.json";
import jobManageFr from "./locales/fr/adminPage/jobManage.json";

import adminNavEn from "./locales/en/adminPage/navigation.json";
import adminNavFr from "./locales/fr/adminPage/navigation.json";

import tableDataEn from "./locales/en/adminPage/tableData.json";
import tableDataFr from "./locales/fr/adminPage/tableData.json";

import myCandidacyEn from "./locales/en/myCandidacy/myCandidacy.json";
import myCandidacyFr from "./locales/fr/myCandidacy/myCandidacy.json";
import UserApplicationEn from "./locales/en/userApplication/userApplication.json";
import UserApplicationFr from "./locales/fr/userApplication/userApplication.json";

const defaultLanguage = "fr";

const savedLanguage = localStorage.getItem("language");

const resources = {
  en: {
    translation: {
      ...transHeaderEN,
      ...HpsectionOneEN,
      ...ApsectionOneEN,
      ...HpsectionTwoEN,
      ...HpsectionThreeEN,
      ...HpsectionFourEN,
      ...HpsectionFiveEN,
      ...HpsectionSixEN,
      ...ApsectionTwoEN,
      ...ApsectionThreeEN,
      ...contactEN,
      ...ContactEN,
      ...FooterEN,
      ...ConnectionEN,
      ...InscriptionEN,
      ...jobFooterEn,
      ...jobSecOneEn,
      ...HpsectionSevenEN,
      ...HpsectionEightEN,
      ...HpsectionNineEN,
      ...HpsectionTenEN,
      ...quickApplyEn,
      ...studentFormEn,
      ...termAndConditionEn,
      ...privatePolicyEn,
      ...myProfileEn,
      ...cvEn,
      ...userManageEn,
      ...appliManageEn,
      ...catManageEn,
      ...compMangeEn,
      ...jobManageEn,
      ...adminNavEn,
      ...tableDataEn,
      ...myCandidacyEn,
      ...UserApplicationEn,
    },
  },
  fr: {
    translation: {
      ...transHeaderFR,
      ...HpsectionOneFR,
      ...ApsectionOneFR,
      ...HpsectionTwoFR,
      ...HpsectionThreeFR,
      ...HpsectionFourFR,
      ...HpsectionFiveFR,
      ...HpsectionSixFR,
      ...ApsectionTwoFR,
      ...ApsectionThreeFR,
      ...contactFR,
      ...ContactFR,
      ...FooterFR,
      ...ConnectionFR,
      ...InscriptionFR,
      ...jobFooterFR,
      ...jobSecOnerFR,
      ...HpsectionSevenFR,
      ...HpsectionEightFR,
      ...HpsectionNineFR,
      ...HpsectionTenFR,
      ...quickApplyFR,
      ...studentFormFr,
      ...termAndConditionFr,
      ...privatePolicyFr,
      ...myProfileFr,
      ...cvFr,
      ...userManageFr,
      ...appliManageFr,
      ...catManageFr,
      ...compManageFr,
      ...jobManageFr,
      ...adminNavFr,
      ...tableDataFr,
      ...myCandidacyFr,
      ...UserApplicationFr
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: savedLanguage || defaultLanguage,
    keySeparator: ".",
    interpolation: {
      escapeValue: false,
    },
  });

i18n.on("languageChanged", (lng) => {
  localStorage.setItem("language", lng);
});

export default i18n;
