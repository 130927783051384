import { useEffect, useState } from "react";
import CatItem from "./CatItem";
import { FaDatabase } from "react-icons/fa";
import PendingUI from "../../UI/RequestUI/PendingUI";
import EmptyData from "../../UI/RequestUI/EmptyData";
import LoadDataFailUI from "../../UI/RequestUI/LoadDataFailUI";
import { useSelector } from "react-redux";

function CatSection({ seeMore }) {
  const [catList, setCatList] = useState([]);

  const catState = useSelector((state) => state.category);
  useEffect(() => {
    if (catState.statusCat === "success") {
      // console.log('seeMore', seeMore)
      if (catState.allCatWithJob.length > 8) {
        if (seeMore) {
          setCatList(catState.allCatWithJob); 
        } else {
          const slicedCat = catState.allCatWithJob.splice(0, 8);
          setCatList(slicedCat);
        }
      } else {
        setCatList(catState.allCatWithJob);
      }
    }
  }, [catState,seeMore]);

  let categories = catList.map((cat) => (
    <div key={cat.id}>
      <CatItem
        icon={<FaDatabase size={40} />}
        title={cat.categoryName}
        description={` ${cat.jobQuantity} emplois`}
        id={cat.id}
      />
    </div>
  ));

  let content;
  switch (catState.statusCat) {
    case "pending":
      content = <PendingUI />;
      break;
    case "error":
      content = <LoadDataFailUI />;
      break;
    case "success":
      content =
        catList.length === 0 ? (
          <EmptyData>Aucune categorie valide disponible</EmptyData>
        ) : (
          <div className="grid grid-cols-1 mx-4 md:grid-cols-2 lg:grid-cols-4 mb-8">
            {categories}
          </div>
        );
      break;

    default:
      break;
  }

  return content;
}

export default CatSection;
