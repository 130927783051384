import React, { useState, useEffect } from "react";
import { Button } from "@material-tailwind/react";
import { FaSave, FaTrash } from "react-icons/fa";
import { motion } from "framer-motion";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../../services/HttpRequest/queryClientProvider/queryClientProvider";
import {
  createCategory,
  editCategory,
} from "../../services/HttpRequest/catRequest/catRequest";
import { isTextValid } from "../Utils/ValidFormElement";
import { useTranslation, Trans } from "react-i18next";

const AddCatForm = ({ onCancel, editedCategoryData, isEditing }) => {
  const { t } = useTranslation();

  const [validStatus, setValidStatus] = useState(false);
  const handleOnCancel = () => {
    onCancel();
    setValidStatus(false);
  };

  const mutationFn = editedCategoryData ? editCategory : createCategory;
  const { mutate, error, status } = useMutation({
    mutationFn: mutationFn,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["getCategories"] });
      handleOnCancel();
    },
  });

  const [formContent, setFormContent] = useState({
    categoryName: { value: "", didEdit: false },
  });

  useEffect(() => {
    if (editedCategoryData) {
      setFormContent({
        categoryName: {
          value: editedCategoryData.categoryName || "",
          didEdit: false,
        },
      });
    } else {
      setFormContent({
        categoryName: {
          value: "",
          didEdit: false,
        },
      });
    }
  }, [editedCategoryData]);

  const handleChange = (id, value) => {
    setValidStatus(false);
    setFormContent((prevContent) => ({
      ...prevContent,
      [id]: { value, didEdit: true },
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setValidStatus(true);
    const dataToSend = {
      categoryName: formContent.categoryName.value,
    };
    // mutate data edit or create
    if (isTextValid(dataToSend.categoryName)) {
      if (editedCategoryData) {
        mutate({
          data: { id: editedCategoryData.id, data: dataToSend },
        });
      } else {
        mutate({ data: dataToSend });
      }
    }
  };

  return (
    <motion.div
      initial={{ y: -30, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ delay: 0.3 }}
      className="p-4 mx-auto overflow-y-auto rounded-lg bg-gray-500/5"
    >
      {validStatus && error?.response?.status === 400 && (
        <p className="mb-2 text-sm font-extrabold text-center text-red-500 border border-t-0 border-b-2 border-red-500 border-solid">
          {t("addcatform.error1")}
        </p>
      )}
      {validStatus &&
        error?.response?.status &&
        error?.response?.status !== 400 && (
          <p className="mb-2 text-sm font-extrabold text-center text-red-500 border border-t-0 border-b-2 border-red-500 border-solid">
            {t("addcatform.error2")}
          </p>
        )}

      <form onSubmit={handleSubmit} className="space-y-2 overflow-y-auto">
        <div className="grid grid-cols-1 gap-4">
          <div>
            <label
              htmlFor="categoryName"
              className="block text-sm font-medium text-gray-700"
            >
              {t("addcatform.label")}
              <span className="text-red-500">*</span>
            </label>
            <input
              disabled={status === "pending"}
              type="text"
              name="categoryName"
              id="categoryName"
              value={formContent.categoryName.value}
              onChange={(event) =>
                handleChange("categoryName", event.target.value)
              }
              className="w-full p-2 mt-1 border rounded-md focus:outline-none"
            />
          </div>
        </div>

        {status === "pending" ? (
          <div className="my-28  justify-evenly text-center  ">
            <motion.span
              animate={{ scale: 1.6, opacity: [1, 0.7, 0.5, 0.3, 0.5, 0.7] }}
              transition={{ repeat: 100, duration: 3 }}
              className="justify-start text-center text-2xl font-bold text-green-500 "
            >
              {t("addcatform.pending")}
            </motion.span>
          </div>
        ) : (
          <div className="flex justify-evenly">
            <Button
              type="reset"
              variant="filled"
              color="red"
              className="flex justify-center h-12 pt-4 opacity-80 hover:bg-opacity-70  "
              onClick={handleOnCancel}
            >
              <span className="justify-start max-sm:hidden mx-1 ">
                {t("addcatform.cancel")}
              </span>
              <span className="justify-end text-md">
                <FaTrash className=" size-4" />
              </span>
            </Button>
            <Button
              type="submit"
              variant="filled"
              color={status === "pending" ? "gray" : "green"}
              onClick={status === "success" ? handleOnCancel : null}
              disabled={status === "pending"}
              className={`flex justify-center  h-12 pt-4 ml-4 hover:bg-opacity-70  `}
            >
              <span className="justify-start max-sm:hidden mx-1 ">
                {t("addcatform.save")}
              </span>
              <span className="justify-end text-md">
                <FaSave className=" size-4" />
              </span>
            </Button>
          </div>
        )}
      </form>
    </motion.div>
  );
};

export default AddCatForm;
