import { useQuery } from "@tanstack/react-query";
import { createContext, useEffect } from "react";
import { getCategories } from "../services/HttpRequest/catRequest/catRequest";
import { useDispatch } from "react-redux";
import { categorySliceAction } from "../store/slice/categorySlice";
import { categorySliceAdminAction } from "../store/slice/categorySliceAdmin";

const CatContext = createContext({
  allCats: [],
  activeCat: {},
  statusCat: "",
  isError: false,
  error: {},
  handleAddAllCat: (catDatas) => {},
  handleAddActiveCat: (selectedCat) => {},
  handleDeleteCat: (catToRemove) => {},
  handleGetCatForAjob: (catIdOfJob) => {},
  handleListCatName: () => {
    return [];
  },
  numberOfCategories: 0,
});

export const CatContextProvider = ({ children }) => {
  const { data, status, error } = useQuery({
    queryKey: ["getCategories"],
    queryFn: getCategories,
  });
  const  dispatch = useDispatch()


  

  useEffect(() => {
    // -------------------dispatching action for the reducer --------------
    dispatch(categorySliceAction.fetchAllCategory({data,status,error}))
    dispatch(categorySliceAdminAction.fetchAllCategory({data,status,error}))
  }, [data,status,error,dispatch]);





  const values = {};

  return <CatContext.Provider value={values}>{children}</CatContext.Provider>;
};

export default CatContext;
