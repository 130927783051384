import React from "react";
import DailyItem from "./DailyItem/index";
import MonthlyItem from "./MonthlyItem/MonthlyData";

const DashBord = () => {
  return (
    <div className="lg:w-[1280px] mx-auto [font-family:'Inter-Bold',Helvetica] mt-6">
      <DailyItem />
      <MonthlyItem />
    </div>
  );
};

export default DashBord;
