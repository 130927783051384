import React, { useState } from "react";
import "./style.css";
import { useTranslation } from "react-i18next";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const { t } = useTranslation();
  const [pageInput, setPageInput] = useState(currentPage);

  const handlePageInputChange = (e) => {
    setPageInput(e.target.value);
  };

  const handlePageInputSubmit = (e) => {
    e.preventDefault();
    const pageNumber = parseInt(pageInput, 10);
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
      onPageChange(pageNumber);
    } else {
      setPageInput(currentPage);
    }
  };

  return (
    <nav className="flex p-4 items-center mb-4 bg-white border border-[#457AA8] w-full pagination justify-between">
      <div className="flex justify-start">
        <button
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="page-link bg-white border rounded-lg p-2 border-[#D0D5DD] text-[#344054]"
          aria-label="Page précédente"
        >
          {t("pagination.previous")}
        </button>
      </div>

      <div className="flex justify-center">
        {t("pagination.page")} {currentPage} {t("pagination.of")} {totalPages}
      </div>

      <form onSubmit={handlePageInputSubmit} className="flex justify-center items-center">
        <input
          type="number"
          value={pageInput}
          onChange={handlePageInputChange}
          className="page-input border rounded-lg p-2 border-[#D0D5DD] text-[#344054]"
          style={{ width: "3rem", textAlign: "center" }}
        />
        <button
          type="submit"
          className="page-link bg-white border rounded-lg p-2 border-[#D0D5DD] text-[#344054]"
        >
          {t("pagination.go")}
        </button>
      </form>

      <div className="flex justify-end">
        <button
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="page-link bg-white border rounded-lg p-2 border-[#D0D5DD] text-[#344054]"
          aria-label="Page suivante"
        >
          {t("pagination.next")}
        </button>
      </div>
    </nav>
  );
};

export default Pagination;
