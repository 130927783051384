import React, { useState, useContext } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  FaUser,
  FaBriefcase,
  FaClipboardCheck,
  FaFolder,
  FaBuilding,
} from "react-icons/fa";

import UserManage from "./UsersManage/UsersManage";
import JobManage from "./JobManage/JobManage";
import AppliManage from "./AppliManage/AppliManage";
import CatManage from "./CatManage/CatManage";
import CompManager from "./CompManager/CompManager";
import AddJobContextProvider from "../../../context/AddJobContextProvider";
import AppliContext from "../../../context/AdminCandidacyContext/AppliContext";

import CompContext from "../../../context/CompContext";

import UserContext from "../../../context/AdminContext/UserContext";
import { selectCurrentUser } from "../../../store/slice/loginSlice";
import "./dottedBackground.css";
import "../../Header/style.css";
import { useTranslation } from "react-i18next";

const ManageBord = () => {
  const { t } = useTranslation();

  const currentUser = useSelector(selectCurrentUser);
  let role = currentUser.roles[0];
  const { numberOfUsers } = useContext(UserContext);

  const { numberOfCompagny } = useContext(CompContext);
  const numberOfJobs = useSelector((state) => state.jobsAdmin.numberOfJobs);
  const numberOfCategories = useSelector(
    (state) => state.categoryAdmin.numberOfCategories
  );

  const { appliForJob, filterJob, numberOfAppli } = useContext(AppliContext);
  const [activeTab, setActiveTab] = useState(() => {
    const storedTab = localStorage.getItem("activeTab");
    if (storedTab) return storedTab;

    // Initialiser l'onglet actif en fonction du rôle de l'utilisateur
    if (role === "Admin") return "users";
    if (role === "Manager") return "jobs";
    return "applications"; // ou une valeur par défaut si nécessaire
  });
  const tabData = [
    ...(role === "Admin"
      ? [
          {
            title: t("navigation.title1"),
            count: numberOfUsers,
            icon: <FaUser className="w-6 h-6" />,
            tabName: "users",
          },
        ]
      : []),
    {
      title: t("navigation.title2"),
      count: numberOfJobs,
      icon: <FaBriefcase className="w-6 h-6" />,
      tabName: "jobs",
    },
    {
      title: t("navigation.title3"),
      count: numberOfAppli,
      icon: <FaClipboardCheck className="w-6 h-6" />,
      tabName: "applications",
    },
    {
      title: t("navigation.title4"),
      count: numberOfCategories,
      icon: <FaFolder className="w-6 h-6" />,
      tabName: "categories",
    },
    {
      title: t("navigation.title5"),
      count: numberOfCompagny,
      icon: <FaBuilding className="w-6 h-6" />,
      tabName: "compagnie",
    },
  ];

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    localStorage.removeItem("activeAppli");
    localStorage.setItem("activeTab", tabName);
  };

  const getNavLinkClass = (tabName) => {
    return `flex text-[15px] items-center px-2 lg:p-2 sm:p-4 justify-center transition duration-300 ease-in-out w-full h-full ${
      activeTab === tabName ? "activeAdminBar font-bold" : ""
    }`;
  };

  const renderAdminTabContent = () => {
    switch (activeTab) {
      case "users":
        return <UserManage />;
      case "jobs":
        return (
          <AddJobContextProvider>
            <JobManage />
          </AddJobContextProvider>
        );
      case "applications":
        return <AppliManage />;
      case "categories":
        return <CatManage />;
      case "compagnie":
        return <CompManager />;
      default:
        return null;
    }
  };

  const renderManagerTabContent = () => {
    switch (activeTab) {
      case "jobs":
        return (
          <AddJobContextProvider>
            <JobManage />
          </AddJobContextProvider>
        );
      case "applications":
        return <AppliManage />;
      case "categories":
        return <CatManage />;
      case "compagnie":
        return <CompManager />;
      default:
        return null;
    }
  };

  const renderTabContent = () => {
    if (role === "Admin") {
      return renderAdminTabContent();
    } else if (role === "Manager") {
      return renderManagerTabContent();
    } else {
      return null;
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-[#F9F9F9]">
      <div className="py-4">
        <div className="bg-[#EAF0F3] shadow-md border-2 sm:w-100 rounded-md flex items-center justify-center">
          <nav className="w-full">
            <div className="flex items-center justify-center mx-auto h-full w-full">
              <div className="flex items-center md:space-x-8 lg:space-x-8 sm:space-x-5 w-full font-semibold">
                {tabData.map((item, index) => (
                  <NavLink
                    key={index}
                    onClick={() => handleTabClick(item.tabName)}
                    className={getNavLinkClass(item.tabName)}
                  >
                    <span
                      className={`mr-2 md:inline ${
                        activeTab === item.tabName
                          ? "text-white"
                          : "text-gray-500"
                      }`}
                    >
                      {item.icon}
                    </span>
                    <span className="hidden md:inline">{item.title}</span>
                    <span
                      className={`ml-2 md:inline ${
                        activeTab === item.tabName ? "" : ""
                      }`}
                    >
                      {item.count}
                    </span>
                  </NavLink>
                ))}
              </div>
            </div>
          </nav>
        </div>
      </div>

      <div className="flex-grow w-full">{renderTabContent()}</div>
    </div>
  );
};

export default ManageBord;
