import { Menu } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";

export const CatItem = ({ cat, activeCatId, onActiveCat, classname }) => {
  const [isActive, setIsactive] = useState(false);

  const handleSelectCat = (id) => {
    onActiveCat(id);
  };

  useEffect(() => {
    setIsactive(cat.id === activeCatId);
  }, [activeCatId, cat.id]);

  return (
    <Menu.Item
      as={"li"}
      onClick={() => handleSelectCat(cat.id)}
      className={`flex py-2 overflow-x-hidden h-auto  justify-between font-[500] ${
        isActive && "bg-slate-100"
      }  ${classname} hover:bg-[#edeff0]  items-center px-2`}
    >
      <span>{cat.categoryName}</span>
      <span>
        {isActive && <FaCheck size={15} className={`text-[#EA580C] `} />}
      </span>
    </Menu.Item>
  );
};
